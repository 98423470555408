import { Validators } from '@angular/forms';
import { ConfigurationType, GsmKey, NetworkKey, WifiKey } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { FormInputType, SettingsFormData } from '../../components';
import { Conditions } from '../../utils';
import { WallboxProductCodeRegex } from '../../utils/WallboxRegexes';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

export const wifiServerHideConditions: Conditions = {
  operator: 'or',
  conditions: [
    {
      operator: 'or',
      negate: true,
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.P30_HAS_WIFI_MODULE },
        {
          condition: 'productCode',
          value: WallboxProductCodeRegex.P40_HAS_WIFI_MODULE,
        },
      ],
    },
    { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
  ],
};

export const wifiServerPasswordHideConditions: Conditions = {
  operator: 'or',
  conditions: [
    {
      operator: 'or',
      negate: true,
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.P30_HAS_WIFI_MODULE },
        {
          condition: 'productCode',
          value: WallboxProductCodeRegex.P40_HAS_WIFI_MODULE,
        },
      ],
    },
    { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
  ],
};

export const gsmHideConditions: Conditions = {
  operator: 'or',
  conditions: [
    {
      operator: 'or',
      negate: true,
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.P30_HAS_GSM_MODULE },
        { condition: 'productCode', value: WallboxProductCodeRegex.P40_HAS_GSM_MODULE },
      ],
    },
    { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
  ],
};

export const networkConnectionFormDefinition: SettingsFormData[] = [
  {
    pageNoteDescription: 'kwa.p40.network-note',
    pageNoteDescriptionPopup: {
      title: 'kwa.p40.network-note-popup.title',
      text: 'kwa.p40.network-note-popup.text',
      mediaUrl: 'kwa.p40.network-note-popup.media-url',
    },
    elements: [
      {
        key: NetworkKey.ENABLE_DHCP_SERVER,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_network_connection_form_local_dhcp_server_description',
        title: 'kwa_lbl_settings_network_connection_form_local_dhcp_server_title',
        configurationType: ConfigurationType.NETWORK,
        configurationPropertyKey: NetworkKey.ENABLE_DHCP_SERVER,
        rebootRequired: true,
        hideConditions: { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        accessibilityId: 'dhcp-server',
      },
      {
        key: GsmKey.ENABLE_GSM,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_network_connection_form_local_mobile_communication_description',
        title: 'kwa_lbl_settings_network_connection_form_local_mobile_communication_title',
        configurationType: ConfigurationType.GSM,
        listenOnChanges: true,
        hideConditions: gsmHideConditions,
        rebootRequired: true,
        accessibilityId: 'gsm',
      },
      // depending on GsmKey.ENABLE_GSM: start
      {
        key: GsmKey.APN_NAME,
        type: FormInputType.TEXT,
        description: 'kwa_lbl_settings_network_connection_form_apn_description',
        title: 'kwa_lbl_settings_network_connection_form_apn_title',
        placeholder: 'kwa_lbl_settings_network_connection_form_apn_placeholder',
        configurationType: ConfigurationType.GSM,
        hideElement: true,
        showDependentOnOtherSetting: { [GsmKey.ENABLE_GSM]: 'true' },
        hideConditions: gsmHideConditions,
        rebootRequired: true,
        accessibilityId: 'apn-name',
      },
      {
        key: GsmKey.APN_USERNAME,
        type: FormInputType.TEXT,
        description: 'kwa_lbl_settings_network_connection_form_apn_user_name_description',
        title: 'kwa_lbl_settings_network_connection_form_apn_user_name_title',
        configurationType: ConfigurationType.GSM,
        hideElement: true,
        showDependentOnOtherSetting: { [GsmKey.ENABLE_GSM]: 'true' },
        hideConditions: gsmHideConditions,
        rebootRequired: true,
        accessibilityId: 'apn-user-name',
      },
      {
        key: GsmKey.APN_PASSWORD,
        type: FormInputType.PASSWORD,
        description: 'kwa_lbl_settings_network_connection_form_apn_password_description',
        title: 'kwa_lbl_settings_network_connection_form_apn_password_title',
        configurationType: ConfigurationType.GSM,
        hideElement: true,
        showDependentOnOtherSetting: { [GsmKey.ENABLE_GSM]: 'true' },
        hideConditions: gsmHideConditions,
        rebootRequired: true,
        accessibilityId: 'apn-password',
      },
      {
        key: GsmKey.SIM_PIN,
        type: FormInputType.PASSWORD,
        description: 'kwa_lbl_settings_network_connection_form_sim_pin_description',
        title: 'kwa_lbl_settings_network_connection_form_sim_pin_title',
        configurationType: ConfigurationType.GSM,
        hideElement: true,
        showDependentOnOtherSetting: { [GsmKey.ENABLE_GSM]: 'true' },
        hideConditions: gsmHideConditions,
        accessibilityId: 'sim-pin',
      },
      // depending on GsmKey.ENABLE_GSM: end
      {
        key: WifiKey.ENABLE_WIFI_CLIENT,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_network_connection_form_wifi_client_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_client_title',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.ENABLE_WIFI_CLIENT,
        hideConfiguration: [WifiKey.ENABLE_WIFI_SERVER],
        listenOnChanges: true,
        hideConditions: wifiServerHideConditions,
        accessibilityId: 'wifi-client',
      },
      // depending on WifiKey.ENABLE_WIFI_CLIENT: start
      {
        key: WifiKey.CLIENT_SSID,
        type: FormInputType.TEXT,
        description: 'kwa_lbl_settings_network_connection_form_wifi_client_ssid_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_client_ssid_title',
        placeholder: 'kwa_lbl_settings_network_connection_form_apn_placeholder',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.CLIENT_SSID,
        hideElement: true,
        showDependentOnOtherSetting: { [WifiKey.ENABLE_WIFI_CLIENT]: 'true' },
        hideConditions: wifiServerHideConditions,
        accessibilityId: 'client-ssd',
        minLength: 1,
        maxLength: 32,
        validators: [Validators.minLength(1), Validators.maxLength(32), Validators.required],
      },
      {
        key: WifiKey.CLIENT_PASSWORD,
        type: FormInputType.SECRET_PASSWORD,
        description: 'kwa_lbl_settings_network_connection_form_wifi_client_ssid_password_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_client_ssid_password_title',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.CLIENT_PASSWORD,
        hideElement: true,
        showDependentOnOtherSetting: { [WifiKey.ENABLE_WIFI_CLIENT]: 'true' },
        hideConditions: wifiServerPasswordHideConditions,
        preventReadCallConditions: { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        accessibilityId: 'secret-password',
        minLength: 8,
        maxLength: 32,
        validators: [Validators.minLength(8), Validators.maxLength(32), Validators.required],
        fakeValue: '********',
      },
      // depending on WifiKey.ENABLE_WIFI_CLIENT: end
      {
        key: WifiKey.ENABLE_WIFI_SERVER,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_network_connection_form_wifi_server_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_server_title',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.ENABLE_WIFI_SERVER,
        hideConfiguration: [WifiKey.ENABLE_WIFI_CLIENT],
        listenOnChanges: true,
        hideConditions: wifiServerHideConditions,
        accessibilityId: 'wifi-server',
      },
      // depending on WifiKey.ENABLE_WIFI_SERVER: start
      {
        key: WifiKey.SERVER_SSID,
        type: FormInputType.TEXT,
        description: 'kwa_lbl_settings_network_connection_form_wifi_server_ssid_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_server_ssid_title',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.SERVER_SSID,
        hideElement: true,
        showDependentOnOtherSetting: { [WifiKey.ENABLE_WIFI_SERVER]: 'true' },
        hideConditions: wifiServerHideConditions,
        accessibilityId: 'server-ssid',
      },
      {
        key: WifiKey.SERVER_PASSWORD,
        type: FormInputType.PASSWORD,
        description: 'kwa_lbl_settings_network_connection_form_wifi_server_password_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_server_password_title',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.SERVER_PASSWORD,
        hideElement: true,
        showDependentOnOtherSetting: { [WifiKey.ENABLE_WIFI_SERVER]: 'true' },
        hideConditions: wifiServerPasswordHideConditions,
        preventReadCallConditions: { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        minLength: 8,
        maxLength: 63,
        hasUpperCase: true,
        hasDigit: true,
        hasLowerCase: true,
        hasSpecialChar: true,
        validators: [Validators.minLength(8), Validators.maxLength(63)],
        accessibilityId: 'server-password',
        fakeValue: '********',
      },
      {
        key: WifiKey.SERVER_CHANNEL,
        type: FormInputType.SELECT,
        description: 'kwa_lbl_settings_network_connection_form_wifi_server_channel_description',
        title: 'kwa_lbl_settings_network_connection_form_wifi_server_channel_title',
        configurationType: ConfigurationType.WIFI,
        configurationPropertyKey: WifiKey.SERVER_CHANNEL,
        selectOptions: [
          { key: '1', text: '1', accessibilityId: 'select-server-1' },
          { key: '2', text: '2', accessibilityId: 'select-server-2' },
          { key: '3', text: '3', accessibilityId: 'select-server-3' },
          { key: '4', text: '4', accessibilityId: 'select-server-4' },
          { key: '5', text: '5', accessibilityId: 'select-server-5' },
          { key: '6', text: '6', accessibilityId: 'select-server-6' },
          { key: '7', text: '7', accessibilityId: 'select-server-7' },
          { key: '8', text: '8', accessibilityId: 'select-server-8' },
          { key: '9', text: '9', accessibilityId: 'select-server-9' },
          { key: '10', text: '10', accessibilityId: 'select-server-10' },
          { key: '11', text: '11', accessibilityId: 'select-server-11' },
        ],
        hideElement: true,
        showDependentOnOtherSetting: { [WifiKey.ENABLE_WIFI_SERVER]: 'true' },
        hideConditions: wifiServerHideConditions,
        accessibilityId: 'server-channel',
      },
      // depending on WifiKey.ENABLE_WIFI_SERVER: end
    ],
  },
];

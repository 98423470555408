import { Validators } from '@angular/forms';
import { ConfigurationType, ExtMeterKey, LmgmtKey } from '@keba-wallbox-plugin/plugin';
import { FormInputType, SettingsFormData } from '../../components';
import { FirmwareVersions, SettingsUnitsConverter, WallboxProductCodeRegex } from '../../utils';
import { WallboxSettingsPageType } from './wallbox-settings-page-type';

export const pvFormDefinition: SettingsFormData[] = [
  {
    description: 'kwa.settings.pv.pre-condition-phase-switch.description',
    deepLink: {
      title: 'kwa.settings.pv.pre-condition-phase-switch.deeplink',
      link: WallboxSettingsPageType.PHASE_SWITCH_SETTINGS,
      showOnCondition: { [LmgmtKey.CONNECTOR_PHASE_ENABLED]: 'true' },
    },
    hideSeparator: true,
    elements: [],
    hideConditions: {
      operator: 'and',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_2_0 },
      ],
    },
  },
  {
    description: 'kwa.settings.pv.pre-condition-tcp-meter.description',
    deepLink: {
      title: 'kwa.settings.pv.pre-condition-tcp-meter.deeplink',
      link: WallboxSettingsPageType.EXTERNAL_TCP_METER,
      showOnCondition: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
    },
    elements: [
      {
        key: LmgmtKey.PV_ENABLE,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.TOGGLE,
        title: 'kw.settings.pv.enable.title',
        description: 'kw.settings.pv.enable.description',
        listenOnChanges: true,
        accessibilityId: 'pv-enable',
      },
      {
        key: LmgmtKey.PV_MIN_SHARE,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.NUMBER,
        description: 'kw.settings.pv.min-share.description',
        title: 'kw.settings.pv.min-share.title',
        hideElement: true,
        showDependentOnOtherSetting: { [LmgmtKey.PV_ENABLE]: 'true' },
        accessibilityId: 'pv-min-share',
        min: 0,
        max: 100,
        validators: [Validators.required, Validators.min(0), Validators.max(100)],
      },
      {
        key: LmgmtKey.PV_PRE_CHARGE_TIME,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.NUMBER,
        description: 'kw.settings.pv.pv-pre-charge-time.description',
        title: 'kw.settings.pv.pv-pre-charge-time.title',
        hideElement: true,
        convertToDisplayUnit: SettingsUnitsConverter.secondsToMinutes,
        convertFromDisplayUnit: SettingsUnitsConverter.minutesToSeconds,
        showDependentOnOtherSetting: { [LmgmtKey.PV_ENABLE]: 'true' },
        accessibilityId: 'pv-pre-charge-time',
        min: 0,
        max: 1440,
        validators: [Validators.required, Validators.min(0), Validators.max(1440)], // 0-6h = 0-360 minutes
      },
      {
        key: LmgmtKey.PV_IGNORE_X1,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.TOGGLE,
        description: 'kw.settings.pv.pv-ignore-x1.description',
        title: 'kw.settings.pv.pv-ignore-x1.title',
        hideElement: true,
        hideConditions: { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        showDependentOnOtherSetting: { [LmgmtKey.PV_ENABLE]: 'true' },
        accessibilityId: 'pv-ignore-x1',
      },
      {
        key: LmgmtKey.PV_THRESHOLD_IMPORT,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.NUMBER,
        description: 'kw.settings.pv.pv-threshold-import.description',
        title: 'kw.settings.pv.pv-threshold-import.title',
        hideElement: true,
        convertToDisplayUnit: SettingsUnitsConverter.milliWattToWatt,
        convertFromDisplayUnit: SettingsUnitsConverter.wattToMilliWatt,
        showDependentOnOtherSetting: { [LmgmtKey.PV_ENABLE]: 'true' },
        accessibilityId: 'pv-threshold-import',
        min: 100,
        max: 2000,
        validators: [Validators.required, Validators.min(100), Validators.max(2000)], // 100-2000 W
      },
      {
        key: LmgmtKey.PV_THRESHOLD_EXPORT,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.NUMBER,
        description: 'kw.settings.pv.pv-threshold-export.description',
        title: 'kw.settings.pv.pv-threshold-export.title',
        hideElement: true,
        convertToDisplayUnit: SettingsUnitsConverter.milliWattToWatt,
        convertFromDisplayUnit: SettingsUnitsConverter.wattToMilliWatt,
        showDependentOnOtherSetting: { [LmgmtKey.PV_ENABLE]: 'true' },
        accessibilityId: 'pv-threshold-export',
        min: 100,
        max: 2000,
        validators: [Validators.required, Validators.min(100), Validators.max(2000)], // 100-2000 W
      },
      {
        key: LmgmtKey.PV_DELAY,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.NUMBER,
        description: 'kw.settings.pv.pv-delay.description',
        title: 'kw.settings.pv.pv-delay.title',
        hideElement: true,
        convertToDisplayUnit: SettingsUnitsConverter.secondsToMinutes,
        convertFromDisplayUnit: SettingsUnitsConverter.minutesToSeconds,
        showDependentOnOtherSetting: { [LmgmtKey.PV_ENABLE]: 'true' },
        accessibilityId: 'pv-delay',
        min: 3,
        max: 20,
        validators: [Validators.required, Validators.min(3), Validators.max(20)],
      },
    ],
  },
];

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormat, FormatPipe } from 'libs/common/pipes';

@Component({
    selector: 'kw-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SliderComponent implements OnInit {
  @Input() defaultValue: number;
  @Input() max: number;
  @Input() min: number;
  @Input() format: BaseFormat;
  @Input() formatMax?: number;
  @Input() unit: string;
  @Input() disabled?: boolean;
  @Input() possibleValues?: number[];
  @Input() accessibilityId?: string;

  @Output() valueChange = new EventEmitter<number>();

  public currentValue: number;

  constructor(private formatPipe: FormatPipe) {}

  ngOnInit(): void {
    this.currentValue = this.formatPipe.transform(this.defaultValue, this.format, this.formatMax) as number;
  }

  public hasDiscreteValues() {
    return this.possibleValues && this.possibleValues.length > 0;
  }

  public onSliderChange(event: any): void {
    if (!this.disabled) {
      let value = Number(event.target.value);
      if (this.hasDiscreteValues()) {
        value = this.possibleValues?.[value] ?? 0;
      }

      this.currentValue = this.formatPipe.transform(value, this.format, this.formatMax) as number;
      this.valueChange.emit(value);
    }
  }

  public getDiscreteValueIndex(value: number): number {
    return this.possibleValues?.findIndex(v => v === value) ?? 0;
  }
}

import { WallboxProductCodeRegex } from '@kw-shared/common';
import { WallboxConnectionType } from '../../../definitions';

export interface MoreMenu {
  key: string;
  icon?: string;
  title?: string;
  color?: string;
  hideForConnectionTypes?: WallboxConnectionType[];
  hideForProductCode?: WallboxProductCodeRegex;
  isHidden?: boolean;
}

import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService, Utils } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { nth } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiDataAccessStorageUtils } from '../../../utils';

export type RemoteHttpError = {
  code: string;
  data?: { key: any; value: any }[];
};

export enum RemoteAction {
  TRANSACTION_START = 'TRANSACTION_START',
  TRANSACTION_STOP = 'TRANSACTION_STOP',
  PV_BOOST_START = 'PV_BOOST_START',
  PV_BOOST_CANCEL = 'PV_BOOST_CANCEL',
  CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION',
}

// skip remote actions because they are handled in the app
export const skippedRemoteActions = [RemoteAction.CHANGE_CONFIGURATION];

export enum RemoteErrorCode {
  // status code
  NOT_REACHABLE = 404,

  // custom error
  NOT_SUCCESSFUL = 400,
  UNKNOWN_ERROR = 500,
  NO_RESPONSE = 503,
  TIMEOUT = 504,
}

export const WALLBOX_NAME = new HttpContextToken(() => undefined);

export function contextWallboxName(): HttpContext | undefined {
  const wallboxName = ApiDataAccessStorageUtils.getSelectedWallboxName();
  return wallboxName ? new HttpContext().set(WALLBOX_NAME, wallboxName) : undefined;
}

@Injectable()
export class OcppResponseInterceptor implements HttpInterceptor {
  constructor(
    private readonly toastMessage: ToastMessageService,
    private readonly translateService: TranslateService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const wallboxName = request.context.get(WALLBOX_NAME);

    return next.handle(request).pipe(
      catchError(error => {
        const { method } = request;
        if (method === 'GET') {
          return throwError(() => error);
        }

        const remoteError: RemoteHttpError = error.error;
        const remoteErrorCode = remoteError?.code as string; // Remote Error Code example: "PORTAL.ACTION.SPECIFIC_ERROR"

        const errorMessage: string[] = [];
        const splitErrorCodes = remoteErrorCode?.split('.');

        if (skippedRemoteActions.includes(nth(splitErrorCodes, 1) as RemoteAction)) {
          return throwError(() => error);
        }

        if (remoteErrorCode?.includes('PORTAL.')) {
          splitErrorCodes?.forEach((value, index) => {
            if (value === 'PORTAL') {
              return;
            }

            if (index === 1) {
              // add RemoteAction f.e: TRANSACTION_START
              const errorData = this.handleErrorData(remoteError);
              const actionMessage = this.handleAction(value as RemoteAction, wallboxName, errorData);
              errorMessage.push(actionMessage);
            }
          });
        }

        // no session from BE exists but error should be shown to user
        if (wallboxName && error.status in RemoteErrorCode && splitErrorCodes?.length === 1) {
          errorMessage.push(this.handleStatusCode(error.status));
        }

        // add messageId
        const remoteErrorMessage = this.handleMessageId(remoteError);
        if (remoteErrorMessage) {
          errorMessage.push(remoteErrorMessage);
        }

        if (errorMessage.length) {
          this.toastMessage.setError(errorMessage.join(' - '), !!errorMessage.length, {
            closeButton: true,
            tapToDismiss: false,
            disableTimeOut: true,
          });
        }

        return throwError(() => error);
      })
    );
  }

  private handleAction(action: RemoteAction, wallboxName?: string, errorCodes?: string): string {
    const actionDescription = Utils.handleAction(action);
    return this.translateService.instant(actionDescription, {
      wallboxName,
      errorCodes,
    });
  }

  private handleStatusCode(statusCode: RemoteErrorCode): string {
    if (statusCode in RemoteErrorCode) {
      // remoteError is already defined
      return this.translateService.instant('kw.remote-error.error-code.' + statusCode);
    }

    // remoteError is not defined - add translation key!
    return String(statusCode);
  }

  private handleMessageId(remoteError?: RemoteHttpError): string | void {
    const messageId: string = remoteError?.data?.find(d => d.key === 'messageId')?.value;
    if (messageId) {
      return this.translateService.instant('kw.remote-error.message-id') + ' ' + messageId;
    }
  }

  private handleErrorData(remoteError?: RemoteHttpError): string | undefined {
    return Utils.handleErrorData(remoteError);
  }
}

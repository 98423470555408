import { Pipe, PipeTransform } from '@angular/core';
import { RemoteWallboxFirmwareUpdateVersion } from '../models';

@Pipe({
    name: 'remoteWallboxFirmwareUpdateVersion',
    pure: true,
    standalone: false
})
export class RemoteWallboxFirmwareUpdateVersionPipe implements PipeTransform {
  transform(value?: RemoteWallboxFirmwareUpdateVersion): string {
    if (value) {
      return `${value.major ?? 0}.${value.minor ?? 0}.${value.patch ?? 0}`;
    }
    return '-';
  }
}

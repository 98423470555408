export enum AdditionalLocalWallboxState {
  REQUIRES_PASSWORD = 'REQUIRES_PASSWORD',
  DEVICE_NOT_REACHABLE = 'DEVICE_NOT_REACHABLE',
}

export enum LocalWallboxState {
  REST_IDLE = 'IDLE',
  REST_READY_FOR_CHARGING = 'READY_FOR_CHARGING',
  REST_CHARGING = 'CHARGING',
  REST_SUSPENDED = 'SUSPENDED',
  REST_RECOVER_FROM_ERROR = 'RECOVER_FROM_ERROR',
  REST_UNRECOVERABLE_ERROR = 'UNRECOVERABLE_ERROR',
  REST_SERVICE_MODE = 'SERVICE_MODE',
  REST_TOKEN_PROGRAMMING_MODE = 'TOKEN_PROGRAMMING_MODE',
  REST_UNAVAILABLE = 'UNAVAILABLE',
  REST_OFFLINE = 'OFFLINE',
  REST_DEGRADED = 'DEGRADED',
  REQUIRES_PASSWORD = 'REQUIRES_PASSWORD',
  DEVICE_NOT_REACHABLE = 'DEVICE_NOT_REACHABLE',
  UDP_STARTUP = 'UDP_STARTUP',
  UDP_NOT_READY_FOR_CHARGING = 'UDP_NOT_READY_FOR_CHARGING',
  UDP_READY_FOR_CHARGING = 'UDP_READY_FOR_CHARGING',
  UDP_CHARGING = 'UDP_CHARGING',
  UDP_ERROR_IS_PRESENT = 'UDP_ERROR_IS_PRESENT',
  UDP_TEMPORARILY_INTERRUPTION = 'UDP_TEMPORARILY_INTERRUPTION',
}

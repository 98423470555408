import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localeNumber',
})
export class LocaleDecimalPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private translateService: TranslateService) {}

  public transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    return this.decimalPipe.transform(value, digitsInfo, locale ?? this.translateService.currentLang);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Account } from '../../../../models';
import { FakeRepository } from '../base/FakeRepository';
import { IAccountRepository } from './IAccountRepository';

export class FakeAccountRepository extends FakeRepository<Account> implements IAccountRepository {
  constructor() {
    super([
      { id: 'a-1', name: 'Smart energy GmbH', userId: '' },
      { id: 'a-2', name: 'Clean energy GmbH', userId: '' },
      { id: 'a-3', name: 'No energy OG', userId: '' },
      { id: 'a-4', name: 'A energy OG', userId: '' },
    ]);
  }

  public deleteAccount(accountId: string, force?: boolean): Observable<void> {
    if (force) {
      return this.delayResponse(of(void 0));
    }
    return this.delayResponse(
      throwError(
        () =>
          new HttpErrorResponse({
            status: 409,
          })
      )
    );
  }

  public leaveAccount(accountId: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public saveName(accountId: string, name: string): Observable<void> {
    return this.update({ id: accountId, name });
  }

  public getOwnedAccountsForUser(email: string): any {
    return this.delayResponse(of(''));
  }

  public getAdminsOfAccount(accountId: string): any {
    return this.delayResponse(of(''));
  }

  public migrateOwnership(accountId: string, newOwnerId: string, oldOwnerId: string): any {
    return this.delayResponse(of(''));
  }
}

import { Capacitor } from '@capacitor/core';
import {
  RemoteWallbox,
  RemoteWallboxConnectionStatus,
  RemoteWallboxState,
  WallboxDiagnosticsStatus,
} from '@kw-shared/api-data-access';
import { RemoteWallboxConfig } from 'apps/keba-wallbox-app/src/app/services/current-wallbox-config.service';
import NativeLinks from '../../../apps/keba-wallbox-app/src/plugins/native-links/NativeLinks.interface';
import { WallboxConnectionType } from '../definitions';
import { WallboxBaseType, WallboxData, WallboxProductType } from '../models';
import { WallboxProductCodeRegex } from './WallboxRegexes';

export abstract class WallboxUtils {
  private static readonly P30_INDICATORS_REGEX = /.....(.).-.../;
  private static readonly P30_X_SERIES_INDICATORS: string = 'BCEGHSU';
  private static readonly P30_C_SERIES_INDICATORS: string = '2';
  private static readonly P30_AB_SERIES_INDICATORS: string = '13';

  public static getWallboxBaseTypeByProductType(model?: string): WallboxBaseType | null | undefined {
    const productType = WallboxUtils.getProductType(model);
    if (productType === null) {
      return null;
    } else if (productType) {
      return productType.baseType;
    }
    return undefined;
  }

  public static getProductType(model?: string): WallboxProductType | undefined | null {
    if (model) {
      if (model.match(WallboxProductCodeRegex.IS_P30)) {
        const match = model.match(WallboxUtils.P30_INDICATORS_REGEX);
        if (match && match.length > 1) {
          const indicator: string = match[1]?.toUpperCase();
          if (WallboxUtils.P30_C_SERIES_INDICATORS.includes(indicator)) {
            // C-Series
            return { baseType: WallboxBaseType.P30_C_SERIES, subType: indicator };
          } else if (WallboxUtils.P30_AB_SERIES_INDICATORS.includes(indicator)) {
            // KfW A- or B-Series
            if (model.length === 14 || model.length === 22) {
              if (model.endsWith('DE')) {
                return { baseType: WallboxBaseType.P30_C_SERIES, subType: indicator };
              } else {
                // return error (null) only when user inserted all characters
                return null;
              }
            }
            // detection for KfW is still ongoing
            return undefined;
          } else if (WallboxUtils.P30_X_SERIES_INDICATORS.includes(indicator)) {
            // X-Series
            return { baseType: WallboxBaseType.P30_X_SERIES, subType: indicator };
          }
          // model format is correct, but type can't be determined
          return null;
        }
      } else if (model.match(WallboxProductCodeRegex.IS_P40)) {
        return { baseType: WallboxBaseType.P40, subType: '' };
      } else if (model.match(WallboxProductCodeRegex.IS_M20)) {
        return { baseType: WallboxBaseType.M20, subType: '' };
      }
    }
    return undefined;
  }

  public static getWallboxCommunicationIconByConnectionType(connectionType?: WallboxConnectionType) {
    switch (connectionType) {
      case WallboxConnectionType.LOCAL_REST:
        return 'assets/icons/32/connect-wlan.svg';
      case WallboxConnectionType.LOCAL_UDP:
        return 'assets/icons/32/connect-wlan.svg';
      case WallboxConnectionType.LOCAL_BLE:
        return 'assets/icons/32/bluetooth.svg';
      case WallboxConnectionType.REMOTE:
        return 'assets/icons/32/remote.svg';

      default:
        return 'assets/icons/32/connect-wlan.svg';
    }
  }

  public static getWallboxCommunicationAccessibilityIdByConnectionType(connectionType?: WallboxConnectionType) {
    const attribute = 'kw-test__home__page-home__icon-connection-type-';

    switch (connectionType) {
      case WallboxConnectionType.LOCAL_REST:
        return attribute + connectionType.toLocaleLowerCase();
      case WallboxConnectionType.LOCAL_UDP:
        return attribute + connectionType.toLocaleLowerCase();
      case WallboxConnectionType.LOCAL_BLE:
        return attribute + connectionType.toLocaleLowerCase();
      case WallboxConnectionType.REMOTE:
        return attribute + connectionType.toLocaleLowerCase();

      default:
        return attribute + 'unknown';
    }
  }

  public static checkProductCode(productCode: string | undefined, regExp?: RegExp): boolean {
    if (regExp && productCode) {
      return !!regExp?.test(productCode);
    }
    return false;
  }

  public static setRemoteWallboxStateBasedOnConnectionStatus(
    connectionStatus: RemoteWallboxConnectionStatus,
    wbStatus?: RemoteWallboxState
  ): RemoteWallboxState {
    let status = wbStatus ?? RemoteWallboxState.OFFLINE;

    if (connectionStatus === RemoteWallboxConnectionStatus.DISCONNECTED) {
      status = RemoteWallboxState.OFFLINE;
    }

    if (connectionStatus === RemoteWallboxConnectionStatus.CONNECTED && !wbStatus) {
      status = RemoteWallboxState.ONLINE;
    }

    return status;
  }

  public static findDeviceBySerial(deviceData: WallboxData, accountDevices: RemoteWallbox[]): RemoteWallbox | null {
    const wallboxSerialNumber = deviceData.serialNumber;

    if (!wallboxSerialNumber) {
      return null;
    }

    const matchingDevices = accountDevices.filter(device => device.serialNumber === wallboxSerialNumber);

    if (matchingDevices.length === 0) {
      return null;
    }

    // Sort the matching devices by `lastSeenConnected` in descending order
    matchingDevices.sort((a, b) => {
      const timeA = a.lastSeenConnected ? new Date(a.lastSeenConnected).getTime() : 0;
      const timeB = b.lastSeenConnected ? new Date(b.lastSeenConnected).getTime() : 0;
      return timeB - timeA;
    });

    return matchingDevices[0];
  }

  public static async onGoToSettingsClick(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await NativeLinks.openAppSettings();
    }
  }

  public static isDiagnosticsExportFailureStatus(status: WallboxDiagnosticsStatus): boolean {
    const failureStatuses = [
      WallboxDiagnosticsStatus.BadMessage,
      WallboxDiagnosticsStatus.Idle,
      WallboxDiagnosticsStatus.NotSupportedOperation,
      WallboxDiagnosticsStatus.PermissionDenied,
      WallboxDiagnosticsStatus.UploadFailure,
    ];
    return failureStatuses.includes(status);
  }

  public static isMasterWallbox(wallbox: RemoteWallbox | RemoteWallboxConfig): boolean {
    return wallbox?.masterChargePointId == null;
  }

  public static isClientWallbox(wallbox: RemoteWallbox | RemoteWallboxConfig): boolean {
    return wallbox?.masterChargePointId !== null;
  }

  public static hasMasterWallboxClients(wallbox: RemoteWallbox | RemoteWallboxConfig): boolean {
    return wallbox?.hasClients ?? false;
  }

  public static hasClientChargePoints(wallbox: RemoteWallbox | RemoteWallboxConfig): boolean {
    return wallbox?.clientChargePoints ? wallbox.clientChargePoints.length >= 1 : false;
  }
}

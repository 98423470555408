import { Enrollment, EnrollmentType } from 'libs/api-data-access/models';
import { Observable, of } from 'rxjs';
import { IEnrollmentRepository } from './IEnrollmentRepository';

export class FakeEnrollmentRepository implements IEnrollmentRepository {
  public getEnrollmentCredentialsP30(accountId: string): Observable<Enrollment> {
    return of({
      accountId: accountId,
      enrollmentToken: "testtoken",
      enrollmentType: EnrollmentType.P30_ENROLLMENT,
      validUntil: new Date(),
    });
  }

  public getEnrollmentCredentialsP40(accountId: string): Observable<Enrollment> {
    return of({
      accountId: accountId,
      enrollmentToken: "testtoken",
      wboxAuthKey: "34453634646456456545F23",
      enrollmentType: EnrollmentType.P40_ENROLLMENT,
      validUntil: new Date(),
    });
  }
}

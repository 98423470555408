<div class="flex items-center w-full mr-auto truncate shrink">
  <span class="w-8 h-8 mr-2 fill-current" [inlineSVG]="'assets/icons/32/' + icon + '.svg'"></span>
  <div class="w-full font-light truncate" [ngClass]="entryNameClass">
    <ng-content select="[title]"></ng-content>
  </div>
</div>

<div class="font-medium text-right grow whitespace-nowrap" [ngClass]="entryValueClass">
  <div class="preview">
    <ng-content select="[text]"></ng-content>
  </div>
</div>

import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventType } from '@azure/msal-browser';
import { ApiAuthService, ApiDataService, User } from '@kw-shared/api-data-access';
import { ToastMessageService, Utils } from '@kw-shared/common';
import { firstValueFrom, lastValueFrom, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PortalUnsubscribeRoutes } from '../../pages/unsubscribe/portal-unsubscribe-routes.definition';
import { PortalRoutes } from '../../portal-routes.definition';

@Injectable({
  providedIn: 'root',
})
export class PortalApiAuthService extends ApiAuthService {
  private readonly unauthenticatedRoutes: string[] = [
    Utils.joinPaths(PortalRoutes.UNSUBSCRIBE, PortalUnsubscribeRoutes.UNSUBSCRIBE_ACR),
  ];

  private msAccountId?: string;
  private interactionLock: boolean = false; // Lock to prevent concurrent interactions

  constructor(
    dataService: ApiDataService,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    toastMessageService: ToastMessageService
  ) {
    super(dataService, toastMessageService);
    this.msalBroadcastService.msalSubject$.subscribe(async event => {
      if (this.interactionLock) {
        return; // Skip processing if an interaction is in progress
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        await this.login();
        return;
      }

      const accounts: AccountInfo[] = this.msalService.instance.getAllAccounts();

      if (accounts?.length) {
        const account = accounts[0];

        if (event.eventType === EventType.LOGIN_SUCCESS && account.idTokenClaims?.['newUser']) {
          await lastValueFrom(this.dataService.user.updateADUser());
        }

        if (account.homeAccountId !== this.msAccountId) {
          this.msAccountId = account.homeAccountId;

          // avoid loading user for unauthenticated routes, since it triggers a redirect to the login page
          if (!this.unauthenticatedRoutes.some(route => location.href.includes(route))) {
            await this.loadUser();
          }
        }
      }
    });
  }

  public async login(suggestedEmail?: string): Promise<User | undefined> {
    if (this.interactionLock) {
      return; // Skip login if an interaction is in progress
    }

    try {
      this.interactionLock = true; // Set interaction lock
      await this.msalService.instance.handleRedirectPromise();
      const account = this.msalService.instance.getActiveAccount();
      if (!account) {
        await this.msalService.instance.loginRedirect({
          scopes: [environment.api.azureLoginConfig.scope],
          loginHint: suggestedEmail,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.interactionLock = false; // Reset interaction lock
    }

    return await this.loadUser();
  }

  public logout(): Promise<void> {
    return firstValueFrom(this.msalService.logoutRedirect());
  }

  public doSilentLoginIfPossible(): Observable<boolean> {
    return this.msalService
      .handleRedirectObservable()
      .pipe(map(() => this.msalService.instance.getAllAccounts().length > 0));
  }

  public getAccessToken(): Promise<{ accessToken: string }> {
    // Not required yet since it's triggered via the AuthGuard
    return Promise.resolve({ accessToken: '' });
  }
}

<div class="w-full mx-auto overflow-hidden md:p-4 kwa-container lg:mt-[15vh]">
  <div class="flex flex-col items-center justify-center h-full md:p-3">
    <div class="max-h-screen p-4 overflow-y-auto bg-white shadow-2xl custom-scrollbar md:rounded">
      <div class="flex justify-end w-full" (click)="close()">
        <span class="w-8 h-8 mb-3 cursor-pointer fill-black" inlineSVG="assets/icons/32/close.svg"></span>
      </div>
      <div class="pb-20 md:pb-8 lg:pb-16 md:px-8 lg:px-16">
        <h2 class="mb-10 font-light text-center">{{ 'kwp.login.how-to-delete-account.title' | translate }}</h2>
        <div class="flex flex-col max-w-4xl space-y-10 md:flex-row md:space-x-14 md:space-y-0">
          <div class="flex flex-col w-full md:w-1/2">
            <div class="flex-grow w-2/3 mx-auto mb-4 font-light text-center">
              {{ 'kwp.login.how-to-delete-account.steps.step-1.description' | translate }}
            </div>
            <img
              class="shadow-2xl"
              src="{{ 'kwp.login.how-to-delete-account.steps.step-1.image' | translate }}"
              alt="{{ 'kwp.login.how-to-delete-account.steps.step-1.description' | translate }}"
            />
          </div>
          <div class="flex flex-col w-full md:w-1/2">
            <div class="flex-grow w-2/3 mx-auto mb-4 font-light text-center">
              {{ 'kwp.login.how-to-delete-account.steps.step-2.description' | translate }}
            </div>
            <img
              class="shadow-2xl"
              src="{{ 'kwp.login.how-to-delete-account.steps.step-2.image' | translate }}"
              alt="{{ 'kwp.login.how-to-delete-account.steps.step-2.description' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

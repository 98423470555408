import { RemoteWallboxState } from '../../../api-data-access/models';
import { AdditionalActionCall, FormInputType, SettingsFormData } from '../../components';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

export const loggingFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: AdditionalActionCall.EXPORT_DIAGNOSTICS,
        type: FormInputType.BUTTON_PRIMARY,
        disabledForWallboxStates: [RemoteWallboxState.OFFLINE, RemoteWallboxState.UNAVAILABLE],
        title: 'kwa.settings.logging.request-diagnostics.title',
        hasDownloadButton: true,
        downloadTitle: 'kwa.settings.logging.export-diagnostics.title',
        additionalActionCallType: AdditionalActionCall.EXPORT_DIAGNOSTICS,
        accessibilityId: 'btn--export-diagnosis',
        hideTitle: true,
      },
      {
        key: AdditionalActionCall.EXPORT_SECURITY_LOG,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.logging.request-security-log.title',
        hasDownloadButton: true,
        downloadTitle: 'kwa.settings.logging.export-diagnostics.title',
        disabledForWallboxStates: [RemoteWallboxState.OFFLINE, RemoteWallboxState.UNAVAILABLE],
        additionalActionCallType: AdditionalActionCall.EXPORT_SECURITY_LOG,
        accessibilityId: 'btn--export-security-log',
        hideTitle: true,
        hideConditions: {
          operator: 'and',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
            // temporarely hidden for all remote wallboxes KEB-4194
            // { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
          ],
        },
      },
    ],
  },
];

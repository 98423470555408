import { StorageUtils, ToggleContext } from '@kw-shared/common';

export class ToggleContextProvider {
  static #instance: ToggleContextProvider;

  private ctx: ToggleContext;

  private constructor() {}

  public static get instance(): ToggleContextProvider {
    if (!ToggleContextProvider.#instance) {
      ToggleContextProvider.#instance = new ToggleContextProvider();
    }

    return ToggleContextProvider.#instance;
  }

  public get context() {
    return this.ctx;
  }

  public initFromStorage() {
    let ctxFromLocalStorage = StorageUtils.get<string>('toggle-context');
    if (ctxFromLocalStorage) {
      this.ctx = JSON.parse(ctxFromLocalStorage);
    }
  }

  public updateContext(context: ToggleContext): void {
    this.ctx = { ...this.ctx, ...context };
    StorageUtils.set<string>('toggle-context', JSON.stringify(this.ctx));
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LmgmtKey } from '@keba-wallbox-plugin/plugin';
import { WallboxNameRegex, WallboxProductCodeRegex } from './WallboxRegexes';

export abstract class SettingsValidators {
  public static readonly ipAddressValidator: ValidatorFn = Validators.pattern(
    '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
  );

  public static readonly numberValidator: ValidatorFn = Validators.pattern('^[0-9]*$');

  public static readonly maxAsymPhaseCurrentValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // Get the current value of MAX_ASYM_PHASE_CURRENT and convert to number
    const maxAsymPhaseCurrentValue: number = Number(control.value);

    // Get the maximum hardware current control
    let maxCurrentControl = control.parent?.get('max_available_current') || control.parent?.get('max_current');

    // If both controls are not present, skip validation
    if (!maxCurrentControl) {
      return null;
    }

    // Get the value of the maxCurrentControl and convert to number
    const maxHwCurrentValue: number = Number(maxCurrentControl.value);

    // Check if maxHwCurrentValue is a valid number and the value is within the allowed range
    if (
      isNaN(maxHwCurrentValue) ||
      isNaN(maxAsymPhaseCurrentValue) ||
      maxAsymPhaseCurrentValue < 0 ||
      maxAsymPhaseCurrentValue > 32 ||
      maxAsymPhaseCurrentValue > maxHwCurrentValue
    ) {
      // Return an error if validation fails
      return {
        maxAsymPhaseCurrentInvalid: true,
      };
    }

    // Return null if validation passes
    return null;
  };

  public static readonly maxCurrentValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    // Avoid infinite recursion by checking a condition before triggering validation
    const maxAsymPhaseCurrentControl = control.parent?.get(LmgmtKey.MAX_ASYM_PHASE_CURRENT);
    if (maxAsymPhaseCurrentControl && !maxAsymPhaseCurrentControl.hasError('recursionPreventionFlag')) {
      // Temporarily set a flag to prevent recursion (this is just a conceptual example)
      maxAsymPhaseCurrentControl.setErrors({ recursionPreventionFlag: true });

      maxAsymPhaseCurrentControl.updateValueAndValidity({ emitEvent: false }); // Consider using { emitEvent: false } to limit side-effects

      // Remember to clear the flag after validation if appropriate
      const errors = maxAsymPhaseCurrentControl.errors;
      if (errors) {
        delete errors['recursionPreventionFlag'];
        if (Object.keys(errors).length === 0) {
          maxAsymPhaseCurrentControl.setErrors(null);
        } else {
          maxAsymPhaseCurrentControl.setErrors(errors);
        }
      }
    }

    return null;
  };

  public static apiAliasValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const apiAlias = control.value;
    if (WallboxProductCodeRegex.IS_P40 || WallboxProductCodeRegex.IS_P30) {
      if (apiAlias && apiAlias.length > 40 && !WallboxNameRegex.VALID_NAME.test(apiAlias) && apiAlias.length <= 0) {
        return {
          apiAliasInvalid: true,
        };
      }
    }

    return null;
  };

  public static getValidatorsForProductCode(productCode: string): ValidatorFn[] {
    const commonValidators = [SettingsValidators.maxCurrentValidator, Validators.min(6)];

    if (WallboxProductCodeRegex.P40_32A.test(productCode)) {
      return [...commonValidators, Validators.max(32)];
    } else if (WallboxProductCodeRegex.P40_16A.test(productCode)) {
      return [...commonValidators, Validators.max(16)];
    }

    return commonValidators;
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { AnalyticsService } from '@kw-shared/common';

@Injectable()
export class AnalyticsErrorHandler extends ErrorHandler {
  constructor(private analyticsService: AnalyticsService) {
    super();
  }

  public override handleError(error: any): void {
    super.handleError(error);
    this.analyticsService.trackException(error, 3);
  }
}

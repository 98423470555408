import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { AnalyticsService, KWLogger, LanguageUtils, LogLevel, Utils } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'kwp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.initLogger();
    this.initRouter();
    this.initLanguage();
    this.initAnalytics();
  }

  private initLogger() {
    KWLogger.setLogLevel(environment.production ? LogLevel.None : LogLevel.Info);
  }

  private initLanguage(): void {
    const language = LanguageUtils.getCurrentLanguage();
    this.translateService.setDefaultLang(LanguageUtils.defaultLanguage);
    this.dateAdapter.setLocale(language);
    this.translateService.use(language);
  }

  private initAnalytics(): void {
    const currentCookieState = Utils.readCookie('cookieconsent_status');
    this.startStopAnalytics(currentCookieState);

    // Track custom event consentStatusChange to update Cookie preferences
    window.addEventListener('consentStatusChange', event => {
      const consentDetails = (event as CustomEvent).detail;
      this.startStopAnalytics(consentDetails);
    });
  }

  private startStopAnalytics(value: string): void {
    if (value === 'allow') {
      this.analyticsService.init(environment.analytics?.trackers);
    } else {
      this.analyticsService.disableAnalytics();
    }
  }

  private initRouter(): void {
    // Deactivate component reuse to ensure everything is re-created after account change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}

import { RemoteWallboxState } from '../models';

export const remoteWallboxStartChargingStates = [
  RemoteWallboxState.AVAILABLE,
  RemoteWallboxState.SUSPENDED_BY_CAR,
  RemoteWallboxState.SUSPENDED_BY_WALLBOX,
  RemoteWallboxState.FINISHING,
  RemoteWallboxState.PREPARING,
];

export const remoteWallboxStartChargingStatesSuspended = [RemoteWallboxState.AVAILABLE, RemoteWallboxState.FINISHING];

export const remoteWallboxStopChargingStates = [RemoteWallboxState.CHARGING];

export const remoteWallboxPvBoostAvailableChargingStates = [
  RemoteWallboxState.CHARGING,
  RemoteWallboxState.SUSPENDED_BY_CAR,
  RemoteWallboxState.SUSPENDED_BY_WALLBOX,
];

export const remoteWallboxChangePowerHiddenStates = [];

export const remoteWallboxChargingSessionHiddenStates = [];

import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken('environment');

export interface IEnvironment {
  production: boolean;
  buildTimestamp: number;
  version: string;

  appType: AppType;

  enableDetailedErrorMessages?: boolean;

  pdfGenerationApi?: {
    baseUrl: string;
    authToken: string;
    projectName: string;
  };

  betaTestEMail?: {
    mail?: string;
  };

  featureFlags?: FeatureFlags;
}

export enum AppType {
  APP,
  PORTAL,
}

export type FeatureFlags = {
  hideArniUpdate?: boolean;
  hideRemoteRfidCards?: boolean;
  hideRemoteChargepointParameters?: boolean;
  hideBLE?: boolean;
  hideUpcomingFeaturesInfo?: boolean;
  hideCommissioningMode?: boolean;
  hideChargingReports?: boolean;
  hideX1X2Settings?: boolean;
  hideM20Settings?: boolean;
};

<div class="flex w-screen min-h-screen kwa-container md:items-center">
  <div class="w-full pb-12 mx-auto md:w-1/2">
    <div class="flex w-full h-8 mt-5 mb-7">
      <div
        accessibility-id="kw-test__kw-common__menu-dialog__btn--close-menu"
        class="ml-auto mr-3 text-white"
        (click)="close()"
      >
        <span class="w-8 h-8" [inlineSVG]="'assets/icons/32/close.svg'" class="mr-2 fill-current"></span>
      </div>
    </div>

    <div class="flex flex-col mx-3 text-gray-900 bg-white shadow-2xl rounded-2xl p-7">
      <div class="max-w-full mt-5 text-2xl font-light text-center truncate">
        {{ menu.title | translate }}
      </div>
      <ng-container *ngFor="let definition of menu.elements; let i = index; let first = first; let last = last">
        <div class="border-b border-gray-300 mt-7" *ngIf="definition.borderTop"></div>
        <a
          [attr.accessibility-id]="definition.accessibilityId"
          (click)="onClick(definition)"
          *ngIf="definition.alwaysVisible"
          class="flex items-center mt-5 appearance-none {{ definition.class }}"
        >
          <div class="flex items-center mr-auto truncate shrink">
            <span
              *ngIf="definition.icon; let icon"
              class="w-8 h-8"
              [inlineSVG]="'assets/icons/32/' + icon + '.svg'"
              class="mr-2 fill-current"
            ></span>
            <div class="font-light truncate">{{ definition.title | translate }}</div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</div>

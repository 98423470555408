import { Validators } from '@angular/forms';
import { ConfigurationType, ExtMeterKey } from '@keba-wallbox-plugin/plugin';
import { AdditionalActionCall, FormInputType, SettingsFormData } from '../../components';
import { SettingsUnitsConverter } from '../../utils';
import { SettingsValidators } from '../../utils/SettingsValidators';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

export const externalTcpMeterFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: ExtMeterKey.ENABLE_EXTERNAL_METER,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_external_tcp_meter_domestic_tcp_monitoring_description',
        title: 'kwa_lbl_settings_external_tcp_meter_domestic_tcp_monitoring_title',
        configurationType: ConfigurationType.EXT_METER,
        listenOnChanges: true,
        accessibilityId: 'toggle-meter',
      },
      // depending on ExtMeterKey.ENABLE_EXTERNAL_METER: start
      {
        key: ExtMeterKey.MAX_CURRENT_PHASE_ONE,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_external_tcp_meter_max_phase_one_description',
        title: 'kwa_lbl_settings_external_tcp_meter_max_phase_one_title',
        configurationType: ConfigurationType.EXT_METER,
        format: '1.1-1',
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'phase-one',
        min: 1,
        max: 999999999,
        validators: [Validators.min(1), Validators.max(999999999), Validators.required],
      },
      {
        key: ExtMeterKey.MAX_CURRENT_PHASE_TWO,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_external_tcp_meter_max_phase_two_description',
        title: 'kwa_lbl_settings_external_tcp_meter_max_phase_two_title',
        configurationType: ConfigurationType.EXT_METER,
        format: '1.1-1',
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'phase-two',
        min: 1,
        max: 999999999,
        validators: [Validators.min(1), Validators.max(999999999), Validators.required],
      },
      {
        key: ExtMeterKey.MAX_CURRENT_PHASE_THREE,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_external_tcp_meter_max_phase_three_description',
        title: 'kwa_lbl_settings_external_tcp_meter_max_phase_three_title',
        configurationType: ConfigurationType.EXT_METER,
        format: '1.1-1',
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'phase-three',
        min: 1,
        max: 999999999,
        validators: [Validators.min(1), Validators.max(999999999), Validators.required],
      },
      {
        key: ExtMeterKey.MAX_POWER,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_external_tcp_meter_max_power_description',
        title: 'kwa_lbl_settings_external_tcp_meter_max_power_title',
        configurationType: ConfigurationType.EXT_METER,
        format: '1.1-1',
        convertToDisplayUnit: SettingsUnitsConverter.milliWattToKiloWatt,
        convertFromDisplayUnit: SettingsUnitsConverter.kiloWattToMilliWatt,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'max-power',
        min: 1,
        max: 999999999,
        validators: [Validators.min(1), Validators.max(999999999), Validators.required],
      },
      {
        key: ExtMeterKey.MAX_POWER_OFFLINE,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_external_tcp_meter_com_lost_description',
        title: 'kwa_lbl_settings_external_tcp_meter_com_lost_title',
        configurationType: ConfigurationType.EXT_METER,
        format: '1.1-1',
        convertToDisplayUnit: SettingsUnitsConverter.milliWattToKiloWatt,
        convertFromDisplayUnit: SettingsUnitsConverter.kiloWattToMilliWatt,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'max-power-offline',
        min: 1,
        max: 999999999,
        validators: [Validators.min(1), Validators.max(999999999), Validators.required],
      },
      {
        key: ExtMeterKey.PROVIDER,
        type: FormInputType.SELECT,
        description: 'kwa_lbl_settings_external_tcp_meter_manufacturer_description',
        title: 'kwa_lbl_settings_external_tcp_meter_manufacturer_title',
        configurationType: ConfigurationType.EXT_METER,
        selectOptions: [],
        selectOptionKey: 'external_meter_home_grid_providers',
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'select-provider',
      },
      {
        key: ExtMeterKey.ADDRESS,
        type: FormInputType.TEXT,
        description: 'kwa_lbl_settings_external_tcp_meter_address_description',
        title: 'kwa_lbl_settings_external_tcp_meter_address_title',
        configurationType: ConfigurationType.EXT_METER,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'address',
        validators: [SettingsValidators.ipAddressValidator, Validators.required],
      },
      {
        key: ExtMeterKey.PORT,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_external_tcp_meter_tcp_port_description',
        title: 'kwa_lbl_settings_external_tcp_meter_tcp_port_title',
        configurationType: ConfigurationType.EXT_METER,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'port',
        min: 0,
        max: 65535,
        validators: [Validators.min(0), Validators.max(65535), Validators.required],
      },
      {
        key: ExtMeterKey.UNIT,
        type: FormInputType.TEXT,
        description: 'kwa_lbl_settings_external_tcp_meter_rs485_address_description',
        title: 'kwa_lbl_settings_external_tcp_meter_rs485_address_title',
        configurationType: ConfigurationType.EXT_METER,
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        accessibilityId: 'unit',
        min: 0,
        max: 65535,
        validators: [SettingsValidators.numberValidator, Validators.min(0), Validators.max(65535)],
      },
      {
        key: 'tcp_meter_call',
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa_lbl_settings_external_tcp_meter_test_connection',
        additionalActionCallType: AdditionalActionCall.TEST_TCP_METER,
        additionalActionCallData: [ExtMeterKey.ADDRESS, ExtMeterKey.PORT, ExtMeterKey.PROVIDER, ExtMeterKey.UNIT],
        hideElement: true,
        showDependentOnOtherSetting: { [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'true' },
        hideConditions: { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        accessibilityId: 'btn--tcp-call',
      },
      // depending on ExtMeterKey.ENABLE_EXTERNAL_METER: end
    ],
  },
];

import { Injectable } from '@angular/core';
import { BaseToggleService } from '../base-toggle.service';

@Injectable({
  providedIn: 'root',
})
export class PageHomeToggleService extends BaseToggleService {
  private readonly prefix = 'kwa.wallboxDetails';

  public isChargingStateVisible(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showChargingStatus`);
  }

  public isDeviceNameVisible(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showDeviceName`);
  }

  public isP40RestOrRemote(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.isP40RestOrRemote`);
  }

  public showInfoBox(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showInfoBox`);
  }

  public showSessionInfo(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showSessionInfo`);
  }

  public isConnectedViaBle(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isBle`);
  }

  public showPowerChangeBox(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showPowerChangeBox`);
  }

  public showActionBar(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showActionBar`);
  }

  public canOpenMasterChargePoint(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.canOpenMasterChargePoint`);
  }

  public showEnrollmentDialog(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showEnrollmentDialog`);
  }

  public isRemote(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isRemote`);
  }

  public isRest(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isRest`);
  }

  public isP40(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isP40`);
  }

  public enableWallboxUpdateFromBackend(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.enableWallboxUpdateFromBackend`);
  }

  public isP40SupportingLanguage(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.isP40SupportingLanguage`);
  }

  public firmwareSupportsEnrollment(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.firmwareSupportsEnrollment`);
  }

  public isRestOrRemote(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isRestOrRemote`);
  }

  public isFirmwareVersionOneZero(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isFirmwareVersionOneZero`);
  }

  public isM20(): boolean {
    return this.toggleService.checkConditions(`${this.commonPrefix}.isM20`);
  }
}

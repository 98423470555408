<div class="absolute top-0 w-full h-full login-image"></div>
<div
  class="flex px-5 pb-14 mb-32 lg:mb-0 py-7 z-10 mx-3 bg-white shadow-keba-light rounded-2xl flex-col items-center mt-[15vh] lg:mt-[25vh]"
>
  <img
    class="w-24 h-24 -mt-16 overflow-hidden bg-white rounded-2xl shadow-keba-light"
    src="/assets/images/app-icon.svg"
  />
  <h1 class="mt-8 text-center">{{ 'kwp.login.title' | translate }}</h1>
  <p class="px-8 mt-3 font-light text-center" [innerHTML]="'kwp.login.description' | translate"></p>

  <button
    [disabled]="buttonDisabled"
    accessibility-id="kw-test__page-login__btn--login"
    class="mt-4 mb-8 button-primary"
    (click)="login()"
  >
    {{ 'kwa.login.button-login' | translate }}
  </button>
  <div class="flex-col space-y-4 text-center lg:flex lg:flex-row lg:space-y-0 lg:space-x-6">
    <div>
      <a [href]="'kwp.login.more-info.portal.link' | translate" class="link-primary" target="_blank">
        {{ 'kwp.login.more-info.portal.text' | translate }}
      </a>
    </div>
    <div>
      <a [href]="'kwp.login.more-info.app.link' | translate" class="link-primary" target="_blank">
        {{ 'kwp.login.more-info.app.text' | translate }}
      </a>
    </div>
  </div>

  <div class="fixed bottom-0 z-20 w-full px-4 py-4 bg-white md:px-5 lg:px-20 drop-shadow-2xl">
    <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-12 md:justify-end md:flex-row">
      <div>
        <a class="link-secondary" (click)="openDialog()">{{ 'kwp.login.how-to-delete-account.title' | translate }}</a>
      </div>
      <div>
        <a class="link-secondary" href="{{ 'kwp.login.data-protection.url' | translate }}" target="_blank">{{
          'kwp.login.data-protection.title' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'kw-wallbox-session-info-row',
    templateUrl: './wallbox-session-info-row.component.html',
    styleUrls: ['./wallbox-session-info-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WallboxSessionInfoRowComponent {
  @Input()
  entryContainerClass: string = '';

  @Input()
  entryNameClass: string = '';

  @Input()
  entryValueClass: string = '';

  @Input()
  icon: string = '';
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDataService } from '@kw-shared/api-data-access';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  RfidSyncFeedbackService,
  ToastMessageService,
} from '@kw-shared/common';
import { PortalNavigationService } from 'apps/keba-wallbox-portal/src/app/services/portal-navigation.service';
import { ActiveToast } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom, repeat } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemoteRfidCardService {
  private updatingItemsSource = new BehaviorSubject<string[]>([]);
  public updatingItems$ = this.updatingItemsSource.asObservable();

  constructor(
    private readonly apiDataService: ApiDataService,
    private readonly navService: PortalNavigationService,
    private readonly messageService: ToastMessageService,
    private readonly feedbackService: RfidSyncFeedbackService
  ) {}

  public async showConfirmImportDialog(remoteWallboxId: string): Promise<boolean> {
    const data: ConfirmDialogData = {
      icon: 'data-down',
      title: 'kwp.rfid-authorization.import-dialog.title',
      description: 'kwp.rfid-authorization.import-dialog.description',
      cancelText: 'kwp.rfid-authorization.import-dialog.cancel-button',
      confirmText: 'kwp.rfid-authorization.import-dialog.confirm-button',
      showCancel: true,
      showConfirm: true,
    };

    return new Promise<boolean>(resolve => {
      this.navService.showDialog(ConfirmDialogComponent, data, async result => {
        if (result) {
          const result = await this.startImport(remoteWallboxId);
          resolve(result);
          return;
        }
        resolve(false);
      });
    });
  }

  public async startImport(remoteWallboxId: string): Promise<boolean> {
    const progressToastMessage = this.messageService.showIndeterminateProgress(
      'kwp.rfid-authorization.action.import.progress',
      'kwp.rfid-authorization.action.import.title'
    );

    this.updateView(remoteWallboxId);

    try {
      const data = await firstValueFrom(this.apiDataService.rfidAuthorization.startImport(remoteWallboxId, true));
      const responseData = JSON.stringify(data);
      const dataArray: any[] = JSON.parse(responseData);

      this.fetchImportState(remoteWallboxId, progressToastMessage);
      this.feedbackService.setDetailedFeedbackData(dataArray);
      return true;
    } catch (error) {
      // api returned an error
      if (error instanceof HttpErrorResponse) {
        const errorResponse = error.error;

        // Check if Wallbox is Offline = exceptionClass is ChargePointOfflineException
        if (errorResponse && errorResponse.exceptionClass === 'ChargePointOfflineException') {
          this.updateView(remoteWallboxId, progressToastMessage);
          this.messageService.setError('kwp.rfid-authorization.action.import.error.offline', true);
        } else {
          this.updateView(remoteWallboxId, progressToastMessage);
          this.messageService.setError('kwp.rfid-authorization.action.import.error.api', true);
        }
      } else {
        this.updateView(remoteWallboxId, progressToastMessage);
        this.messageService.setError('kwp.rfid-authorization.action.import.error.api', true);
      }
      return false;
    }
  }

  private fetchImportState(remoteWallboxId: string, progressToastMessage: ActiveToast<any>): void {
    const fetchImportSubscription = this.apiDataService.rfidAuthorization
      .fetchImportState(remoteWallboxId)
      .pipe(
        repeat({
          count: 5,
          delay: 1000,
        })
      )
      .subscribe({
        next: result => {
          if (result?.importStatus === 'ImportSucceeded') {
            // import was successful
            fetchImportSubscription.unsubscribe();
            this.updateView(remoteWallboxId, progressToastMessage);
          } else if (result?.importStatus === 'ImportFailed') {
            fetchImportSubscription.unsubscribe();
            this.updateView(remoteWallboxId, progressToastMessage);
            this.messageService.setError('kwp.rfid-authorization.action.import.error.api', true);
          } else if (result?.importStatus === 'NoRfidCardsToImport') {
            fetchImportSubscription.unsubscribe();
            this.updateView(remoteWallboxId, progressToastMessage);
            this.messageService.setInfo('kwp.rfid-authorization.action.import.error.no-cards');
          }
        },
        error: () => {
          // api returned an error
          this.updateView(remoteWallboxId, progressToastMessage);
          this.messageService.setError('kwp.rfid-authorization.action.import.error.api', true);
        },
        complete: () => {
          // timeout
          this.updateView(remoteWallboxId, progressToastMessage);
          this.messageService.setError('kwp.rfid-authorization.action.import.error.timeout', true);
        },
      });
  }

  private updateView(remoteWallboxId: string, progressToastMessage?: ActiveToast<any>) {
    let updatingItems = this.updatingItemsSource.value;

    if (progressToastMessage) {
      // Close the progress toast and remove the item from the updating list
      progressToastMessage.toastRef.manualClose();
      updatingItems = updatingItems.filter(id => id !== remoteWallboxId);
    } else {
      // Add the item to the updating list
      updatingItems.push(remoteWallboxId);
    }

    this.updatingItemsSource.next(updatingItems);
  }
}

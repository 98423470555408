import { ChargingSessionDisplayInfo } from './charging-session-display.definition';

export const currentChargingSessionM20Definition: ChargingSessionDisplayInfo[] = [
  {
    key: 'totalActiveChargingSessions',
    title: 'kwa_lbl_active_charging_sessions',
    icon: 'statistics',
    alwaysVisible: true,
  },
];

import { StorageUtils } from '@kw-shared/common';

export abstract class ApiDataAccessStorageUtils {
  private static readonly KEY_PREFIX = 'kw';

  private static readonly KEY_FAKE_DATA: string = 'fake-data';
  private static readonly NO_USER: string = 'no-user';
  private static readonly KEY_SELECTED_ACCOUNT_ID: string = 'selected-account-id';
  private static readonly KEY_SELECTED_WALLBOX_NAME: string = 'selected-wallbox-name';

  public static getIsFakeDataSourceEnabled(): boolean {
    return StorageUtils.get<boolean>(this.getKey(this.KEY_FAKE_DATA)) ?? false;
  }

  public static setIsFakeDataSourceEnabled(enabled: boolean): void {
    StorageUtils.set<boolean>(this.getKey(this.KEY_FAKE_DATA), enabled);
  }

  public static getHasNoUser(): boolean {
    return StorageUtils.get<boolean>(this.getKey(this.NO_USER)) ?? false;
  }

  public static setHasNoUser(enabled: boolean): void {
    StorageUtils.set<boolean>(this.getKey(this.NO_USER), enabled);
  }

  public static setSelectedAccountId(accountId: string | undefined): void {
    StorageUtils.set<string>(this.getKey(this.KEY_SELECTED_ACCOUNT_ID), accountId);
  }

  public static getSelectedAccountId(): string | undefined {
    return StorageUtils.get<string>(this.getKey(this.KEY_SELECTED_ACCOUNT_ID));
  }

  public static setSelectedWallboxName(wallboxName: string | undefined): void {
    StorageUtils.set<string>(this.getKey(this.KEY_SELECTED_WALLBOX_NAME), wallboxName);
  }

  public static getSelectedWallboxName(): string | undefined {
    return StorageUtils.get<string>(this.getKey(this.KEY_SELECTED_WALLBOX_NAME));
  }

  private static getKey(key: string): string {
    return `${this.KEY_PREFIX}.${key}`;
  }
}

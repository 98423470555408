import { Validators } from '@angular/forms';
import { ConfigurationType, IoKey } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { AdditionalCall, FormInputType, SettingsFormData, SettingsFormInputData } from '../../components';
import { SettingsUnitsConverter } from '../../utils/SettingsUnitConverter';

export const x1x2SettingsDefinition: SettingsFormInputData[] = [
  {
    key: IoKey.INST_X1_1_ENABLE,
    type: FormInputType.TOGGLE,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x1a.options.toggle.description',
    title: 'kwa.settings.installer-settings.x1a.options.toggle.title',
    readOnly: false,
    listenOnChanges: true,
    accessibilityId: 'installer-settings-x1a-toggle',
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X1_1_MODE,
    type: FormInputType.SELECT,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x1a.options.mode.description',
    title: 'kwa.settings.installer-settings.x1a.options.mode.title',
    readOnly: false,
    showDependentOnOtherSetting: { [IoKey.INST_X1_1_ENABLE]: 'true' },
    listenOnChanges: true,
    accessibilityId: 'installer-settings-x1a-mode',
    validators: [Validators.required],
    selectOptions: [
      { key: 'CHARGING', text: 'CHARGING', accessibilityId: 'installer-settings-select-x1a-charging' },
      {
        key: 'LIMIT_CHARGING',
        text: 'LIMIT_CHARGING',
        accessibilityId: 'installer-settings-select-x1a-limit-charging',
      },
      { key: 'AUTH_BYPASS', text: 'AUTH_BYPASS', accessibilityId: 'installer-settings-select-x1a-auth-bypass' },
      { key: 'PV_BOOST', text: 'PV_BOOST', accessibilityId: 'installer-settings-select-x1a-pv-boost' },
    ],
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X1_1_VALUE,
    type: FormInputType.NUMBER,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x1a.options.value.description',
    title: 'kwa.settings.installer-settings.x1a.options.value.title',
    // only allow certain range: 0 and 6-32
    validators: [Validators.pattern(/^(0|([6-9]|[1-2][0-9]|3[0-2]))$/), Validators.required],
    showDependentOnOtherSetting: { [IoKey.INST_X1_1_ENABLE]: 'true', [IoKey.INST_X1_1_MODE]: 'LIMIT_CHARGING' },
    accessibilityId: 'installer-settings-x1a-value',
    convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
    convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X1_2_ENABLE,
    type: FormInputType.TOGGLE,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x1b.options.toggle.description',
    title: 'kwa.settings.installer-settings.x1b.options.toggle.title',
    readOnly: false,
    listenOnChanges: true,
    accessibilityId: 'installer-settings-x1b-toggle',
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X1_2_MODE,
    type: FormInputType.SELECT,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x1b.options.mode.description',
    title: 'kwa.settings.installer-settings.x1b.options.mode.title',
    readOnly: false,
    showDependentOnOtherSetting: { [IoKey.INST_X1_2_ENABLE]: 'true' },
    listenOnChanges: true,
    accessibilityId: 'installer-settings-x1b-mode',
    validators: [Validators.required],
    selectOptions: [
      { key: 'CHARGING', text: 'CHARGING', accessibilityId: 'installer-settings-select-x1b-charging' },
      {
        key: 'LIMIT_CHARGING',
        text: 'LIMIT_CHARGING',
        accessibilityId: 'installer-settings-select-x1b-limit-charging',
      },
      { key: 'AUTH_BYPASS', text: 'AUTH_BYPASS', accessibilityId: 'installer-settings-select-x1b-auth-bypass' },
      { key: 'PV_BOOST', text: 'PV_BOOST', accessibilityId: 'installer-settings-select-x1b-pv-boost' },
    ],
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X1_2_VALUE,
    type: FormInputType.NUMBER,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x1b.options.value.description',
    title: 'kwa.settings.installer-settings.x1b.options.value.title',
    // only allow certain range: 0 and 6-32
    validators: [Validators.pattern(/^(0|([6-9]|[1-2][0-9]|3[0-2]))$/), Validators.required],
    showDependentOnOtherSetting: { [IoKey.INST_X1_2_ENABLE]: 'true', [IoKey.INST_X1_2_MODE]: 'LIMIT_CHARGING' },
    accessibilityId: 'installer-settings-x1b-value',
    convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
    convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X2_ENABLE,
    type: FormInputType.TOGGLE,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x2.options.toggle.description',
    title: 'kwa.settings.installer-settings.x2.options.toggle.title',
    listenOnChanges: true,
    accessibilityId: 'installer-settings-x2-toggle',
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
  {
    key: IoKey.INST_X2_MODE,
    type: FormInputType.SELECT,
    configurationType: ConfigurationType.IO,
    description: 'kwa.settings.installer-settings.x2.options.mode.description',
    title: 'kwa.settings.installer-settings.x2.options.mode.title',
    showDependentOnOtherSetting: { [IoKey.INST_X2_ENABLE]: 'true' },
    accessibilityId: 'installer-settings-x2-mode',
    validators: [Validators.required],
    listenOnChanges: true,
    selectOptions: [
      { key: 'EV_PLUGGED', text: 'EV_PLUGGED', accessibilityId: 'installer-settings-select-x2-ev-plugged' },
      { key: 'EV_CHARGING', text: 'EV_CHARGING', accessibilityId: 'installer-settings-select-x2-ev-charging' },
      {
        key: 'WELDED_CONTACTOR',
        text: 'WELDED_CONTACTOR',
        accessibilityId: 'installer-settings-select-x2-welded-contactor',
      },
    ],
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
  },
];

export const x1x2FormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: 'x1-x2',
        type: FormInputType.TEXT,
        hideElement: true,
        additionalCallType: AdditionalCall.X1_X2_LOCK_SETTINGS,
      },
      ...x1x2SettingsDefinition,
    ],
  },
];

import { Utils, WallboxUtils } from '@kw-shared/common';
import { Observable, of, throwError } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { BaseModel, RemoteWallbox } from '../../../../models';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort, SortDirection } from '../../types/Sort';
import { IRepository } from './IRepository';

export class FakeRepository<T extends BaseModel> implements IRepository<T> {
  constructor(protected entities: T[]) {}

  public isRemoteWallbox(obj: any): obj is RemoteWallbox {
    return 'connectionStatus' in obj;
  }

  public getAll(filters?: Filter[], sort?: Sort<T>[], pagination?: Pagination): Observable<DataWithCount<T>> {
    return this.delayResponse(this.getEntities()).pipe(
      map(items => {
        let totalSize = 0;
        if (items) {
          items = this.applyFilters(items, filters);
          totalSize = items.length;

          items = this.applySorting(items, sort);
          items = this.applyPagination(items, pagination);

          items = items.map(item => {
            if (this.isRemoteWallbox(item)) {
              item.state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(item.connectionStatus, item.state);
            }
            return item;
          });
        }

        return { list: items ?? [], totalCount: totalSize };
      })
    );
  }

  public getById(id: string): Observable<T> {
    const entity = this.entities.find(e => e.id === id);
    if (entity) {
      if (this.isRemoteWallbox(entity)) {
        entity.state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(entity.connectionStatus, entity.state);
      }
      return this.delayResponse(of(Utils.clone(entity)!));
    }
    return this.throwElementNotFoundError(id);
  }

  public update(entity: Partial<T>): Observable<void> {
    const oldEntity = this.entities.find(e => e.id === entity.id);
    if (oldEntity) {
      Object.assign(oldEntity, entity);
      return this.delayResponse(of(void 0));
    }
    return this.throwElementNotFoundError(entity.id!);
  }

  public add(entity: T): Observable<T> {
    entity.id = this.generateId();
    this.entities.push(entity);
    return this.delayResponse(of(entity));
  }

  public delete(entity: Partial<T>): Observable<void> {
    this.entities = this.entities.filter(e => e.id !== entity.id);
    return this.delayResponse(of(void 0));
  }

  protected getEntities(): Observable<T[] | undefined> {
    return of(Utils.clone(this.entities));
  }

  protected delayResponse<T>(observable: Observable<T>, customDelay?: number): Observable<T> {
    return observable.pipe(delay(customDelay ?? Math.random() * 2000));
  }

  protected applyFilter(items: T[], f: Filter): T[] {
    // Implement custom filters in subclass
    return items;
  }

  private applyFilters(items: T[], filters?: Filter[]): T[] {
    if (filters?.length) {
      filters.forEach(filter => {
        if (filter.fieldName === 'search') {
          const searchStr = String(filter.value).toLowerCase().trim();
          items = items.filter(item =>
            Object.keys(item).some(key => {
              // @ts-ignore
              const value = item[key];
              if (value) {
                return String(value).toLowerCase().includes(searchStr);
              }
              return false;
            })
          );
        }
        items = this.applyFilter(items, filter);
      });
    }
    return items;
  }

  private applySorting(items: T[], sort?: Sort<T>[]): T[] {
    if (sort) {
      items = this.sortBy(items, sort[0].fieldName as string, sort[0].direction == SortDirection.Ascending ? +1 : -1);
    }
    return items;
  }

  private applyPagination(items: T[], pagination?: Pagination): T[] {
    if (pagination) {
      items.splice(0, pagination.pageNumber * pagination.pageSize);
      items.splice(pagination.pageSize);
    }
    return items;
  }

  private sortBy(items: T[], fieldName: string, direction: number): T[] {
    return items.slice(0).sort((a: any, b: any) => {
      return (a[fieldName] > b[fieldName] ? 1 : a[fieldName] < b[fieldName] ? -1 : 0) * direction;
    });
  }

  private generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  private throwElementNotFoundError(id: string) {
    return throwError(() => new Error(`No element with id ${id} was found!`));
  }
}

import { WallboxChargingSession, WallboxData } from '../../../models';
import { BaseFormat } from '../../../pipes';

export enum ChargingSessionUnit {
  A = 'A',
  KW = 'kW',
  KWH = 'kWh',
  Percent = '%',
  V = 'V',
}

export interface ChargingSessionDisplayInfo {
  key: keyof WallboxData | keyof WallboxChargingSession;
  subKey?: string;
  titleKey?: string;
  title: string;
  icon: string;
  alwaysVisible?: boolean;
  format?: BaseFormat;
  unit?: ChargingSessionUnit;
}

export const currentChargingSessionDefinition: ChargingSessionDisplayInfo[] = [
  {
    key: 'duration',
    title: 'kwa_lbl_detail_charging_time',
    icon: 'time',
    alwaysVisible: true,
    format: BaseFormat.DURATION_TO_INTERVAL_SHORT,
  },
  {
    key: 'energyConsumed',
    title: 'kwa_lbl_detail_charged_energy',
    icon: 'battery-low',
    alwaysVisible: true,
    unit: ChargingSessionUnit.KWH,
    format: BaseFormat.MWH_TO_KWH,
  },
  {
    key: 'totalActivePower',
    title: 'kwa_lbl_detail_charging_power',
    icon: 'energy',
    alwaysVisible: true,
    unit: ChargingSessionUnit.KW,
    format: BaseFormat.MW_TO_KW,
  },
  {
    key: 'lines',
    subKey: 'voltage',
    titleKey: 'socketPhase',
    title: 'kwa_lbl_detail_voltage',
    icon: 'voltage',
    unit: ChargingSessionUnit.V,
  },
  {
    key: 'lines',
    subKey: 'current',
    titleKey: 'socketPhase',
    title: 'kwa_lbl_detail_multi_amperage',
    icon: 'amperage',
    unit: ChargingSessionUnit.A,
    format: BaseFormat.MA_TO_A,
  },
  {
    key: 'currentOffered',
    title: 'kwa_lbl_detail_amperage',
    icon: 'amperage',
    unit: ChargingSessionUnit.A,
    format: BaseFormat.MA_TO_A,
  },
  {
    key: 'maxCurrent',
    title: 'kwa_lbl_detail_max_amperage',
    icon: 'amperage',
    unit: ChargingSessionUnit.A,
    format: BaseFormat.MA_TO_A,
  },
  {
    key: 'totalPowerFactor',
    title: 'kwa_lbl_detail_power_factor',
    icon: 'factor',
    unit: ChargingSessionUnit.Percent,
    format: BaseFormat.PERCENTAGE,
  },
];

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'kw-global-info-dialog',
    templateUrl: './global-info-dialog.component.html',
    styleUrls: ['./global-info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GlobalInfoDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; mediaUrl?: string; text: string; portal?: boolean },
    private mdDialogRef: MatDialogRef<GlobalInfoDialogComponent>
  ) {}

  ngOnInit(): void {
    this.mdDialogRef.addPanelClass('full-screen');
  }

  // TODO: create a function in a service to check if the mediaUrl is a video
  public isVideo(): boolean {
    return this.data.mediaUrl?.endsWith('.mp4') ?? false;
  }

  public cancel(): void {
    this.mdDialogRef.close(false);
  }
}

import { BaseModel } from '@kw-shared/api-data-access';
import { WallboxBaseType } from '@kw-shared/common';

export interface RemoteWallboxFirmwareUpdate extends BaseModel {
  version: RemoteWallboxFirmwareUpdateVersion;
  minimumRequiredVersion?: RemoteWallboxFirmwareUpdateVersion;
  productType: WallboxBaseType;
  fileSize: number; // in bytes
  status: RemoteWallboxFirmwareUpdateStatus;
  latestStatusUpdate: string; // date as UTC ISO string
  criticalUpdate: boolean;
  updateFileLocation?: string;
  updateFileName?: string;
  description?: RemoteWallboxFirmwareUpdateDescription[];
  signingCertificateFileLocation?: string;
  signingCertificateFileName?: string;
  updateHashFileLocation?: string;
  updateHashFileName?: string;
}

export interface RemoteWallboxFirmwareUpdateVersion {
  id?: string;
  major: number;
  minor: number;
  patch: number;
}

export interface RemoteWallboxFirmwareUpdateDescription {
  lang: string;
  value: string;
}

export enum RemoteWallboxFirmwareUpdateStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DEPRECATED = 'deprecated',
  PENDING = 'pending',
  FAILED = 'failed',
}

export type RemoteWallboxFirmwareUpdateFileTypeP40 = '.raucb';
export type RemoteWallboxFirmwareUpdateFileTypeP30 = '.keb';
export type RemoteWallboxFirmwareCertificateFileType = '.pem';
export type RemoteWallboxFirmwareHashFileType = '.txt';

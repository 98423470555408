import { Injectable } from '@angular/core';
import { ErrorDataCounts, FeedbackCall, RfidResponseData, ToastMessageService } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RfidSyncFeedbackService {
  constructor(private messageService: ToastMessageService, private translateService: TranslateService) {}

  public checkResponseStatus(response: RfidResponseData[], call?: FeedbackCall): void {
    const total = response.length;
    const { success, failed } = this.countResponseData(response);

    const messageKeyMap = {
      [FeedbackCall.RFID_EDIT]: 'kw.rfid-card-management',
      [FeedbackCall.RFID_AUTHORIZATION]: 'kw.rfid-authorization-management',
      default: 'kw.rfid-card-management',
    };

    const messageKey = messageKeyMap[call || 'default'];

    if (success === total) {
      // All successful
      this.messageService.setSuccess(`${messageKey}.status-successful`);
    } else if (failed !== total) {
      // Partial successful
      const message = this.translateService.instant(`${messageKey}.status-partial`, {
        success,
        total,
      });
      this.messageService.setInfo(message, `${messageKey}.title`);
    } else if (failed === total) {
      // All failed
      this.messageService.setError(`${messageKey}.status-failed`, true);
    }
  }

  public isRfidResponseData(item: any): item is RfidResponseData {
    return item && typeof item === 'object' && 'statusCodeValue' in item && typeof item.statusCodeValue === 'number';
  }

  public setDetailedFeedbackData(response: any): ErrorDataCounts {
    const syncFeedBack: any[] = response?.syncFeedBack || [];
    const results: ErrorDataCounts = {};

    syncFeedBack.forEach((feedbackObject: any) => {
      Object.keys(feedbackObject).forEach((key: string) => {
        const feedbackList: RfidResponseData[] = feedbackObject[key];

        feedbackList.forEach((feedback: RfidResponseData) => {
          const { body, statusCodeValue } = feedback;

          let name = body.chargePointName || body.chargePointSerial;

          if (!results[name]) {
            results[name] = { total: 0, success: 0, failed: 0 };
          }

          if (statusCodeValue === 200) {
            results[name].success++;
          } else {
            results[name].failed++;
          }

          results[name].total++;
        });
      });
    });

    const messages: string[] = [];

    if (Object.values(results).length > 0) {
      const allSuccess = Object.values(results).every(item => item.success === item.total);

      Object.entries(results).forEach(([id, { success, total }]) => {
        const message = this.translateService.instant('kw.rfid-card-import.status-per-card', {
          wallboxId: id,
          success,
          total,
        });
        messages.push(message);
      });

      const combinedMessage = messages.join('\n');

      if (allSuccess) {
        this.messageService.setSuccess(combinedMessage, {
          timeOut: 8000,
        });
      } else {
        this.messageService.setInfo(combinedMessage, 'kw.rfid-card-import.status-per-card-title', {
          timeOut: 8000,
        });
      }
    }

    return results;
  }

  private countResponseData(response: RfidResponseData[]): { total: number; success: number; failed: number } {
    return response.reduce(
      (acc, item) => {
        acc.total++;
        if (item.statusCodeValue === 200) {
          acc.success++;
        } else {
          acc.failed++;
        }
        return acc;
      },
      { total: 0, success: 0, failed: 0 }
    );
  }
}

import { BaseModel } from './BaseModel';

export interface RemoteRfidAuthorization extends BaseModel {
  chargePointId: string;
  authorization: string;
  chargePointName?: string;
  offlineAuthorization?: RfidOfflineAuthorization;
  onlineAuthorization?: RfidOnlineAuthorization;
  chargePointSerial: string;
}

export enum RfidOnlineAuthorization {
  FIRST_LOCAL = 'FirstLocal',
  FIRST_ONLINE = 'FirstOnline',
  ONLY_LOCAL = 'OnlyLocal',
}

export enum RfidOfflineAuthorization {
  LOCAL_UNKNOWN_AUTHORIZATION = 'OfflineLocalUnknownAuthorization',
  LOCAL_AUTHORIZATION = 'OfflineLocalAuthorization',
  NO_AUTHORIZATION = 'OfflineNoAuthorization',
  NO_CHARGING = 'OfflineNoCharging',
  FREE_CHARGING = 'OfflineFreeCharging',
}

export type RemoteRfidAuthorizationImport = {
  id: string;
  chargePointId: string;
  importStatus: RemoteRfidAuthorizationState;
  error?: string;
};

export type RemoteRfidAuthorizationState =
  | 'ImportSucceeded'
  | 'ImportFailed'
  | 'ImportInProgress'
  | 'NoRfidCardsToImport';

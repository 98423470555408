import { WallboxFirmwareVersionInfo } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { RemoteWallboxFirmwareUpdate } from '../../../../models/RemoteWallboxFirmwareUpdate';
import { AdvancedHttpClient, RequestParams } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IUkUpdatesRepository } from './IUkUpdatesRepository';

export interface OfflineUpdateDetail {
  location: string;
  description: string;
  version: WallboxFirmwareVersionInfo;
  minimumRequiredVersion: WallboxFirmwareVersionInfo;
}

export class UkUpdatesRepository
  extends WebDataRepository<RemoteWallboxFirmwareUpdate>
  implements IUkUpdatesRepository
{
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'updates');
  }

  public checkFirmwareUpdateOffline(
    serialNumber: string,
    model: string,
    version: string
  ): Observable<OfflineUpdateDetail | undefined> {
    // undefined, when no update is available
    let header: RequestParams = {
      'X-API-KEY': 'offline-api-key',
      'X-API-SECRET': '67890qrstuv12345wxyz67890abcdefghij',
      Cookie: 'JSESSIONID=F5677DB070CA0AF247C1BA4F0913A8D7',
    };

    let req = this.httpClient.get<OfflineUpdateDetail | undefined>({
      url: `/offline?serial=99991816&model=KC-P40-32EU0-C633AEP0-LS1R1111BL0-CL00&version=1.0.0-SNAPSHOT`,
      headers: header,
    });
    return req;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { SecurityGuard } from '@kw-shared/api-data-access';
import { concatMap, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalAuthGuard  {
  constructor(private readonly msalGuard: MsalGuard, private readonly authGuard: SecurityGuard) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.msalGuard
      .canActivate(route, state)
      .pipe(concatMap(value => this.authGuard.canActivateAuth(value, route)));
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.msalGuard
      .canActivateChild(route, state)
      .pipe(concatMap(value => this.authGuard.canActivateAuth(value, route)));
  }
}

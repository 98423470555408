import { AdditionalCall, FormInputType, SettingsFormData } from '../../components';

export const dswFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: 'dsw0',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.1.description',
        title: 'kwa.settings.dsw.1.title',
        additionalCallType: AdditionalCall.DSW_SETTINGS,
        readOnly: true,
        accessibilityId: 'toggle-dsw0',
      },
      {
        key: 'dsw1',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.2.description',
        title: 'kwa.settings.dsw.2.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw1',
      },
      {
        key: 'dsw2',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.3.description',
        title: 'kwa.settings.dsw.3.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw2',
      },
      {
        key: 'dsw3',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.4.description',
        title: 'kwa.settings.dsw.4.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw3',
      },
      {
        key: 'dsw4',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.5.description',
        title: 'kwa.settings.dsw.5.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw4',
      },
      {
        key: 'dsw5',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.6.description',
        title: 'kwa.settings.dsw.6.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw5',
      },
      {
        key: 'dsw6',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.7.description',
        title: 'kwa.settings.dsw.7.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw6',
      },
      {
        key: 'dsw7',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.8.description',
        title: 'kwa.settings.dsw.8.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw7',
      },
      {
        key: 'dsw8',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.9.description',
        title: 'kwa.settings.dsw.9.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw8',
      },
      {
        key: 'dsw9',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.10.description',
        title: 'kwa.settings.dsw.10.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw9',
      },
      {
        key: 'dsw10',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.11.description',
        title: 'kwa.settings.dsw.11.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw10',
      },
      {
        key: 'dsw11',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.12.description',
        title: 'kwa.settings.dsw.12.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw11',
      },
      {
        key: 'dsw12',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.13.description',
        title: 'kwa.settings.dsw.13.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw12',
      },
      {
        key: 'dsw13',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.14.description',
        title: 'kwa.settings.dsw.14.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw13',
      },
      {
        key: 'dsw14',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.15.description',
        title: 'kwa.settings.dsw.15.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw14',
      },
      {
        key: 'dsw15',
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.dsw.16.description',
        title: 'kwa.settings.dsw.16.title',
        readOnly: true,
        accessibilityId: 'toggle-dsw15',
      },
    ],
  },
];

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'kw-wallbox-info-box',
    templateUrl: './wallbox-info-box.component.html',
    styleUrls: ['./wallbox-info-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WallboxInfoBoxComponent {
  @Input()
  svgUrl: string;

  @Input()
  title: string;

  @Input()
  descriptionOne: string;

  @Input()
  descriptionTwo: string;

  constructor() {}
}

import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { RemoteChargingProfile } from '../../../../models/RemoteChargingProfile';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IChargingProfilesRepository } from './IChargingProfilesRepository';

export class ChargingProfilesRepository
  extends WebDataRepository<RemoteChargingProfile>
  implements IChargingProfilesRepository
{
  constructor(public override httpClient: AdvancedHttpClient, private basePath?: string) {
    super(httpClient, Utils.joinPaths(basePath, 'chargingprofiles'));
  }

  public assignWallboxes(
    chargingProfileId: string,
    tagIds: string[],
    wallboxIds: string[],
    overwriteExistingProfileForWallboxIds?: string[]
  ): Observable<{ chargingProfile: RemoteChargingProfile }> {
    return this.httpClient.put({
      url: `${this.resource}/${chargingProfileId}/assign`,
      body: {
        tags: tagIds.join(','),
        chargePoint: wallboxIds.join(','),
        overwrite: overwriteExistingProfileForWallboxIds?.join(','),
      },
    });
  }
}

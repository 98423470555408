<canvas
  *ngIf="barChartData"
  class="h-full"
  baseChart
  [data]="barChartData"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="false"
  type="bar"
>
</canvas>

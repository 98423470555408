import { HttpErrorResponse } from '@angular/common/http';
import { RemoteWallboxConnectionStatus } from 'libs/api-data-access/models';
import { Observable, of, throwError } from 'rxjs';
import { RemoteChargingProfile, RemoteChargingProfileType } from '../../../../models/RemoteChargingProfile';
import { FakeRepository } from '../base/FakeRepository';
import { IChargingProfilesRepository } from './IChargingProfilesRepository';

export class FakeChargingProfileRepository
  extends FakeRepository<RemoteChargingProfile>
  implements IChargingProfilesRepository
{
  constructor() {
    super([
      {
        id: '1',
        kind: RemoteChargingProfileType.POWER_PROFILE,
        name: 'Test Profile',
        validFrom: new Date().toISOString(),
        chargePoints: [
          {
            name: 'My favorite',
            serialNumber: '123',
            id: 'w-123',
            connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
            tags: [
              { id: '1', name: 'Home' },
              { id: '3', name: 'Private' },
            ],
          },
          {
            name: 'Office Outdoor',
            connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
            serialNumber: '124',
            id: 'w-124',
            tags: [{ id: '2', name: 'Work' }],
          },
        ],
        assignedWallboxesCount: 2,
        chargingSchedule: {
          chargingSchedulePeriod: [
            {
              startPeriod: 0,
              limit: -1,
            },
            {
              startPeriod: 28800,
              limit: 1000,
            },
            {
              startPeriod: 43200,
              limit: 2000,
            },
            {
              startPeriod: 50400,
              limit: -1,
            },
            {
              startPeriod: 201600,
              limit: 1000,
            },
            {
              startPeriod: 216000,
              limit: 2000,
            },
            {
              startPeriod: 223200,
              limit: -1,
            },
            {
              startPeriod: 460800,
              limit: 3000,
            },
            {
              startPeriod: 475200,
              limit: -1,
            },
          ],
        },
      },
      {
        id: '2',
        kind: RemoteChargingProfileType.POWER_PROFILE,
        name: 'Test Profile 2',
        chargePoints: [],
        assignedWallboxesCount: 0,
        chargingSchedule: {
          chargingSchedulePeriod: [
            {
              startPeriod: 0,
              limit: -1,
            },
            {
              startPeriod: 28800,
              limit: 1000,
            },
            {
              startPeriod: 43200,
              limit: 2000,
            },
            {
              startPeriod: 50400,
              limit: -1,
            },
            {
              startPeriod: 201600,
              limit: 1000,
            },
            {
              startPeriod: 216000,
              limit: 2000,
            },
            {
              startPeriod: 223200,
              limit: -1,
            },
            {
              startPeriod: 460800,
              limit: 3000,
            },
            {
              startPeriod: 475200,
              limit: -1,
            },
          ],
        },
      },
    ]);
  }

  public assignWallboxes(
    chargingProfileId: string,
    tagIds: string[],
    wallboxIds: string[],
    overwriteExistingProfileForWallboxIds?: string[]
  ): Observable<{ chargingProfile: RemoteChargingProfile }> {
    if (overwriteExistingProfileForWallboxIds) {
      return this.delayResponse(of({ chargingProfile: this.entities.find(e => e.id === chargingProfileId)! }));
    } else {
      return this.delayResponse(
        throwError(
          () =>
            new HttpErrorResponse({
              error: {
                chargePointsWithCurrentlyAssignedProfile: [
                  {
                    name: 'My favorite',
                    serialNumber: '123',
                    id: 'w-123',
                  },
                  {
                    name: 'Office Outdoor',
                    serialNumber: '124',
                    id: 'w-124',
                  },
                ],
              },
              status: 409,
            })
        )
      );
    }
  }
}

import { Observable, of } from 'rxjs';
import { FixedUserRoleType, InvitedUser, InvitedUserResponse, UserInvitationState } from '../../../../models';
import { FakeRepository } from '../base/FakeRepository';
import { IUserManagementRepository } from './IUserManagementRepository';

const fakeInvitedUsers: InvitedUser[] = [
  {
    id: '1',
    firstname: 'Max',
    lastname: 'Mustermann',
    email: 'max.mustermann@test.com',
    fixedRole: FixedUserRoleType.ADMIN,
    invitationState: UserInvitationState.INVITED_NEW,
  },
  {
    id: '2',
    firstname: 'Susi',
    lastname: 'Musterfrau',
    email: 'susi.musterfrau@test.com',
    fixedRole: FixedUserRoleType.OWNER,
    invitationState: UserInvitationState.INVITED_EXISTING,
  },
  {
    id: '3',
    email: 'maria.mustermember@test.com',
    fixedRole: FixedUserRoleType.MEMBER,
    invitationState: UserInvitationState.ACCEPTED,
    tags: [{ id: 'Work' }],
    chargePoints: [{ id: '123' }],
  },
  {
    id: '4',
    email: 'maria.musteradmin@test.com',
    fixedRole: FixedUserRoleType.ADMIN,
    invitationState: UserInvitationState.EXPIRED,
  },
];
export class FakeUserManagementRepository extends FakeRepository<InvitedUser> implements IUserManagementRepository {
  constructor() {
    super(fakeInvitedUsers);
  }

  public getInvitedUserByEmail(email: string): Observable<InvitedUser> {
    const user: InvitedUser | undefined = this.entities.find(user => user.email === email);
    if (!user) {
      throw new Error('Invited User not found');
    }
    return of(user);
  }

  public updateInvitedUser(entity: InvitedUser): Observable<void> {
    return super.update(entity);
  }

  public inviteUser(entity: InvitedUser): Observable<InvitedUser> {
    return super.add({
      ...entity,
      invitationState: UserInvitationState.INVITED_NEW,
    });
  }

  public acceptInvitation(token: string): Observable<InvitedUserResponse> {
    const fakeData: InvitedUserResponse = {
      id: 'd7455d51-f1c0-434d-99d2-2cbc00a7ee48',
      token: 'fb017255-9efd-43c6-9b1e-115990bfbde9',
      expirationDate: '2024-09-03T10:39:59.075035Z',
      invitationState: UserInvitationState.ACCEPTED,
      link: '/invite/accept/fb017255-9efd-43c6-9b1e-115990bfbde9?email=neven.savanovic@netural.com&new=false',
      userAccountId: '5ee4bbe5-b894-4654-87d0-227a7b9a3134',
      acceptedAccountId: '444ef8cc-9b33-4c3d-8f1b-2a14f1581ea8',
    };

    return this.delayResponse(of(fakeData)); // Return observable of fake data
  }

  public reInviteUser(email: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }
}

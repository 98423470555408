import { WallboxData } from "@kw-shared/common";

export interface Enrollment {
  accountId: string;
  enrollmentHost?: string;
  enrollmentPath?: string;
  enrollmentPort?: number;
  enrollmentToken?: string;
  enrollmentType: EnrollmentType;
  id?: string;
  serialNumber?: string;
  validUntil?: Date;
  wboxAuthKey?: string;
  wboxId?: string;
  portalSecurityProfile?: number;
  wallboxData?: WallboxData;
  isP40?: boolean;
}

export enum EnrollmentType {
  P30_ENROLLMENT,
  P40_ENROLLMENT
}

<button *ngIf="moreMenus && moreMenus.length > 0" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"
  class="hover:text-basil-900" [class.rotate-90]="type === 'horizontal'"
  accessibility-id="kw-test__shared__more-menu__btn--open-menu">
  <span class="w-6 h-6 fill-current" inlineSVG="assets/icons/32/dots.svg"></span>
</button>
<mat-menu #menu="matMenu" class="px-4 rounded-xl shadow-keba-light">
  <button *ngFor="let menu of moreMenus; let last = last"
    class="flex items-center w-full py-4 space-x-2 border-gray-300 {{ menu.color }}" [class.border-b]="!last"
    (click)="clicked.emit(menu)"
    [attr.accessibility-id]="'kw-test__page-slect__saved-wallbox-card__btn--' + menu.key + '-button'">
    <span *ngIf="menu.icon; let icon" class="fill-current" [inlineSVG]="'assets/icons/32/' + icon + '.svg'"></span>
    <span *ngIf="menu.title; let title">{{ title | translate }}</span>
  </button>
</mat-menu>
import { BaseModel, RemoteWallbox } from '@kw-shared/api-data-access';

export interface RemoteChargingProfile extends BaseModel {
  name: string;
  kind: RemoteChargingProfileType;
  chargePoints?: RemoteWallbox[];
  assignedWallboxesCount?: number;

  chargingProfileKind?: RemoteChargingProfileScheduleType;
  recurrencyKind?: RemoteChargingProfileRecurrencyType;
  validFrom?: string;
  chargingSchedule?: {
    chargingSchedulePeriod: RemoteChargingSchedulePeriod[];
  };
}

export interface RemoteChargingSchedulePeriod {
  startPeriod: number; // unit: seconds since Monday 00:00
  limit: number; // unit: watt
}

export enum RemoteChargingProfileType {
  POWER_PROFILE = 'POWER_PROFILE',
}

export enum RemoteChargingProfileRecurrencyType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
}

export enum RemoteChargingProfileScheduleType {
  ABSOLUTE = 'Absolute',
  RECURRING = 'Recurring',
  RELATIVE = 'Relative',
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { HeaderNavigationComponent } from './header-navigation.component';

@NgModule({
  imports: [CommonModule, InlineSVGModule, TranslateModule],
  declarations: [HeaderNavigationComponent],
  exports: [HeaderNavigationComponent],
})
export class HeaderNavigationModule {}

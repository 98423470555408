export abstract class TimeUtils {
  public static readonly SECONDS_PER_DAY: number = 24 * 60 * 60;
  public static millisecondsToHours(milliseconds: number): number {
    return Math.floor(milliseconds / 60000 / 60);
  }

  public static millisecondsToMinutes(milliseconds: number): number {
    return Math.floor((milliseconds / 60000) % 60);
  }

  public static millisecondsToSeconds(milliseconds: number): number {
    return Math.floor(((milliseconds / 1000) % 60) % 60);
  }

  public static timeStringToSeconds(time: string) {
    const [hour, minute] = time.split(':').map(t => parseInt(t));
    return (hour * 60 + minute) * 60;
  }

  public static getDayOfWeekBasedOnMonday(date: Date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  }

  public static getTimeDifferenceInHours(timeDifference: number): number {
    // timeDifference is in milliseconds
    return Math.floor(timeDifference / 3600000);
  }

  public static getTimeDifferenceInMinutes(timeDifference: number): number {
    // timeDifference is in milliseconds
    return Math.floor((timeDifference % 3600000) / 60000);
  }

  public static getTimeDifferenceInSeconds(timeDifference: number): number {
    // timeDifference is in milliseconds
    return Math.floor((timeDifference % 60000) / 1000);
  }

  public static getCurrentTimeStamp(): string {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${dd}_${mm}_${yyyy}_${hour}_${minutes}_${seconds}`;
  }
}

import { WallboxState } from './WallboxData';

export interface WallboxAboutData {
  name?: string;
  type?: string;
  serialNumber?: string;
  firmwareVersion?: string;
  state?: WallboxState;
  stateTitle?: string;
  lifetimeChargedEnergy?: number;
  ipAddresses?: WallboxAboutAddress[];
  macAddresses?: WallboxAboutAddress[];
  ocppUrl?: string;
  ocppState?: string;
  ocppLastHeartbeat?: Date;
  totalActiveChargingSessions?: number;
}

export interface WallboxAboutAddress {
  title: string;
  value?: string;
}

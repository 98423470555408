import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'kw-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent {
  @Input()
  errors?: ValidationErrors | null;

  public getErrorList(): { [key: string]: any }[] {
    if (!this.errors) {
      return [];
    }

    return Object.keys(this.errors).map(key => ({
      key,
      value: this.errors![key][key],
      data: this.errors![key],
    }));
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tab } from './interfaces/tabs.interface';

@Component({
    selector: 'kw-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TabsComponent implements OnInit {
  @Input() tabs: Tab[];
  @Input() initialSelection: string;
  @Input() tabClass: string = '';

  @Output() clicked = new EventEmitter<string>();

  public selectedTab: string;

  constructor() {}

  ngOnInit(): void {
    this.selectedTab = this.initialSelection;
  }

  public setTab(tab: string): void {
    this.selectedTab = tab;
    this.clicked.emit(tab);
  }
}

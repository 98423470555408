import { RemoteChargingSessionRfidCard, RemoteRfidCard } from '@kw-shared/api-data-access';
import { Utils } from '@kw-shared/common';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IRfidCardRepository } from './IRfidCardRepository';
import { Observable } from 'rxjs';

export class RfidCardRepository extends WebDataRepository<RemoteRfidCard> implements IRfidCardRepository {
  constructor(public override httpClient: AdvancedHttpClient, private basePath?: string) {
    super(httpClient, Utils.joinPaths(basePath, 'rfid-cards'));
  }

  public getAvailableFilterRfidCards(): Observable<RemoteChargingSessionRfidCard[]> {
    return this.httpClient.get<RemoteChargingSessionRfidCard[]>({ url: `${this.resource}/filter/rfidData` });
  }
}

import { Injectable } from '@angular/core';
import {
  AccountsService,
  ApiAuthService,
  ApplicationFeature,
  FixedUserRoleType,
  SecurityPermission,
} from '@kw-shared/api-data-access';
import { Observable } from 'rxjs';
import { SecurityUtils } from './SecurityUtils';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(
    private readonly authService: ApiAuthService,
    private readonly accountsService: AccountsService
  ) {}

  public checkFeatureAccessAllowed(
    feature: ApplicationFeature,
    permission?: SecurityPermission,
    allowIfNotLoggedIn: boolean = false
  ): boolean {
    return SecurityUtils.checkFeatureAccessAllowed(
      this.authService.getUser(),
      this.accountsService.getSelectedAccount(),
      feature,
      permission,
      allowIfNotLoggedIn
    );
  }

  public checkIfUserIsAdminOrOwner(): boolean {
    return this.getUserRole() === FixedUserRoleType.ADMIN || this.getUserRole() === FixedUserRoleType.OWNER;
  }

  public getUserRole(): FixedUserRoleType | undefined {
    const account = this.accountsService.getSelectedAccount();
    return this.authService.getUser()?.fixedRoles?.find(r => r.accountId === account?.id)?.type;
  }

  public checkFeatureAccessAllowed$(
    feature: ApplicationFeature,
    permission?: SecurityPermission,
    allowIfNotLoggedIn: boolean = false
  ): Observable<boolean> {
    return SecurityUtils.checkFeatureAccessAllowed$(
      this.authService.getUser$(),
      this.accountsService.getSelectedAccount$(),
      feature,
      permission,
      allowIfNotLoggedIn
    );
  }

  public isCurrentUser(email: string): boolean {
    return this.authService.getUser()?.email === email;
  }
}

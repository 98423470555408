<div *ngIf="progressState" [ngSwitch]="progressState" class="w-full">
  <!-- PROGRESS -->
  <div *ngSwitchCase="ProgressState.Loading">
    <ng-container>
      <h1 class="mb-2 text-center">{{ percentage }}%</h1>
      <mat-progress-bar mode="determinate" [value]="percentage" class="w-full"></mat-progress-bar>
    </ng-container>
  </div>

  <!-- PROGRESS -->
  <div *ngSwitchCase="ProgressState.Empty">
    <h1 class="mb-2 text-center truncate">{{ 'kw.base.download.wait' | translate }}</h1>
    <mat-progress-bar mode="indeterminate" class="w-full"></mat-progress-bar>
  </div>

  <!-- DEFAULT -->
  <div *ngSwitchDefault></div>
</div>

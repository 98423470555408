<div [ngClass]="headerClass" class="flex items-center">
  <div class="text-2xl font-extralight">
    {{ headerText | translate }}
  </div>
  <div
    (click)="openChargingSessionDialog()"
    accessibility-id="kw-test__kw-common__wallbox-session-info__btn--info"
    class="ml-2 cursor-pointer text-iron-500"
  >
    <span class="w-4 h-4 fill-basil-500" inlineSVG="assets/icons/24/info.svg"></span>
  </div>
</div>
<ng-container *ngIf="isWallboxSessionInfoVisible()">
  <kw-wallbox-session-info-row
    *ngIf="wallboxPvStatusInfo?.status === WallboxPVStatus.PV_PRE_CHARGING"
    [entryNameClass]="entryNameClass"
    [entryValueClass]="entryValueClass"
    [ngClass]="entryContainerClass"
    icon="pre-boost"
  >
    <ng-container title>{{ 'kw.charging-session-pv-status-info.pre-boost' | translate }}</ng-container>
    <ng-container text>
      {{ (wallboxPvStatusInfo?.timeLeft ?? 0) * 1000 | format: BaseFormat.DURATION_TO_INTERVAL_SHORT }}
    </ng-container>
  </kw-wallbox-session-info-row>

  <kw-wallbox-session-info-row
    *ngIf="wallboxPvStatusInfo?.status === WallboxPVStatus.PV_X1_ACTIVE"
    [entryNameClass]="entryNameClass"
    [entryValueClass]="entryValueClass"
    [ngClass]="entryContainerClass"
    icon="time"
  >
    <ng-container title>{{ 'kw.charging-session-pv-status-info.x1-boost' | translate }}</ng-container>
    <ng-container text>{{ 'kw.charging-session-pv-status-info.enabled' | translate }}</ng-container>
  </kw-wallbox-session-info-row>

  <div class="h-4"></div>
</ng-container>

<ng-container *ngFor="let definition of chargingSessionDefinition">
  <kw-wallbox-session-info-row
    *ngIf="definition.alwaysVisible || showAdditionalDetail"
    [entryNameClass]="entryNameClass"
    [entryValueClass]="entryValueClass"
    [icon]="definition.icon"
    [ngClass]="entryContainerClass"
  >
    <ng-container title>
      {{ definition.title | translate }}
      <ng-container *ngIf="definition.titleKey && getSubValues(definition); let values">
        (
        <ng-container *ngFor="let line of values; let last = last">
          <span class="bg-red">{{ line[definition.titleKey] }}<span *ngIf="!last">/</span></span></ng-container
        >
        )
      </ng-container>
    </ng-container>
    <ng-container text>
      <ng-container *kwAlias="getValue(definition.key) as value">
        <div
          *ngIf="isWallboxDataValueVisible(value); else noValue"
          [ngClass]="isShimmeringVisible(definition.key) ? 'is-loading' : ''"
        >
          <div class="preview">
            <ng-container *ngIf="isSingleValue(value); else isArray">
              <span> {{ value | format: definition.format }} </span>
            </ng-container>
            <ng-template #isArray>
              <ng-container *ngFor="let line of value; let last = last">
                <span *ngIf="definition.subKey; let subKey"
                  >{{ line[subKey] | format: definition.format }}<span *ngIf="!last">/</span></span
                >
              </ng-container>
            </ng-template>
            <span *ngIf="definition.unit; let unit"> {{ unit }}</span>
          </div>
        </div>
        <ng-template #noValue><span>-</span></ng-template>
      </ng-container>
    </ng-container>
  </kw-wallbox-session-info-row>
</ng-container>

<div class="text-center mt-7" *ngIf="enableAdditionalDetailButton">
  <button
    (click)="toggleShowAdditionalDetail()"
    accessibility-id="kw-test__kw-common__wallbox-session-info__btn--show-details"
    class="link-primary"
  >
    {{
      (showAdditionalDetail ? 'kwa_btn_detail_hide_additional_details' : 'kwa_btn_detail_show_additional_details')
        | translate
    }}
  </button>
</div>

import { BaseModel, RemoteRfidCard, RemoteRfidCardStatus } from '@kw-shared/api-data-access';

export interface WallboxRfidCard {
  id?: string;
  tokenId: string;
  name?: string;
  status?: RfidStatus;
  details?: string;
  master?: boolean;
  serialNumbers?: number[];
  expiryDate?: Date;
  usedDate?: Date;
  changedDate?: Date;
  chargePoints?: (BaseModel & { name?: string; serialNumber?: string; tags?: string[] })[];
}

export function toRemoteRfidCard(rfidCard: WallboxRfidCard): RemoteRfidCard {
  return {
    ...rfidCard,
    status: rfidCard.status as unknown as RemoteRfidCardStatus,
    expiryDate: rfidCard.expiryDate?.toISOString(),
  } as RemoteRfidCard;
}

export enum RfidStatus {
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
  INVALID = 'INVALID',
}

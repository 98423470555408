import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ENVIRONMENT, IEnvironment, Utils } from '@kw-shared/common';
import { filter, map, Observable, of, withLatestFrom } from 'rxjs';
import { IApiDataAccessEnvironment } from '../../IApiDataAccessEnvironment';
import { AccountsService } from '../accounts/accounts.service';
import { ApiAuthService } from '../auth/api-auth.service';
import { SecurityRule } from './securityRule';
import { SecurityUtils } from './SecurityUtils';

export interface SecureRouteData {
  securityRule?: SecurityRule;
}

@Injectable({
  providedIn: 'root',
})
export class SecurityGuard  {
  constructor(
    private readonly authService: ApiAuthService,
    private readonly accountsService: AccountsService,
    private readonly router: Router,
    @Inject(ENVIRONMENT) private readonly environment: IApiDataAccessEnvironment
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateAuth(true, route);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateAuth(true, route);
  }

  public canActivateAuth(value: boolean | UrlTree, route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (value) {
      const securityRule = (route.data as SecureRouteData)?.securityRule;
      if (securityRule?.allowIfNotLoggedIn && !this.authService.isLoggedIn()) {
        return of(true);
      }

      return this.authService.getUser$().pipe(
        filter(user => Utils.filterNullOrUndefined(user)),
        withLatestFrom(this.accountsService.getSelectedAccount$()),
        map(([user, account]) => {
          const accessRouteAllowed = SecurityUtils.checkAccessAllowed(
            user,
            account,
            securityRule,
            this.environment.api.defaultAllowRouteWithoutAccount
          );
          if (accessRouteAllowed) {
            return true;
          }

          if (user && !account && !securityRule?.allowWithoutAccount && this.environment.api.noAccountsRoute) {
            return this.router.createUrlTree([this.environment.api.noAccountsRoute]);
          }
          return this.router.createUrlTree(['/']);
        })
      );
    }
    return of(value);
  }
}

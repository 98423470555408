export interface AppVersionControlRequest {
  platform: string;
  version: string;
  language: string;
  messageId: 'updateToLatest';
}

export interface AppVersionControlResponse {
  update?: {
    link: string;
    message: string;
  };
}

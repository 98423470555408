import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { RemoteWallboxDiagnostics } from '../../../../models';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IDiagnosticsRepository } from './IDiagnosticsRepository';

export class DiagnosticsRepository
  extends WebDataRepository<RemoteWallboxDiagnostics>
  implements IDiagnosticsRepository
{
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath?: string
  ) {
    super(httpClient, Utils.joinPaths(basePath, 'diagnostics'));
  }

  public exportDiagnostics(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const endpoint = `${this.resource}/export/${wallboxId}`;
    return this.httpClient.get<RemoteWallboxDiagnostics>({ url: endpoint });
  }

  public exportSecurityLogs(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const endpoint = `${this.resource}/export/security/${wallboxId}`;
    return this.httpClient.get<RemoteWallboxDiagnostics>({ url: endpoint });
  }

  public getDiagnosticsFileDownloadUrl(diagnosticsId: string): Observable<Blob> {
    return this.httpClient.downloadFileDiagnostics<Blob>({ url: `${this.resource}/${diagnosticsId}/download` });
  }

  public getSecurityLogsFileDownloadUrl(diagnosticsId: string): Observable<Blob> {
    return this.httpClient.downloadFileDiagnostics<Blob>({
      url: `${this.resource}/${diagnosticsId}/download`,
    });
  }

  public getLatestDiagnostics(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    return this.httpClient.get<RemoteWallboxDiagnostics>({ url: `${this.resource}/${wallboxId}/latest` });
  }

  public getLatestSecurityLog(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    return this.httpClient.get<RemoteWallboxDiagnostics>({ url: `${this.resource}/${wallboxId}/latest` });
  }
}

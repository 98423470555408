import { Validators } from '@angular/forms';
import { AdditionalActionCall, FormInputType, SettingsFormData } from '../../components';

export const dataExportFormDefinition: SettingsFormData[] = [
  {
    title: 'kwa.settings.data-export.measurement.form-title',
    description: 'kwa.settings.data-export.measurement.form-description',
    elements: [
      {
        key: 'all',
        type: FormInputType.TOGGLE,
        defaultValue: 'true',
        title: 'kwa.settings.data-export.measurement.all.title',
        hideConfiguration: ['section'],
        listenOnChanges: true,
        accessibilityId: 'toggle-all',
      },
      {
        key: 'section',
        type: FormInputType.TOGGLE,
        title: 'kwa.settings.data-export.measurement.section.title',
        hideConfiguration: ['all'],
        listenOnChanges: true,
        accessibilityId: 'toggle-section',
      },
      {
        key: 'sectionInput',
        type: FormInputType.TEXT,
        description: 'kwa.settings.data-export.measurement.section.description',
        title: 'kwa.settings.data-export.measurement.section.title',
        hideElement: true,
        listenOnChanges: true,
        showDependentOnOtherSetting: { section: 'true' },
        accessibilityId: 'toggle-section-input',
        min: 1,
        validators: [
          Validators.pattern(/^\b(?:[1-9][0-9]*|\b[1-9][0-9]*-[0-9]+)(?:,[1-9][0-9]*|,[1-9][0-9]*-[0-9]+)*$/),
        ],
      },
      {
        key: AdditionalActionCall.DATA_EXPORT_MEASUREMENT,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.data-export.measurement.download',
        additionalActionCallType: AdditionalActionCall.DATA_EXPORT_MEASUREMENT,
        additionalActionCallData: ['section-input'],
        accessibilityId: 'btn--data-export',
      },
    ],
  },
  {
    title: 'kwa.settings.data-export.log.form-title',
    description: 'kwa.settings.data-export.log.form-description',
    elements: [
      {
        key: AdditionalActionCall.DATA_EXPORT_LOG,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.data-export.log.download',
        additionalActionCallType: AdditionalActionCall.DATA_EXPORT_LOG,
        accessibilityId: 'btn--data-export-log',
      },
    ],
  },
];

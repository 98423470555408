import { Validators } from '@angular/forms';
import { ConfigurationType, InterfaceType, OcppKey } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';

import { WallboxProductCodeRegex } from 'libs/common/utils/WallboxRegexes';
import {
  AdditionalActionCall,
  AdditionalCall,
  CONDITION_SHOW_IF_VALUE_IS_NOT_EMPTY,
  FormInputType,
  SettingsFormData,
} from '../../components';
import { WallboxCertificate } from '../../models';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

const NON_PORTAL_OCPP_CONNECTION_VERSIONS = ['OCPP_15', 'OCPP_16_SOAP', 'OCPP_16_JSON', '1.5', '1.6S', '1.6J'];

export const ocppFormDefinition: SettingsFormData[] = [
  {
    title: 'kwa.settings.proxy.form-title',
    accessibilityId: 'proxy-settings',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
      ],
    },
    elements: [
      {
        key: OcppKey.ENABLE_PROXY,
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.proxy.proxy_enable.description',
        title: 'kwa.settings.proxy.proxy_enable.title',
        listenOnChanges: true,
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'proxy',
      },
      {
        key: OcppKey.PROXY_ADDRESS,
        type: FormInputType.TEXT,
        description: 'kwa.settings.proxy.proxy_address.description',
        title: 'kwa.settings.proxy.proxy_address.title',
        hideElement: true,
        showDependentOnOtherSetting: { [OcppKey.ENABLE_PROXY]: 'true' },
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'proxy-address',
      },
      {
        key: OcppKey.PROXY_PORT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.proxy.proxy_port.description',
        title: 'kwa.settings.proxy.proxy_port.title',
        hideElement: true,
        showDependentOnOtherSetting: { [OcppKey.ENABLE_PROXY]: 'true' },
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'proxy-port',
        validators: [Validators.min(0), Validators.max(65535), Validators.required],
      },
      {
        key: OcppKey.PROXY_USERNAME,
        type: FormInputType.TEXT,
        description: 'kwa.settings.proxy.proxy_username.description',
        title: 'kwa.settings.proxy.proxy_username.title',
        hideElement: true,
        showDependentOnOtherSetting: { [OcppKey.ENABLE_PROXY]: 'true' },
        listenOnChanges: true,
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'proxy-username',
      },
      {
        key: OcppKey.PROXY_PASSWORD,
        type: FormInputType.PASSWORD,
        description: 'kwa.settings.proxy.proxy_password.description',
        title: 'kwa.settings.proxy.proxy_password.title',
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.PROXY_USERNAME]: CONDITION_SHOW_IF_VALUE_IS_NOT_EMPTY,
          [OcppKey.ENABLE_PROXY]: 'true',
        },
        listenOnChanges: true,
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'proxy-password',
      },
    ],
  },
  {
    accessibilityId: '',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
      ],
    },
    description: 'kw.settings.ocpp.remote-info',
    deepLink: {
      // dummy deeplink to ensure the group is shown
      link: '',
      title: '',
      showOnCondition: {
        alwaysFalseCondition: 'never',
      },
    },
    elements: [],
  },
  {
    title: 'kwa.settings.ocpp.form-title',
    accessibilityId: 'ocpp-settings',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
      ],
    },
    elements: [
      {
        key: OcppKey.CHARGE_POINT_ID,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.chargepoint-identity.description',
        title: 'kwa.settings.ocpp.chargepoint-identity.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'charge-point-id',
        pattern: /^\S+$/,
        patternText: 'kwa.settings.wallbox-name.requirement.no-space',
        validators: [Validators.pattern(/^\S+$/)],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.VERSION,
        type: FormInputType.SELECT,
        description: 'kwa.settings.ocpp.central-communication-type.description',
        title: 'kwa.settings.ocpp.central-communication-type.title',
        configurationType: ConfigurationType.OCPP,
        selectOptions: [
          { key: '1.5', text: '1.5', accessibilityId: 'select-1.5' },
          { key: '1.6S', text: '1.6 SOAP', accessibilityId: 'select-1.6s' },
          { key: '1.6J', text: '1.6 JSON', accessibilityId: 'select-1.6j' },
          { key: '1.6P', text: '1.6 PORTAL', disabled: true, accessibilityId: 'select-1.6p' },
        ],
        listenOnChanges: true,
        rebootRequired: true,
        accessibilityId: 'version',
        hideConditions: {
          operator: 'or',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'localRestApiVersion', value: '=1' },
          ],
        },
        changeValueConfirmation: {
          condition: (oldValue: any, nextValue: any) => nextValue !== oldValue && oldValue === '1.6P',
          data: {
            title: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.title',
            description: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.description',
            confirmText: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.confirm',
            showConfirm: true,
            showCancel: true,
          },
        },
      },
      {
        key: OcppKey.VERSION,
        type: FormInputType.SELECT,
        description: 'kwa.settings.ocpp.central-communication-type.description',
        title: 'kwa.settings.ocpp.central-communication-type.title',
        configurationType: ConfigurationType.OCPP,
        selectOptions: [
          { key: 'OCPP_15', text: '1.5', accessibilityId: 'select-1.5' },
          { key: 'OCPP_16_SOAP', text: '1.6 SOAP', accessibilityId: 'select-1.6s' },
          { key: 'OCPP_16_JSON', text: '1.6 JSON', accessibilityId: 'select-1.6j' },
          { key: 'OCPP_16_PORTAL', text: '1.6 PORTAL', disabled: true, accessibilityId: 'select-1.6p' },
        ],
        listenOnChanges: true,
        rebootRequired: true,
        accessibilityId: 'version',
        hideConditions: {
          operator: 'or',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'localRestApiVersion', value: '>1' },
          ],
        },
        changeValueConfirmation: {
          condition: (oldValue: any, nextValue: any) => nextValue !== oldValue && oldValue === 'OCPP_16_PORTAL',
          data: {
            title: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.title',
            description: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.description',
            confirmText: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.confirm',
            showConfirm: true,
            showCancel: true,
          },
        },
      },
      {
        key: OcppKey.VERSION,
        type: FormInputType.SELECT,
        description: 'kwa.settings.ocpp.central-communication-type.description',
        title: 'kwa.settings.ocpp.central-communication-type.title',
        configurationType: ConfigurationType.OCPP,
        selectOptions: [
          { key: 'OCPP_16_JSON', text: '1.6 JSON', accessibilityId: 'select-1.6j' },
          { key: 'OCPP_16_PORTAL', text: '1.6 PORTAL', disabled: true, accessibilityId: 'select-1.6p' },
        ],
        listenOnChanges: true,
        rebootRequired: true,
        accessibilityId: 'version',
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              negate: true,
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            { condition: 'localRestApiVersion', value: '>1' },
          ],
        },
        changeValueConfirmation: {
          condition: (oldValue: any, nextValue: any) => nextValue !== oldValue && oldValue === 'OCPP_16_PORTAL',
          data: {
            title: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.title',
            description: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.description',
            confirmText: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.confirm',
            showConfirm: true,
            showCancel: true,
          },
        },
      },
      {
        key: OcppKey.VERSION,
        type: FormInputType.SELECT,
        description: 'kwa.settings.ocpp.central-communication-type.description',
        title: 'kwa.settings.ocpp.central-communication-type.title',
        configurationType: ConfigurationType.OCPP,
        selectOptions: [
          { key: '1.6J', text: '1.6 JSON', accessibilityId: 'select-1.6j' },
          { key: '1.6P', text: '1.6 PORTAL', disabled: true, accessibilityId: 'select-1.6p' },
        ],
        listenOnChanges: true,
        rebootRequired: true,
        accessibilityId: 'version',
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              negate: true,
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            { condition: 'localRestApiVersion', value: '=1' },
          ],
        },
        changeValueConfirmation: {
          condition: (oldValue: any, nextValue: any) => nextValue !== oldValue && oldValue === '1.6P',
          data: {
            title: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.title',
            description: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.description',
            confirmText: 'kwa.settings.ocpp.confirm-change-communication-type-dialog.confirm',
            showConfirm: true,
            showCancel: true,
          },
        },
      },
      {
        key: AdditionalActionCall.PORTAL_UNENROLL,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.ocpp.btn-unenroll',
        additionalActionCallType: AdditionalActionCall.PORTAL_UNENROLL,
        accessibilityId: 'btn--unenroll',
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['OCPP_16_PORTAL', '1.6P'],
        },
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_ADDRESS,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.central-system-hostname.description',
        title: 'kwa.settings.ocpp.central-system-hostname.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'central-system-address',
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_PORT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.central-system-port.description',
        title: 'kwa.settings.ocpp.central-system-port.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'central-system-port',
        validators: [Validators.min(0), Validators.max(65535)],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_PATH,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.central-system-path.description',
        title: 'kwa.settings.ocpp.central-system-path.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'central-system-path',
        validators: [Validators.pattern(/^[a-zA-Z0-9$\-_.+!*'()/&?=:%]*$/)],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.OUTGOING_TLS,
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.ocpp.central-system-secure-connection.description',
        title: 'kwa.settings.ocpp.central-system-secure-connection.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'outgoing-tls',
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_AUTHENTICATION,
        type: FormInputType.SELECT,
        description: 'kwa.settings.ocpp.central-system-authentication-method.description',
        title: 'kwa.settings.ocpp.central-system-authentication-method.title',
        configurationType: ConfigurationType.OCPP,
        selectOptions: [
          { key: 'None', text: 'None', accessibilityId: 'select-none' },
          { key: 'BasicAuthentication', text: 'BasicAuthentication', accessibilityId: 'select-basic-auth' },
        ],
        listenOnChanges: true,
        rebootRequired: true,
        accessibilityId: 'central-system-auth',
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_USERNAME,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.central-system-username.description',
        title: 'kwa.settings.ocpp.central-system-username.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.CENTRAL_SYSTEM_AUTHENTICATION]: 'BasicAuthentication',
          [OcppKey.VERSION]: ['1.5', 'OCPP_15', '1.6S', 'OCPP_16_SOAP'],
        },
        rebootRequired: true,
        accessibilityId: 'central-system-username',
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_PASSWORD,
        type: FormInputType.PASSWORD,
        description: 'kwa.settings.ocpp.central-system-password.description',
        title: 'kwa.settings.ocpp.central-system-password.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.CENTRAL_SYSTEM_AUTHENTICATION]: 'BasicAuthentication',
          [OcppKey.VERSION]: ['1.5', 'OCPP_15', '1.6S', 'OCPP_16_SOAP'],
        },
        rebootRequired: true,
        accessibilityId: 'central-system-password',
      },
      {
        key: OcppKey.CENTRAL_SYSTEM_AUTHORIZATION_KEY,
        type: FormInputType.PASSWORD,
        description: 'kwa.settings.ocpp.central-system-authorization-key.description',
        title: 'kwa.settings.ocpp.central-system-authorization-key.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.CENTRAL_SYSTEM_AUTHENTICATION]: 'BasicAuthentication',
          [OcppKey.VERSION]: ['1.6J', 'OCPP_16_JSON'],
        },
        rebootRequired: true,
        accessibilityId: 'central-system-auth-key',
      },
      {
        key: OcppKey.CONNECTION_RETRY_INTERVAL,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.connection_retry_interval.description',
        title: 'kwa.settings.ocpp.connection_retry_interval.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        validators: [Validators.min(0), Validators.max(65535), Validators.required],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['1.6J', 'OCPP_16_JSON'],
        },
        rebootRequired: true,
        accessibilityId: 'connection-interval',
      },
      {
        key: OcppKey.WEB_SOCKET_PING_INTERVAL,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.web_socket_ping_interval.description',
        title: 'kwa.settings.ocpp.web_socket_ping_interval.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        validators: [Validators.min(0), Validators.max(65535), Validators.required],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['1.6J', 'OCPP_16_JSON'],
        },
        rebootRequired: true,
        accessibilityId: 'socket-interval',
      },
      {
        key: OcppKey.CHARGE_POINT_ADDRESS,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.chargepoint-address.description',
        title: 'kwa.settings.ocpp.chargepoint-address.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['1.5', 'OCPP_15', '1.6S', 'OCPP_16_SOAP'],
        },
        rebootRequired: true,
        accessibilityId: 'chargepoint-address',
      },
      {
        key: OcppKey.CHARGE_POINT_INTERFACE,
        type: FormInputType.SELECT,
        description: 'kwa.settings.ocpp.chargepoint-interface.description',
        title: 'kwa.settings.ocpp.chargepoint-interface.title',
        configurationType: ConfigurationType.OCPP,
        selectOptions: [{ key: InterfaceType.LAN, text: InterfaceType.LAN }],
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['1.5', 'OCPP_15', '1.6S', 'OCPP_16_SOAP'],
        },
        rebootRequired: true,
        accessibilityId: 'chargepoint-interface',
      },
      {
        key: OcppKey.CHARGE_POINT_PORT,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.ocpp.chargepoint-port.description',
        title: 'kwa.settings.ocpp.chargepoint-port.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['1.5', 'OCPP_15', '1.6S', 'OCPP_16_SOAP'],
        },
        rebootRequired: true,
        accessibilityId: 'chargepoint-port',
        validators: [Validators.min(0), Validators.max(65535), Validators.required],
      },
      {
        key: OcppKey.INCOMING_TLS,
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.ocpp.chargepoint-secure-connection.description',
        title: 'kwa.settings.ocpp.chargepoint-secure-connection.title',
        configurationType: ConfigurationType.OCPP,
        hideElement: true,
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: ['1.5', 'OCPP_15', '1.6S', 'OCPP_16_SOAP'],
        },
        rebootRequired: true,
        accessibilityId: 'incoming-tls',
      },
      {
        key: OcppKey.DEFAULT_TOKEN_ID,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.predefined-token.description',
        title: 'kwa.settings.ocpp.predefined-token.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'token-id',
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.METER_VALUES_SAMPLE_INTERVAL,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.meter-values-during-charging-session.description',
        title: 'kwa.settings.ocpp.meter-values-during-charging-session.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'meter-sample-interval',
        validators: [Validators.min(0), Validators.max(2147483647), Validators.required],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
      {
        key: OcppKey.METER_VALUES_CLOCK_ALIGNED_INTERVAL,
        type: FormInputType.TEXT,
        description: 'kwa.settings.ocpp.meter-values-referenced-to-clock.description',
        title: 'kwa.settings.ocpp.meter-values-referenced-to-clock.title',
        configurationType: ConfigurationType.OCPP,
        rebootRequired: true,
        accessibilityId: 'meter-clock-interval',
        validators: [Validators.min(0), Validators.max(2147483647), Validators.required],
        showDependentOnOtherSetting: {
          [OcppKey.VERSION]: NON_PORTAL_OCPP_CONNECTION_VERSIONS,
        },
      },
    ],
  },
  {
    title: 'kwa.settings.ocpp-certificates.form-title',
    accessibilityId: 'ocpp-certificates',
    elements: [
      {
        key: AdditionalCall.OCPP_CERTIFICATES,
        title: '',
        type: FormInputType.CERTIFICATES_LIST,
        hideElement: true,
        rebootRequired: true,
      },

      {
        key: AdditionalActionCall.OCPP_CERTIFICATE_SYSTEM_ROOT,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.ocpp-certificates.install-central-certificate.title',
        additionalActionCallType: AdditionalActionCall.OCPP_CERTIFICATE_SYSTEM_ROOT,
        hideConditions: { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        rebootRequired: true,
        accessibilityId: 'root-certificate',
      },
      {
        key: AdditionalActionCall.OCPP_CERTIFICATE_SERVER,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.ocpp-certificates.install-ocpp-certificate.title',
        additionalActionCallType: AdditionalActionCall.OCPP_CERTIFICATE_SERVER,
        hideConditions: { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        rebootRequired: true,
        accessibilityId: 'server-certificate',
      },
    ],
  },
];

export const ocppCertificateDefinition: { key: keyof WallboxCertificate; inputType?: FormInputType }[] = [
  {
    key: 'purpose',
  },
  {
    key: 'domain',
  },
  {
    key: 'issuedBy',
  },
  {
    key: 'serialNumber',
  },
  {
    key: 'validFrom',
    inputType: FormInputType.DATE,
  },
  {
    key: 'validTo',
    inputType: FormInputType.DATE,
  },
];

export enum PortalMainRoutes {
  DASHBOARD = 'dashboard',
  MY_WALLBOXES = 'wallboxes',

  STATISTICS = 'statistics',
  STATISTICS_CHARGING_SESSIONS = 'charging-sessions',
  STATISTICS_CHARGING_REPORTS = 'charging-reports',

  RFID_CARDS = 'rfid-card-management',
  RFID_CARDS_SETTINGS = 'rfid-cards',
  RFID_AUTHORIZATION = 'rfid-authorization',

  SETTINGS = 'settings',
  SETTINGS_TAGS = 'tags',
  SETTINGS_USERS = 'users',

  PROFILE = 'profile',
  PROFILE_EDIT = 'edit',
  PROFILE_CHANGE_PASSWORD = 'change-password',
  PROFILE_GLOBAL_SETTINGS = 'global-settings',

  CHARGING_PROFILES = 'charging-profiles',
  CHARGING_PROFILES_ADMINISTRATION = 'administration',
  CHARGING_PROFILES_ASSIGN_WALLBOXES = 'assign',

  ADMINISTRATION = 'admin',
  ADMINISTRATION_UPDATES = 'update-administration',
  ADMINISTRATION_OWNERSHIP_MIGRATION = 'ownership-migration',

  CONTACT = 'contact',
}

export enum PortalMainRouteParams {
  CHARGING_PROFILE_ID = 'charging-profile-id',
}

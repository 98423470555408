export enum WallboxBaseType {
  P30_X_SERIES = 'x-series',
  P30_C_SERIES = 'c-series',

  P40 = 'p40',
  M20 = 'm20',
}

export interface WallboxProductType {
  baseType: WallboxBaseType;
  subType?: string;
}

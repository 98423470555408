import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { Account } from '../../../../models';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IAccountRepository } from './IAccountRepository';

export class AccountRepository extends WebDataRepository<Account> implements IAccountRepository {
  constructor(public override httpClient: AdvancedHttpClient, private basePath?: string) {
    super(httpClient, Utils.joinPaths(basePath, 'accounts'));
  }

  public leaveAccount(accountId: string): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/${accountId}/leave` });
  }

  public deleteAccount(accountId: string, force?: boolean): Observable<void> {
    return this.httpClient.delete<void>({ url: `${this.resource}/${accountId}${force ? '/confirm' : ''}` });
  }

  public saveName(accountId: string, name: string): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/${accountId}/name`, body: { name } });
  }
}

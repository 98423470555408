import { Enrollment } from 'libs/api-data-access/models';
import { Observable } from 'rxjs';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IEnrollmentRepository } from './IEnrollmentRepository';

export class EnrollmentRepository extends WebDataRepository<any> implements IEnrollmentRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'enrollment');
  }

  public getEnrollmentCredentialsP30(accountId: string): Observable<Enrollment> {
    return this.httpClient.post<Enrollment>({ url: `${this.resource}/credentials`, body: { accountId } });
  }

  public getEnrollmentCredentialsP40(accountId: string): Observable<Enrollment> {
    return this.httpClient.post<Enrollment>({ url: `${this.resource}/p40/credentials`, body: { accountId } });
  }
}

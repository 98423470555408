import { RemoteWallboxState } from '@kw-shared/api-data-access';
import { LocalWallboxState, WallboxConnectionType, WallboxData, WallboxState } from '@kw-shared/common';
import { CommunicationChannel, LmgmtKey, ObjectKeys } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';

export abstract class AppWallboxUtils {
  public static isSuspended(state?: WallboxState) {
    return state
      ? [
          LocalWallboxState.REST_SUSPENDED,
          LocalWallboxState.UDP_TEMPORARILY_INTERRUPTION,
          RemoteWallboxState.SUSPENDED_BY_CAR,
          RemoteWallboxState.SUSPENDED_BY_WALLBOX,
        ].includes(state as RemoteWallboxState | LocalWallboxState)
      : false;
  }

  public static isCharging(state?: WallboxState) {
    return state
      ? [LocalWallboxState.REST_CHARGING, LocalWallboxState.UDP_CHARGING, RemoteWallboxState.CHARGING].includes(state)
      : false;
  }

  public static getChargingState(connectionType?: WallboxConnectionType): string {
    switch (connectionType) {
      case WallboxConnectionType.REMOTE:
        return RemoteWallboxState.CHARGING;
      case WallboxConnectionType.LOCAL_UDP:
        return LocalWallboxState.UDP_CHARGING;
      case WallboxConnectionType.LOCAL_REST:
      default:
        return LocalWallboxState.REST_CHARGING;
    }
  }

  public static getSuspendedState(connectionType?: WallboxConnectionType): string {
    switch (connectionType) {
      case WallboxConnectionType.REMOTE:
        return RemoteWallboxState.SUSPENDED_BY_WALLBOX || RemoteWallboxState.SUSPENDED_BY_CAR;
      case WallboxConnectionType.LOCAL_UDP:
        return LocalWallboxState.UDP_TEMPORARILY_INTERRUPTION;
      case WallboxConnectionType.LOCAL_REST:
        return LocalWallboxState.REST_SUSPENDED;
      default:
        return RemoteWallboxState.SUSPENDED_BY_CAR;
    }
  }

  public static calculateMaxPower(
    lmgmtConfiguration?: ObjectKeys,
    wallboxInfo?: Partial<WallboxData>
  ): number | undefined {
    if (lmgmtConfiguration && wallboxInfo) {
      const nominalVoltage = lmgmtConfiguration[LmgmtKey.NOMINAL_VOLTAGE];
      const maxPhases = wallboxInfo.maxPhases?.valueOf();
      const maxAvailableCurrent = lmgmtConfiguration[LmgmtKey.MAX_AVAILABLE_CURRENT];
      const maxCurrent = wallboxInfo.maxCurrent;

      if (maxPhases && nominalVoltage && maxAvailableCurrent && maxCurrent) {
        return Math.min(maxAvailableCurrent, maxCurrent) * maxPhases * nominalVoltage;
      }
    }
    return undefined;
  }

  public static calculateMaxAvailablePower(
    lmgmtConfiguration?: ObjectKeys,
    wallboxInfo?: Partial<WallboxData>
  ): number | undefined {
    if (lmgmtConfiguration && wallboxInfo) {
      const nominalVoltage = lmgmtConfiguration[LmgmtKey.NOMINAL_VOLTAGE];
      const maxCurrent = wallboxInfo?.maxCurrent;
      const maxPhases = wallboxInfo?.maxPhases?.valueOf();
      if (maxCurrent && maxPhases && nominalVoltage) {
        return maxCurrent * nominalVoltage * maxPhases;
      }
    }
    return undefined;
  }

  public static calculateMinAvailablePower(
    lmgmtConfiguration?: ObjectKeys,
    wallboxInfo?: Partial<WallboxData>
  ): number | undefined {
    if (lmgmtConfiguration) {
      const nominalVoltage = lmgmtConfiguration[LmgmtKey.NOMINAL_VOLTAGE];
      const minDefaultCurrent = lmgmtConfiguration[LmgmtKey.MIN_DEFAULT_CURRENT];
      const maxPhases = wallboxInfo?.maxPhases?.valueOf();
      if (maxPhases && minDefaultCurrent && nominalVoltage) {
        return minDefaultCurrent * nominalVoltage * maxPhases;
      }
    }
    return undefined;
  }

  public static readonly CHANNEL_PRIORITIES = [
    CommunicationChannel.REST,
    CommunicationChannel.BLE,
    CommunicationChannel.UDP,
  ];
  public static getPriorizedWallboxCommunicationChannels(
    channels: {
      address: string;
      channel: CommunicationChannel;
    }[]
  ) {
    return channels.sort((a, b) => {
      return this.CHANNEL_PRIORITIES.indexOf(a.channel) - this.CHANNEL_PRIORITIES.indexOf(b.channel);
    });
  }

  public static getWallboxCommunicationIconByChannel(channel?: CommunicationChannel) {
    switch (channel) {
      case CommunicationChannel.REST:
        return 'assets/icons/32/connect-wlan.svg';
      case CommunicationChannel.UDP:
        return 'assets/icons/32/connect-wlan.svg';
      case CommunicationChannel.BLE:
        return 'assets/icons/32/bluetooth.svg';

      default:
        return 'assets/icons/32/connect-wlan.svg';
    }
  }

  public static getWallboxAccessibilityIdAttributeByConnectionType(channel?: CommunicationChannel): string {
    return channel ? channel.toLocaleLowerCase() : 'unknown';
  }
}

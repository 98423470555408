import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { AnalyticsService, KWLogger, LanguageUtils, LogLevel, PlatformType, Utils } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { ApiAuthService } from '@kw-shared/api-data-access';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'kwp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly analyticsService: AnalyticsService,
    private readonly apiAuthService: ApiAuthService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.listenToUser();
    this.initLogger();
    this.initRouter();
    this.initLanguage();
    this.initAnalytics();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private listenToUser(): void {
    this.apiAuthService
      .getUser$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async user => {
        if (user) {
          this.analyticsService.setUserId(user.id);
        }
      });
  }

  private initLogger() {
    KWLogger.setLogLevel(environment.production ? LogLevel.None : LogLevel.Info);
  }

  private initLanguage(): void {
    const language = LanguageUtils.getCurrentLanguage();
    this.translateService.setDefaultLang(LanguageUtils.defaultLanguage);
    this.dateAdapter.setLocale(language);
    this.translateService.use(language);
  }

  private initAnalytics(): void {
    const currentCookieState = Utils.readCookie('cookieconsent_status');
    this.startStopAnalytics(currentCookieState);

    // Track custom event consentStatusChange to update Cookie preferences
    window.addEventListener('consentStatusChange', event => {
      const consentDetails = (event as CustomEvent).detail;
      this.startStopAnalytics(consentDetails);
    });
  }

  private startStopAnalytics(value: string): void {
    if (value === 'allow') {
      this.analyticsService.init(environment.analytics?.trackers);
    } else {
      this.analyticsService.disableAnalytics();
    }
  }

  private initRouter(): void {
    // Deactivate component reuse to ensure everything is re-created after account change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}

import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { Account, User } from '../../../../models';
import { AdvancedHttpClient, RequestParams } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IAccountRepository } from './IAccountRepository';

export class AccountRepository extends WebDataRepository<Account> implements IAccountRepository {
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath?: string
  ) {
    super(httpClient, Utils.joinPaths(basePath, 'accounts'));
  }

  public leaveAccount(accountId: string): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/${accountId}/leave` });
  }

  public getOwnedAccountsForUser(email: string): Observable<Account[]> {
    const queryParams: RequestParams = { email };
    return this.httpClient.get({
      url: `${this.resource}/owner`,
      queryParams: queryParams,
    });
  }

  public getAdminsOfAccount(accountId: string): Observable<User[]> {
    return this.httpClient.get({
      url: `/accounts/users/admin?accountId=${accountId}`,
    });
  }

  public migrateOwnership(accountId: string, newOwnerId: string, oldOwnerId: string): any {
    return this.httpClient.post({
      url: `/accounts/migrate?accountId=${accountId}&newOwnerId=${newOwnerId}&oldOwnerId=${oldOwnerId}`,
    });
  }

  public deleteAccount(accountId: string, force?: boolean): Observable<void> {
    return this.httpClient.delete<void>({ url: `${this.resource}/${accountId}${force ? '/confirm' : ''}` });
  }

  public saveName(accountId: string, name: string): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/${accountId}/name`, body: { name } });
  }
}

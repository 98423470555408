import { RemoteChargingSessionRfidCard, RemoteRfidCard, RemoteRfidCardStatus } from '@kw-shared/api-data-access';
import { addDays } from 'date-fns';
import { FakeRepository } from '../base/FakeRepository';
import { IRfidCardRepository } from './IRfidCardRepository';
import { map, Observable, of } from 'rxjs';

export class FakeRfidCardRepository extends FakeRepository<RemoteRfidCard> implements IRfidCardRepository {
  constructor() {
    super([
      {
        id: '1',
        tokenId: '123',
        name: 'Rfid card 1',
        expiryDate: addDays(new Date(), 10).toISOString(),
        chargePointsCount: 2,
        restrictedToChargePoints: true,
        status: RemoteRfidCardStatus.ACCEPTED,
        chargePoints: [
          { name: 'My favorite', serialNumber: '123', id: 'w-123' },
          { name: 'Office Indoor', serialNumber: '125', id: 'w-125' },
        ],
        tags: [
          { name: 'My favorite', id: '1' },
          { name: 'Office Indoor', id: '2' },
        ],
      },
      {
        id: '2',
        tokenId: 'abc',
        name: 'Rfid card 2',
        expiryDate: addDays(new Date(), 10).toISOString(),
        chargePointsCount: 1,
        restrictedToChargePoints: true,
        status: RemoteRfidCardStatus.EXPIRED,
        chargePoints: [{ name: 'My favorite', serialNumber: '123', id: 'w-123' }],
      },
    ]);
  }

  public getAvailableFilterRfidCards(): Observable<RemoteChargingSessionRfidCard[]> {
    return this.getAll().pipe(map(result => result.list.map(r => ({ tokenId: r.tokenId, tokenOwner: r.name }))));
  }
}

import { KWLogger, ToastMessageService } from '@kw-shared/common';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { User } from '../../models';
import { ApiDataService } from '../data-access/ApiDataService';

export abstract class ApiAuthService {
  protected user$ = new BehaviorSubject<User | undefined>(undefined);

  protected constructor(
    protected readonly dataService: ApiDataService,
    protected readonly messageService: ToastMessageService
  ) {}

  public loadUser(): Promise<User | undefined> {
    this.dataService.user.getUserMe().subscribe({
      next: user => {
        user.fullName = `${user.firstname} ${user.lastname}`;
        this.user$.next(user);
      },
      error: error => {
        KWLogger.warn(error);
        this.messageService.setError('loadUser()', error);
        this.user$.next(undefined);
      },
    });
    return firstValueFrom(this.getUser$());
  }

  public getUser$(): Observable<User | undefined> {
    return this.user$.asObservable();
  }

  public getUser(): User | undefined {
    return this.user$.value;
  }

  public isLoggedIn(): boolean {
    return this.user$.value != null;
  }

  public abstract login(suggestedEmail?: string): Promise<User | undefined>;
  public abstract logout(): Promise<void>;
  public abstract getAccessToken(): Promise<{ accessToken?: string }>;
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RemoteWallboxState } from '@kw-shared/api-data-access';
import { AppWallboxUtils } from 'apps/keba-wallbox-app/src/app/utils/AppWallboxUtils';
import { LmgmtKey, SessionStatus, WallboxType } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { DescriptionDialogComponent } from 'libs/common/components/dialogs/description-dialog/description-dialog.component';
import { Utils } from 'libs/common/utils';
import { Subscription } from 'rxjs';
import { WallboxChargingSession, WallboxData, WallboxPVStatus, WallboxPVStatusInfo } from '../../models';
import { BaseFormat } from '../../pipes';
import {
  ChargingSessionDisplayInfo,
  currentChargingSessionDefinition,
} from './definitions/charging-session-display.definition';
import { currentChargingSessionM20Definition } from './definitions/charging-session-m20-display.definition';
import { chargingSessionsM20DescriptionDefinition } from './definitions/charging-sessions-description-m20.definition';
import { chargingSessionsDescriptionDefinition } from './definitions/charging-sessions-description.definition';

@Component({
    selector: 'kw-wallbox-session-info',
    templateUrl: './wallbox-session-info.component.html',
    styleUrls: ['./wallbox-session-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WallboxSessionInfoComponent implements OnDestroy, OnInit {
  public readonly WallboxPVStatus = WallboxPVStatus;
  public readonly BaseFormat = BaseFormat;
  public readonly Utils = Utils;

  @Input()
  wallboxData: WallboxData;

  @Input()
  wallboxChargingSession?: WallboxChargingSession;

  @Input()
  wallboxPvStatusInfo?: WallboxPVStatusInfo;

  @Input()
  chargingStateKey: string;

  @Input()
  isRemoteWallbox: boolean;

  @Input()
  headerText: string = '';

  @Input()
  headerClass: string = '';

  @Input()
  entryContainerClass: string = '';

  @Input()
  entryNameClass: string = '';

  @Input()
  entryValueClass: string = '';

  @Input()
  isChargingSessionAvailable?: boolean;

  public showAdditionalDetail = false;
  public enableAdditionalDetailButton = true;
  public chargingSessionDefinition = currentChargingSessionDefinition;
  public chargingSessionDescription = chargingSessionsDescriptionDefinition;
  protected readonly RemoteWallboxState = RemoteWallboxState;
  private subscription = new Subscription();

  constructor(private dialog: MatDialog) {}

  private get hasChargingSessionEnded(): boolean {
    return this.wallboxChargingSession?.hasEnded || true;
  }

  ngOnInit(): void {
    const wallboxType = Utils.getWallboxModel(this.wallboxData.model ?? '');
    switch (wallboxType) {
      case (WallboxType.P40, WallboxType.P30):
        this.chargingSessionDefinition = currentChargingSessionDefinition;
        this.chargingSessionDescription = chargingSessionsDescriptionDefinition;
        break;
      case WallboxType.M20:
        this.chargingSessionDefinition = currentChargingSessionM20Definition;
        this.chargingSessionDescription = chargingSessionsM20DescriptionDefinition;
        this.enableAdditionalDetailButton = false;
        this.headerText = 'kw.wallbox-detail.charging-overview';
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggleShowAdditionalDetail(): void {
    this.showAdditionalDetail = !this.showAdditionalDetail;
  }

  public isSingleValue(value: unknown): boolean {
    return !Array.isArray(value);
  }

  public openChargingSessionDialog() {
    this.dialog.open(DescriptionDialogComponent, {
      data: {
        data: this.chargingSessionDescription,
      },
    });
  }

  public getValue(key: keyof WallboxData | keyof WallboxChargingSession): any {
    if (
      key === 'totalActiveChargingSessions' ||
      AppWallboxUtils.isCharging(this.wallboxData?.state) ||
      (AppWallboxUtils.isSuspended(this.wallboxData?.state) &&
        this.wallboxChargingSession?.status === SessionStatus.PWM_CHARGING) ||
      (this.isRemoteWallbox && !this.hasChargingSessionEnded) ||
      (this.isRemoteWallbox && this.hasChargingSessionEnded && (key === 'duration' || key === 'energyConsumed')) // show charging time and charged energy for ended remote charging session
    ) {
      if (key === 'lines') {
        let phaseUsed =
          this.wallboxData.phaseUsed ||
          Utils.getNestedPropertyValue(this.wallboxData, 'configuration.connector_phase_rotation');
        if (phaseUsed && ['L1', 'L2', 'L3'].indexOf(phaseUsed) >= 0) {
          return this.wallboxData.lines?.filter(it => it.socketPhase === phaseUsed);
        }
      }
      // @ts-ignore
      return this.wallboxData?.[key] ?? this.wallboxChargingSession?.[key] ?? null;
    }
    return null;
  }

  public isShimmeringVisible(key: keyof WallboxData | keyof WallboxChargingSession): boolean {
    if (this.wallboxData?.state === RemoteWallboxState.FINISHING || this.getValue(key) === 0) {
      return false;
    }
    return !this.getValue(key) || this.getValue(key) === null;
  }

  public isWallboxDataValueVisible(value: string): boolean {
    if (this.isChargingSessionAvailable) {
      if (value === null) {
        return true;
      }

      return true;
    } else {
      return value !== null;
    }
  }

  public getSubValues(definition: ChargingSessionDisplayInfo): any[] | undefined {
    if (definition.titleKey) {
      const subValues = (this.getValue(definition.key) as any[])?.filter(value => value[definition.titleKey ?? '']);
      if (subValues?.length) {
        return subValues;
      }
    }
    return undefined;
  }

  public isWallboxSessionInfoVisible(): boolean {
    if (
      Utils.isConfigurationEnabled(this.wallboxData, LmgmtKey.PV_ENABLE) &&
      this.wallboxPvStatusInfo &&
      this.wallboxPvStatusInfo.status !== WallboxPVStatus.PV_NONE
    ) {
      return true;
    }

    // Default return value to cover all other cases
    return false;
  }

  public isPVChargingActive(): boolean {
    return Utils.isConfigurationEnabled(this.wallboxData, LmgmtKey.PV_ENABLE);
  }
}

import { Observable, catchError, of } from 'rxjs';
import { GdprReport, User } from '../../../../models/User';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IUserRepository } from './IUserRepository';

export class UserRepository extends WebDataRepository<User> implements IUserRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'user');
  }

  public getUserMe(): Observable<User> {
    return this.httpClient.get<User>({ url: this.resource });
  }

  public deleteAuthUser(user: User): Observable<void> {
    return this.httpClient.delete<void>({ url: `${this.resource}/${user.id}` });
  }

  public updateADUser(): Observable<void> {
    return this.httpClient.post<void>({ url: 'ad/users/update', body: {} });
  }

  public generateGdprReport(userId: string): Observable<GdprReport> {
    return this.httpClient.post<GdprReport>({ url: `${this.resource}/${userId}/report` });
  }

  public getGdprReport(userId: string): Observable<GdprReport> {
    return this.httpClient
      .get<GdprReport>({ url: `${this.resource}/${userId}/report` })
      .pipe(catchError(() => of({ id: '' })));
  }

  public deleteGdprReport(userId: string): Observable<void> {
    return this.httpClient.delete<void>({ url: `${this.resource}/${userId}/report` });
  }

  public downloadGdprReport(url: string): Observable<Blob> {
    return this.httpClient.downloadFile<Blob>(url);
  }
}

import { IAnalyticsTracker } from '@kw-shared/common';
import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';

export class AzureAIAnalyticsTracker implements IAnalyticsTracker {
  private readonly defaultConfig: IConfiguration & IConfig = {
    enableCorsCorrelation: true, // required to correlate backend logs with frontend logs
    autoExceptionInstrumented: false,
    autoTrackPageVisitTime: false,
    autoUnhandledPromiseInstrumented: false,
    enableAutoRouteTracking: false,
    excludeRequestFromAutoTrackingPatterns: ['.*/assets/.*'],
  };

  private appInsights: ApplicationInsights;

  constructor(public config: IConfiguration & IConfig) {}

  public init(): void {
    this.appInsights = new ApplicationInsights({ config: { ...this.defaultConfig, ...this.config } });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(envelope => {
      let itemTags = envelope.tags;
      if (itemTags) {
        itemTags = envelope.tags || [];
        itemTags['ai.cloud.role'] = this.config.appId;
      }
    });
  }

  public trackEvent(event: string, params?: object): void {
    this.appInsights.trackEvent({ name: event, properties: params });
  }

  public trackScreen(name: string): void {
    this.appInsights.trackPageView({ name });
  }

  public trackException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  public disableAnalytics(): void {}
}

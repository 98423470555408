import { AdditionalActionCall, AdditionalCall, FormInputType, SettingsFormData } from '../../components';

export const webuiCertFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: AdditionalCall.WEBUI_CERTIFICATE,
        title: '',
        type: FormInputType.CERTIFICATES_LIST,
        hideElement: true,
      },
      {
        key: AdditionalActionCall.WEBUI_CERTIFICATE,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kwa.settings.webui-certificates.install.title',
        additionalActionCallType: AdditionalActionCall.WEBUI_CERTIFICATE,
        accessibilityId: 'btn--webui-certificate',
      },
    ],
  },
];

export const webuiCertificateDefinition = [
  {
    key: 'purpose',
  },
  {
    key: 'domain',
  },
  {
    key: 'issuedBy',
  },
  {
    key: 'serialNumber',
  },
  {
    key: 'validFrom',
    inputType: FormInputType.DATE,
  },
  {
    key: 'validTo',
    inputType: FormInputType.DATE,
  },
];

import { ToggleContext } from '@kw-shared/common';
import { ToggleConditionDefinition } from '../models/ToggleCondition';

export abstract class ToggleServiceUtils {
  public static checkUserRole(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (!ctx.userRole || !this.isConditionValid(condition)) {
      return false;
    }
    switch (condition.comparator) {
      case '==':
        return ctx.userRole === condition.value;
      case '!=':
        return ctx.userRole !== condition.value;
      case 'in':
        return condition.value!.indexOf(ctx.userRole) >= 0;
      case 'not-in':
        return condition.value!.indexOf(ctx.userRole) < 0;
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkUserRole`);
    }
  }

  public static checkWallboxType(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    let productCode = ctx.productCode;
    if (!productCode && ctx.type != undefined) {
      productCode = `-${ctx.type}`;
    }
    if (!productCode || !this.isConditionValid(condition)) {
      return false;
    }

    let wallboxType = productCode.split('-')[1];
    switch (condition.comparator) {
      case '==':
        return wallboxType === condition.value;
      case '!=':
        return wallboxType !== condition.value;
      case 'in':
        return condition.value!.indexOf(wallboxType) >= 0;
      case 'not-in':
        return condition.value!.indexOf(wallboxType) < 0;
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkWallboxType`);
    }
  }

  public static checkFirmwareVersion(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (!ctx.firmwareVersion || Array.isArray(ctx.firmwareVersion) || !this.isConditionValid(condition)) {
      return false;
    }

    let desiredVersion = condition.value!.toString();
    switch (condition.comparator) {
      case '==':
      case 'startsWith':
        return this.calculateFirmwareDiff(ctx.firmwareVersion, desiredVersion) === 0;
      case '!=':
        return this.calculateFirmwareDiff(ctx.firmwareVersion, desiredVersion) !== 0;
      case '>=':
        return this.calculateFirmwareDiff(ctx.firmwareVersion, desiredVersion) >= 0;
      case '<=':
        return this.calculateFirmwareDiff(ctx.firmwareVersion, desiredVersion) <= 0;
      case '>':
        return this.calculateFirmwareDiff(ctx.firmwareVersion, desiredVersion) > 0;
      case '<':
        return this.calculateFirmwareDiff(ctx.firmwareVersion, desiredVersion) < 0;
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkFirmwareVersion`);
    }
  }

  public static checkIsClient(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (!this.isConditionValid(condition)) {
      return false;
    }

    let stringValue = condition.value!.toString();
    switch (condition.comparator) {
      case '==':
        return this.isClient(ctx) === (stringValue.toLowerCase() === 'true');
      case '!=':
        return this.isClient(ctx) !== (stringValue.toLowerCase() === 'true');
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkIsClient`);
    }
  }

  public static checkHasClients(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (!this.isConditionValid(condition)) {
      return false;
    }

    let stringValue = condition.value!.toString();
    switch (condition.comparator) {
      case '==':
        return ctx.hasClients === (stringValue.toLowerCase() === 'true');
      case '!=':
        return ctx.hasClients !== (stringValue.toLowerCase() === 'true');
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkHasClients`);
    }
  }

  public static checkConnectionType(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (!ctx.connectionType || !this.isConditionValid(condition)) {
      return false;
    }

    switch (condition.comparator) {
      case '==':
        return ctx.connectionType === condition.value;
      case '!=':
        return ctx.connectionType !== condition.value;
      case 'in':
        return condition.value!.indexOf(ctx.connectionType) >= 0;
      case 'not-in':
        return condition.value!.indexOf(ctx.connectionType) < 0;
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkConnectionType`);
    }
  }

  public static checkWallboxState(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (!ctx.wallboxState || !this.isConditionValid(condition)) {
      return false;
    }
    switch (condition.comparator) {
      case 'not-null':
        return ctx.wallboxState != undefined;
      case 'is-null':
        return ctx.wallboxState == undefined;
      case '==':
        return ctx.wallboxState == condition.value;
      case '!=':
        return ctx.wallboxState != condition.value;
      case 'in':
        return condition.value!.indexOf(ctx.wallboxState) >= 0;
      case 'not-in':
        return condition.value!.indexOf(ctx.wallboxState) <= 0;
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkWallboxState`);
    }
  }

  public static checkCanAccessMaster(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (ctx.masterChargePointId == undefined) {
      return true;
    }

    if (
      ctx.allowedChargePointIds == undefined ||
      ctx.allowedChargePointIds.length === 0 ||
      !this.isConditionValid(condition)
    ) {
      return false;
    }

    let stringValue = condition.value!.toString();
    switch (condition.comparator) {
      case '==':
        return ctx.allowedChargePointIds.includes(ctx.masterChargePointId) == (stringValue === 'true');
      case '!=':
        return ctx.allowedChargePointIds.includes(ctx.masterChargePointId) != (stringValue === 'true');
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkCanAccessMaster`);
    }
  }

  public static checkConfig(condition: ToggleConditionDefinition, ctx: ToggleContext): boolean {
    if (
      !ctx.configuration ||
      !condition.propertyToCheck ||
      !ctx.configuration.hasOwnProperty(condition.propertyToCheck)
    ) {
      return false;
    }

    switch (condition.comparator) {
      case '==':
        return ctx.configuration[condition.propertyToCheck] === condition.value;
      case '!=':
        return ctx.configuration[condition.propertyToCheck] !== condition.value;
      default:
        throw new Error(`Comparator ${condition.comparator} not implemented for checkConfig`);
    }
  }

  private static isClient(ctx: ToggleContext) {
    return ctx.masterChargePointId != null;
  }

  private static isConditionValid(condition: ToggleConditionDefinition) {
    if (['in', 'not-in'].indexOf(condition.comparator) < 0) {
      return !Array.isArray(condition.value);
    }
    if (['in', 'not-in'].indexOf(condition.comparator) >= 0) {
      return Array.isArray(condition.value);
    }
    if (['null', 'not-null'].indexOf(condition.comparator) < 0) {
      return condition.value != undefined;
    }
    if (['null', 'not-null'].indexOf(condition.comparator) >= 0) {
      return condition.value == undefined;
    }
    return false;
  }

  private static calculateFirmwareDiff(currentVersion: string, desiredVersion: string) {
    let versionParts = currentVersion.replace(/\*/, '').split('.');
    let desiredVersionParts = desiredVersion.split('.').filter(it => it != '');
    let maxLength = Math.min(desiredVersionParts.length, versionParts.length);

    let result = 0;
    for (let i = 0; i < maxLength; i++) {
      result = parseInt(versionParts[i], 10) - parseInt(desiredVersionParts[i], 10);
      if (result !== 0) {
        return result;
      }
    }
    return result;
  }
}

import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeFR from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ApiAuthService, ApiDataAccessModule, IApiDataAccessEnvironment } from '@kw-shared/api-data-access';
import {
  CustomUrlSerializer,
  ENVIRONMENT,
  IEnvironment,
  KWCommonModule,
  KWLogger,
  NotificationComponent,
} from '@kw-shared/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalApiAuthService } from './services/auth/portal-api-auth.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDE);
registerLocaleData(localeFR);
registerLocaleData(localeEN);

export function MsalAuthFactory(env: IApiDataAccessEnvironment & IEnvironment) {
  const config = env.api.azureLoginConfig;
  return new PublicClientApplication({
    auth: {
      clientId: config.clientId,
      authority: config.authority,
      knownAuthorities: config.knownAuthorities,
      redirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          KWLogger.log(message);
        },
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MsalGuardConfigFactory(env: IApiDataAccessEnvironment & IEnvironment): MsalGuardConfiguration {
  const config = env.api.azureLoginConfig;
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [],
    },
    loginFailedRoute: config.loginFailedRoute,
  };
}

export function MsalInterceptorConfigFactory(
  env: IApiDataAccessEnvironment & IEnvironment
): MsalInterceptorConfiguration {
  const config = env.api.azureLoginConfig;
  const protectedResourceMap = new Map<string, string[] | null>();
  const unauthenticatedRoutes = ['/report/api/v1/charging-reports/unsubscribe'];

  for (const route of unauthenticatedRoutes) {
    protectedResourceMap.set(env.api.baseURL + route, null);
  }

  protectedResourceMap.set(env.api.baseURL, [config.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        InlineSVGModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatNativeDateModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            closeButton: true,
            positionClass: 'toast-top-center',
            toastComponent: NotificationComponent,
        }),
        MatDialogModule,
        KWCommonModule,
        AppRoutingModule,
        ApiDataAccessModule,
        MsalModule], providers: [
        DecimalPipe,
        DatePipe,
        { provide: ENVIRONMENT, useValue: environment },
        { provide: ApiAuthService, useExisting: PortalApiAuthService },
        {
            provide: MSAL_INSTANCE,
            useFactory: MsalAuthFactory,
            deps: [ENVIRONMENT],
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MsalGuardConfigFactory,
            deps: [ENVIRONMENT],
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MsalInterceptorConfigFactory,
            deps: [ENVIRONMENT],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

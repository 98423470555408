import { ToggleContext, ToggleContextProvider } from '@kw-shared/common';
import { ToggleService } from '../internal/toggle.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseToggleService {

  protected readonly contextProvider: ToggleContextProvider;
  protected readonly commonPrefix = 'kw.common';

  constructor(protected readonly toggleService: ToggleService) {
    this.contextProvider = ToggleContextProvider.instance;
  }

  public updateContext(context: ToggleContext): void {
    this.contextProvider.updateContext(context);
  }
}
<ul *ngIf="errors" class="list-unstyled">
  <li *ngFor="let error of getErrorList()" class="text-sm text-error-500">
    <ng-container [ngSwitch]="error['key']">
      <ng-container *ngSwitchCase="'required'">
        {{ 'kw.form-validation.required' | translate }}
      </ng-container>

      <ng-container *ngSwitchCase="'min'">
        {{ 'kw.form-validation.min' | translate: { value: error['value'] } }}
      </ng-container>

      <ng-container *ngSwitchCase="'max'">
        {{ 'kw.form-validation.max' | translate: { value: error['value'] } }}
      </ng-container>

      <ng-container *ngSwitchCase="'maxlength'">
        {{ 'kw.form-validation.maxlength' | translate: { value: error['data']['requiredLength'] } }}
      </ng-container>

      <ng-container *ngSwitchCase="'pattern'">
        {{ 'kw.form-validation.pattern' | translate }}
      </ng-container>

      <ng-container *ngSwitchCase="'maxAsymPhaseCurrentInvalid'">
        {{ 'kwa.settings.installer-settings.max-asym-current.error' | translate }}
      </ng-container>
    </ng-container>
  </li>
</ul>

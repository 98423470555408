import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgChartsModule } from 'ng2-charts';
import { ToastContainerDirective } from 'ngx-toastr';
import { HeaderNavigationModule } from '../../apps/keba-wallbox-app/src/app/components/header-navigation/header-navigation.module';
import {
  ConfirmByInputDialogComponent,
  ConfirmDialogComponent,
  FormFieldErrorComponent,
  FullScreenDialogComponent,
  InputSwitchComponent,
  MenuDialogComponent,
  MoreMenuComponent,
  NotificationComponent,
  ProfileInformationComponent,
  PvBoostButtonComponent,
  SettingsNoteComponent,
  StartStopChargingButtonComponent,
  UpdateStateComponent,
  WallboxSessionInfoComponent,
} from './components';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BaseConfirmDialogComponent } from './components/dialogs/base-confirm-dialog/base-confirm-dialog.component';
import { DescriptionDialogComponent } from './components/dialogs/description-dialog/description-dialog.component';
import { GlobalInfoDialogComponent } from './components/dialogs/global-info-dialog/global-info-dialog.component';
import { OptionsDialogComponent } from './components/dialogs/options-dialog/options-dialog.component';
import { DownloadProgressComponent } from './components/download-progress/download-progress.component';
import { GraphComponent } from './components/graph/graph.component';
import { PvChargingBoostConfigComponent } from './components/pv-charging-boost-config/pv-charging-boost-config.component';
import { SliderComponent } from './components/slider/slider.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { WallboxInfoBoxComponent } from './components/wallbox-info-box/wallbox-info-box.component';
import { WallboxSessionInfoRowComponent } from './components/wallbox-session-info/wallbox-session-info-row/wallbox-session-info-row.component';
import { AliasDirective } from './directives/alias.directive';
import { FormatPipe, LocaleDatePipe, LocaleDecimalPipe, ReplacePipe, ReverseFormatPipe } from './pipes';
import { AnalyticsErrorHandler } from './services/analytics/AnalyticsErrorHandler';

const COMPONENTS = [
  DownloadProgressComponent,
  GraphComponent,
  MenuDialogComponent,
  StartStopChargingButtonComponent,
  TabsComponent,
  WallboxSessionInfoRowComponent,
  WallboxSessionInfoComponent,
  DescriptionDialogComponent,
  OptionsDialogComponent,
  InputSwitchComponent,
  BaseConfirmDialogComponent,
  ConfirmDialogComponent,
  AvatarComponent,
  AliasDirective,
  ConfirmByInputDialogComponent,
  FullScreenDialogComponent,
  NotificationComponent,
  UpdateStateComponent,
  SliderComponent,
  PvBoostButtonComponent,
  PvChargingBoostConfigComponent,
  FormFieldErrorComponent,
  MoreMenuComponent,
  ProfileInformationComponent,
  WallboxInfoBoxComponent,
  SettingsNoteComponent,
  GlobalInfoDialogComponent,
];

const PIPES = [FormatPipe, ReverseFormatPipe, LocaleDatePipe, LocaleDecimalPipe, ReplacePipe];

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  InlineSVGModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSliderModule,
  NgChartsModule,
  ToastContainerDirective,
];

@NgModule({
  imports: [CommonModule, ...MODULES, HeaderNavigationModule],
  declarations: [...COMPONENTS, ...PIPES],
  providers: [DecimalPipe, DatePipe, { provide: ErrorHandler, useClass: AnalyticsErrorHandler }, ...PIPES],
  exports: [...COMPONENTS, ...PIPES, ...MODULES],
})
export class KWCommonModule {}

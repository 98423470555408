import { Inject, Injectable } from '@angular/core';
import { IAppEnvironment } from '../../../../../apps/keba-wallbox-app/src/environments/IAppEnvironment';
import { ENVIRONMENT, ToastMessageService, ToggleContextProvider } from '@kw-shared/common';
import { AccountsService, SecurityService } from '@kw-shared/api-data-access';
import { KWLogger, StorageUtils, Utils } from '../../../utils';
import { ToggleCondition, ToggleConditionDefinition, ToggleConditionGroup } from '../../../models/ToggleCondition';
import { ToggleServiceUtils } from '../../../utils/ToggleServiceUtils';
import configJson from './toggle-config.base.json';
import devConfigJson from './toggle-config.dev.json';
import qaConfigJson from './toggle-config.qa.json';
import { clone, mergeDeepRight } from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class ToggleService {
  private contextProvider: ToggleContextProvider;
  private readonly config: any;
  private readonly isDev: boolean;

  // in case checkConditions is called with a non-existing flag key, return this
  private DEFAULT_FLAG_VALUE: boolean = false;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: IAppEnvironment,
    private readonly accountsService: AccountsService,
    private readonly securityService: SecurityService,
    private readonly toastMessageService: ToastMessageService
  ) {
    this.isDev = !this.environment.production && !this.environment.qaEnvironment;
    this.config = this.initConfig();
    this.contextProvider = ToggleContextProvider.instance;
    this.contextProvider.initFromStorage();
    this.accountsService.getSelectedAccount$().subscribe(account => {
      this.contextProvider.updateContext({ userRole: this.securityService.getUserRole() });
    });
    this.readAllowedChargePointsFromStorage();
  }

  private readAllowedChargePointsFromStorage() {
    let allowedChargePointIds = StorageUtils.get<string>('allowedChargePointIds');
    if (allowedChargePointIds !== undefined) {
      this.contextProvider.updateContext({ allowedChargePointIds: allowedChargePointIds.split(';') });
    }
  }

  public checkConditions(key: string): boolean {
    let relevantConditions = Utils.getNestedPropertyValue(this.config, key);
    if (relevantConditions == undefined) {
      if (this.isDev) {
        this.toastMessageService.setError(`Unable to find conditions for ToggleKey [${key}]`);
      }
      KWLogger.error(`Unable to find conditions for ToggleKey`, key);
      return this.DEFAULT_FLAG_VALUE;
    }

    let prefix = key.substring(0, key.lastIndexOf('.'));
    return this.evaluateConditions(relevantConditions, prefix) || false;
  }

  private evaluateConditions(condition: ToggleCondition, prefix: string): boolean | undefined {
    if (typeof condition === 'boolean') {
      return condition;
    }

    if (typeof condition === 'string') {
      let conditionKey = condition.indexOf('.') >= 0 ? condition : `${prefix}.${condition}`;
      let cond = Utils.getNestedPropertyValue(this.config, conditionKey);
      if (!cond) {
        return false;
      }
      condition = cond;
    }

    if (condition.hasOwnProperty('operator')) {
      const groupCondition = condition as ToggleConditionGroup;
      let res: boolean | undefined = groupCondition.operator === 'and';
      for (let cond of groupCondition.conditions) {
        res =
          groupCondition.operator === 'and'
            ? res && this.evaluateConditions(cond, prefix)
            : res || this.evaluateConditions(cond, prefix);

        // Short-circuit if possible
        if ((groupCondition.operator === 'and' && !res) || (groupCondition.operator === 'or' && res)) {
          break;
        }
      }

      return groupCondition.invert ? !res : res;
    } else {
      const leafCondition = condition as ToggleConditionDefinition;
      return leafCondition.invert ? !this.calculateCondition(leafCondition) : this.calculateCondition(leafCondition);
    }
  }

  private calculateCondition(condition: ToggleConditionDefinition) {
    switch (condition.type) {
      case 'userRole':
        return ToggleServiceUtils.checkUserRole(condition, this.contextProvider.context);
      case 'wallboxType':
        return ToggleServiceUtils.checkWallboxType(condition, this.contextProvider.context);
      case 'firmwareVersion':
        return ToggleServiceUtils.checkFirmwareVersion(condition, this.contextProvider.context);
      case 'isClient':
        return ToggleServiceUtils.checkIsClient(condition, this.contextProvider.context);
      case 'hasClients':
        return ToggleServiceUtils.checkHasClients(condition, this.contextProvider.context);
      case 'connectionType':
        return ToggleServiceUtils.checkConnectionType(condition, this.contextProvider.context);
      case 'wallboxState':
        return ToggleServiceUtils.checkWallboxState(condition, this.contextProvider.context);
      case 'canAccessMaster':
        return ToggleServiceUtils.checkCanAccessMaster(condition, this.contextProvider.context);
      case 'config':
        return ToggleServiceUtils.checkConfig(condition, this.contextProvider.context);
      default:
        return false;
    }
  }

  private initConfig(): FeatureConfig {
    let mergedConfig: any = clone(configJson);
    if (this.environment.qaEnvironment) {
      mergedConfig = mergeDeepRight(mergedConfig, qaConfigJson);
    } else if (this.isDev) {
      mergedConfig = mergeDeepRight(mergedConfig, devConfigJson);
    }

    return mergedConfig;
  }
}

interface FeatureConfig {
  [namespace: string]: {
    [section: string]: {
      [featureName: string]: ToggleCondition;
    };
  };
}

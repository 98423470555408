<div
  class="flex items-center ml-auto"
  [attr.accessibility-id]="'kw-test__main__switch__btn--' + name + '--' + (value ?? false)"
>
  <span *ngIf="showLabel && !disabled" class="mr-2 text-sm font-light text-gray-900">
    {{ (value ? 'kw.switch.on' : 'kw.switch.off') | translate }}
  </span>

  <div
    class="fill-current shrink-0"
    [inlineSVG]="'assets/icons/switch-' + (small ? 'small-' : '') + (disabled ? 'on-disabled.svg' : 'on.svg')"
    [hidden]="!value"
  ></div>
  <div
    class="fill-current shrink-0"
    [inlineSVG]="'assets/icons/switch-' + (small ? 'small-' : '') + (disabled ? 'off-disabled.svg' : 'off.svg')"
    [hidden]="value"
  ></div>
</div>

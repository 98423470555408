import { ComponentType } from '@angular/cdk/portal';
import { BrowsingCallbackDataAdded } from '@keba-wallbox-plugin/plugin';
import { ProgressState, WallboxBaseType } from '@kw-shared/common';
import { BaseConnectGuideStepComponent } from '../../p30-connect-guide/components/base-connect-guide-step-component.directive';

export interface ConnectGuideStepDefinition<T = object> {
  title: string;
  component: ComponentType<BaseConnectGuideStepComponent>;
  progress?: number;
  input?: object;
  showIf?: (data: T) => boolean;
  canGoBack?: (data: T) => boolean;
  canGoForward?: (data: T) => boolean;
  canCloseGuide?: (data: T) => boolean;
  showCloseButton?: (data: T) => boolean;
}

export interface ConnectGuideData {
  productCode?: string;
  productType?: WallboxBaseType;
  productSubType?: string;
  networkType?: WallboxNetworkSelectionType;
  webUi?: {
    ipAddress?: string;
    username?: string;
    password?: string;
    loginValidated?: boolean;

    changePasswordRequired?: boolean;
    newPassword?: string;
    changePasswordDone?: boolean;
  };
  updatesRunning?: boolean;
  updatesFinished?: boolean;
  enablingRestApi?: boolean;
  restApiEnabled?: boolean;
  closeConnectGuide?: boolean;
  // ARNI
  isP40?: boolean;
  bleConnected?: boolean;
  bleConnection?: BrowsingCallbackDataAdded;
  showNextStep?: boolean;
  bleIpAddress?: string;
  showOpenWallbox?: boolean;
  canOpenWallbox?: boolean;
  showPairWallboxButton?: boolean;
  testingConnectionState?: ProgressState;
  origin?: ConnectGuideOrigin;
}

export enum WallboxNetworkSelectionType {
  LAN = 'lan',
  WLAN = 'wlan',
  WLAN_ACCESS_POINT = 'wlan-access-point',
  ALREADY_CONNECTED = 'already-connected',
  BLE = 'ble',
}

export enum ConnectGuideOrigin {
  CONNECT_GUIDE = 'connect-guide',
  INSTALLER_MODE = 'installer-mode',
  WALLBOX_DETAIL = 'wallbox-detail',
  WALLBOX_OVERVIEW = 'wallbox-overview',
}

export enum SubStepDisplayState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ACTION = 'action',
  LOADING = 'loading',
  HIDDEN = 'hidden',
}

export type BaseConnectGuideDialogData = {
  closeAll: boolean;
  origin: ConnectGuideOrigin;
};

export interface SubStepDisplayDefinition {
  title: string;
  info?: {
    title: string;
    mediaUrl?: string;
    text: string;
  };
  action?: {
    title: string;
    accessibilityId: string;
    state?: SubStepDisplayState;
    showStateTitle?: boolean;
    onClick?: () => void;
  };
}

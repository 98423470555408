import { AzureLoginConfig } from './api-data-access.module';

export interface IApiDataAccessEnvironment {
  api: {
    baseURL: string;
    azureLoginConfig: AzureLoginConfig;

    defaultAllowRouteWithoutAccount?: boolean;
    noAccountsRoute?: string;
    unauthorizedAPIs?: string[];
    updateApiSecret?: string;
    updateApiKey?: string;
  };
}

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { contextWallboxName } from '../interceptors/ocpp-response.interceptor';

export type RequestParams = { [s: string]: string };

export type RequestContext = 'context_wallbox_name';

export type RequestData = {
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';
  url: string;
  body?: any;
  headers?: RequestParams;
  queryParams?: RequestParams;
  version?: string;
  requestContext?: RequestContext;
  observe?: 'response' | 'body';
};

export abstract class AdvancedHttpClient {
  protected constructor(public httpClient: HttpClient) {}

  protected abstract get baseUri(): string;
  protected abstract get defaultVersion(): string;

  public get<T>(data: RequestData): Observable<T> {
    return this.request<T>({ ...data, method: 'GET' });
  }

  public getWithObserve<T>(data: RequestData): Observable<T | HttpResponse<T>> {
    return this.requestWithObserve<T>({ ...data, method: 'GET' });
  }

  public putWithObserve<T>(data: RequestData): Observable<T | HttpResponse<T>> {
    return this.requestWithObserve<T>({ ...data, method: 'PUT' });
  }

  public put<T>(data: RequestData): Observable<T> {
    return this.request<T>({ ...data, method: 'PUT' });
  }

  public post<T>(data: RequestData): Observable<T> {
    return this.request<T>({ ...data, method: 'POST' });
  }

  public delete<T>(data: RequestData): Observable<T> {
    return this.request<T>({ ...data, method: 'DELETE' });
  }

  public downloadFile<T>(url: string): Observable<Blob> {
    return this.requestFile<T>({ method: 'GET', url });
  }

  public downloadFileDiagnostics<T>(data: RequestData): Observable<Blob> {
    return this.requestFileDiagnostics<T>({ ...data, method: 'GET' });
  }

  protected request<T>(data: RequestData): Observable<T> {
    const finalVersion: string = data?.version || this.defaultVersion;
    const finalUrl: string = this.buildUrl(finalVersion, data.url);
    const context = this.getContext(data.requestContext);
    console.log('finalUrl', finalUrl);
    return this.httpClient
      .request(data.method!, finalUrl, {
        headers: data.headers,
        params: data.queryParams,
        body: data.body,
        responseType: 'json',
        observe: 'response',
        context,
      })
      .pipe(map(event => event.body as T));
  }

  protected requestWithObserve<T>(data: RequestData): Observable<T | HttpResponse<T>> {
    const finalVersion: string = data?.version || this.defaultVersion;
    const finalUrl: string = this.buildUrl(finalVersion, data.url);
    const context = this.getContext(data.requestContext);

    return this.httpClient
      .request<T>(data.method!, finalUrl, {
        headers: data.headers,
        params: data.queryParams,
        body: data.body,
        responseType: 'json',
        observe: 'response',
        context,
      })
      .pipe(
        map(event => {
          // Check if the full response is requested
          if (data.observe === 'response') {
            return event as HttpResponse<T>;
          } else {
            // Otherwise, return the response body
            return event.body as T;
          }
        })
      );
  }

  protected requestFile<T>(data: RequestData): Observable<Blob> {
    return this.httpClient
      .get(data.url, {
        responseType: 'arraybuffer',
        // observe: 'response',
        // reportProgress: true,
      })
      .pipe(map(event => new Blob([event], { type: 'application/octet-stream' })));
  }

  protected requestFileDiagnostics<T>(data: RequestData): Observable<Blob> {
    const finalVersion: string = data?.version || this.defaultVersion;
    const finalUrl: string = this.buildUrl(finalVersion, data.url);
    return this.httpClient
      .get(finalUrl, {
        responseType: 'arraybuffer',
      })
      .pipe(map(event => new Blob([event], { type: 'application/octet-stream' })));
  }

  protected buildUrl(version: string, url: string): string {
    return Utils.joinPaths(this.baseUri, version, url);
  }

  private getContext(requestContext?: RequestContext): HttpContext | undefined {
    if (!requestContext) {
      return;
    }

    switch (requestContext) {
      case 'context_wallbox_name':
        return contextWallboxName();
    }
  }
}

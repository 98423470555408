import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { marked } from 'marked';

@Component({
    selector: 'kw-base-confirm-dialog',
    templateUrl: './base-confirm-dialog.component.html',
    styleUrls: ['./base-confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BaseConfirmDialogComponent {
  @Input()
  iconName?: string;

  @Input()
  headline: string;

  @Input()
  description?: string;

  @Input()
  showCancel?: boolean;

  @Input()
  showConfirm?: boolean;

  @Input()
  @HostBinding('class')
  containerClass?: string;

  @Input()
  iconClass?: string;

  @Input()
  titleClass?: string;

  @Input()
  descriptionClass?: string;

  @Input()
  cancelButtonClass?: string;

  @Input()
  confirmButtonClass?: string;

  @Input()
  cancelButtonText?: string;

  @Input()
  confirmButtonText?: string;

  @Input()
  isMarkdownDescription?: boolean;

  @Input()
  additionalDescription?: string;

  @Input()
  isLoading?: boolean;

  @Output()
  cancel = new EventEmitter<false | undefined>(); // false = clicked on cancel button, undefined = user closed the dialog without deciding

  @Output()
  confirm = new EventEmitter<void>();

  public readonly marked = marked;

  public onCancelClicked(result: false | undefined): void {
    this.cancel.emit(result);
  }

  public onConfirmClicked(): void {
    this.confirm.emit();
  }
}

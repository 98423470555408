import { WebDataRepository } from '../base/WebDataRepository';
import { ITagRepository } from './ITagRepository';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { RemoteTag } from '../../../../models';
import { Utils } from '@kw-shared/common';

export class TagRepository extends WebDataRepository<RemoteTag> implements ITagRepository {
  constructor(public override httpClient: AdvancedHttpClient, private basePath?: string) {
    super(httpClient, Utils.joinPaths(basePath, 'tags'));
  }
}

import { UpdateStatus } from '@keba-wallbox-plugin/plugin';

export type WallboxUpdateStep = {
  title: string;
  updateState: UpdateStatus | undefined;
  finished: boolean;
  status?: UpdateStepStatus;
  progress?: number;
};

export interface WallboxUpdateStepStatus {
  status: UpdateStepStatus;
}

export enum UpdateStepStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
}

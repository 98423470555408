import { WallboxStateColor, WallboxStateDisplayDefinition } from '@kw-shared/common';
import { RemoteWallboxState } from '../models';

export const remoteWallboxStateDisplayDefinitions: { [key in RemoteWallboxState]: WallboxStateDisplayDefinition } = {
  [RemoteWallboxState.AVAILABLE]: {
    stateColor: WallboxStateColor.GREEN,
    title: 'kwp.charging-state.available',
    icon: 'available',
    accessibilityId: 'wallbox-available'
  },
  [RemoteWallboxState.PREPARING]: {
    stateColor: WallboxStateColor.GREEN,
    title: 'kwp.charging-state.preparing',
    icon: 'preparing_charging_finishing',
    accessibilityId: 'wallbox-preparing'
  },
  [RemoteWallboxState.CHARGING]: {
    stateColor: WallboxStateColor.GREEN,
    title: 'kwp.charging-state.charging',
    icon: 'preparing_charging_finishing',
    accessibilityId: 'wallbox-charging'
  },
  [RemoteWallboxState.FINISHING]: {
    stateColor: WallboxStateColor.BLUE,
    title: 'kwp.charging-state.finishing',
    icon: 'preparing_charging_finishing',
    accessibilityId: 'wallbox-finishing'
  },
  [RemoteWallboxState.SUSPENDED_BY_WALLBOX]: {
    stateColor: WallboxStateColor.BLUE,
    title: 'kwp.charging-state.suspended-by-wallbox',
    icon: 'suspended',
    accessibilityId: 'suspended-by-wallbox'
  },
  [RemoteWallboxState.SUSPENDED_BY_CAR]: {
    stateColor: WallboxStateColor.BLUE,
    title: 'kwp.charging-state.suspended-by-car',
    icon: 'suspended',
    accessibilityId: 'suspended-by-car'
  },
  [RemoteWallboxState.UNAVAILABLE]: {
    stateColor: WallboxStateColor.BLUE,
    title: 'kwp.charging-state.unavailable',
    icon: 'unavailable',
    accessibilityId: 'wallbox-unavailable'
  },
  [RemoteWallboxState.FAULTED]: {
    stateColor: WallboxStateColor.RED,
    title: 'kwp.charging-state.faulted',
    icon: 'faulted',
    accessibilityId: 'wallbox-faulted'
  },
  [RemoteWallboxState.OFFLINE]: {
    stateColor: WallboxStateColor.BLACK,
    title: 'kwp.charging-state.offline',
    icon: 'offline',
    accessibilityId: 'wallbox-offline'
  },
  [RemoteWallboxState.RESERVED]: {
    stateColor: WallboxStateColor.BLUE,
    title: 'kwp.charging-state.reserved',
    icon: 'reserved',
    accessibilityId: 'wallbox-reserved'
  },

  // TODO not in list?
  [RemoteWallboxState.ONLINE]: {
    stateColor: WallboxStateColor.ORANGE,
    title: 'kwp.charging-state.online',
    icon: 'available',
    accessibilityId: 'wallbox-online'
  },
};

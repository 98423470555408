import { Injectable } from '@angular/core';
import {
  ApiDataService,
  ExportStatus,
  RemoteWallbox,
  RemoteWallboxDiagnostics,
  WallboxDiagnosticsStatus,
} from '@kw-shared/api-data-access';
import { DownloadType, StorageUtils, ToastMessageService, WallboxUtils } from '@kw-shared/common';
import { RemoteWallboxConfig } from 'apps/keba-wallbox-app/src/app/services/current-wallbox-config.service';
import { BehaviorSubject, interval, Observable, Subject, switchMap, takeUntil, takeWhile, tap } from 'rxjs';

export interface DiagnosticStatus {
  diagnosticId: string;
  exportStatus: ExportStatus;
  type: DownloadType;
  isSecurityLog: boolean;
}

export interface SecurityLogStatus {
  securityLogId: string;
  exportStatus: ExportStatus;
  type: DownloadType;
  isSecurityLog: boolean;
}

export enum DiagnosticStorageKey {
  DIAGNOSTIC = 'diagnostic',
  SECURITY_LOG = 'security-log',
}

@Injectable({
  providedIn: 'root',
})
export class DiagnosticsService {
  private unsubscribe$ = new Subject<void>();
  private diagnosticStatus = new BehaviorSubject<DiagnosticStatus>({
    diagnosticId: '',
    exportStatus: ExportStatus.NOT_STARTED,
    type: DownloadType.DIAGNOSTICS,
    isSecurityLog: false,
  });

  private securityLogStatus = new BehaviorSubject<SecurityLogStatus>({
    securityLogId: '',
    exportStatus: ExportStatus.NOT_STARTED,
    type: DownloadType.SECURITY_LOG,
    isSecurityLog: true,
  });

  diagnosticCast = this.diagnosticStatus.asObservable();
  securityLogCast = this.securityLogStatus.asObservable();

  protected constructor(
    private messageService: ToastMessageService,
    private apiDataService: ApiDataService
  ) {}

  private static readonly STATUS_POLL_INTERVAL: number = 120;

  public exportDiagnostics(wallbox: RemoteWallbox | RemoteWallboxConfig | undefined, storage: boolean): void {
    if (!wallbox) {
      return;
    }

    let sessionStorage: any;
    if (storage) {
      sessionStorage = StorageUtils.get(DiagnosticStorageKey.DIAGNOSTIC + wallbox?.id) as any;
    }

    let id = sessionStorage?.id || '';
    const isExportDiagnostics = !sessionStorage?.isSecurityLogResponse;

    let diagnosticStatus = {
      diagnosticId: id,
      exportStatus: ExportStatus.LOADING,
      type: DownloadType.DIAGNOSTICS,
      isSecurityLog: false,
    };
    this.diagnosticStatus.next(diagnosticStatus);
    if (id && isExportDiagnostics) {
      this.pollDiagnosticsStatus(id, wallbox, storage, false);
    } else {
      this.startExportDiagnostics(wallbox, storage);
    }
  }

  private startExportDiagnostics(wallbox: RemoteWallbox | RemoteWallboxConfig, storage: boolean): void {
    this.apiDataService.diagnostics
      .exportDiagnostics(wallbox.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        diagnosticExportResult => {
          let id = diagnosticExportResult.id;
          if (storage) {
            StorageUtils.set(DiagnosticStorageKey.DIAGNOSTIC + wallbox?.id, {
              id: id,
              finished: false,
              isSecurityLog: false,
            });
          }
          this.pollDiagnosticsStatus(id, wallbox, storage, false);
        },
        error => {
          this.messageService.setError('kwp.error-loading-data');
          this.setStatus(false);
        }
      );
  }

  public getLatestDiagnostics(
    wallbox: RemoteWallbox | RemoteWallboxConfig | undefined
  ): Observable<RemoteWallboxDiagnostics> | undefined {
    if (wallbox) {
      return this.apiDataService.diagnostics.getLatestDiagnostics(wallbox.id).pipe(takeUntil(this.unsubscribe$));
    }
    return undefined;
  }

  public getLatestSecurityLog(
    wallbox: RemoteWallbox | RemoteWallboxConfig | undefined
  ): Observable<RemoteWallboxDiagnostics> | undefined {
    if (wallbox) {
      return this.apiDataService.diagnostics.getLatestSecurityLog(wallbox.id).pipe(takeUntil(this.unsubscribe$));
    }
    return undefined;
  }

  public exportSecurityLogs(wallbox: RemoteWallbox | RemoteWallboxConfig | undefined, storage: boolean): void {
    if (!wallbox) return;

    let sessionStorage: any;
    if (storage) {
      sessionStorage = StorageUtils.get(DiagnosticStorageKey.SECURITY_LOG + wallbox?.id) as any;
    }

    const id = sessionStorage?.id || '';
    const isExportSecurityLogs = sessionStorage?.isSecurityLogResponse;

    this.securityLogStatus.next({
      securityLogId: id,
      exportStatus: ExportStatus.LOADING,
      type: DownloadType.SECURITY_LOG,
      isSecurityLog: true,
    });
    if (id && isExportSecurityLogs) {
      this.pollDiagnosticsStatus(id, wallbox, storage, true);
    } else {
      this.startExportSecurityLogs(wallbox, storage);
    }
  }

  private startExportSecurityLogs(wallbox: RemoteWallbox | RemoteWallboxConfig, storage: boolean): void {
    this.apiDataService.diagnostics
      .exportSecurityLogs(wallbox.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        diagnosticExportResult => {
          const id = diagnosticExportResult.id;
          if (storage) {
            StorageUtils.set(DiagnosticStorageKey.SECURITY_LOG + wallbox?.id, {
              id: id,
              finished: false,
              isSecurityLog: true,
            });
          }
          this.pollDiagnosticsStatus(id, wallbox, storage, true);
        },
        error => {
          this.setStatus(false);
          this.messageService.setError('kwp.error-loading-data');
        }
      );
  }

  private pollDiagnosticsStatus(
    id: string,
    wallbox: RemoteWallbox | RemoteWallboxConfig,
    storage: boolean,
    isSecurityLog: boolean
  ): void {
    let count = 0;
    let isPollingActive = true; // Flag to stop polling when the diagnostic is complete
    const maxPolls = DiagnosticsService.STATUS_POLL_INTERVAL;
    const exportingMessage = this.messageService.showIndeterminateProgress(
      isSecurityLog
        ? `kwa.settings.logging.export-security-log.loading-title`
        : `kwa.settings.logging.export-diagnostics.loading-title`,
      isSecurityLog
        ? `kwa.settings.logging.export-security-log.loading-description`
        : `kwa.settings.logging.export-diagnostics.loading-description`
    );

    // Poll every 2 seconds
    interval(2000)
      .pipe(
        takeWhile(() => isPollingActive && count < maxPolls), // Stop polling if diagnostic is complete or max polls reached
        switchMap(() => this.apiDataService.diagnostics.getById(id)), // API call to fetch diagnostic status
        tap(diagnostic => {
          count++; // Increment the count at each interval tick
          const isSecurityLogResponse = diagnostic.securityLog; // Compare from response

          if (diagnostic.lastStatus === WallboxDiagnosticsStatus.Uploaded) {
            this.handleDiagnosticsComplete(
              diagnostic,
              wallbox,
              storage,
              exportingMessage,
              isSecurityLog,
              isSecurityLogResponse
            );
            isPollingActive = false; // Stop further polling
          } else if (WallboxUtils.isDiagnosticsExportFailureStatus(diagnostic.lastStatus)) {
            this.handleDiagnosticsFailure(diagnostic, wallbox, storage, exportingMessage, isSecurityLog);
            isPollingActive = false; // Stop further polling
          }

          if (count >= maxPolls) {
            this.handleTimeout(wallbox, storage, exportingMessage, isSecurityLog); // Handle timeout if max polls reached
            isPollingActive = false; // Stop further polling
          }
        }),
        takeUntil(this.unsubscribe$) // Stop polling if the service is unsubscribed
      )
      .subscribe({
        error: () => {
          this.messageService.setError('kwp.error-loading-data');
          exportingMessage.toastRef.manualClose();
        },
      });
  }

  private handleDiagnosticsComplete(
    diagnostic: RemoteWallboxDiagnostics,
    wallbox: RemoteWallbox | RemoteWallboxConfig,
    storage: boolean,
    exportingMessage: any,
    isSecurityLog: boolean,
    isSecurityLogResponse: boolean
  ): void {
    exportingMessage.toastRef.manualClose();

    if (storage) {
      const storageKey = isSecurityLog ? DiagnosticStorageKey.SECURITY_LOG : DiagnosticStorageKey.DIAGNOSTIC;
      StorageUtils.set(storageKey + wallbox?.id, {
        id: diagnostic.id,
        isSecurityLogResponse: isSecurityLogResponse,
        finished: true,
      });
    }
    this.setStatus(isSecurityLog, diagnostic, isSecurityLogResponse);
  }

  private handleDiagnosticsFailure(
    diagnostic: RemoteWallboxDiagnostics,
    wallbox: RemoteWallbox | RemoteWallboxConfig,
    storage: boolean,
    exportingMessage: any,
    isSecurityLog: boolean
  ): void {
    exportingMessage.toastRef.manualClose();
    this.messageService.setError('kwp.error-loading-data');
    if (storage) {
      const storageKey = isSecurityLog ? DiagnosticStorageKey.SECURITY_LOG : DiagnosticStorageKey.DIAGNOSTIC;
      StorageUtils.remove(storageKey + wallbox?.id);
    }
    this.setStatus(isSecurityLog, diagnostic);
  }

  private handleTimeout(
    wallbox: RemoteWallbox | RemoteWallboxConfig,
    storage: boolean,
    exportingMessage: any,
    isSecurityLog: boolean
  ): void {
    exportingMessage.toastRef.manualClose();
    this.messageService.setError('kwp.timeout-loading-data');

    if (storage) {
      const storageKey = isSecurityLog ? DiagnosticStorageKey.SECURITY_LOG : DiagnosticStorageKey.DIAGNOSTIC;
      StorageUtils.remove(storageKey + wallbox?.id);
    }
    this.setStatus(isSecurityLog);
  }

  private setStatus(
    isSecurityLog: boolean,
    diagnostic?: RemoteWallboxDiagnostics,
    isSecurityLogResponse?: boolean
  ): void {
    const type = isSecurityLog ? DownloadType.SECURITY_LOG : DownloadType.DIAGNOSTICS;
    isSecurityLog
      ? this.securityLogStatus.next({
          securityLogId: diagnostic?.id ?? '',
          exportStatus: ExportStatus.FINISHED,
          type: type,
          isSecurityLog: isSecurityLogResponse ?? false,
        })
      : this.diagnosticStatus.next({
          diagnosticId: diagnostic?.id ?? '',
          exportStatus: ExportStatus.FINISHED,
          type: type,
          isSecurityLog: isSecurityLogResponse ?? false,
        });
  }
}

import { FixedUserRoleType, RemoteWallboxSettingsConfiguration } from '@kw-shared/api-data-access';
import { WallboxConnectionType } from '../definitions';
import { WallboxState } from './WallboxData';

export interface ToggleContext {
  firmwareVersion?: string;
  productCode?: string;
  // todo
  options?: any;
  userRole?: FixedUserRoleType;
  connectionType?: WallboxConnectionType;
  masterChargePointId?: string;
  hasClients?: boolean;
  wallboxState?: WallboxState;
  allowedChargePointIds?: string[];
  configuration?: RemoteWallboxSettingsConfiguration;
  type?: 'P30' | 'P40' | 'M20';
}

import { Validators } from '@angular/forms';
import { ConfigurationType, ModbusKey } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { FormInputType, SettingsFormData } from '../../components';

export const modbusFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: ModbusKey.ENABlE_MODBUS,
        type: FormInputType.TOGGLE,
        defaultValue: 'false',
        description: 'kwa_lbl_settings_modbus_enabled_description',
        title: 'kwa_lbl_settings_modbus_enabled_title',
        configurationType: ConfigurationType.MODBUS,
        listenOnChanges: true,
        accessibilityId: 'modbus-enabled',
      },
      {
        key: ModbusKey.PORT,
        type: FormInputType.NUMBER,
        defaultValue: 502,
        description: 'kwa_lbl_settings_modbus_port_description',
        title: 'kwa_lbl_settings_modbus_port_title',
        configurationType: ConfigurationType.MODBUS,
        hideElement: true,
        showDependentOnOtherSetting: { [ModbusKey.ENABlE_MODBUS]: 'true' },
        min: 1,
        max: 65535,
        validators: [Validators.min(1), Validators.max(65535), Validators.required],
        accessibilityId: 'modbus-port',
      },
      {
        key: ModbusKey.UNIT_ID,
        type: FormInputType.NUMBER,
        defaultValue: 255,
        description: 'kwa_lbl_settings_modbus_unit_id_description',
        title: 'kwa_lbl_settings_modbus_unit_id_title',
        configurationType: ConfigurationType.MODBUS,
        hideElement: true,
        showDependentOnOtherSetting: { [ModbusKey.ENABlE_MODBUS]: 'true' },
        min: 0,
        max: 255,
        validators: [Validators.min(0), Validators.max(255), Validators.required],
        accessibilityId: 'modbus-unit-id',
      },
      {
        key: ModbusKey.ENABLE_RFID,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_modbus_rfid_enabled_description',
        title: 'kwa_lbl_settings_modbus_rfid_enabled_title',
        configurationType: ConfigurationType.MODBUS,
        hideElement: true,
        showDependentOnOtherSetting: { [ModbusKey.ENABlE_MODBUS]: 'true' },
        accessibilityId: 'modbus-rfid-enabled',
      },
      {
        key: ModbusKey.FAILSAFE_CURRENT,
        type: FormInputType.NUMBER,
        defaultValue: 32000,
        description: 'kwa_lbl_settings_modbus_failsafe_current_description',
        title: 'kwa_lbl_settings_modbus_failsafe_current_title',
        configurationType: ConfigurationType.MODBUS,
        hideElement: true,
        showDependentOnOtherSetting: { [ModbusKey.ENABlE_MODBUS]: 'true' },
        min: 0,
        max: 32000,
        validators: [Validators.min(0), Validators.max(32000), Validators.required],
        accessibilityId: 'modbus-failsafe-current',
      },
      {
        key: ModbusKey.FAILSAFE_TIMEOUT,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_modbus_failsafe_timeout_description',
        defaultValue: 30,
        title: 'kwa_lbl_settings_modbus_failsafe_timeout_title',
        configurationType: ConfigurationType.MODBUS,
        hideElement: true,
        showDependentOnOtherSetting: { [ModbusKey.ENABlE_MODBUS]: 'true' },
        max: 600,
        pattern: /^(0|[5-9]|\d{2,})$/,
        patternText: 'kwa.settings.modbus.requirement.failsafe-timeout-pattern',
        accessibilityId: 'modbus-failsafe-current',
        validators: [
          Validators.min(0),
          Validators.max(600),
          Validators.pattern(/^(0|[5-9]|\d{2,})$/),
          Validators.required,
        ],
      },
    ],
  },
];

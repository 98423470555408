export interface WallboxFirmwareVersionInfo {
  currentVersion?: string;
  updateId?: string;
  nextAvailableVersion?: {
    version: string;
    critical?: boolean;
    description?: string;
  };
  isUpdating?: boolean;
}

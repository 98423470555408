import { BaseModel } from './BaseModel';
import { UserInvitationState } from './InvitedUser';

export enum AccountType {
  PRIVATE = 'PRIVATE',
  ELECTRICIAN = 'ELECTRICIAN',
  OPERATOR = 'OPERATOR',
  WHOLESALE = 'WHOLESALE',
  OTHER = 'OTHER',
}

export interface Account extends BaseModel {
  name: string;
  userId: string;
  accountType?: AccountType;
  accountOwner?: boolean;
  userAccountInvitation?: UserAccountInvitation;
}

export interface UserAccountInvitation extends BaseModel {
  token: String;
  expirationDate: Date;
  invitationState: UserInvitationState;
  link: String;
  userAccountId: String;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupInfo } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { GlobalInfoDialogComponent } from '../dialogs/global-info-dialog/global-info-dialog.component';

@Component({
    selector: 'kw-setting-note',
    templateUrl: './settings-note.component.html',
    styleUrls: ['./settings-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SettingsNoteComponent {
  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}
  @Input() note: string;
  @Input() popupInfo?: PopupInfo;
  @Input() portal?: boolean;

  public openDialog(): void {
    if (!this.popupInfo) {
      return;
    }
    this.dialog.open(GlobalInfoDialogComponent, {
      data: {
        title: this.popupInfo.title,
        mediaUrl: this.popupInfo.mediaUrl ? this.translateService.instant(this.popupInfo.mediaUrl) : undefined,
        text: this.popupInfo.text,
        portal: this.portal,
      },
    });
  }
}

export enum WallboxSettingsPageType {
  DEVICE = 'DEVICE',
  CHARGING_NETWORK_PARAMETERS = 'CHARGING_NETWORK_PARAMETERS',
  NETWORK_CONNECTION = 'NETWORK_CONNECTION',
  PV_SETTINGS = 'PV_SETTINGS',
  PHASE_SWITCH_SETTINGS = 'PHASE_SWITCH_SETTINGS',
  X1_X2_SETTINGS = 'X1_X2',
  INSTALLER_SETTINGS = 'INSTALLER_SETTINGS',
  DATA_EXPORT = 'DATA_EXPORT',
  OCPP_SETTINGS = 'OCPP_SETTINGS',
  EXTERNAL_TCP_METER = 'EXTERNAL_TCP_METER',
  DISPLAY_TEXT = 'DISPLAY_TEXT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  FACTORY_DATA_RESET = 'FACTORY_DATA_RESET',
  AUTHENTICATION = 'AUTHENTICATION',
  LOGGING = 'LOGGING',
  REBOOT = 'REBOOT',
  DSW_SETTINGS = 'DSW_SETTINGS',
  WEBUI_CERTIFICATE = 'WEBUI_CERTIFICATE',
  CHARGEPOINT_PARAMETERS = 'CHARGEPOINT_PARAMETERS',
  MODBUS = 'MODBUS'
}

import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseModel } from '@kw-shared/api-data-access';
import { ConfirmDialogComponent, ConfirmDialogData } from '@kw-shared/common';
import { ItemDialogData } from '../pages/main/components/dialogs/ItemDialogData';
import { PortalMainRoutes } from '../pages/main/portal-main-routes.definition';
import { PortalRoutes } from '../portal-routes.definition';

@Injectable({
  providedIn: 'root',
})
export class PortalNavigationService {
  constructor(private readonly router: Router, private readonly dialog: MatDialog) {}

  public navigateToWallboxDetail(wallboxId: string) {
    this.router.navigate([PortalRoutes.MAIN, PortalMainRoutes.MY_WALLBOXES, wallboxId]);
  }

  public navigateToWallboxOverview(): void {
    this.router.navigate([PortalRoutes.MAIN, PortalMainRoutes.MY_WALLBOXES]);
  }

  public showRepositoryDialog<T extends BaseModel>(
    dialogComponent: ComponentType<unknown>,
    data?: ItemDialogData<T>,
    onClosed?: (result: any) => void
  ) {
    this.showDialog(dialogComponent, data, onClosed);
  }

  public showConfirmDialog(data: ConfirmDialogData, onClosed?: (result?: boolean) => void) {
    this.showDialog(ConfirmDialogComponent, data, onClosed);
  }

  public showDialog(dialogComponent: ComponentType<unknown>, data?: any, onClosed?: (result: any) => void) {
    this.dialog
      .open(dialogComponent, { data })
      .afterClosed()
      .subscribe(result => onClosed?.(result));
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, switchMap } from 'rxjs';
import { AccountsService } from './accounts.service';
import { WebApiDataService } from '../data-access/WebApiDataService';

@Injectable()
export class AccountInterceptor implements HttpInterceptor {
  constructor(private readonly accountsService: AccountsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(WebApiDataService.ACCOUNT_ID_PLACEHOLDER)) {
      return this.accountsService.getSelectedAccount$().pipe(
        filter(account => account != null),
        switchMap(account => {
          const newRequest = request.clone({
            url: request.url.replace(WebApiDataService.ACCOUNT_ID_PLACEHOLDER, account?.id ?? ''),
          });
          return next.handle(newRequest);
        })
      );
    }

    return next.handle(request);
  }
}

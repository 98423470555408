import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppType, ENVIRONMENT, IEnvironment } from '../../../IEnvironment';
import { ConfirmByInputDialogData } from './interfaces/confirm-by-input-dialog.interface';

@Component({
    selector: 'kw-confirm-by-input-dialog',
    templateUrl: './confirm-by-input-dialog.component.html',
    styleUrls: ['./confirm-by-input-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ConfirmByInputDialogComponent implements OnInit {
  public confirmation = '';
  public inputDirty = false;

  @ViewChild('confirmationInput') private confirmationInput: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmByInputDialogData,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    private mdDialogRef: MatDialogRef<ConfirmByInputDialogComponent>
  ) {}

  ngOnInit() {
    if (this.isApp) {
      this.mdDialogRef.addPanelClass('full-screen');
    }
  }

  public onConfirmClick(): void {
    if (this.confirmationValid) {
      this.mdDialogRef.close(true);
      return;
    }
    this.confirmationInput.nativeElement.focus();
  }

  public onClose(): void {
    this.mdDialogRef.close(false);
  }

  public onInputBlur(): void {
    this.inputDirty = true;
  }

  get confirmationValid(): boolean {
    return this.confirmation === this.data.confirmPhrase;
  }

  get phrase(): string {
    return this.data.confirmPhrase;
  }

  get isApp(): boolean {
    return this.environment.appType === AppType.APP;
  }

  get isPortal(): boolean {
    return !this.isApp;
  }
}

import { CsvColumnsExportDefinition, ExportFormat, TimeUtils } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';

export abstract class ExportUtils {
  public static CSV_DEFAULT_NUMBER_FORMAT: Intl.NumberFormatOptions = {
    useGrouping: false,
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    minimumIntegerDigits: 1,
  };

  public static async exportToCsv<T extends object>(
    columns: CsvColumnsExportDefinition<T>[],
    data: Observable<T[]> | T[],
    translateService: TranslateService
  ): Promise<string | undefined> {
    let dataResult: T[];
    if (Array.isArray(data)) {
      dataResult = data;
    } else {
      dataResult = await firstValueFrom(data);
    }

    if (dataResult) {
      return this.mapToCsv(columns, dataResult, translateService);
    }
    return undefined;
  }

  public static generateFileName(exportFormat: ExportFormat, prefix?: string): string {
    const timestamp = TimeUtils.getCurrentTimeStamp();

    return `${prefix}_${timestamp}.${exportFormat}`;
  }

  private static mapToCsv<T>(
    columns: CsvColumnsExportDefinition<T>[],
    data: T[],
    translateService: TranslateService,
    separator: string = ';'
  ): string {
    const headers = columns.map(e => translateService.instant(e.columnTitle));

    return [headers.map(header => `"${header}"`).join(separator)]
      .concat(
        data.map(element =>
          columns
            .map(column => {
              let result = column.getValue(element);
              if (column.numberFormatting) {
                result = (result as number).toLocaleString(translateService.currentLang, column.numberFormatting);
              }
              return result ? `"${result}"` : '';
            })
            .join(separator)
        )
      )
      .join('\n');
  }
}

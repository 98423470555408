import { UnitConverter } from './UnitConverter';

export class SettingsUnitsConverter {
  public static milliAmpereToAmpere(value: string): number | undefined {
    let parsedValue = Number.parseFloat(value);
    if (isNaN(parsedValue)) {
      return undefined;
    }
    return UnitConverter.milliAmpereToAmpere(Number.parseFloat(value));
  }

  public static ampereToMilliAmpere(value: number): string {
    return String(UnitConverter.ampereToMilliAmpere(value));
  }

  public static milliWattToKiloWatt(value: string): number {
    return UnitConverter.milliWattToKiloWatt(Number.parseFloat(value));
  }

  public static kiloWattToMilliWatt(value: number): string {
    return String(UnitConverter.kiloWattToMilliWatt(value));
  }

  public static milliWattToWatt(value: string): number {
    return UnitConverter.milliWattToWatt(Number.parseFloat(value));
  }

  public static wattToMilliWatt(value: number): string {
    return String(UnitConverter.wattToMilliWatt(value));
  }

  public static secondsToMinutes(value: string): number {
    return UnitConverter.secondsToMinutes(Number.parseFloat(value));
  }

  public static minutesToSeconds(value: number): string {
    return String(UnitConverter.minutesToSeconds(value));
  }
}

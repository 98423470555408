<div
  class="flex flex-col items-center px-5 mb-3 text-center bg-white shadow-keba-light rounded-2xl py-7"
>
  <div *ngIf="svgUrl" [inlineSVG]="svgUrl" class="w-8 h-8"></div>
  <p *ngIf="title" class="mt-2 font-medium">{{ title | translate }}</p>
  <p *ngIf="descriptionOne" class="font-light">
    {{ descriptionOne | translate }}
  </p>
  <p *ngIf="descriptionTwo" class="mt-6 font-light">
    {{ descriptionTwo | translate }}
  </p>
  <div class="py-2"></div>
  <ng-content select="#cta-button"></ng-content>
</div>

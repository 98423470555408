export class FirmwareVersions {
  public static P40_1_0_5 = '1.0.5';
  public static P40_1_0_0 = '1.0.0';
  public static P40_1_1_0 = '1.1.0';
  public static P40_1_2_0 = '1.2.0';
  public static P30_Supports_User_Name = '2.2.1';
  public static P30_1_15_0 = '1.15.0';
  public static P30_1_17_0 = '1.17.0';
  public static P30_1_19_0 = '1.19.0';
  public static M20_1_18_0 = '1.18.0';
}

import { WallboxBaseType } from '@kw-shared/common';
import { Observable, of } from 'rxjs';
import {
  RemoteWallboxFirmwareUpdate,
  RemoteWallboxFirmwareUpdateStatus,
} from '../../../../models/RemoteWallboxFirmwareUpdate';
import { FakeRepository } from '../base/FakeRepository';
import { IUkUpdatesRepository } from './IUkUpdatesRepository';
import { OfflineUpdateDetail } from './UkUpdatesRepository';

export class FakeUkUpdateRepository
  extends FakeRepository<RemoteWallboxFirmwareUpdate>
  implements IUkUpdatesRepository
{
  constructor() {
    super([
      {
        id: 'update-1',
        version: { major: 1, minor: 1, patch: 0 },
        minimumRequiredVersion: { major: 1, minor: 0, patch: 0 },
        productType: WallboxBaseType.P30_X_SERIES,
        status: RemoteWallboxFirmwareUpdateStatus.PUBLISHED,
        fileSize: 120000,
        latestStatusUpdate: new Date().toISOString(),
        criticalUpdate: true,
        description: [
          { lang: 'de', value: 'test de' },
          { lang: 'en', value: 'test en' },
          { lang: 'fr', value: 'test fr' },
        ],
      },
      {
        id: 'update-2',
        version: { major: 2, minor: 10, patch: 4 },
        minimumRequiredVersion: { major: 1, minor: 1, patch: 0 },
        productType: WallboxBaseType.P30_X_SERIES,
        status: RemoteWallboxFirmwareUpdateStatus.DRAFT,
        fileSize: 104000,
        latestStatusUpdate: new Date().toISOString(),
        criticalUpdate: false,
        description: [
          { lang: 'de', value: 'test de' },
          { lang: 'en', value: 'test en' },
          { lang: 'fr', value: 'test fr' },
        ],
      },
    ]);
  }

  public checkFirmwareUpdateOffline(
    serialNumber: string,
    model: string,
    version: string
  ): Observable<OfflineUpdateDetail | undefined> {
    const firmwareVersion = {
      currentVersion: '123',
      updateId: '111',

      isUpdating: false,
    };
    const updateDetail = {
      location: '',
      description: '',
      signingCertificate: '',
      signature: '',
      version: firmwareVersion,
      minimumRequiredVersion: firmwareVersion,
    };
    return this.delayResponse(of(updateDetail));
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { DescriptionDialogComponent, DescriptionDialogElement } from '@kw-shared/common';

@Component({
  selector: 'kwa-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavigationComponent {
  @Input() backButtonUrl?: string;
  @Input() backButtonParameter?: Params;
  @Input() backButtonTitle: string;
  @Input() showResetDialog?: boolean = false;

  @Input() headerTitle?: string;
  @Input() headerImage?: string;

  @Input() headerBackground: string;

  @Input() infoText: DescriptionDialogElement[];

  @Output() backButtonClick: EventEmitter<void> = new EventEmitter<void>();

  public verticalOffset: number;

  constructor(
    private router: Router,
    private readonly dialog: MatDialog
  ) {}

  @HostListener('window:scroll', []) onWindowScroll() {
    this.verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  public back(): void {
    if (this.backButtonUrl !== 'close') {
      this.router.navigate([this.backButtonUrl], { queryParams: this.backButtonParameter });
    }

    this.backButtonClick.emit();
  }

  public openInfoText(): void {
    this.dialog.open(DescriptionDialogComponent, {
      data: {
        data: this.infoText,
        centeredHeadline: true,
      },
    });
  }
}

import { RemoteAction, RemoteHttpError } from '@kw-shared/api-data-access';
import { Observable, fromEvent, map, startWith } from 'rxjs';
import { WallboxData } from '../models';
import { WallboxProductCodeRegex } from './WallboxRegexes';

import { NgForm } from '@angular/forms';
import { WallboxType } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';

export abstract class Utils {
  static media(query: string): Observable<boolean> {
    const mediaQuery = window.matchMedia(query);
    return fromEvent(mediaQuery, 'change').pipe(
      // @ts-ignore
      startWith(mediaQuery),
      map((list: MediaQueryList) => list.matches)
    );
  }

  /**
   * Combine paths and make sure two slashes aren't in sequence
   */
  public static joinPaths(...paths: Array<string | undefined>): string {
    return paths
      .filter(path => this.filterNullOrUndefined(path))
      .map((path, i) => {
        if (i === 0) {
          return path?.trim().replace(/[\/]*$/g, '');
        } else {
          return path?.trim().replace(/(^[\/]*|[\/]*$)/g, '');
        }
      })
      .filter(path => path?.length)
      .join('/');
  }

  public static clone<T>(obj?: T): T | undefined {
    if (obj != null) {
      return JSON.parse(JSON.stringify(obj));
    }
    return undefined;
  }

  public static isEscapeKeyPress(event: KeyboardEvent): boolean {
    return event.key === 'Escape' || event.key === 'Esc';
  }

  public static filterNullOrUndefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
  }

  public static first<T>(list: T[]): T | undefined {
    return list?.length ? list[0] : undefined;
  }

  public static getPromiseSettledResultOrUndefined<T>(result: PromiseSettledResult<T>): T | undefined {
    return result?.status === 'fulfilled' ? result.value : undefined;
  }

  public static createTimezoneAdjustedDate(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  public static isPageVisible(): boolean {
    return document.visibilityState === 'visible';
  }

  /**
   * Converts a string boolean value to a classic boolean.
   *
   * @param value The string boolean value to convert.
   * @returns The classic boolean value.
   */
  public static convertStringToBoolean(value: string): boolean {
    return value.toLowerCase() === 'true';
  }

  /**
   * Reads a cookie by name
   */
  public static readCookie(name: string): string {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
  }

  /**
   *  This function checks if the provided version string is equal to or greater than 2.1 (version),
   *  taking into account potential suffixes like "-SNAPSHOT".
   */
  public static isValidApiVersion(version: string, majorVersion: number, minorVersion: number) {
    // Removing any non-standard suffix like "-SNAPSHOT"
    const baseVersion = version.split('-')[0];

    // Splitting version into [major, minor, patch]
    const [major, minor = 0, patch = 0] = baseVersion.split('.').map(Number);

    return major > majorVersion || (major === majorVersion && minor >= minorVersion);
  }

  /**
   * used for error response handling to show user specific error messages
   */

  public static handleErrorData(remoteError?: RemoteHttpError): string | undefined {
    const errorData: any = remoteError?.data?.find(d => d.key === 'errorData')?.value;
    if (errorData) {
      return errorData.body?.join(', ');
    }
    return;
  }

  public static handleAction(action: RemoteAction): string {
    if (action in RemoteAction) {
      // action is already defined
      return 'kw.remote-error.action.' + action.toLowerCase();
    }

    // action is not defined - add translation key!
    return action;
  }

  public static getConfigurationValue(wallboxData: WallboxData, key: string): string | undefined {
    const configValue = wallboxData?.configuration?.[key];
    if (Array.isArray(configValue)) {
      return configValue[0] ?? undefined;
    }
    return configValue ?? undefined;
  }

  public static isConfigurationEnabled(wallboxData: WallboxData, key: string): boolean {
    const configValue = this.getConfigurationValue(wallboxData, key);
    return configValue === 'true';
  }

  public static isP40Wallbox(wallboxModel: string): boolean {
    return Boolean(wallboxModel.match(WallboxProductCodeRegex.IS_P40));
  }

  public static isP30Wallbox(wallboxModel: string): boolean {
    return Boolean(wallboxModel.match(WallboxProductCodeRegex.IS_P30));
  }

  public static isM20Wallbox(wallboxModel: string | undefined): boolean {
    return wallboxModel == undefined ? false : Boolean(wallboxModel.match(WallboxProductCodeRegex.IS_M20));
  }

  public static getWallboxModel(wallboxModel: string): string {
    if (this.isP40Wallbox(wallboxModel)) {
      return WallboxType.P40;
    }
    if (this.isP30Wallbox(wallboxModel)) {
      return WallboxType.P30;
    }
    if (this.isM20Wallbox(wallboxModel)) {
      return WallboxType.M20;
    }
    return 'UNKNOWN';
  }

  /**
   * Checks if two BLE pin fields are identical.
   * @param newBlePin - The first BLE pin.
   * @param newBlePinRepeat - The repeated BLE pin.
   * @returns True if the BLE pins are identical, false otherwise.
   */
  public static checkIdenticalBlePins(newBlePin: string, newBlePinRepeat: string): boolean {
    return newBlePin === newBlePinRepeat;
  }

  /**
   * Validates the full form and checks the repeat BLE pin control for errors.
   * @param form - The form object containing the controls.
   * @param controlName
   * @param newValue
   * @param repeatValue
   */
  public static validateFullForm(
    form: NgForm,
    controlName: 'password' | 'repeat-ble-pin',
    newValue: string,
    repeatValue: string
  ): void {
    const control = form?.form.controls[controlName];

    if (newValue !== repeatValue) {
      control?.setErrors({ invalid: true });
    } else {
      control?.setErrors(null);
    }

    control?.updateValueAndValidity();
  }

  public static getNestedPropertyValue(obj: any, key: string) {
    let keyParts = key.split('.');
    if (!obj.hasOwnProperty(keyParts[0])) {
      return undefined;
    }

    let result = undefined;
    for (let i = 0; i < keyParts.length; i++) {
      result = result ? result[keyParts[i]] : obj[keyParts[i]];
    }
    return result;
  }

  public static tryParseInt(value: string): number {
    let result = parseInt(value, 10);
    if (result && !isNaN(result)) {
      return result;
    }
    throw new Error(`${value} is not a valid number`);
  }
}

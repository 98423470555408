import { Validators } from '@angular/forms';
import { ConfigurationType, LmgmtKey } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { FormInputType, SettingsFormData } from '../../components';
import { SettingsUnitsConverter, WallboxProductCodeRegex } from '../../utils';
import { SettingsValidators } from '../../utils/SettingsValidators';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

export const chargingNetworkFormDefinition: SettingsFormData[] = [
  {
    title: 'kwa.settings.charging-network.form-title',
    accessibilityId: 'charging-network-settings',
    elements: [
      {
        key: LmgmtKey.MAX_AVAILABLE_CURRENT,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.charging-network.max-available-current.description',
        title: 'kwa.settings.charging-network.max-available-current.title',
        configurationType: ConfigurationType.LMGMT,
        format: '1.1-1',
        min: 6,
        max: 512,
        validators: [
          Validators.min(6),
          Validators.max(512),
          Validators.required,
          SettingsValidators.maxCurrentValidator,
        ],
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        accessibilityId: 'max-available-current',
      },
      {
        key: LmgmtKey.MIN_DEFAULT_CURRENT,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.charging-network.min-charge-current.description',
        title: 'kwa.settings.charging-network.min-charge-current.title',
        configurationType: ConfigurationType.LMGMT,
        format: '1.1-1',
        min: 6,
        max: 32,
        validators: [
          Validators.min(6),
          Validators.max(32),
          SettingsValidators.maxCurrentValidator,
          Validators.required,
        ],
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        accessibilityId: 'min-default-current',
      },
      {
        key: LmgmtKey.MAX_ASYM_PHASE_CURRENT,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.charging-network.max-current-asymmetric-load.description',
        title: 'kwa.settings.charging-network.max-current-asymmetric-load.title',
        configurationType: ConfigurationType.LMGMT,
        format: '1.1-1',
        min: 0,
        max: 32,
        validators: [
          Validators.min(0),
          Validators.max(32),
          Validators.required,
          SettingsValidators.maxAsymPhaseCurrentValidator,
        ],
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        accessibilityId: 'max-phase-current',
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
              ],
            },
          ],
        },
      },
      {
        key: LmgmtKey.ENABLE_ASYM_NETWORK,
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.charging-network.avoid-asymmetric-loads.description',
        title: 'kwa.settings.charging-network.avoid-asymmetric-loads.title',
        configurationType: ConfigurationType.LMGMT,
        accessibilityId: 'asym-network',
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
              ],
            },
          ],
        },
      },
    ],
  },
];

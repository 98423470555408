<nav class="flex overflow-hidden border divide-x rounded-lg border-basil-500 divide-basil-500" aria-label="Tabs">
  <a
    *ngFor="let tab of tabs; let first = first; let last = last"
    (click)="setTab(tab.key)"
    class="flex-1 p-4 text-center cursor-pointer hover:bg-basil-900 hover:text-white"
    [ngClass]="[selectedTab === tab.key ? 'bg-basil-500 text-white' : 'bg-white text-basil-500', tabClass]"
    [attr.accessibility-id]="tab.accessibilityId"
  >
    <span> {{ tab.title | translate }} </span>
  </a>
</nav>

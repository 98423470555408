import { InvitedUser, InvitedUserResponse } from '@kw-shared/api-data-access';
import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { User } from '../../../../models/User';
import { AdvancedHttpClient, RequestParams } from '../../http/AdvancedHttpClient';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort } from '../../types/Sort';
import { WebDataRepository } from '../base/WebDataRepository';
import { IUserManagementRepository } from './IUserManagementRepository';

export class UserManagementRepository extends WebDataRepository<User> implements IUserManagementRepository {
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath?: string
  ) {
    super(httpClient, Utils.joinPaths(basePath, 'user'));
  }

  public getInvitedUserByEmail(email: string): Observable<InvitedUser> {
    return this.httpClient.get<InvitedUser>({ url: `${this.resource}/${email}` });
  }

  public updateInvitedUser(entity: InvitedUser): Observable<InvitedUser> {
    return this.putInvitedUser(entity);
  }

  public inviteUser(entity: InvitedUser): Observable<InvitedUser> {
    return this.putInvitedUser(entity);
  }

  public reInviteUser(email: string): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/reinvite`, body: { email } });
  }

  public override getAll(
    filters?: Filter[],
    sort?: Sort<User>[],
    pagination?: Pagination
  ): Observable<DataWithCount<User>> {
    const queryParams: RequestParams = {};
    this.addFilterQueryParams(queryParams, filters);
    this.addSortQueryParams(queryParams, sort);
    this.addPaginationQueryParams(queryParams, pagination);
    return this.httpClient.get<DataWithCount<User>>({ url: `${this.resource}/all`, queryParams });
  }

  public override delete(entity: Partial<User>): Observable<void> {
    return this.httpClient.delete<void>({ url: `${this.resource}/${entity.email}` });
  }

  public acceptInvitation(token: string): Observable<InvitedUserResponse> {
    return this.httpClient.put<InvitedUserResponse>({ url: `${this.resource}/invite/accept`, body: { token } }); // returns error code 410 (GONE) when token is expired
  }

  protected putInvitedUser(entity: InvitedUser): Observable<InvitedUser> {
    return this.httpClient.put<InvitedUser>({ url: `${this.resource}/invite`, body: entity });
  }
}

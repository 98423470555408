import { Account, ApplicationFeature, SecurityRule, User, SecurityPermission } from '@kw-shared/api-data-access';
import { map, Observable, withLatestFrom } from 'rxjs';

export abstract class SecurityUtils {
  public static checkFeatureAccessAllowed$(
    user$: Observable<User | undefined>,
    account$: Observable<Account | undefined>,
    feature: ApplicationFeature,
    permission: SecurityPermission = SecurityPermission.READ,
    allowIfNotLoggedIn: boolean = false,
    defaultAllowWithoutAccount?: boolean
  ): Observable<boolean> {
    return SecurityUtils.checkAccessAllowed$(
      user$,
      account$,
      {
        allowIfNotLoggedIn,
        requiredAccountPermissions: [{ feature, permission }],
      },
      defaultAllowWithoutAccount
    );
  }
  public static checkFeatureAccessAllowed(
    user: User | undefined,
    account: Account | undefined,
    feature: ApplicationFeature,
    permission: SecurityPermission = SecurityPermission.READ,
    allowIfNotLoggedIn: boolean = false,
    defaultAllowWithoutAccount?: boolean
  ): boolean {
    return SecurityUtils.checkAccessAllowed(
      user,
      account,
      {
        allowIfNotLoggedIn,
        requiredAccountPermissions: [{ feature, permission }],
      },
      defaultAllowWithoutAccount
    );
  }

  public static checkAccessAllowed$(
    user$: Observable<User | undefined>,
    account$: Observable<Account | undefined>,
    securityRule?: SecurityRule,
    defaultAllowWithoutAccount?: boolean
  ): Observable<boolean> {
    return user$.pipe(
      withLatestFrom(account$),
      map(([user, account]) =>
        SecurityUtils.checkAccessAllowed(user, account, securityRule, defaultAllowWithoutAccount)
      )
    );
  }

  public static checkAccessAllowed(
    user?: User,
    account?: Account,
    securityRule?: SecurityRule | null,
    defaultAllowWithoutAccount?: boolean
  ): boolean {
    if (user) {
      if (account) {
        let isAccessAllowed = true;
        if (securityRule) {
          if (securityRule.requiresKebaAdmin && !user.admin) {
            return false;
          }

          if (securityRule.requiredAccountPermissions?.length) {
            isAccessAllowed &&= securityRule.requiredAccountPermissions.every(securityRule =>
              user.accountPermissions
                .filter(role => role.accountId === account.id)
                .some(accountRole => {
                  if (accountRole.feature === securityRule.feature) {
                    switch (accountRole.permission) {
                      case SecurityPermission.EXECUTE:
                        return true;
                      case SecurityPermission.WRITE:
                        return securityRule.permission !== SecurityPermission.EXECUTE;
                      case SecurityPermission.READ:
                        return securityRule.permission === SecurityPermission.READ;
                    }
                  }
                  return false;
                })
            );
          }

          if (securityRule.requiredAccountRoles?.length) {
            const accountRole = user.fixedRoles?.find(a => a.accountId === account.id);
            isAccessAllowed &&= accountRole != null && securityRule.requiredAccountRoles.includes(accountRole.type);
          }
        }
        return isAccessAllowed;
      }
      return (securityRule?.allowWithoutAccount ?? defaultAllowWithoutAccount) === true;
    }
    return securityRule?.allowIfNotLoggedIn === true;
  }
}

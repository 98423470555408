import { DescriptionDialogElement } from '../..';

export enum DescriptionDialogKey {
  ACTIVE_CHARGING_SESSIONS = 'ACTIVE_CHARGING_SESSIONS',
}

export const chargingSessionsM20DescriptionDefinition: DescriptionDialogElement[] = [
  {
    key: DescriptionDialogKey.ACTIVE_CHARGING_SESSIONS,
    title: 'charging-sessions-m20-description.active-charging-sessions.title',
    description: 'charging-sessions-m20-description.active-charging-sessions.description',
  },
];

import { BaseModel } from './BaseModel';

export interface RemoteWallboxDiagnostics extends BaseModel {
  lastStatus: WallboxDiagnosticsStatus;
  latestStatusUpdate: Date;
  expired?: boolean;
  securityLog: boolean;
}

export enum WallboxDiagnosticsStatus {
  Idle = 'Idle',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  BadMessage = 'BadMessage',
  NotSupportedOperation = 'NotSupportedOperation',
  PermissionDenied = 'PermissionDenied',
  UploadFailure = 'UploadFailure',
}

import { FirmwareVersions } from 'libs/common/utils';
import { FormInputType, SettingsFormData } from '../../components';
import { Conditions } from '../../utils/SettingsConditionUtils';
import { WallboxProductCodeRegex } from '../../utils/WallboxRegexes';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';
import { chargingNetworkFormDefinition } from './settings-form.charging-network.definition';
import { dataExportFormDefinition } from './settings-form.data-export.definition';
import { deviceFormDefinition } from './settings-form.device.definition';
import { displayTextFormDefinition } from './settings-form.display-text.definition';
import { dswFormDefinition } from './settings-form.dsw.definition';
import { externalTcpMeterFormDefinition } from './settings-form.external-tcp-meter.definition';
import { factoryResetFormDefinition } from './settings-form.factory-reset.definition';
import { installerSettingsFormDefinition } from './settings-form.installer-settings.definition';
import { loggingFormDefinition } from './settings-form.logging.definition';
import { modbusFormDefinition } from './settings-form.modbus.definition';
import {
  gsmHideConditions,
  networkConnectionFormDefinition,
  wifiServerHideConditions,
  wifiServerPasswordHideConditions,
} from './settings-form.network-connection.definition';
import { ocppFormDefinition } from './settings-form.ocpp.definition';
import { phaseSwitchFormDefinition } from './settings-form.phase-switch.definition';
import { pvFormDefinition } from './settings-form.pv.definition';
import { rebootFormDefinition } from './settings-form.reboot.definition';
import { webuiCertFormDefinition } from './settings-form.web-ui-cert.definition';
import { x1x2FormDefinition } from './settings-form.x1-x2.definition';
import { WallboxSettingsPageType } from './wallbox-settings-page-type';

export interface SettingsPage {
  key: WallboxSettingsPageType;
  title: string;
  icon?: string;
  showInCommonlyUsedSection?: boolean;
  accessibilityId?: string;
  settingGroups: SettingsFormData[];
  hideSaveButton?: boolean;
  showResetDialog?: boolean;
  hideConditions?: Conditions;
  comingSoonConditions?: Conditions;
  comingSoonDescription?: string;
  hideActionButtons?: Conditions;
  hiddenActionButtonsDescription?: string;
}

export const settingsPagesDefinition: SettingsPage[] = [
  {
    key: WallboxSettingsPageType.DEVICE,
    title: 'kwa_lbl_settings_menu_device',
    icon: 'settings',
    showInCommonlyUsedSection: true,
    settingGroups: deviceFormDefinition,
    accessibilityId: 'device',
    hideConditions: { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
  },
  {
    key: WallboxSettingsPageType.CHARGING_NETWORK_PARAMETERS,
    title: 'kwa_lbl_settings_menu_charging_network_parameters',
    icon: 'suspended',
    showInCommonlyUsedSection: true,
    settingGroups: chargingNetworkFormDefinition,
    accessibilityId: 'charging-network-parameters',
    hideConditions: {
      operator: 'or',
      conditions: [
        {
          operator: 'and',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_1_0 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_1_0 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
          ],
        },
      ],
    },
  },
  {
    key: WallboxSettingsPageType.NETWORK_CONNECTION,
    title: 'kwa_lbl_settings_menu_network_connection',
    icon: 'connect-wlan',
    showInCommonlyUsedSection: true,
    settingGroups: networkConnectionFormDefinition,
    accessibilityId: 'network-connection',
    hideConditions: {
      operator: 'and',
      conditions: [
        wifiServerHideConditions,
        wifiServerPasswordHideConditions,
        gsmHideConditions,
        { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
      ],
    },
  },

  {
    key: WallboxSettingsPageType.PV_SETTINGS,
    title: 'kw.settings.pv.title',
    icon: 'sun',
    showInCommonlyUsedSection: true,
    settingGroups: pvFormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_M20 },
        {
          operator: 'or',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
            { condition: 'firmwareVersionsBelow', value: '1.15.0' },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
            { condition: 'isMasterWallbox', value: true },
            { condition: 'hasConnectedClients', value: true },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
          ],
        },
      ],
    },
    accessibilityId: 'pv-settings',
  },
  {
    key: WallboxSettingsPageType.PHASE_SWITCH_SETTINGS,
    title: 'kwa.settings.phase-switch.title',
    icon: 'phase-switching',
    showInCommonlyUsedSection: true,
    settingGroups: phaseSwitchFormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_M20 },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        {
          operator: 'and',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P30_1_15_0 },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
            { condition: 'isMasterWallbox', value: true },
            { condition: 'hasConnectedClients', value: true },
          ],
        },
      ],
    },
    comingSoonConditions: {
      operator: 'and',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_2_0 },
      ],
    },
    comingSoonDescription: 'kwa.settings.phase-switch.coming-soon-description',
    accessibilityId: 'phase-switch',
  },
  {
    key: WallboxSettingsPageType.X1_X2_SETTINGS,
    title: 'kwa.settings.x1-x2.title',
    icon: 'settings-installer',
    settingGroups: x1x2FormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        {
          operator: 'and',
          negate: true,
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
          ],
        },
        { condition: 'featureFlag', value: 'hideX1X2Settings' },
      ],
    },
    accessibilityId: 'x1-x2',
  },
  {
    key: WallboxSettingsPageType.INSTALLER_SETTINGS,
    title: 'kwa.settings.installer-settings.title',
    icon: 'settings-installer',
    showInCommonlyUsedSection: true,
    settingGroups: installerSettingsFormDefinition,
    accessibilityId: 'installer-settings',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'featureFlag', value: 'hideBLE' },
        {
          negate: true,
          operator: 'or',
          conditions: [{ condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 }],
        },
      ],
    },
  },
  {
    key: WallboxSettingsPageType.DATA_EXPORT,
    title: 'kwa_lbl_settings_menu_data_export',
    icon: 'dataexport',
    showInCommonlyUsedSection: true,
    settingGroups: dataExportFormDefinition,
    hideSaveButton: true,
    hideConditions: {
      operator: 'or',
      conditions: [
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_2_0 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
          ],
        },
        {
          operator: 'or',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
          ],
        },
        {
          operator: 'or',
          negate: true,
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.P30_MID_ME_WALLBOX },
            { condition: 'productCode', value: WallboxProductCodeRegex.P40_MID_ME_WALLBOX },
          ],
        },
      ],
    },
    accessibilityId: 'data-export',
  },
  {
    key: WallboxSettingsPageType.CHARGEPOINT_PARAMETERS,
    title: 'kw.settings.chargepoint-parameters.menu-title',
    icon: 'chargepoint',
    showInCommonlyUsedSection: true,
    settingGroups: [
      {
        title: 'kw.settings.chargepoint-parameters.menu-title',
        description: 'kw.settings.chargepoint-parameters.menu-description',
        elements: [
          {
            key: 'chargepoint-parameters-table',
            title: '',
            type: FormInputType.CUSTOM,
            hideElement: false,
          },
        ],
      },
    ],
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.P30_DOESNT_SUPPORT_CHARGEPOINT_PARAMETERS },
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
        {
          operator: 'and',
          conditions: [
            { condition: 'featureFlag', value: 'hideRemoteChargepointParameters' },
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
          ],
        },
      ],
    },
    hideActionButtons: { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
    hiddenActionButtonsDescription: 'kwa.settings.charging-network.coming-soon-description',
    accessibilityId: 'chargepoint-parameters',
  },
  {
    key: WallboxSettingsPageType.OCPP_SETTINGS,
    title: 'kwa_lbl_settings_menu_ocpp_settings',
    icon: 'ocpp',
    showInCommonlyUsedSection: false,
    settingGroups: ocppFormDefinition,
    accessibilityId: 'ocpp-settings',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
        {
          operator: 'and',
          conditions: [
            { condition: 'startsWith', value: '1.0' },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'startsWith', value: '1.0' },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
          ],
        },
      ],
    },
  },
  {
    key: WallboxSettingsPageType.EXTERNAL_TCP_METER,
    title: 'kwa_lbl_settings_menu_external_tcp_meter',
    icon: 'tcp',
    showInCommonlyUsedSection: false,
    settingGroups: externalTcpMeterFormDefinition,
    accessibilityId: 'tcp-meter',
    hideConditions: {
      operator: 'or',
      conditions: [
        {
          operator: 'or',
          conditions: [{ condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE }],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
          ],
        },
      ],
    },
  },
  {
    key: WallboxSettingsPageType.DISPLAY_TEXT,
    title: 'kwa_lbl_settings_menu_display_text',
    icon: 'displaytext',
    showInCommonlyUsedSection: false,
    settingGroups: displayTextFormDefinition,
    accessibilityId: 'display-text',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
      ],
    },
  },

  {
    key: WallboxSettingsPageType.FACTORY_DATA_RESET,
    title: 'kwa_lbl_settings_menu_factory_reset_data',
    icon: 'restart',
    showInCommonlyUsedSection: true,
    hideSaveButton: true,
    settingGroups: factoryResetFormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        {
          operator: 'and',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_1_0 },
          ],
        },
      ],
    },
    accessibilityId: 'factory-data-reset',
  },
  {
    key: WallboxSettingsPageType.LOGGING,
    title: 'kwa_lbl_settings_menu_logging',
    icon: 'logging',
    showInCommonlyUsedSection: false,
    hideSaveButton: true,
    settingGroups: loggingFormDefinition,
    accessibilityId: 'logging',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P30_1_17_0 },
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
          ],
        },
      ],
    },
  },
  //uncomment for portal bonded devices task
  // {
  //   key: WallboxSettingsPageType.AUTHENTICATION,
  //   title: 'kwa_lbl_settings_menu_bonded_devices',
  //   icon: 'logging',
  //   showInCommonlyUsedSection: true,
  //   settingGroups: bondedDevicesFormDefinition,
  //   accessibilityId: 'bounded_devices',
  //   hideConditions: {
  //     operator: 'and',
  //     negate: true,
  //     conditions: [
  //       { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
  //       { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
  //     ],
  //   },
  // },
  {
    key: WallboxSettingsPageType.MODBUS,
    title: 'kwa_lbl_settings_menu_modbus',
    icon: 'modbus',
    showInCommonlyUsedSection: false,
    settingGroups: modbusFormDefinition,
    accessibilityId: 'modbus',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_M20 },
        {
          operator: 'and',
          conditions: [
            { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_1_0 },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
          ],
        },
        {
          operator: 'and',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
          ],
        },
      ],
    },
  },
  {
    key: WallboxSettingsPageType.REBOOT,
    title: 'kw.settings.reboot.title',
    icon: 'restart',
    showInCommonlyUsedSection: false,
    hideSaveButton: true,
    settingGroups: rebootFormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
      ],
    },
    accessibilityId: 'reboot',
  },
  {
    key: WallboxSettingsPageType.DSW_SETTINGS,
    title: 'kwa_lbl_settings_menu_dsw_settings',
    icon: 'settings',
    showInCommonlyUsedSection: false,
    settingGroups: dswFormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
      ],
    },
    accessibilityId: 'dsw-settings',
  },
  {
    key: WallboxSettingsPageType.WEBUI_CERTIFICATE,
    title: 'kwa_lbl_settings_menu_web_ui_certificate',
    icon: 'certificate',
    showInCommonlyUsedSection: false,
    settingGroups: webuiCertFormDefinition,
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
      ],
    },
    accessibilityId: 'webui-certificate',
  },
];

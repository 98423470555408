import { Observable } from 'rxjs';
import { Sort } from '../../types/Sort';
import { Pagination } from '../../types/Pagination';
import { Filter } from '../../types/Filter';
import { BaseModel } from '../../../../models';
import { DataWithCount } from '../../types/DataWithCount';

export interface IRepository<T extends BaseModel> {
  getAll(filter?: Filter[], sort?: Sort<T>[], pagination?: Pagination): Observable<DataWithCount<T>>;

  getById(id: string): Observable<T>;

  add(entity: T): Observable<T>;

  update(entity: Partial<T>): Observable<void>;

  delete(entity: Partial<T>): Observable<void>;
}

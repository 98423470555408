import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalRoutes } from 'apps/keba-wallbox-portal/src/app/portal-routes.definition';
import { PortalApiAuthService } from 'apps/keba-wallbox-portal/src/app/services/auth/portal-api-auth.service';
import { Subject, takeUntil } from 'rxjs';
import { DeleteAccountInfoDialogComponent } from './delete-account-info-dialog/delete-account-info-dialog.component';

@Component({
  selector: 'kwp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnDestroy, OnInit {
  public buttonDisabled = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly portalAuthService: PortalApiAuthService,
    private readonly cdRef: ChangeDetectorRef,

    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // Check if the delete-account-info parameter exists in the URL
      if (params['delete-account-info']) {
        // Call openDialog() function
        this.openDialog();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public login(): void {
    this.buttonDisabled = true; // disable the button when login starts

    this.portalAuthService
      .doSilentLoginIfPossible()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: isLoggedIn => {
          if (isLoggedIn) {
            this.router.navigate(['/', PortalRoutes.MAIN]);
          } else {
            this.portalAuthService.login();
          }
          this.buttonDisabled = false; // re-enable the button when login completes or fails
          this.cdRef.detectChanges();
        },
        error: () => {
          this.buttonDisabled = false; // re-enable the button if login fails
          this.cdRef.detectChanges();
        },
      });
  }

  public openDialog(): void {
    this.dialog.open(DeleteAccountInfoDialogComponent, {
      panelClass: 'full-screen',
    });
  }
}

export interface AppReviewPlugin {
  /**
   * Donates the Event and decides if the native app review request should be triggered.
   * The system decides if the review view is shown or not.
   */
  requestReview(options: { event: Event }): Promise<void>;
}

export enum Event {
  CHARGING_STARTED = 'CHARGING_STARTED',
  CHARGING_STOPPED = 'CHARGING_STOPPED',
}

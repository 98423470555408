import { Observable, of } from 'rxjs';
import { ApplicationFeature, FixedUserRoleType, SecurityPermission } from '../../../../models';
import { GdprReport, User } from '../../../../models/User';
import { FakeRepository } from '../base/FakeRepository';
import { IUserRepository } from './IUserRepository';

let fakeUsers: User[] = [
  {
    id: '1',
    firstname: 'Max',
    lastname: 'Mustermann',
    admin: false,
    email: 'max.mustermann@test.com',
    accounts: [
      { id: 'a-1', name: 'Smart energy GmbH', accountOwner: true, userId: '' },
      { id: 'a-2', name: 'Clean energy GmbH', userId: '' },
      { id: 'a-3', name: 'No energy OG', userId: '' },
      { id: 'a-4', name: 'A energy OG', userId: '' },
    ],
    fixedRoles: [
      { id: '1', type: FixedUserRoleType.OWNER, accountId: 'a-1' },
      { id: '2', type: FixedUserRoleType.MEMBER, accountId: 'a-2' },
    ],
    accountPermissions: [
      {
        feature: ApplicationFeature.WALLBOX_START_STOP_CHARGING,
        permission: SecurityPermission.EXECUTE,
        accountId: 'a-1',
        id: '1',
      },
      {
        feature: ApplicationFeature.CHARGING_PROFILES_MANAGEMENT,
        permission: SecurityPermission.EXECUTE,
        accountId: 'a-1',
        id: '1',
      },
      {
        feature: ApplicationFeature.WALLBOX_SETTINGS,
        permission: SecurityPermission.EXECUTE,
        accountId: 'a-1',
        id: '1',
      },
      {
        feature: ApplicationFeature.WALLBOX,
        permission: SecurityPermission.WRITE,
        accountId: 'a-1',
        id: '1',
      },
      {
        feature: ApplicationFeature.WALLBOX_FIRMWARE_UPDATE,
        permission: SecurityPermission.WRITE,
        accountId: 'a-1',
        id: '1',
      },
      {
        feature: ApplicationFeature.ACCOUNT_SETTINGS,
        permission: SecurityPermission.EXECUTE,
        accountId: 'a-1',
        id: '1',
      },
    ],
    registrationDate: '26-03',
    fullName: '',
  },
  {
    id: '2',
    admin: false,
    firstname: 'Susi',
    lastname: 'Musterfrau',
    email: 'susi.musterfrau@test.com',
    accounts: [
      { id: 'a-1', name: 'Smart energy GmbH', userId: '' },
      { id: 'a-2', name: 'Clean energy GmbH', userId: '' },
      { id: 'a-3', name: 'No energy OG', userId: '' },
    ],
    accountPermissions: [],
    registrationDate: '26-03',
    fullName: '',
  },
  {
    id: '3',
    admin: false,
    email: 'maria.mustermember@test.com',
    accounts: [
      { id: 'a-1', name: 'Smart energy GmbH', userId: '' },
      { id: 'a-2', name: 'Clean energy GmbH', userId: '' },
      { id: 'a-3', name: 'No energy OG', userId: '' },
    ],
    accountPermissions: [],
    registrationDate: '26-03',
    fullName: '',
  },
  {
    id: '4',
    admin: false,
    email: 'maria.musteradmin@test.com',
    accounts: [
      { id: 'a-1', name: 'Smart energy GmbH', userId: '' },
      { id: 'a-2', name: 'Clean energy GmbH', userId: '' },
      { id: 'a-3', name: 'No energy OG', userId: '' },
    ],
    accountPermissions: [],
    registrationDate: '26-03',
    fullName: '',
  },
];

const fakeGdprReport: GdprReport = {
  id: '1',
  expirationDate: new Date(),
  fileSource: '',
  status: 'IN_PROGRESS',
};

export class FakeUserRepository extends FakeRepository<User> implements IUserRepository {
  constructor() {
    super(fakeUsers);
  }

  public getUserMe(): Observable<User> {
    return super.getById('1');
  }

  public deleteAuthUser(user: User): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public updateADUser(): Observable<void> {
    return of(void 0);
  }

  public generateGdprReport(userId: string): Observable<GdprReport> {
    return this.delayResponse(of(fakeGdprReport));
  }

  public getGdprReport(userId: string): Observable<GdprReport> {
    return this.delayResponse(of(fakeGdprReport));
  }

  public deleteGdprReport(userId: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public downloadGdprReport(url: string): Observable<Blob> {
    const blob = new Blob([JSON.stringify({ url }, null, 2)], {
      type: 'application/json',
    });
    return this.delayResponse(of(blob));
  }
}

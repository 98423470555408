import { Capacitor } from '@capacitor/core';
import {
  AccountsService,
  ApiAuthService,
  ApiDataService,
  Filter,
  Pagination,
  RemoteWallboxChargingSession,
  Sort,
  SortDirection,
} from '@kw-shared/api-data-access';
import { CsvColumnsExportDefinition, PlatformType } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { subDays } from 'date-fns';
import { Observable, firstValueFrom } from 'rxjs';
import { CurrentWallboxConfigService } from '../../../apps/keba-wallbox-app/src/app/services/current-wallbox-config.service';
import { ChargingSessionsSummary } from '../../api-data-access/services/data-access/repositories/charging-sessions/IChargingSessionsRepository';
import { ExportUtils } from '../utils/ExportUtils';
import { PdfGenerationService } from './pdf-generation.service';

export enum ExportFormat {
  CSV = 'csv',
  PDF = 'pdf',
  ZIP = 'zip',
}

export const EXPORT_DATA_PAGINATION = 10_000;

export interface CreatorExportData {
  userName: string;
  account: string;
}

export interface ChargingSessionsPdfExportData {
  creator: CreatorExportData;
  filter: {
    dateRange: {
      start: string;
      end: string;
    } | null;
    tags: string[];
    rfidCards: string[];
  };
  chargingSessions: ChargingSessionsSummary;
}

export abstract class KWExportService {
  protected constructor(
    protected readonly translateService: TranslateService,
    protected readonly pdfGenerationService: PdfGenerationService,
    protected readonly apiDataService: ApiDataService,
    protected readonly apiAuthService: ApiAuthService,
    protected readonly accountService: AccountsService,
    protected readonly currentWallboxConfigService: CurrentWallboxConfigService
  ) {}

  public async exportAndSaveToCsv<T extends object>(
    columns: CsvColumnsExportDefinition<T>[],
    data: Observable<T[]> | T[],
    fileNamePrefix?: string
  ): Promise<void> {
    const result = await ExportUtils.exportToCsv(columns, data, this.translateService);
    await this.saveExportResultAsFile(result, ExportFormat.CSV, fileNamePrefix);
  }

  public async exportAndSaveChargingSessionsToPdf(filter: Filter[]): Promise<void> {
    if (Capacitor.getPlatform() !== PlatformType.WEB) {
      const wallboxConfig = this.currentWallboxConfigService.getCurrentWallboxConfig();
      if (!wallboxConfig || !wallboxConfig?.isRemoteWallbox()) {
        return;
      }

      filter.push(new Filter('chargepoints', [wallboxConfig.id]));
    }

    const chargingSessions = await firstValueFrom(
      this.apiDataService.chargingSessions.getAllWithSummary(
        filter,
        [new Sort<RemoteWallboxChargingSession>('start', SortDirection.Descending)],
        new Pagination(0, EXPORT_DATA_PAGINATION)
      )
    );

    const dateRangeFilter: string[] | undefined = filter.find(f => f.fieldName === 'dateRange')?.value;
    const data: ChargingSessionsPdfExportData = {
      creator: await this.getExportCreatorData(),
      filter: {
        tags: filter.find(f => f.fieldName === 'tags')?.value ?? [],
        rfidCards: filter.find(f => f.fieldName === 'RFIDCards')?.value ?? [],
        dateRange:
          dateRangeFilter?.length === 2
            ? {
                start: dateRangeFilter[0],
                end: subDays(new Date(dateRangeFilter[1]), 1).toISOString(),
              }
            : null,
      },
      chargingSessions: chargingSessions,
    };
    return this.exportAndSaveToPdfFromApi('charging-sessions', data, 'charging-sessions');
  }

  public canExportAsPdf(): boolean {
    return this.pdfGenerationService.canExportAsPdf();
  }

  protected async getExportCreatorData(): Promise<CreatorExportData> {
    const account = await firstValueFrom(this.accountService.getSelectedAccount$());
    const user = await firstValueFrom(this.apiAuthService.getUser$());

    return {
      userName: user?.fullName ?? '',
      account: account?.name ?? '',
    };
  }

  protected async exportAndSaveToPdfFromApi<T extends object>(
    exportTemplate: string,
    data: T,
    fileNamePrefix?: string
  ): Promise<void> {
    const result = await firstValueFrom(this.pdfGenerationService.generatePdf(exportTemplate, data));
    await this.saveExportResultAsFile(result, ExportFormat.PDF, fileNamePrefix);
  }

  public abstract saveExportResultAsFile<T>(
    exportResult: string | Blob | undefined,
    exportFormat: ExportFormat,
    fileNamePrefix?: string,
    fileName?: string
  ): Promise<void>;
}

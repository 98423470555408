export class Filter {
  constructor(public fieldName: string, public value: any) {}

  public toString() {
    return `filter[${this.fieldName}]=${this.value}`;
  }
}

export class SearchFilter extends Filter {
  constructor(searchQuery: string) {
    super('search', searchQuery.trim().toLocaleLowerCase());
  }
}

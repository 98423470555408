<div
  *ngIf="data"
  class="items-center w-full min-h-screen bg-white kwa-safe-area-top kwa-safe-area-left kwa-safe-area-right kw-full-screen-dialog"
>
  <div class="flex w-full h-8 pt-5 mb-7">
    <kwa-header-navigation class="mt-4" headerBackground="bg-white" (backButtonClick)="onClose()">
      <div class="ml-auto">
        <span
          accessibility-id="kw-test__app-shared__full-screen-dialog__btn--close"
          *ngIf="data.showClose"
          class="w-8 h-8 fill-current"
          inlineSVG="assets/icons/32/close.svg"
          (click)="onClose()"
        ></span>
      </div>
    </kwa-header-navigation>
  </div>

  <div *ngIf="data.icons" class="flex flex-col items-center w-full p-6">
    <div class="flex mb-4">
      <span
        *ngFor="let icon of data.icons"
        class="flex items-center fill-current w-14 h-14"
        [ngClass]="(icon.centered ? 'justify-center ' : '') + (icon.color || 'text-basil-500')"
        [inlineSVG]="'assets/icons/' + (icon.size || 56) + '/' + icon.name + '.svg'"
      ></span>
    </div>
    <h2
      *ngIf="data.isMarkdownTitle"
      [ngClass]="data.variant === 'dense' ? 'mb-2' : 'mb-10'"
      class="text-center"
      [innerHTML]="marked(data.title || '' | translate)"
    ></h2>
    <h2 *ngIf="!data.isMarkdownTitle" [ngClass]="data.variant === 'dense' ? 'mb-2' : 'mb-10'" class="text-center">
      {{ data.title | translate }}
    </h2>
    <div *ngIf="data.subtitle" class="text-center mb-10">{{ data.subtitle | translate }}</div>
    <div
      *ngIf="data.isMarkdownDescription"
      [ngClass]="data.variant === 'dense' ? 'mb-8 text-center' : 'mb-14'"
      class="font-light leading-6 text-center"
      [innerHTML]="marked(data.description || '' | translate)"
    ></div>
    <div
      *ngIf="!data.isMarkdownDescription && (data.description || data.extendedDescription)"
      [ngClass]="data.variant === 'dense' ? 'mb-8 text-center' : 'mb-14'"
      class="font-light leading-6"
    >
      <div>
        <span [innerHTML]="data.description || '' | translate" *ngIf="data.description"></span>
        <span
          [innerHTML]="data.extendedDescription | translate: { wallboxSerial: data.wallboxSerial }"
          *ngIf="data.wallboxSerial && data.extendedDescription"
        >
        </span>
      </div>
    </div>

    <ng-container *ngFor="let button of data.ctaButtons">
      <button [class]="button.class || 'button-primary'" (click)="onButtonClick(button)" class="mb-8">
        {{ button.text | translate }}
        <mat-spinner *ngIf="button.loading" [diameter]="16" class="kw-progress-spinner-white ml-3"></mat-spinner>
      </button>
    </ng-container>
  </div>
  <div *ngIf="data.infoTextTitle || data.infoTextSteps" class="bg-gray-200 pt-6 px-6 pb-12">
    <div *ngIf="data.infoTextTitle" class="font-bold mb-4" [innerHTML]="data.infoTextTitle | translate"></div>
    <ng-container *ngFor="let step of data.infoTextSteps">
      <div class="leading-6 text-sm pb-4" [innerHTML]="step | translate"></div>
    </ng-container>
    <ng-container *ngFor="let link of data.links">
      <a [href]="link.href | translate" target="_blank" class="link-primary" [innerHTML]="link.text | translate"></a>
    </ng-container>
  </div>
</div>

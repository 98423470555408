import { HttpClient } from '@angular/common/http';
import { AdvancedHttpClient } from './AdvancedHttpClient';

export class PortalAPIClient extends AdvancedHttpClient {
  constructor(protected baseUrl: string, public override httpClient: HttpClient) {
    super(httpClient);
  }

  protected get baseUri(): string {
    return this.baseUrl;
  }

  protected get defaultVersion(): string {
    return 'v1';
  }
}

import { BaseModel } from '@kw-shared/api-data-access';

export interface RemoteRfidCard extends BaseModel {
  name: string;
  tokenId: string;
  expiryDate?: string;
  restrictedToChargePoints: boolean;
  chargePoints?: (BaseModel & { name?: string; serialNumber?: string })[];
  tags?: (BaseModel & { name?: string })[];
  chargePointsCount: number;
  status: RemoteRfidCardStatus;
}

export enum RemoteRfidCardStatus {
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
  INVALID = 'INVALID',
}

<div class="w-full h-screen p-4 mx-auto kwa-container" [ngClass]="[data.portal ? 'md:w-1/3' : 'md:w-1/2']">
  <div class="flex flex-col items-center justify-center h-full p-3">
    <div class="flex justify-end w-full" (click)="cancel()">
      <span class="w-8 h-8 mb-3 cursor-pointer fill-white" inlineSVG="assets/icons/32/close.svg"></span>
    </div>

    <div class="flex flex-col items-center w-full px-6 py-8 overflow-y-auto bg-white shadow-2xl rounded-2xl">
      <h2 class="font-light text-center text-gray-900">{{ data.title | translate }}</h2>

      <ng-container *ngIf="data.mediaUrl">
        <video *ngIf="isVideo(); else image" controls class="w-full mt-6">
          <source [src]="data.mediaUrl" type="video/mp4" />
        </video>
        <ng-template #image>
          <img [src]="data.mediaUrl" class="w-full mt-6" />
        </ng-template>
      </ng-container>

      <p class="self-start mt-6 font-light whitespace-pre-line" [innerHTML]="data.text | translate"></p>
    </div>
  </div>
</div>

export type Language = 'en' | 'de' | 'fr';

export abstract class LanguageUtils {
  private static readonly availableLanguages: Language[] = ['en', 'de', 'fr'];
  public static readonly defaultLanguage: Language = 'en';

  public static getCurrentLanguage(): Language {
    const browserLanguage = navigator.language?.substring(0, 2);
    return this.availableLanguages.find(e => e === browserLanguage) || this.defaultLanguage;
  }
}

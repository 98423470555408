import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiAuthService, ApiDataService } from '@kw-shared/api-data-access';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import {
  ConfirmByInputDialogComponent,
  ConfirmByInputDialogData,
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../components';
import { ToastMessageService } from './toast-message.service';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly authService: ApiAuthService,
    private readonly dialog: MatDialog,
    private readonly dataService: ApiDataService,
    private readonly toastMessageService: ToastMessageService
  ) {}

  public showDeleteAccountDialog(accountId: string): void {
    this.showDialog({
      icon: 'delete',
      iconColor: 'text-black',
      title: 'kw.user-profile.delete-account.title',
      description: 'kw.user-profile.delete-account.description',
      confirmText: 'kwp.remove',
      confirmPhrase: 'DELETE',
    }).subscribe(() =>
      this.triggerAccountAction(
        this.dataService.accounts.deleteAccount(accountId),
        `kw.user-profile.delete-account.success`,
        `kw.user-profile.delete-account.error`,
        error => {
          if (error.status === 409) {
            // account still has other users in it
            this.showDeleteAccountConfirmDialog(accountId);

            return true;
          }
          return false;
        }
      )
    );
  }

  private showDeleteAccountConfirmDialog(accountId: string): void {
    const data: ConfirmDialogData = {
      icon: 'delete',
      title: 'kw.user-profile.delete-account-confirm.title',
      description: 'kw.user-profile.delete-account-confirm.description',
      showConfirm: true,
      showCancel: true,
      confirmText: 'kwp.remove',
    };
    this.dialog
      .open(ConfirmDialogComponent, {
        data,
      })
      .beforeClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(confirmed => confirmed)
      )
      .subscribe(() =>
        this.triggerAccountAction(
          this.dataService.accounts.deleteAccount(accountId, true),
          `kw.user-profile.delete-account-confirm.success`,
          `kw.user-profile.delete-account-confirm.error`
        )
      );
  }

  public showLeaveAccountDialog(accountId: string): void {
    this.showDialog({
      icon: 'delete',
      iconColor: 'text-black',
      title: 'kw.user-profile.leave-account.title',
      description: 'kw.user-profile.leave-account.description',
      confirmText: 'kwp.leave',
      confirmPhrase: 'LEAVE',
    }).subscribe(() =>
      this.triggerAccountAction(
        this.dataService.accounts.leaveAccount(accountId),
        `kw.user-profile.leave-account.success`,
        `kw.user-profile.leave-account.error`
      )
    );
  }

  private showDialog(data: ConfirmByInputDialogData): Observable<any> {
    return this.dialog
      .open(ConfirmByInputDialogComponent, {
        data,
      })
      .beforeClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(confirmed => confirmed)
      );
  }

  private triggerAccountAction(
    action: Observable<void>,
    successText: string,
    errorText: string,
    handleError?: (error: any) => boolean
  ): void {
    action.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        this.toastMessageService.setSuccess(successText);

        // timeout needed to keep success toast message readable for user
        setTimeout(async () => {
          await this.authService.loadUser();
        }, 1000);
      },
      error: error => {
        if (!handleError || !handleError(error)) {
          this.toastMessageService.setError(errorText);
        }
      },
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export interface KebaWallboxPluginPlugin {
  login(options: { config: WallboxConfiguration; username: string; password: string }): Promise<void>;
  freshLogin(options: { config: WallboxConfiguration; username: string; password: string }): Promise<void>;
  logout(options: { config: WallboxConfiguration }): Promise<void>;
  logoutAll(options: { config: WallboxConfiguration }): Promise<void>;
  changePassword(options: {
    config: WallboxConfiguration;
    username: string;
    oldPassword: string;
    newPassword: string;
  }): Promise<void>;

  /**
   * Allows to reset the password for a specific user with the recovery key generated on assembly line.
   */
  resetPassword(options: {
    config: WallboxConfiguration;
    username: string;
    recoveryKey: string;
    newPassword: string;
  }): Promise<void>;

  /**
   * Check whether the password for a specific user has been changed at any point in time.
   */
  hasPasswordChanged(options: { config: WallboxConfiguration; username: string }): Promise<{ changed: boolean }>;

  readWallboxList(options: {
    config: WallboxConfiguration;
    filterOptions: WallboxFilterOptions;
  }): Promise<{ wallboxes: WallboxInfo[] }>;
  writeWallboxList(options: { config: WallboxConfiguration; wallboxes: WallboxOptions[] }): Promise<void>;
  readWallboxInfo(options: { config: WallboxConfiguration; serialNumber?: string }): Promise<WallboxInfo>;
  writeWallboxInfo(options: { config: WallboxConfiguration; wallboxOptions: WallboxOptions }): Promise<WallboxInfo>;
  readWallboxInfoP40(options: { config: WallboxConfiguration }): Promise<WallboxInfoP40>;
  writeWallboxInfoP40(options: { config: WallboxConfiguration; wallboxOptions: WallboxOptionsP40 }): Promise<void>;
  readWallboxInfoM20(options: { config: WallboxConfiguration }): Promise<WallboxInfoM20>;
  startWallboxCharging(options: {
    config: WallboxConfiguration;
    serialNumber?: string;
  }): Promise<{ state?: NativeLocalWallboxState }>;
  stopWallboxCharging(options: {
    config: WallboxConfiguration;
    serialNumber?: string;
  }): Promise<{ state?: NativeLocalWallboxState }>;
  unlockWallbox(options: { config: WallboxConfiguration; serialNumber?: string }): Promise<void>;
  changeWallboxAvailability(options: {
    config: WallboxConfiguration;
    serialNumber?: string;
    available: boolean;
  }): Promise<void>;

  /**
   * Checks if a wallbox has clients and returns their serial numbers.
   */
  readChargepointInformation(options: { config: WallboxConfiguration }): Promise<{ clientWallboxes: string[] }>;

  readSessions(options: {
    config: WallboxConfiguration;
    sessionOptions: WallboxFilterOptions;
  }): Promise<WallboxSessionResults>;

  readConfiguration(options: {
    config: WallboxConfiguration;
    configurationType: ConfigurationType;
    propertyKey?: ConfigurationPropertyKey | string;
  }): Promise<ConfigurationResult>;
  writeConfiguration(options: {
    config: WallboxConfiguration;
    configurationType: ConfigurationType;
    configurationOptions: ConfigurationResult;
  }): Promise<{ restartRequired: boolean }>;
  hasNetworkModule(options: {
    config: WallboxConfiguration;
    interfaceType: NetworkType;
  }): Promise<{ hasModule: boolean }>;
  readNetworkInterfaces(options: { config: WallboxConfiguration }): Promise<{ interfaces: NetworkInterface[] }>;
  readMaxClientLicenseM20(options: {
    config: WallboxConfiguration;
  }): Promise<{ maxClients: number }>;
  readOcppStatus(options: { config: WallboxConfiguration }): Promise<{ lastHeartbeat?: Date; status: string }>;

  startBrowsing(
    options: {
      channel?: CommunicationChannel;
    },
    callback: BrowsingCallback,
  ): Promise<CallbackID>;
  stopBrowsing(options: { channel?: CommunicationChannel }): Promise<void>;

  readRfidTokens(options: {
    config: WallboxConfiguration;
    filterOptions: WallboxFilterOptions;
  }): Promise<WallboxRfidTokenResults>;
  removeRfidToken(options: { config: WallboxConfiguration; id: string }): Promise<void>;
  editRfidToken(options: { config: WallboxConfiguration; token: WallboxRfidToken }): Promise<WallboxRfidToken>;
  // user file picker with mime type: text/csv
  importRfidTokens(options: { config: WallboxConfiguration; path: string }): Promise<void>;
  exportRfidTokens(options: { config: WallboxConfiguration }): Promise<{ path: string }>;

  /**
   * Returns the cached update information.
   */
  readCachedUpdateInformation(options: { config: WallboxConfiguration }): Promise<UpdateInformationResult>;
  /**
   * Triggers the check if information about an update is available.
   */
  performUpdateInformationCheck(options: {
    config: WallboxConfiguration;
    updateOptions?: UpdateInformationOptions;
  }): Promise<UpdateInformationResult>;
  /**
   * Triggers automatic retrieval and installation of an update file at a given location.
   */
  requestOnlineUpdate(options: { config: WallboxConfiguration; updateOptions: UpdateRequestOptions }): Promise<void>;
  /**
   * Returns the current status of the online update process.
   * In case of the state DOWNLOADING also the progress is returned (number between 0 and 1).
   */
  readOnlineUpdateStatus(options: {
    config: WallboxConfiguration;
  }): Promise<{ progress?: number; status: UpdateStatus }>;
  //
  /**
   * Uploads a .keb/.raucb file the wallbox.
   * To get the file path use file picker with mime type: \*\/\*
   * @throws {APP.APP.FILE_INVALID} in case the uploaded firmware file is invalid.
   * @throws {APP.PAYLOAD_TOO_LARGE} in case the wallbox is out of storage.
   */
  uploadOfflineUpdate(
    options: {
      config: WallboxConfiguration;
      path: string;
    },
    callback: UploadFileCallback,
  ): Promise<CallbackID>;
  /**
   * Triggers the update preparation on the wallbox. Only required for P40.
   * Requires an uploaded update file.
   * @throws {APP.UPDATE_FILE_MISSING} if no file was uploaded.
   */
  prepareOfflineUpdate(options: { config: WallboxConfiguration }): Promise<void>;
  /**
   * Returns the current status of the offline update process.
   */
  readOfflineUpdateStatus(options: { config: WallboxConfiguration }): Promise<{ status: UpdateStatus }>;
  /**
   * Starts the update process with the uploaded file.
   * P40 requires a successful prepare call before.
   * @throws {APP.UPDATE_FILE_MISSING} in case it was not prepared/uploaded.
   */
  startOfflineUpdate(options: { config: WallboxConfiguration }): Promise<void>;

  readExternalMeterProviders(options: { config: WallboxConfiguration }): Promise<ExternalMeterProviders>;
  testExternalMeterConnection(options: {
    config: WallboxConfiguration;
    testOptions: ExternalMeterTestOptions;
  }): Promise<void>;

  readCertificates(options: { config: WallboxConfiguration }): Promise<CertificatesResults>;
  // use file picker with mime type: application/x-x509-ca-cert
  installCentralCertificate(options: { config: WallboxConfiguration; path: string }): Promise<void>;
  // use file picker with mime type: application/x-pkcs12
  installOcppCertificate(options: { config: WallboxConfiguration; path: string; password: string }): Promise<void>;
  // use file picker with mime type: application/x-pkcs12
  installWebCertificate(options: { config: WallboxConfiguration; path: string; password: string }): Promise<void>;
  readWebCertificates(options: { config: WallboxConfiguration }): Promise<{ hasCertificates: boolean }>;

  performFactoryReset(options: { config: WallboxConfiguration }): Promise<void>;
  performSystemReboot(options: { config: WallboxConfiguration }): Promise<void>;
  performTimeSync(options: { config: WallboxConfiguration }): Promise<void>;

  readMvaWallboxes(options: { config: WallboxConfiguration }): Promise<MvaWallboxResults>;
  synchronizeMvaRecords(options: {
    config: WallboxConfiguration;
    serialNumber: string;
    type: MvaRecordType;
    section?: string;
  }): Promise<void>;
  readMvaSynchronizationStatus(options: {
    config: WallboxConfiguration;
    serialNumber: string;
    type: MvaRecordType;
  }): Promise<{ retrievedRecordsCount: number; totalRecordsCount: number }>;
  exportMvaRecords(options: {
    config: WallboxConfiguration;
    serialNumber: string;
    type: MvaRecordType;
  }): Promise<{ path: string }>;

  exportDiagnostics(options: { config: WallboxConfiguration }): Promise<{ path: string }>;
  exportSecurityLogs(options: { config: WallboxConfiguration }): Promise<{ path: string }>;

  startEnrollment(options: { config: WallboxConfiguration }): Promise<void>;
  startEnrollmentWithConfiguration(options: {
    config: WallboxConfiguration;
    enrollmentConfiguration: EnrollmentConfiguration;
  }): Promise<void>;
  startEnrollmentWithConfigurationP40(options: {
    config: WallboxConfiguration;
    enrollmentConfiguration: EnrollmentConfiguration;
  }): Promise<void>;
  unenroll(options: { config: WallboxConfiguration }): Promise<void>;
  unenrollM20(options: { config: WallboxConfiguration }): Promise<void>;
  readEnrollmentStatus(options: { config: WallboxConfiguration }): Promise<{ status: EnrollmentStatus }>;
  readEnrollmentStatusM20(options: { config: WallboxConfiguration }): Promise<{ status: EnrollmentStatus }>;

  readRestApiVersion(options: {
    config: {
      address: string;
      port: number;
      verificationMode: CertificateVerificationMode;
      channel: CommunicationChannel.REST;
    };
  }): Promise<{ apiVersion: string }>;
  readRestApiSerialNumber(options: {
    config: {
      address: string;
      port: number;
      verificationMode: CertificateVerificationMode;
      channel: CommunicationChannel.REST;
    };
  }): Promise<{ serialNumber: string }>;
  hasRestApiCredentials(options: { serialNumber?: string }): Promise<{ hasCredentials: boolean }>;
  isCommunicationChannelAvailable(options: {
    config: {
      address: string;
      port?: number;
      verificationMode: CertificateVerificationMode;
      channel: CommunicationChannel;
    };
  }): Promise<void>;
  observeCommunicationChannelStatus(
    options: {
      channel: CommunicationChannel;
    },
    callback: ChannelStatusCallback,
  ): Promise<CallbackID>;
  predictBestCertificateVerificationMode(options: {
    config: {
      address: string;
      port: number;
      channel: CommunicationChannel.REST;
    };
  }): Promise<{ verificationMode: CertificateVerificationMode }>;

  cacheKnownWallbox(options: {
    wallbox: {
      address: string;
      serialNumber: string;
      verificationMode: CertificateVerificationMode;
      channel: CommunicationChannel;
      productCode?: string;
    };
  }): Promise<void>;
  removeKnownWallbox(options: { serialNumber: string }): Promise<void>;
  readKnownWallboxes(options: {}): Promise<{
    wallboxes: {
      serialNumber: string;
      verificationMode: CertificateVerificationMode;
      productCode?: string;
      availableChannels: {
        address: string;
        channel: CommunicationChannel;
      }[];
    }[];
  }>;

  readPVStatus(options: { config: WallboxConfiguration }): Promise<{ status: PVStatus; timeLeft?: number }>;
  startChargingSessionBoost(options: { config: WallboxConfiguration; duration: number }): Promise<void>;
  stopChargingSessionBoost(options: { config: WallboxConfiguration }): Promise<void>;
  readPVConfiguration(options: { config: WallboxConfiguration }): Promise<ConfigurationResult>;
  writePVConfiguration(options: {
    config: WallboxConfiguration;
    configurationOptions: ConfigurationResult;
  }): Promise<void>;

  // P40 only
  readInstallerModeState(options: { config: WallboxConfiguration }): Promise<InstallerModeStateResult>;
  stopInstallerMode(options: { config: WallboxConfiguration }): Promise<void>;
  readPowerConfiguration(options: { config: WallboxConfiguration }): Promise<ConfigurationResult>;
  writePowerConfiguration(options: {
    config: WallboxConfiguration;
    configurationOptions: ConfigurationResult;
  }): Promise<{ restartRequired: boolean }>;
  readInstallerSettingValues(options: {
    config: WallboxConfiguration;
    settingsType: InstallerSettingType;
  }): Promise<{ values: string[] }>;
  lockInstallerSettings(options: {
    config: WallboxConfiguration;
    settingKeys: ConfigurationPropertyKey[];
  }): Promise<void>;
  readLockedInstallerSettings(options: {
    config: WallboxConfiguration;
  }): Promise<{ settingKeys: ConfigurationPropertyKey[] }>;
  writeCommissioningModeState(options: { config: WallboxConfiguration; active: boolean }): Promise<void>;
  clearPairingList(options: { config: WallboxConfiguration }): Promise<void>;
}

export enum WallboxType {
  P30 = 'P30',
  P40 = 'P40',
  M20 = 'M20',
}

export enum CertificateVerificationMode {
  SECURE = 'SECURE',
  INSECURE = 'INSECURE',
}

export interface InstallerModeStateResult extends ObjectKeys {
  installerModeIsActive: boolean;
  installerModeRemainingSeconds: number;
}

export enum EnrollmentStatus {
  ENROLLED = 'ENROLLED',
  UNENROLLED = 'UNENROLLED',
  FAILED = 'FAILED',
}

export interface EnrollmentConfiguration extends ObjectKeys {
  portalEnrollmentToken: string;
  portalAuthKey?: string;
  portalHost?: string;
  portalPath?: string;
  portalPort?: string;
  portalWboxId?: string;
  portalSecurityProfile?: number;
}

export interface MvaWallboxResults extends ObjectKeys {
  serials: string[];
}

export enum MvaRecordType {
  MEASUREMENT = 'MEASUREMENT',
  LOG = 'LOG',
}

export enum InterfaceType {
  LAN = 'eth0',
  ACCESS_POINT = 'eth1',
  WIFI = 'wlan0',
  MOBILE = 'ppp0',
  MOBILE_P40 = 'wwan0',
  LOOPBACK = 'lo',
}

export interface NetworkInterface {
  type: InterfaceType;
  ip?: string;
  mac?: string;
}

export interface ExternalMeterTestOptions extends ObjectKeys {
  address: string;
  port: number;
  provider: string;
  unit?: string;
}

export interface ExternalMeterProviders extends ObjectKeys {
  providers: string[];
}

export interface UpdateRequestOptions extends ObjectKeys {
  location: string;
  retries?: number;
  retryInterval?: number;

  retrieveDate?: Date;
  installDate?: Date;

  signature?: string;
  signingCertificate?: string;
}

export interface UpdateInformationOptions extends ObjectKeys {
  language: string;
}

export type CallbackID = string;
export type BrowsingCallback = (message: BrowsingCallbackData | null, err?: any) => void;

export interface BrowsingCallbackDataAdded {
  serialNumber: string;
  name?: string;
  channel: CommunicationChannel;
  address: string;
  port?: number;
  verificationMode: CertificateVerificationMode;
  type?: WallboxType;
}

export interface BrowsingCallbackDataRemoved {
  serialNumber: string;
}

export interface BrowsingCallbackData {
  added: BrowsingCallbackDataAdded[];
  removed: BrowsingCallbackDataRemoved[];
}

export type ChannelStatusCallback = (message: ChannelStatusCallbackData, err?: any) => void;

export interface ChannelStatusCallbackData {
  status: CommunicationChannelStatus;
}

export enum CommunicationChannelStatus {
  UNSUPPORTED = 'unsupported',
  UNAUTHORIZED = 'unauthorized',
  POWERED_OFF = 'poweredOff',
  POWERED_ON = 'poweredOn',
}

export type UploadFileCallback = (message: UploadFileCallbackData, err?: any) => void;

export interface UploadFileCallbackData {
  progress: number;
  finished: boolean;
}

export interface ObjectKeys {
  [key: string]: string | number | NativeLocalWallboxState | undefined | any;
}

export interface WallboxInfo extends ObjectKeys {
  alias?: string;
  model: string;
  serialNumber: string;
  number: number;
  state: NativeLocalWallboxState;
  totalActivePower?: number; // mW
  lines?: Line[];
  currentOffered?: number; // mA
  totalPowerFactor?: number;
  maxCurrent: number; // mA
  dipSwitchSettings: boolean[];
  maxPhases?: MaxPhases; // 1, 3
  phaseUsed?: PhaseUsed; //L1, L2, L3 | L1_L2_L3 (all possible combinations)
  lifetimeChargedEnergy: number; // mWh
  timeQuality?: number; // 0/1/2/3
  x2active?: boolean;
  failsafeCurrent?: number; // mA
}

export interface WallboxInfoP40 extends ObjectKeys {
  alias: string;
  maxCurrent: number; // mA
  phaseUsed: PhaseUsed; //L1, L2, L3 | L1_L2_L3 (all possible combinations)
  serialNumber: string;
  model: string;
}

export interface WallboxInfoM20 extends ObjectKeys {
  alias?: string;
  serialNumber?: string;
  model?: string;
  state?: NativeLocalWallboxState;
}

export interface WallboxOptions extends ObjectKeys {
  number: number;
  alias?: string;
  maxPhases?: MaxPhases; // 1, 3
  phaseUsed?: PhaseUsed; //L1, L2, L3 | L1_L2_L3 (all possible combinations)
  state?: NativeLocalWallboxState; // can only be set to UNAVAILABLE or IDLE
  serialNumber: string;
  failsafeCurrent?: number; // mA
}

export interface WallboxOptionsP40 extends ObjectKeys {
  maxCurrent?: number; // mA
  phaseUsed?: PhaseUsed; //L1, L2, L3 | L1_L2_L3 (all possible combinations)
}

export interface Line extends ObjectKeys {
  current: number; // mA
  socketPhase?: string;
  voltage: number; // V
}
export enum CommunicationChannel {
  UDP = 'udp',
  REST = 'rest',
  BLE = 'ble',
}

export interface UdpConfiguration extends ObjectKeys {
  address: string;
  port: number;
  channel: CommunicationChannel.UDP;
}

export interface RestConfiguration extends ObjectKeys {
  address: string;
  port: number;
  channel: CommunicationChannel.REST;
  serialNumber: string;
  apiVersion: string;
  verificationMode: CertificateVerificationMode;
}

export interface BleConfiguration extends ObjectKeys {
  address: string;
  channel: CommunicationChannel.BLE;
}

export type WallboxConfiguration = UdpConfiguration | RestConfiguration | BleConfiguration;

export interface WallboxFilter extends ObjectKeys {
  field: string;
  comparator: FilterComparator;
  value: string;
}

export interface WallboxFilterOptions extends ObjectKeys {
  offset?: number;
  limit?: number;
  orderField?: string;
  orderDir?: FilterOrdering;
  filters: WallboxFilter[];
}

export interface WallboxSession extends ObjectKeys {
  duration: number;
  startDate: Date;
  endDate?: Date;
  energyConsumed: number; //mWh
  tokenId?: string;
  wallboxSerialNumber: string;
  status: SessionStatus;
}

export enum SessionStatus {
  INITIATED = 'INITIATED',
  WAITING_AUTHORIZATION = 'WAITING_AUTHORIZATION',
  PWM_CHARGING = 'PWM_CHARGING',
  BLOCKED = 'BLOCKED',
  CLOSED = 'CLOSED',
}

export interface WallboxSessionResults extends ObjectKeys {
  sessions: WallboxSession[];
}

export interface WallboxRfidToken extends ObjectKeys {
  id: string;
  name?: string;
  status: RfidStatus;
  details?: string;
  master?: boolean;
  serialNumbers?: number[];
  expiryDate?: Date;
  usedDate?: Date;
  changedDate?: Date;
}

export interface WallboxRfidTokenResults extends ObjectKeys {
  tokens: WallboxRfidToken[];
}

export interface CertificateResult extends ObjectKeys {
  delete: boolean;
  domain: string;
  id: string;
  issuedBy: string;
  purpose: string;
  serialNumber: string;
  validFrom: Date;
  validTo: Date;
}

export interface CertificatesResults extends ObjectKeys {
  certificates: CertificateResult[];
}

export interface UpdateInformationResult extends ObjectKeys {
  isUpToDate: boolean;
  updateInformation?: UpdateInformation;
}

export interface UpdateInformation extends ObjectKeys {
  location: string;
  checkDate?: number;
  retries?: number;
  retryInterval?: number;
  description?: string;
  signingCertificate?: string;
  signature?: string;
}

export enum MaxPhases {
  ONE = 1,
  THREE = 3,
}

export enum PhaseUsed {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  L1_L2_L3 = 'L1_L2_L3',
  L2_L3_L1 = 'L2_L3_L1', // this one is bugged in the REST API < 1.14.0(Wallbox Firmware)
  L3_L1_L2 = 'L3_L1_L2',
  L1_L3_L2 = 'L1_L3_L2',
  L2_L1_L3 = 'L2_L1_L3',
  L3_L2_L1 = 'L3_L2_L1',
}

export enum MaxPhasesWallbox {
  ONE = '1',
  THREE = '3',
}

export enum NetworkType {
  GSM = 'GSM',
  WIFI = 'WIFI',
}

export enum RfidStatus {
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
  INVALID = 'INVALID',
}

export enum FilterOrdering {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FilterComparator {
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  EQ = 'EQ',
}

export enum SessionFilterField {
  SOCKET_NUMBER = 'SOCKET_NUMBER',
  SOCKET_SERIAL_NUMBER = 'SOCKET_SERIAL_NUMBER',
  SESSION_START_DATE = 'SESSION_START_DATE',
  SESSION_END_DATE = 'SESSION_END_DATE',
  CHARGING_SESSION_STATUS = 'CHARGING_SESSION_STATUS',
  CHARGING_TOKEN_ID = 'CHARGING_TOKEN_ID',
  TERMINATION_REASON = 'TERMINATION_REASON',
}

export enum RfidFilterField {
  ID = 'ID',
  NAME = 'NAME',
  STATUS = 'STATUS',
  EXPIRY_DATE = 'EXPIRY_DATE',
  USED_DATE = 'USED_DATE',
  CHANGED_DATE = 'CHANGED_DATE',
}

export enum NativeLocalWallboxState {
  REST_IDLE = 'IDLE',
  REST_READY_FOR_CHARGING = 'READY_FOR_CHARGING',
  REST_CHARGING = 'CHARGING',
  REST_SUSPENDED = 'SUSPENDED',
  REST_RECOVER_FROM_ERROR = 'RECOVER_FROM_ERROR',
  REST_UNRECOVERABLE_ERROR = 'UNRECOVERABLE_ERROR',
  REST_SERVICE_MODE = 'SERVICE_MODE',
  REST_TOKEN_PROGRAMMING_MODE = 'TOKEN_PROGRAMMING_MODE',
  REST_UNAVAILABLE = 'UNAVAILABLE',
  REST_OFFLINE = 'OFFLINE',
  REST_DEGRADED = 'DEGRADED',

  UDP_STARTUP = 'UDP_STARTUP',
  UDP_NOT_READY_FOR_CHARGING = 'UDP_NOT_READY_FOR_CHARGING',
  UDP_READY_FOR_CHARGING = 'UDP_READY_FOR_CHARGING',
  UDP_CHARGING = 'UDP_CHARGING',
  UDP_ERROR_IS_PRESENT = 'UDP_ERROR_IS_PRESENT',
  UDP_TEMPORARILY_INTERRUPTION = 'UDP_TEMPORARILY_INTERRUPTION',
}

export enum InstallerSettingType {
  VOLTAGES = 'VOLTAGES',
  PHASE_ROTATIONS = 'PHASE_ROTATIONS',
  X1_1_MODES = 'X1_1_MODES',
  X1_2_MODES = 'X1_2_MODES',
  X2_MODES = 'X2_MODES',
}

export enum ConfigurationType {
  RFID = 'RFID',
  TIMESYNCH = 'TIMESYNCH', // write only
  USB = 'USB',
  DIAGNOSTICS = 'DIAGNOSTICS',
  LMGMT = 'LMGMT',
  NETWORK = 'NETWORK', // read, only with key
  GSM = 'GSM',
  WIFI = 'WIFI', // read, only with key
  LAN = 'LAN', // only available on P40
  OCPP = 'OCPP',
  EXT_METER = 'EXT_METER',
  RESTAPI = 'RESTAPI',
  SYSTEM = 'SYSTEM', // read, only with key
  DISPLAY_TEXT = 'DISPLAY_TEXT', // read, only with key
  LANGUAGE_SPECIFIC_DISPLAY_TEXT = 'LANGUAGE_SPECIFIC_DISPLAY_TEXT', // read only
  PORTAL = 'PORTAL',
  IO = 'IO', // only P40
  BLE = 'BLE', // only P40
  MODBUS = 'MODBUS', // only P40
}

export interface ConfigurationResult {
  configs: { key: string; value: string }[];
}

export type ConfigurationPropertyKey =
  | RfidKey
  | TimesynchKey
  | UsbKey
  | DiagnosticsKey
  | LmgmtKey
  | ModbusKey
  | NetworkKey
  | GsmKey
  | OcppKey
  | ExtMeterKey
  | SystemKey
  | RestapiKey
  | DisplayTextLanguageKey
  | DisplayTextKey
  | WifiKey
  | LanKey
  | PortalKey
  | IoKey
  | BleKey;

export enum PortalKey {
  PORTAL_ENROLLMENT_TOKEN = 'portal_enrollment_token',
  PORTAL_HOST = 'portal_host',
  PORTAL_PORT = 'portal_port',
  PORTAL_PATH = 'portal_path',
  PORTAL_WALLBOX_ID = 'portal_wbox_id',
  PORTAL_AUTH_KEY = 'portal_auth_key',
}

export enum RfidKey {
  ENABLE_RFID_AUTHORIZATION = 'rfid_authorization_enable',
  AUTHORIZATION_MODE_ONLINE = 'rfid_authorization_online_mode', // possible values: (FirstLocal, FirstOnline, OnlyLocal)
  AUTHORIZATION_MODE_OFFLINE = 'rfid_authorization_offline_mode', // possible values: ((OfflineLocalUnknownAuthorization, OfflineLocalAuthorization, OfflineNoAuthorization, OfflineNoCharging, OfflineFreeCharging))
}

export enum TimesynchKey {
  TIMESYNCH_WINDOW = 'time_synchronization_window',
}

export enum UsbKey {
  ENABLE_USB_INIT = 'usb_init_enable',
  ENABLE_USB_CONFIG = 'usb_config_enable',
  ENABLE_USB_UPDATE = 'usb_update_enable',
}

export enum DiagnosticsKey {
  MAX_DAYS_OF_LOGS = 'max_days_of_logs',
}

export enum LmgmtKey {
  NOMINAL_VOLTAGE = 'nominal_voltage',
  MAX_AVAILABLE_CURRENT = 'max_available_current',
  MIN_DEFAULT_CURRENT = 'min_default_current',
  MAX_ASYM_PHASE_CURRENT = 'max_asymmetrical_phase_current',
  ENABLE_ASYM_NETWORK = 'asymmetrical_network_enable',
  FAILSAFE_CURRENT_SERIAL = 'failsafe_current_serial_1', // + [1..n] some index needs to be added in the end, maybe needs extra call

  // The following keys are only available starting from API version 2.0.0
  PV_ENABLE = 'pv_enable', // Enables/disables the local Intelligent Photo Voltaic Charging optimization
  PV_MIN_SHARE = 'pv_min_share', // Minimum share (percentage) of photovoltaic power to begin charging.
  PV_PRE_CHARGE_TIME = 'pv_pre_charge_time', // Time elapsed (seconds) from the start of charge that the PV optimization is not applied and the EV gets full power.
  PV_IGNORE_X1 = 'pv_ignore_x1', // Enables/disables X1 to act as a inhibitor for the PV optimization. If enabled and X1 input is active, then the EV gets full power.
  PV_THRESHOLD_IMPORT = 'pv_threshold_import', // Power import threshold (mW) above which we run PV optimization.
  PV_THRESHOLD_EXPORT = 'pv_threshold_export', // Power export threshold (mW) above which we run PV optimization.
  PV_DELAY = 'pv_delay', // Minimum period (seconds) at which adjustments of the PV optimization can be made.
  CONNECTOR_PHASE_ENABLED = 'connector_phase_enable', // Enable/disable of Switch3to1Phase feature
  CONNECTOR_PHASE_SOURCE = 'connector_phase_source', // Set the phase toggle source (OCPP, REST, MODBUS and UDP.)
}

export enum ModbusKey {
  ENABlE_MODBUS = 'modbus_enable',
  PORT = 'modbus_port',
  UNIT_ID = 'modbus_unit_id',
  ENABLE_RFID = 'modbus_rfid_enable',
  FAILSAFE_CURRENT = 'modbus_failsafe_current',
  FAILSAFE_TIMEOUT = 'modbus_failsafe_timeout',
  SWAP_WORD = 'modbus_swap_word',
  SWAP_BYTE = 'modbus_swap_byte',
}

export enum NetworkKey {
  ENABLE_DHCP_SERVER = 'dhcp_server_enable',
  ENABLE_SSH_SERVER = 'ssh_server_enable',
}

export enum GsmKey {
  ENABLE_GSM = 'gsm_enable',
  APN_NAME = 'gsm_apn',
  APN_USERNAME = 'gsm_apn_username',
  APN_PASSWORD = 'gsm_apn_password',
  SIM_PIN = 'gsm_sim_pin',
  SIM_STATUS = 'simStatusCheck', //renamed to 'gsm_sim_status'
  TEST_RECEPTION = 'gsmreceptiontest', // VALUE: test time in seconds
  RECEPTION_STATUS = 'gsmreceptionteststatus',
}

export enum WifiKey {
  ENABLE_WIFI_CLIENT = 'wifi_client_enable',
  CLIENT_SSID = 'wifi_client_ssid',
  CLIENT_PASSWORD = 'wifi_client_password',
  CLIENT_IP = 'wifi_client_ip',
  ENABLE_WIFI_SERVER = 'wifi_server_enable',
  SERVER_SSID = 'wifi_server_ssid',
  SERVER_PASSWORD = 'wifi_server_password',
  SERVER_CHANNEL = 'wifi_server_channel',
  SERVER_IP = 'wifi_server_ip', // only P40
  ENABLE_WIFI_DEVICE = 'wifi_device_enable', // only P40
}

export enum LanKey {
  ENABLE_LAN = 'lan_enable', // only P40
  LAN_CABLE_PLUGGED = 'lan_cable_plugged', // only P40
  LAN_IP = 'lan_ip', // only P40
}

export enum OcppKey {
  VERSION = 'ocpp_version', // possible values: (1.5 | 1.6J | 1.6S | 2.0RC2)
  CHARGE_POINT_ID = 'ocpp_charge_point_id',
  CHARGE_POINT_ADDRESS = 'ocpp_charge_point_address',
  CHARGE_POINT_PORT = 'ocpp_charge_point_port',
  CHARGE_POINT_INTERFACE = 'ocpp_charge_point_interface',
  CHARGE_POINT_AUTHENTICATION = 'ocpp_charge_point_authentication', // possible values: (None, BasicAuthentication)
  CHARGE_POINT_USERNAME = 'ocpp_charge_point_username', // write only
  CHARGE_POINT_PASSWORD = 'ocpp_charge_point_password', // write only
  INCOMING_TLS = 'ocpp_incoming_tls',
  OUTGOING_TLS = 'ocpp_outgoing_tls',
  CENTRAL_SYSTEM_ADDRESS = 'ocpp_central_system_address',
  CENTRAL_SYSTEM_PORT = 'ocpp_central_system_port',
  CENTRAL_SYSTEM_PATH = 'ocpp_central_system_path',
  CENTRAL_SYSTEM_AUTHENTICATION = 'ocpp_central_system_authentication', // possible values: (None, BasicAuthentication)
  CENTRAL_SYSTEM_AUTHORIZATION_KEY = 'ocpp_central_system_authorization_key', // write only
  CENTRAL_SYSTEM_USERNAME = 'ocpp_central_system_username', // write only
  CENTRAL_SYSTEM_PASSWORD = 'ocpp_central_system_password', // write only
  CENTRAL_SYSTEM_URL = 'ocpp_central_system_url',
  DEFAULT_TOKEN_ID = 'ocpp_default_token_id',
  CONNECTION_RETRY_INTERVAL = 'ocpp_connection_retry_interval',
  WEB_SOCKET_PING_INTERVAL = 'ocpp_web_socket_ping_interval',
  HEARTBEAT_INTERVAL = 'ocpp_heartbeat_interval',
  METER_COLLECTION_TYPE = 'ocpp_meter_collection_type',
  METER_VALUES_SAMPLE_INTERVAL = 'ocpp_meter_values_sample_interval',
  METER_VALUES_CLOCK_ALIGNED_INTERVAL = 'ocpp_meter_values_clock_aligned_data_interval',
  ENABLE_PROXY = 'ocpp_proxy_enable',
  PROXY_ADDRESS = 'ocpp_proxy_address',
  PROXY_PORT = 'ocpp_proxy_port',
  PROXY_USERNAME = 'ocpp_proxy_username', // write only
  PROXY_PASSWORD = 'ocpp_proxy_password', // write only
}

export enum ExtMeterKey {
  ENABLE_EXTERNAL_METER = 'external_meter_home_grid_enable',
  PROVIDER = 'external_meter_home_grid_provider',
  ADDRESS = 'external_meter_home_grid_address',
  PORT = 'external_meter_home_grid_port',
  UNIT = 'external_meter_home_grid_unit',
  MAX_POWER = 'external_meter_home_grid_max_power', // mW
  MAX_POWER_OFFLINE = 'external_meter_home_grid_offline_max_power', // mW
  MAX_CURRENT_PHASE_ONE = 'external_meter_home_grid_phase1_max_current', // mA
  MAX_CURRENT_PHASE_TWO = 'external_meter_home_grid_phase2_max_current', // mA
  MAX_CURRENT_PHASE_THREE = 'external_meter_home_grid_phase3_max_current', // mA
}

export enum SystemKey {
  SESSION_RESUM_AFTER_POWER_CUT = 'session_resume_after_power_cut',
  APPLICATION_VERSION = 'application_version', // On P30 application version equals package version
  PACKAGE_VERSION = 'application_version_package', // Use this for P40
}

export enum VersionKey {
  PACKAGE = 'package',
}

export enum RestapiKey {
  ALIAS = 'restapi_alias',
}

export enum DswKey {
  DSW_SETTINGS = 'dsw_settings',
}

export enum DisplayTextLanguageKey {
  ENGLISH = 'en',
  ROMANIAN = 'ro',
  ITALIAN = 'it',
  GERMAN = 'de',
}

export enum DisplayTextKey {
  LANGUAGE = 'display_text_language', //possible values (en | ro | it | de)
  CARD_BLOCKED_TEXT = 'display_text_card_blocked', //- Set display text card blocked
  CARD_BLOCKED_MIN_TIME = 'display_text_card_blocked_min_time', //- Set display text card blocked minimum time [s]
  CARD_BLOCKED_MAX_TIME = 'display_text_card_blocked_max_time', //- Set display text card blocked maximum time [s]
  CARD_BLOCKED_FREQUENCY = 'display_text_card_blocked_frequency', //- Set display text card blocked frequency [s]
  CARD_EXPIRED_TEXT = 'display_text_card_expired', //- Set display text card expired
  CARD_EXPIRED_MIN_TIME = 'display_text_card_expired_min_time', //- Set display text card expired minimum time [s]
  CARD_EXPIRED_MAX_TIME = 'display_text_card_expired_max_time', //- Set display text card expired maximum time [s]
  CARD_EXPIRED_FREQUENCY = 'display_text_card_expired_frequency', //- Set display text card expired frequency [s]
  CARD_INVALID_TEXT = 'display_text_card_invalid', //- Set display text card invalid
  CARD_INVALID_MIN_TIME = 'display_text_card_invalid_min_time', //- Set display text card invalid minimum time [s]
  CARD_INVALID_MAX_TIME = 'display_text_card_invalid_max_time', //- Set display text card invalid maximum time [s]
  CARD_INVALID_FREQUENCY = 'display_text_card_invalid_frequency', //- Set display text card invalid frequency [s]
  CARD_OK_TEXT = 'display_text_card_ok', //- Set display text card ok
  CARD_OK_MIN_TIME = 'display_text_card_ok_min_time', //- Set display text card ok minimum time [s]
  CARD_OK_MAX_TIME = 'display_text_card_ok_max_time', //- Set display text card ok maximum time [s]
  CARD_OK_FREQUENCY = 'display_text_card_ok_frequency', //- Set display text card ok frequency [s]
  CARD_PLEASE_TEXT = 'display_text_card_please', //- Set display text card please
  CARD_PLEASE_MIN_TIME = 'display_text_card_please_min_time', //- Set display text card please minimum time [s]
  CARD_PLEASE_MAX_TIME = 'display_text_card_please_max_time', //- Set display text card please maximum time [s]
  CARD_PLEASE_FREQUENCY = 'display_text_card_please_frequency', //- Set display text card please frequency [s]
  CHARGING_TEXT = 'display_text_charging', //- Set display text charging
  CHARGING_MIN_TIME = 'display_text_charging_min_time', //- Set display text charging minimum time [s]
  CHARGING_MAX_TIME = 'display_text_charging_max_time', //- Set display text charging maximum time [s]
  CHARGING_FREQUENCY = 'display_text_charging_frequency', //- Set display text charging frequency [s]
  CHARGING_SUSPENDED_TEXT = 'display_text_charging_suspended', //- Set display text charging suspended
  CHARGING_SUSPENDED_MIN_TIME = 'display_text_charging_suspended_min_time', //- Set display text charging suspended minimum time [s]
  CHARGING_SUSPENDED_MAX_TIME = 'display_text_charging_suspended_max_time', //- Set display text charging suspended maximum time [s]
  CHARGING_SUSPENDED_FREQUENCY = 'display_text_charging_suspended_frequency', //- Set display text charging suspended frequency [s]
  CHARGING_STOPPED_TEXT = 'display_text_charging_stopped', //- Set display text charging stopped
  CHARGING_STOPPED_MIN_TIME = 'display_text_charging_stopped_min_time', //- Set display text charging stopped minimum time [s]
  CHARGING_STOPPED_MAX_TIME = 'display_text_charging_stopped_max_time', //- Set display text charging stopped maximum time [s]
  CHARGING_STOPPED_FREQUENCY = 'display_text_charging_stopped_frequency', //- Set display text charging stopped frequency [s]
  CHECKING_CARD_TEXT = 'display_text_checking_card', //- Set display text checking card
  CHECKING_CARD_MIN_TIME = 'display_text_checking_card_min_time', //- Set display text checking card minimum time [s]
  CHECKING_CARD_MAX_TIME = 'display_text_checking_card_max_time', //- Set display text checking card maximum time [s]
  CHECKING_CARD_FREQUENCY = 'display_text_checking_card_frequency', //- Set display text checking card frequency [s]
  INSERT_PLUG_TEXT = 'display_text_insert_plug', //- Set display text insert plug
  INSERT_PLUG_MIN_TIME = 'display_text_insert_plug_min_time', //- Set display text insert plug minimum time [s]
  INSERT_PLUG_MAX_TIME = 'display_text_insert_plug_max_time', //- Set display text insert plug maximum time [s]
  INSERT_PLUG_FREQUENCY = 'display_text_insert_plug_frequency', //- Set display text insert plug frequency [s]
  RESERVED_ID_TEXT = 'display_text_reserved_id', //- Set display text reserved id
  RESERVED_ID_MIN_TIME = 'display_text_reserved_id_min_time', //- Set display text reserved id minimum time [s]
  RESERVED_ID_MAX_TIME = 'display_text_reserved_id_max_time', //- Set display text reserved id maximum time [s]
  RESERVED_ID_FREQUENCY = 'display_text_reserved_id_frequency', //- Set display text reserved id frequency [s]
  WRONG_RESERVATION_TEXT = 'display_text_wrong_reservation', //- Set display text wrong reservation
  WRONG_RESERVATION_MIN_TIME = 'display_text_wrong_reservation_min_time', //- Set display text wrong reservation minimum time [s]
  WRONG_RESERVATION_MAX_TIME = 'display_text_wrong_reservation_max_time', //- Set display text wrong reservation maximum time [s]
  WRONG_RESERVATION_FREQUENCY = 'display_text_wrong_reservation_frequency', //- Set display text wrong reservation frequency [s]
  PV_BOOST_CHARGING_TEXT = 'display_text_pv_boost_charging', //- Get display text charging when PV optimization is enabled and in boosting state
  PV_BOOST_CHARGING_FREQUENCY = 'display_text_pv_boost_charging_frequency', //- Get display text PV boost charging frequency [s]
  PV_BOOST_CHARGING_MIN_TIME = 'display_text_pv_boost_charging_max_time', //- Get display text PV boost charging maximum time [s]
  PV_BOOST_CHARGING_MAX_TIME = 'display_text_pv_boost_charging_min_time', //- Get display text PV boost charging minimum time [s]
  PV_CHARGING_TEXT = 'display_text_pv_charging', //- Get display text charging when PV optimization is enabled and in optimizing state
  PV_CHARGING_FREQUENCY = 'display_text_pv_charging_frequency', //- Get display text PV charging frequency [s]
  PV_CHARGING_MIN_TIME = 'display_text_pv_charging_max_time', //- Get display text PV charging maximum time [s]
  PV_CHARGING_MAX_TIME = 'display_text_pv_charging_min_time', //- Get display text PV charging minimum time [s]
}

// only P40
export enum IoKey {
  INST_X1_1_ENABLE = 'inst_x1_1_enable',
  INST_X1_1_MODE = 'inst_x1_1_mode',
  INST_X1_1_VALUE = 'inst_x1_1_value',
  INST_X1_2_ENABLE = 'inst_x1_2_enable',
  INST_X1_2_MODE = 'inst_x1_2_mode',
  INST_X1_2_VALUE = 'inst_x1_2_value',
  INST_X2_ENABLE = 'inst_x2_enable',
  INST_X2_MODE = 'inst_x2_mode',
}

// only P40
export enum BleKey {
  BLE_DEVICE_NAME = 'ble_device_name',
  BLE_FW_VERSION = 'ble_fw_version',
  BLE_DEVICE_PIN = 'ble_device_pin',
}

export enum PVStatus {
  PV_NONE = 'PV_NONE',
  PV_PRE_CHARGING = 'PV_PRE_CHARGING',
  PV_OPTIMIZING = 'PV_OPTIMIZING',
  PV_X1_ACTIVE = 'PV_X1_ACTIVE',
  PV_BOOST = 'PV_BOOST',
}

export enum PVConnectorSource {
  NONE = 'NONE',
  CPM_PROFILES = 'CPM_PROFILES',
  CPM_DIR_CTRL = 'CPM_DIR_CTRL',
  MODBUS = 'MODBUS',
  UDP = 'UDP',
}

export enum UpdateStatus {
  IDLE = 'IDLE',
  CANCELLED = 'CANCELLED',
  PREPARING = 'PREPARING',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
  DOWNLOAD_SCHEDULED = 'DOWNLOAD_SCHEDULED',
  INSTALLING = 'INSTALLING',
  INSTALLED = 'INSTALLED',
  INSTALLATION_FAILED = 'INSTALLATION_FAILED',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  SIGNATURE_VERIFIED = 'SIGNATURE_VERIFIED',
}

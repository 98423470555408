export interface RfidResponseData {
  headers: Record<string, any>;
  body: {
    code?: string; // Optional
    data?: { key: string; value: string }[]; // Optional
    chargePointId: string;
    chargePointName?: string; // Optional
    chargePointSerial: string;
    syncStatus?: string; // Optional
  };
  statusCodeValue: number;
  statusCode: string;
}

export interface CountedData {
  total: number;
  success: number;
  failed: number;
}

export interface ErrorDataCounts {
  [key: string]: CountedData;
}

export const rfidFailureStatuses = [
  'PORTAL.SYNC_TO_CHARGEPOINT.SOCKET_CONNECTION',
  'PORTAL.SYNC_TO_CHARGEPOINT.WRONG_CONFIRMATION',
  'PORTAL.SYNC_TO_CHARGEPOINT.NON_PARSABLE_RESPONSE',
  'PORTAL.SYNC_TO_CHARGEPOINT.REJECTED_MESSAGE',
  'PORTAL.SYNC_TO_CHARGEPOINT.TIMEOUT',
  'PORTAL.SYNC_TO_CHARGEPOINT.NOT_ONLINE',
];

<ng-container *kwAlias="gdprReport$ | async as gdprReport">
  <div class="flex items-center gap-6 {{ isApp ? 'flex-col' : 'flex-row' }} mb-8">
    <mat-spinner *ngIf="isLoading$ | async" [diameter]="24" class=""></mat-spinner>

    <ng-container *ngFor="let action of profileInfoActions">
      <button
        *ngIf="action.isVisible(gdprReport)"
        [attr.accessibility-id]="'kw-test__main__profile-information__btn--' + action.key + '-report'"
        [disabled]="action.isDisabled(gdprReport) || (isLoading$ | async)"
        [ngClass]="action.class"
        (click)="action.onClick(gdprReport)"
        class="flex items-center gap-1"
      >
        <span
          *ngIf="action.icon; let icon"
          [inlineSVG]="'assets/icons/24/' + icon + '.svg'"
          class="fill-current"
        ></span>
        {{ 'kw.user-profile.profile-information.cta.' + action.key + '-report' | translate }}
      </button>
    </ng-container>
  </div>

  <div *ngIf="gdprReport?.status === 'CREATED'" class="flex gap-2 whitespace-pre-line items-top body-small">
    <span class="fill-basil-500" inlineSVG="assets/icons/24/info.svg"></span>
    {{ 'kw.user-profile.profile-information.information.download' | translate }}
  </div>
</ng-container>

<div toastContainer class="w-full"></div>

export class FileUtils {
  public static async downloadFile(url: string, filename: string): Promise<void> {
    // Test for download link support
    if ('download' in document.createElement('a')) {
      const element = document.createElement('a');
      element.style.display = 'none';
      element.target = '_blank';
      element.href = url;

      element.download = filename;

      document.body.appendChild(element);
      return element.click();
    } else {
      // failover, open resource in current tab.
      window.location.assign(url);
    }
  }

  public static generateCsvFileUrl(data: string, filename: string): string {
    return 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormat, WallboxPVStatusInfo } from '@kw-shared/common';

@Component({
  selector: 'kw-pv-charging-boost-config',
  templateUrl: './pv-charging-boost-config.component.html',
  styleUrls: ['./pv-charging-boost-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PvChargingBoostConfigComponent implements OnInit {
  public readonly DURATION_VALUES = [15, 30, 60, 90, 120, 150, 180, 240, 300, 360].map(v => v * 60 * 1000);
  public readonly DEFAULT_DURATION = this.DURATION_VALUES[1];
  public readonly BaseFormat = BaseFormat;

  @Input()
  pvStatusInfo?: WallboxPVStatusInfo;

  @Output()
  boostConfigChanged = new EventEmitter<number | undefined>();

  public durationInMs: number = this.DEFAULT_DURATION;
  public untilFullyCharged = false;

  public ngOnInit(): void {
    this.onValueChange(this.DEFAULT_DURATION);
  }

  public onValueChange(durationInMs: number): void {
    this.durationInMs = durationInMs;
    this.boostConfigChanged.emit(durationInMs);
  }

  public onUntilFullyChargedChanged(value: boolean): void {
    this.untilFullyCharged = value;
    this.boostConfigChanged.emit(this.untilFullyCharged ? undefined : this.durationInMs);
  }
}

export interface DialogMenuElement {
  alwaysVisible?: boolean;
  borderTop?: boolean;
  class?: string;
  fragment?: string;
  icon: string;
  key: string;
  title: string;
  url?: string;
  openExternal?: boolean;
  subMenu?: DialogMenuElement[];
  customIconColor?: string;
  accessibilityId?: string;
  translateableUrl?: boolean;
  email?: string;
  emailSubject?: string;
}

export interface DialogMenu {
  title: string;
  elements: DialogMenuElement[];
}

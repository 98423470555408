import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BrowsingCallbackDataAdded } from '@keba-wallbox-plugin/plugin';
import { FullScreenDialogComponent } from '@kw-shared/common';
import { BehaviorSubject } from 'rxjs';
import { ConnectGuideOrigin } from '../../pages/select-wallbox/pages/connect-guide/shared/types/connect-guide.types';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _onFullScreenDialogBtnClick = new BehaviorSubject<boolean>(false);
  private _blePairingSuccessful = new BehaviorSubject<boolean>(false);
  private _wallboxConnected = new BehaviorSubject<BrowsingCallbackDataAdded | undefined>(undefined);

  constructor(
    private dialog: MatDialog,
    private router: Router
  ) {}

  get onFullScreenDialogBtnClick() {
    return this._onFullScreenDialogBtnClick.asObservable();
  }

  public setOnFullScreenDialogBtnClick(loading: boolean): void {
    this._onFullScreenDialogBtnClick.next(loading);
  }

  get blePairingSuccessful() {
    return this._blePairingSuccessful.asObservable();
  }

  public setBlePairingSuccessful(success: boolean): void {
    this._blePairingSuccessful.next(success);
  }

  get wallboxConnected() {
    return this._wallboxConnected.asObservable();
  }

  public setWallboxConnected(loading?: BrowsingCallbackDataAdded): void {
    this._wallboxConnected.next(loading);
  }

  public openBleRePairDialog(origin?: ConnectGuideOrigin): void {
    this.dialog
      .open(FullScreenDialogComponent, {
        data: {
          title: 'kwa.p40.re-pair-info.title',
          description: 'kwa.p40.re-pair-info.description',
          showClose: true,
          variant: 'wide',
          icons: [
            {
              name: 'warning',
              color: 'text-basil-500',
            },
          ],
          ctaButtons: [{ key: 'cancel', text: 'kwa.p40.re-pair-info.cta' }],
        },
      })
      .beforeClosed()
      .subscribe(result => {
        if (result && origin === ConnectGuideOrigin.INSTALLER_MODE) {
        } else {
          this.router.navigate(['/']);
        }
      });
  }
}

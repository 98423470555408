import { Inject, Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ENVIRONMENT, IEnvironment } from '../IEnvironment';
import { NotificationComponent } from '../components';
import { KWLogger } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  constructor(private toastr: ToastrService, @Inject(ENVIRONMENT) private readonly environment: IEnvironment) {}

  public setError(
    message: string,
    showInProduction?: boolean,
    options?: Partial<IndividualConfig>,
    inlineContainer?: ToastContainerDirective
  ): ActiveToast<NotificationComponent> {
    const config = this.applyInlineOptions(options, inlineContainer);
    if (this.environment.enableDetailedErrorMessages) {
      KWLogger.error(message);
    }
    return this.toastr.error(
      this.environment.enableDetailedErrorMessages || showInProduction ? message : 'kwa_toast_error_description',
      'kwa_toast_error_title',
      config
    );
  }

  public setInfo(
    message?: string,
    title?: string,
    options?: Partial<IndividualConfig>,
    inlineContainer?: ToastContainerDirective
  ): ActiveToast<NotificationComponent> {
    const config = this.applyInlineOptions(options, inlineContainer);
    return this.toastr.info(message || 'kwa_toast_info_description', title ?? 'kwa_toast_info_title', config);
  }

  public setSuccess(
    message?: string,
    options?: Partial<IndividualConfig>,
    inlineContainer?: ToastContainerDirective
  ): void {
    const config = this.applyInlineOptions(options, inlineContainer);
    this.toastr.success(message || 'kwa_toast_success_description', 'kwa_toast_success_title', config);
  }

  public setWarning(
    message?: string,
    options?: Partial<IndividualConfig>,
    inlineContainer?: ToastContainerDirective
  ): void {
    const config = this.applyInlineOptions(options, inlineContainer);
    this.toastr.warning(message || 'kwa_toast_warning_description', 'kwa_toast_warning_title', config);
  }

  public showIndeterminateProgress(message?: string, title?: string, options?: Partial<IndividualConfig>) {
    const config: Partial<IndividualConfig> = {
      disableTimeOut: true,
      closeButton: false,
      progressBar: true,
    };
    Object.assign(config, options);
    return this.toastr.info(message || 'kwa_toast_info_description', title || 'kwa_toast_info_title', config);
  }

  public clearAll(): void {
    this.toastr.clear();
  }

  public clearMessage(id: number): void {
    this.toastr.remove(id);
  }

  private setContainer(toastContainer?: ToastContainerDirective): void {
    this.toastr.overlayContainer = toastContainer;
  }

  private applyInlineOptions(
    options?: Partial<IndividualConfig>,
    inlineContainer?: ToastContainerDirective
  ): Partial<IndividualConfig> {
    const config: Partial<IndividualConfig> = inlineContainer
      ? {
          positionClass: 'toast-inline',
          disableTimeOut: true,
          closeButton: false,
        }
      : {};

    Object.assign(config, options);
    this.setContainer(inlineContainer);
    return config;
  }
}

import {
  RemoteRfidAuthorization,
  RemoteRfidAuthorizationImport,
  RfidOnlineAuthorization,
} from '@kw-shared/api-data-access';
import { Observable, of } from 'rxjs';
import { FakeRepository } from '../base/FakeRepository';
import { IRfidAuthorizationRepository } from './IRfidAuthorizationRepository';

export class FakeRfidAuthorizationRepository
  extends FakeRepository<RemoteRfidAuthorization>
  implements IRfidAuthorizationRepository
{
  constructor() {
    super([
      {
        id: '1',
        chargePointId: '1',
        chargePointName: 'Rfid card 1',
        authorization: String(false),
        onlineAuthorization: RfidOnlineAuthorization.FIRST_ONLINE,
        chargePointSerial: '1234567890',
      },
      {
        id: '2',
        chargePointId: '2',
        chargePointName: 'Rfid card 2',
        authorization: String(true),
        chargePointSerial: '1234567890',
      },
      {
        id: '3',
        chargePointId: '3',
        chargePointName: 'Rfid card 4',
        authorization: String(true),
        chargePointSerial: '1234567890',
      },
      {
        id: '4',
        chargePointId: '4',
        authorization: String(true),
        chargePointSerial: '1234567890',
      },
    ]);
  }

  public startImport(chargePointId: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public fetchImportState(chargePointId: string): Observable<RemoteRfidAuthorizationImport> {
    return this.delayResponse(of({ id: '123', chargePointId, importStatus: 'ImportSucceeded' }));
  }
}

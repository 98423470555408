import { AppVersionControlRequest, AppVersionControlResponse } from 'libs/api-data-access/models/AppVersionControl';
import { Observable, of } from 'rxjs';
import { FakeRepository } from '../base/FakeRepository';
import { IAppVersionControlRepository } from './IAppVersionControlRepository';

export class FakeAppVersionControlRepository extends FakeRepository<any> implements IAppVersionControlRepository {
  constructor() {
    super([]);
  }

  public getRequiredVersion(request: AppVersionControlRequest): Observable<AppVersionControlResponse | null> {
    const response = { update: { link: '', message: 'FAKE' } };
    return this.delayResponse(of(null));
  }
}

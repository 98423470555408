import { Validators } from '@angular/forms';
import { FormInputType, SettingsFormData } from '../../components';
import { SettingsUnitsConverter } from '../../utils';
import { SettingsValidators } from '../../utils/SettingsValidators';
import { WallboxNameRegex } from '../../utils/WallboxRegexes';

export const chargepointParametersFormDefinition: SettingsFormData[] = [
  {
    title: 'kw.settings.chargepoint-parameters.title',
    accessibilityId: 'chargepoint-parameters-settings',
    elements: [
      {
        key: 'alias',
        type: FormInputType.TEXT,
        description: 'kw.settings.chargepoint-parameters.alias.description',
        title: 'kw.settings.chargepoint-parameters.alias.title',
        maxLength: 40, //maxlength
        minLength: 1,
        pattern: WallboxNameRegex.VALID_NAME,
        patternText: 'kwa.settings.wallbox-name.requirement.letter-followed',
        validators: [Validators.maxLength(40), Validators.pattern(WallboxNameRegex.VALID_NAME)], // on Android maxlength doesn't work reliably
        // https://stackoverflow.com/questions/26010588/maxlength-not-working-in-input-type-text-in-cordova-android-app
        accessibilityId: 'alias',
      },
      {
        key: 'number',
        type: FormInputType.TEXT,
        description: 'kw.settings.chargepoint-parameters.connector-id.description',
        title: 'kw.settings.chargepoint-parameters.connector-id.title',
        accessibilityId: 'connector-id',
        readOnly: true,
      },
      {
        key: 'serialNumber',
        type: FormInputType.TEXT,
        description: 'kw.settings.chargepoint-parameters.serialnumber.description',
        title: 'kw.settings.chargepoint-parameters.serialnumber.title',
        rebootRequired: true,
        accessibilityId: 'serialnumber',
        validators: [Validators.required],
      },
      {
        key: 'maxPhases',
        type: FormInputType.SELECT,
        description: 'kw.settings.chargepoint-parameters.type-of-installation.description',
        title: 'kw.settings.chargepoint-parameters.type-of-installation.title',
        selectOptions: [
          { key: '1', text: '1', accessibilityId: 'select-one-phase' },
          { key: '3', text: '3', accessibilityId: 'select-three-phases' },
        ],
        translatePrefix: 'kw.settings.chargepoint-parameters.type-of-installation.translation-prefix.',
        accessibilityId: 'type-of-installation',
      },
      {
        key: 'phaseUsed',
        type: FormInputType.SELECT,
        description: 'kw.settings.chargepoint-parameters.phase-assignment.description',
        title: 'kw.settings.chargepoint-parameters.phase-assignment.title',
        selectOptions: [],
        accessibilityId: 'phase-assignment',
      },
      {
        key: 'failsafeCurrent',
        type: FormInputType.NUMBER,
        description: 'kw.settings.chargepoint-parameters.failsafe-current.description',
        title: 'kw.settings.chargepoint-parameters.failsafe-current.title',
        accessibilityId: 'failsafeCurrent',
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        min: 0,
        max: 32,
        pattern: /^(0|[6-9]|\d{2,})$/,
        patternText: 'kw.settings.chargepoint-parameters.failsafe-current.requirements.zero-or-min',
        validators: [
          Validators.min(0),
          SettingsValidators.maxCurrentValidator,
          Validators.max(32),
          Validators.pattern(/^(0|[6-9]|\d{2,})$/),
          Validators.required,
        ],
      },
      {
        key: 'state',
        type: FormInputType.SELECT,
        description: 'kw.settings.chargepoint-parameters.availability.description',
        title: 'kw.settings.chargepoint-parameters.availability.title',
        selectOptions: [],
        translatePrefix: 'kw.settings.chargepoint-parameters.availability.options.',
        accessibilityId: 'availability',
        hideElement: true,
      },
    ],
  },
];

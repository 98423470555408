import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IAppEnvironment } from '../../../../apps/keba-wallbox-app/src/environments/IAppEnvironment';
import { AppReview, Event } from '../../../../apps/keba-wallbox-app/src/plugins/native-app-review';
import { WallboxConnectionType } from '../../definitions';
import { ENVIRONMENT } from '../../IEnvironment';
import { PlatformType, WallboxState } from '../../models';

@Component({
    selector: 'kw-start-stop-charging-button',
    templateUrl: './start-stop-charging-button.component.html',
    styleUrls: ['./start-stop-charging-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StartStopChargingButtonComponent {
  @Input()
  startStates: WallboxState[];

  @Input()
  stopStates: WallboxState[];

  @Input() connectionType?: WallboxConnectionType;

  @Input()
  state: WallboxState;

  @Input()
  disabled: boolean = false;

  @Output()
  triggerChargingAction = new EventEmitter<boolean>();

  constructor(@Inject(ENVIRONMENT) private readonly environment: IAppEnvironment) {}

  public get chargingStateIcon(): string | undefined {
    if (this.canStartCharging()) {
      return 'assets/icons/32/play.svg';
    } else if (this.stopStates.includes(this.state)) {
      if (this.connectionType === WallboxConnectionType.LOCAL_UDP) {
        return 'assets/icons/32/pause.svg';
      } else {
        return 'assets/icons/32/stop.svg';
      }
    }
    return;
  }

  public async startChargingHandler(): Promise<void> {
    if (
      (Capacitor.getPlatform() === PlatformType.IOS || Capacitor.getPlatform() === PlatformType.ANDROID) &&
      !this.preventAppReview()
    ) {
      await this.triggerAppReview();
    }
    if (!this.disabled) {
      this.triggerChargingAction.emit(this.canStartCharging());
    }
  }

  public async triggerAppReview(): Promise<void> {
    if (this.canStartCharging()) {
      await AppReview.requestReview({ event: Event.CHARGING_STARTED });
    } else if (this.stopStates.includes(this.state)) {
      await AppReview.requestReview({ event: Event.CHARGING_STOPPED });
    }
  }

  public getStartChargingClasses(): string {
    if (this.canStartCharging()) {
      return 'border-basil-500 text-basil-500 shadow-green';
    } else {
      return 'border-red-500 text-red-500 shadow-red';
    }
  }

  private canStartCharging(): boolean {
    return this.startStates.includes(this.state);
  }

  private preventAppReview(): boolean {
    /* https://keba.atlassian.net/browse/KEB-5199:
    do not trigger rating dialogue if connection type is UDP or isDevelopmentEnvironment */
    return this.isDevelopmentEnvironment() || this.connectionType == WallboxConnectionType.LOCAL_UDP;
  }

  private isDevelopmentEnvironment(): boolean {
    return !this.environment.production && !this.environment.qaEnvironment;
  }
}

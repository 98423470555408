import { RemoteRfidAuthorization, RemoteRfidAuthorizationImport } from '@kw-shared/api-data-access';
import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IRfidAuthorizationRepository } from './IRfidAuthorizationRepository';

export class RfidAuthorizationRepository
  extends WebDataRepository<RemoteRfidAuthorization>
  implements IRfidAuthorizationRepository
{
  constructor(public override httpClient: AdvancedHttpClient, private basePath?: string) {
    super(httpClient, Utils.joinPaths(basePath, 'rfid-authorization'));
  }

  public startImport(chargePointId: string, assignCards?: boolean): Observable<void> {
    let url = `${this.resource}/import/${chargePointId}`;
    if (assignCards) {
      url += '?assignCards=true';
    }
    return this.httpClient.put<void>({
      url: url,
      body: { chargePointId },
    });
  }

  public fetchImportState(chargePointId: string): Observable<RemoteRfidAuthorizationImport> {
    return this.httpClient.get<RemoteRfidAuthorizationImport>({
      url: `${this.resource}/import/${chargePointId}`,
    });
  }
}

import { Observable } from 'rxjs';
import { RegistrationToken } from '../../../../models';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { INotificationsRepository } from './INotificationRepository';

export class NotificationRepository extends WebDataRepository<any> implements INotificationsRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'notifications');
  }

  setRegistrationToken(registrationToken: RegistrationToken): Observable<void> {
    return this.httpClient.post<void>({ url: this.resource, body: registrationToken });
  }

  removeRegistrationToken(registrationToken: RegistrationToken): Observable<void> {
    return this.httpClient.delete<void>({ url: this.resource, body: registrationToken });
  }
}

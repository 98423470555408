import { AppVersionControlRequest, AppVersionControlResponse } from 'libs/api-data-access/models/AppVersionControl';
import { Observable } from 'rxjs';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IAppVersionControlRepository } from './IAppVersionControlRepository';

export class AppVersionControlRepository extends WebDataRepository<any> implements IAppVersionControlRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'versionControl/requiredVersion');
  }

  public getRequiredVersion(request: AppVersionControlRequest): Observable<AppVersionControlResponse> {
    return this.httpClient.post<AppVersionControlResponse>({ url: this.resource, body: request });
  }
}

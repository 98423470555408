import { HttpResponse } from '@angular/common/http';
import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { RemoteChargingReport } from '../../../../models/RemoteChargingReport';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IChargingReportRepository } from './IChargingReportRepository';

export class ChargingReportRepository
  extends WebDataRepository<RemoteChargingReport>
  implements IChargingReportRepository
{
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath?: string
  ) {
    super(httpClient, Utils.joinPaths(basePath, 'charging-reports'));
  }

  public unsubscribe(token: string): Observable<HttpResponse<any>> {
    // no authentication is required for this API!
    return this.httpClient.putWithObserve({ url: `charging-reports/unsubscribe?token=` + token, body: {} });
  }
}

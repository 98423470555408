import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'kw-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationComponent extends Toast {
  public type: string;
  public mail: string;

  constructor(override toastrService: ToastrService, override toastPackage: ToastPackage) {
    super(toastrService, toastPackage);

    this.type = this.toastPackage?.toastType?.replace('toast-', '');
  }
}

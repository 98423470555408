import { Injectable } from '@angular/core';
import { BaseToggleService } from '../base-toggle.service';

@Injectable({
  providedIn: 'root',
})
export class WallboxDetailsToggleService extends BaseToggleService {
  private readonly prefix = 'kwp.wallboxDetails';

  public isTileVisibleP40(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.tileVisibleP40`);
  }

  public isM20Wallbox(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.isM20Wallbox`);
  }

  public showPvBoostButton(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showPvBoostButton`);
  }

  public showRfidTile(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showRfidTile`);
  }

  public showTotalChargingSessionsTile(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.showTotalChargingSessionsTile`);
  }

  public canOpenMasterChargePoint(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.canOpenMasterChargePoint`);
  }

  public hasClients(): boolean {
    return this.toggleService.checkConditions(`${this.prefix}.hasClients`);
  }
}

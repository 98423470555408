import { RegistrationToken } from '../../../../models';
import { Observable } from 'rxjs';
import { INotificationsRepository } from './INotificationRepository';

export class FakeNotificationRepository implements INotificationsRepository {
  setRegistrationToken(registrationToken: RegistrationToken): Observable<void> {
    throw new Error('Method not implemented.');
  }
  removeRegistrationToken(registrationToken: RegistrationToken): Observable<void> {
    throw new Error('Method not implemented.');
  }
}

import { FirmwareVersionUtils, Utils, WallboxUtils } from '@kw-shared/common';
import _ from 'lodash';
import { Observable, map, of, tap } from 'rxjs';
import { RemoteWallbox } from '../../../../models';
import { ApiDataAccessStorageUtils } from '../../../../utils';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort } from '../../types/Sort';
import { WebDataRepository } from '../base/WebDataRepository';
import { IWallboxRepository } from './IWallboxRepository';
import { RemoteWallboxFirmwareUpdateInfoDTO } from './dto/RemoteWallboxFirmwareUpdateInfoDTO';
import {
  RemoteWallboxSettingsChargepointAvailabilityDTO,
  RemoteWallboxSettingsDTO,
} from './dto/RemoteWallboxSettingsDTO';

export class WallboxRepository extends WebDataRepository<RemoteWallbox> implements IWallboxRepository {
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath?: string
  ) {
    super(httpClient, Utils.joinPaths(basePath, 'chargepoints'));
  }
  getOverview(
    filter?: Filter[],
    sort?: Sort<RemoteWallbox>[],
    pagination?: Pagination
  ): Observable<DataWithCount<RemoteWallbox>> {
    return super.getAll(filter, sort, pagination, 'overview').pipe(
      map(dataWithCount => {
        dataWithCount.list.forEach(remoteWallbox => {
          remoteWallbox.state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(
            remoteWallbox.connectionStatus,
            remoteWallbox.state
          );
        });

        return dataWithCount; // Return the modified DataWithCount<RemoteWallbox> object
      })
    );
  }

  searchByNameOrSerial(
    search: string,
    sort?: Sort<RemoteWallbox>[],
    pagination?: Pagination
  ): Observable<DataWithCount<RemoteWallbox>> {
    const queryParams: Record<string, string> = {
      search: search,
      sort: sort?.[0]?.toString() ?? '+name',
      page: String(pagination?.pageNumber ?? 0),
      pageSize: String(pagination?.pageSize ?? 30),
    };

    return this.httpClient
      .get<DataWithCount<RemoteWallbox>>({
        url: `${this.resource}/byNameOrSerial`, // /chargepoints/byNameOrSerial
        queryParams,
      })
      .pipe(
        map(dataWithCount => {
          dataWithCount.list.forEach(remoteWallbox => {
            remoteWallbox.state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(
              remoteWallbox.connectionStatus,
              remoteWallbox.state
            );
          });

          return dataWithCount;
        })
      );
  }

  public saveChargepointAvailabilitySettings(
    wallboxId: string,
    availabilities: RemoteWallboxSettingsChargepointAvailabilityDTO[]
  ): Observable<void> {
    // TODO: enable for KEB-2605 again
    // return this.httpClient.put<void>({
    //   url: `${this.basePath}/settings/${wallboxId}/chargepoint-parameters-availability`,
    //   body: availabilities,
    // });
    return of();
  }

  public override getById(id: string): Observable<RemoteWallbox> {
    return this.httpClient.get<RemoteWallbox>({ url: `${this.resource}/${id}` }).pipe(
      map((remoteWallbox: RemoteWallbox) => {
        remoteWallbox.state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(
          remoteWallbox.connectionStatus,
          remoteWallbox.state
        );
        return remoteWallbox;
      }),
      tap(remoteWallbox => {
        ApiDataAccessStorageUtils.setSelectedWallboxName(remoteWallbox?.name ?? remoteWallbox?.serialNumber);
      })
    );
  }

  public override getAll(
    filters?: Filter[],
    sort?: Sort<RemoteWallbox>[],
    pagination?: Pagination,
    path?: string
  ): Observable<DataWithCount<RemoteWallbox>> {
    return super.getAll(filters, sort, pagination, path).pipe(
      map(dataWithCount => {
        dataWithCount.list.forEach(remoteWallbox => {
          remoteWallbox.state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(
            remoteWallbox.connectionStatus,
            remoteWallbox.state
          );
        });

        return dataWithCount; // Return the modified DataWithCount<RemoteWallbox> object
      })
    );
  }

  public getWallboxSettings(wallboxId: string): Observable<RemoteWallboxSettingsDTO> {
    return this.httpClient.get<RemoteWallboxSettingsDTO>({ url: `${this.basePath}/settings/${wallboxId}` });
  }

  public saveWallboxSettings(
    wallboxId: string,
    settings: RemoteWallboxSettingsDTO,
    extendedResponse?: boolean
  ): Observable<RemoteWallboxSettingsDTO> {
    const newSettings = _.cloneDeep(settings);
    newSettings.chargingNetworkChargepoints?.forEach(cp => {
      delete cp.availability;
    });

    let url = `${this.basePath}/settings/${wallboxId}`;
    if (extendedResponse) {
      url += '/response';
    }

    return this.httpClient.put<RemoteWallboxSettingsDTO>({
      url,
      body: newSettings,
      requestContext: extendedResponse ? 'context_wallbox_name' : undefined,
    });
  }

  public saveWallboxSettingsWithResponse(wallboxId: string, settings: Object[]): Observable<Object[]> {
    return this.httpClient.put<Object[]>({
      url: `${this.basePath}/settings/${wallboxId}/response`,
      body: settings,
      requestContext: 'context_wallbox_name',
    });
  }

  public saveName(wallboxId: string, name: string): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/${wallboxId}/name`, body: { name } });
  }

  public restart(wallboxId: string): Observable<void> {
    return this.httpClient.get<void>({ url: `${this.resource}/${wallboxId}/reboot` });
  }

  public getFirmwareUpdateDetails(
    wallboxId: string,
    language?: string
  ): Observable<RemoteWallboxFirmwareUpdateInfoDTO> {
    return this.httpClient.get<RemoteWallboxFirmwareUpdateInfoDTO>({
      url: `${this.resource}/${wallboxId}/updateFirmware/detail`,
      queryParams: {
        language: language ?? '',
      },
    });
  }

  public startPvBoost(wallboxId: string, duration: number | null): Observable<boolean> {
    return this.httpClient.put<boolean>({
      url: `${this.basePath}/pvboost/${wallboxId}/start`,
      body: { duration: duration ?? 0 },
      requestContext: 'context_wallbox_name',
    });
  }

  public stopPvBoost(wallboxId: string): Observable<boolean> {
    return this.httpClient.put<boolean>({
      url: `${this.basePath}/pvboost/${wallboxId}/stop`,
      requestContext: 'context_wallbox_name',
    });
  }

  public archiveWallbox(wallboxId: string, firmwareVersion?: string, model?: string): Observable<void> {
    const endpoint =
      FirmwareVersionUtils.isAtLeastVersion(firmwareVersion, '1.16.0') || model?.includes('P40')
        ? '/unenroll'
        : '/archive';

    return this.httpClient.put<void>({ url: `${this.resource}/${wallboxId}${endpoint}` });
  }

  public changeMaxAvailableCurrent(wallboxId: string, maxAvailableCurrent: number): Observable<void> {
    return this.saveWallboxSettings(
      wallboxId,
      {
        configuration: {
          ['max_available_current']: String(maxAvailableCurrent),
        },
      },
      true
    ).pipe(map(() => void 0));
  }

  public clearPairedDevices(wallboxId: string): Observable<void> {
    return this.httpClient.post<void>({ url: `${this.resource}/${wallboxId}/clearPairedDevices` });
  }
}

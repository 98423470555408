import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChartData, ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphData } from './interfaces/graphData';

@Component({
  selector: 'kw-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphComponent implements OnChanges {
  @Input()
  graphData: GraphData[];

  @Input()
  formatValue?: (value: number) => string;

  @Input()
  stepSize?: number;

  @Output()
  finishedRendering = new EventEmitter<void>();

  public barChartOptions?: ChartOptions;
  public barChartPlugins = [ChartDataLabels];
  public barChartData?: ChartData;

  private finishedRender = false;

  ngOnChanges(changes: SimpleChanges): void {
    const graphData = changes['graphData']?.currentValue;
    if (graphData && graphData.length > 0) {
      this.setChartData(graphData);
    }
  }

  private setChartData(graphData: GraphData[]): void {
    this.barChartData = {
      labels: graphData.map((e: GraphData) => e.key),
      datasets: [
        {
          data: graphData.map((e: GraphData) => e.value),
          backgroundColor: '#52AE32',
          hoverBackgroundColor: '#4DA12E',
          borderColor: '#ffffff',
          borderRadius: 5,
        },
      ],
    };
    this.finishedRender = false;
    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        onComplete: () => {
          if (!this.finishedRender) {
            this.finishedRender = true;
            this.finishedRendering.emit();
          }
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#848D92',
          },
        },
        y: {
          min: 0,
          grace: '3%',
          ticks: {
            stepSize: this.stepSize,
            color: '#848D92',
            callback: value => this.format(value),
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => this.format(tooltipItem.parsed.y),
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          padding: 1,
          clamp: true,
          color: '#848D92',
          formatter: value => this.format(value),
        },
      },
    };
  }

  private format(value: number | string): string {
    if (typeof value !== 'number') {
      return value;
    }
    return this.formatValue ? this.formatValue?.(value) : String(value);
  }
}

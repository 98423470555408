import { ValidatorFn } from '@angular/forms';
import {
  ConfigurationPropertyKey,
  ConfigurationType,
  DiagnosticsKey,
  ExtMeterKey,
  GsmKey,
  LmgmtKey,
  NetworkKey,
  OcppKey,
  RestapiKey,
  RfidKey,
  SystemKey,
  TimesynchKey,
  UsbKey,
} from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { RemoteWallboxState } from 'libs/api-data-access/models/RemoteWallbox';

import { PopupInfo } from '@kw-shared/common';
import { SettingsSubStepsDefinition } from '../../../../../apps/keba-wallbox-app/src/app/pages/settings/definitions/app-wallbox-about-items.definition';
import { Conditions } from '../../../utils/SettingsConditionUtils';
import { ConfirmDialogData } from '../../dialogs/base-confirm-dialog/confirm-dialog-data.interface';

export enum FormInputType {
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
  SECRET_PASSWORD = 'SECRET_PASSWORD',
  NUMBER = 'NUMBER',
  TOGGLE = 'TOGGLE',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT', // TODO: don't forget to use FormArray
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  BUTTON_PRIMARY = 'BUTTON_PRIMARY',
  CERTIFICATES_LIST = 'CERTIFICATES_LIST',
  CUSTOM = 'CUSTOM',
  INSTALLER_SETTINGS = 'INSTALLER_SETTINGS',
  LINK = 'LINK',
}

export const CONDITION_SHOW_IF_VALUE_IS_NOT_EMPTY = 'SHOW_IF_VALUE_IS_NOT_EMPTY';

export type SelectOption = {
  key: string;
  text: string;
  disabled?: boolean;
  accessibilityId?: string;
};

export enum ValidatedFormName {
  BLE_PIN = 'blePin',
  USER_PASSWORD = 'userPassword',
  HOTSPOT_PASSWORD = 'hotspotPassword',
}

export interface BaseSetting {
  title?: string;
  description?: string;
  accessibilityId?: string;
  subSteps?: SettingsSubStepsDefinition[];
  hideConditions?: Conditions;
  preventReadCallConditions?: Conditions;
}

export interface SettingsFormInputData extends BaseSetting {
  key:
    | RfidKey
    | TimesynchKey
    | UsbKey
    | DiagnosticsKey
    | LmgmtKey
    | NetworkKey
    | GsmKey
    | OcppKey
    | ExtMeterKey
    | SystemKey
    | RestapiKey
    | string;
  type: FormInputType;
  placeholder?: string;
  configurationType?: ConfigurationType;
  configurationPropertyKey?: ConfigurationPropertyKey;
  selectOptions?: SelectOption[];
  selectOptionKey?: string;
  defaultValue?: any;
  min?: any;
  max?: any;
  minLength?: number;
  maxLength?: number;
  pattern?: any;
  hasUpperCase?: boolean;
  hasLowerCase?: boolean;
  hasDigit?: boolean;
  hasSpecialChar?: boolean;
  patternText?: string;
  additionalCallType?: AdditionalCall | RestapiKey; // used for reading and writing
  additionalActionCallType?: AdditionalActionCall; // only used on action, f.e. button trigger
  additionalActionCallData?: any[];
  listenOnChanges?: boolean;
  hideConfiguration?: any[];
  hideElement?: boolean;
  format?: string;
  convertToDisplayUnit?: (value: string) => any;
  convertFromDisplayUnit?: (value: any) => string;
  readOnly?: boolean;
  rebootRequired?: boolean;
  translatePrefix?: string;
  validators?: ValidatorFn[];
  detailTranslatePrefix?: string;
  disabledForWallboxStates?: RemoteWallboxState[];
  hasDownloadButton?: boolean;
  downloadType?: DownloadType;
  downloadTitle?: string;
  changeValueConfirmation?: {
    condition?: (oldValue: any, nextValue: any) => boolean;
    data: ConfirmDialogData;
  };
  showDependentOnOtherSetting?: { [key: string]: string | string[] };
  fakeValue?: string;
  hideTitle?: boolean;
  preventDecimal?: boolean;
  errorTranslationMap?: { [key: string]: string };
}

export interface SettingsFormData extends BaseSetting {
  deepLink?: DeepLink;
  elements: SettingsFormInputData[];
  hideSeparator?: boolean;
  hasGrayHeader?: boolean;
  showAsSubPage?: boolean;
  showPageNoteSection?: boolean;
  pageNoteDescription?: string;
  pageNoteDescriptionPopup?: PopupInfo;
}

export interface DeepLink {
  title: string;
  link: string;
  showOnCondition?: { [key: string]: string | string[] };
}

export enum DownloadType {
  DIAGNOSTICS = 'DIAGNOSTICS',
  SECURITY_LOG = 'SECURITY_LOG',
}

export enum AdditionalCall {
  OCPP_CERTIFICATES = 'OCPP_CERTIFICATES',
  DSW_SETTINGS = 'dsw_settings',
  WEBUI_CERTIFICATE = 'WEBUI_CERTIFICATE',
  DISPLAY_TEXT = 'DISPLAY_TEXT',
  CHARGING_NETWORK = 'CHARGING_NETWORK',
  X1_X2_LOCK_SETTINGS = 'X1_X2_LOCK_SETTINGS',
  INSTALLER_LOCK_SETTINGS = 'INSTALLER_LOCK_SETTINGS',
  INSTALLER_SETTINGS_MAX_CURRENT = 'MAX_CURRENT',
  INSTALLER_SETTINGS_MAX_CURRENT_PHASE_USED = 'PHASE_USED',
}

export enum AdditionalActionCall {
  OCPP_CERTIFICATE_SERVER = 'OCPP_CERTIFICATE_SERVER',
  OCPP_CERTIFICATE_SYSTEM_ROOT = 'OCPP_CERTIFICATE_SYSTEM_ROOT',
  TEST_TCP_METER = 'TEST_TCP_METER',
  DATA_EXPORT_LOG = 'DATA_EXPORT_LOG',
  DATA_EXPORT_MEASUREMENT = 'DATA_EXPORT_MEASUREMENT',
  FACTORY_RESET = 'FACTORY_RESET',
  EXPORT_DIAGNOSTICS = 'EXPORT_DIAGNOSTICS',
  EXPORT_SECURITY_LOG = 'EXPORT_SECURITY_LOG',
  WEBUI_CERTIFICATE = 'WEBUI_ACTION_CERTIFICATE',
  REBOOT = 'REBOOT',
  PORTAL_UNENROLL = 'PORTAL_UNENROLL',
  USER_PASSWORD = 'USER_PASSWORD',
  CLEAR_BONDED_DEVICES = 'CLEAR_BONDED_DEVICES',
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'kw-input-switch',
    templateUrl: './input-switch.component.html',
    styleUrls: ['./input-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputSwitchComponent), multi: true }],
    standalone: false
})
export class InputSwitchComponent implements ControlValueAccessor {
  @Input()
  value?: boolean = false;

  @Input()
  name?: string;

  @Input()
  disabled: boolean = false;

  @Input()
  showLabel: boolean = false;

  @Input()
  small: boolean = false;

  @Output()
  valueChange = new EventEmitter<boolean>();

  @HostBinding('class.cursor-pointer')
  get enabled() {
    return !this.disabled;
  }

  private onChange?: any;
  private onTouch?: any;

  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('click')
  public onClick(): void {
    if (!this.disabled) {
      this.value = !this.value;
      this.valueChange.emit(this.value);

      this.onChange?.(this.value);
      this.onTouch?.();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.value = value === true || value === 'true';
    this.cdr.detectChanges();
  }
}

import { registerPlugin } from '@capacitor/core';

import type { KebaWallboxPluginPlugin } from './definitions';

const KebaWallboxPlugin = registerPlugin<KebaWallboxPluginPlugin>(
  'KebaWallboxPlugin',
  {
    web: () => import('./web').then(m => new m.KebaWallboxPluginWeb()),
  },
);

export * from './definitions';
export { KebaWallboxPlugin };

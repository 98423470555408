import { Injectable } from '@angular/core';
import { LocaleDatePipe } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackerService {
  protected constructor(
    private translateService: TranslateService,
    private localeDatePipe: LocaleDatePipe
  ) {}

  public formatLastSeenDate(date: Date | undefined): string {
    if (date) {
      return this.translateService.instant('kwp.wallbox-detail.last-seen', {
        date: this.localeDatePipe.transform(date, 'short'),
      });
    } else {
      return this.translateService.instant('kwp.wallbox-detail.last-seen-a-while-ago');
    }
  }
}

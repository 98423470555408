import { LocalWallboxState } from '../models';

export const localWallboxStartChargingStates = [
  LocalWallboxState.REST_IDLE,
  LocalWallboxState.REST_SUSPENDED,
  LocalWallboxState.UDP_NOT_READY_FOR_CHARGING,
  LocalWallboxState.UDP_TEMPORARILY_INTERRUPTION,
];

export const localWallboxStopChargingStates = [
  LocalWallboxState.REST_CHARGING,
  LocalWallboxState.REST_READY_FOR_CHARGING,
  LocalWallboxState.UDP_CHARGING,
  LocalWallboxState.UDP_READY_FOR_CHARGING,
];

export const localWallboxStartChargingStatesSuspended = [
  LocalWallboxState.REST_IDLE,
  LocalWallboxState.UDP_NOT_READY_FOR_CHARGING,
  LocalWallboxState.UDP_TEMPORARILY_INTERRUPTION,
];

export const localWallboxStopChargingStatesSuspended = [
  LocalWallboxState.REST_CHARGING,
  LocalWallboxState.REST_READY_FOR_CHARGING,
  LocalWallboxState.REST_SUSPENDED,
  LocalWallboxState.UDP_CHARGING,
  LocalWallboxState.UDP_READY_FOR_CHARGING,
];

export const localWallboxPvBoostAvailableChargingStates = [
  LocalWallboxState.REST_CHARGING,
  LocalWallboxState.REST_READY_FOR_CHARGING,
  LocalWallboxState.REST_SUSPENDED,
];

export const localWallboxChangePowerHiddenStates = [LocalWallboxState.UDP_TEMPORARILY_INTERRUPTION];

export const localWallboxChargingSessionHiddenStates = [LocalWallboxState.UDP_TEMPORARILY_INTERRUPTION];

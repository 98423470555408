<div class="flex flex-col items-center pb-10 font-light text-center">
  <span class="w-8 h-8 fill-current" inlineSVG="assets/icons/32/rocket.svg"></span>
  <h2 class="my-2">{{ 'kw.pv.boost.title' | translate }}</h2>
  <p class="text-iron-500">
    {{ 'kw.pv.boost.description' | translate }}
  </p>
</div>

<kw-slider
  class="transition-all"
  [ngClass]="{ 'grayscale opacity-50': untilFullyCharged }"
  [min]="DURATION_VALUES[0]"
  [max]="DURATION_VALUES[DURATION_VALUES.length - 1]"
  [possibleValues]="DURATION_VALUES"
  [disabled]="untilFullyCharged"
  [format]="BaseFormat.DURATION_TO_INTERVAL"
  unit=""
  [defaultValue]="DEFAULT_DURATION"
  (valueChange)="onValueChange($event)"
></kw-slider>

<div class="flex items-center self-center mt-16">
  <kw-input-switch (valueChange)="onUntilFullyChargedChanged($event)"></kw-input-switch>
  <p class="ml-3">{{ 'kw.pv.boost.until-fully-charged' | translate }}</p>
</div>

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@kw-shared/common';
import { IApiDataAccessEnvironment } from './IApiDataAccessEnvironment';
import { RemoteWallboxFirmwareUpdateVersionPipe } from './pipes/remote-wallbox-firmware-update-version.pipe';
import { AccountInterceptor, ApiDataService, FakeApiDataService, WebApiDataService } from './services';
import { OcppResponseInterceptor } from './services/data-access/interceptors/ocpp-response.interceptor';
import { ApiDataAccessStorageUtils } from './utils';

export interface AzureLoginConfig {
  clientId: string;
  authority: string;
  knownAuthorities: string[];
  scope: string;
  loginFailedRoute: string;
}

export function ApiDataServiceFactory(http: HttpClient, env: IApiDataAccessEnvironment & IEnvironment) {
  if (!env.production && ApiDataAccessStorageUtils.getIsFakeDataSourceEnabled()) {
    return new FakeApiDataService();
  }
  return new WebApiDataService(http, env.api.baseURL);
}

const PIPES = [RemoteWallboxFirmwareUpdateVersionPipe];

@NgModule({ declarations: [...PIPES],
    exports: [...PIPES], imports: [CommonModule], providers: [
        ...PIPES,
        { provide: HTTP_INTERCEPTORS, useClass: AccountInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OcppResponseInterceptor, multi: true },
        {
            provide: ApiDataService,
            useFactory: ApiDataServiceFactory,
            deps: [HttpClient, ENVIRONMENT],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class ApiDataAccessModule {}

<div class="flex flex-col items-center mb-4 space-y-4 text-{{ type }}-500 kwa-notification sm:items-end px-3">
  <div accessibility-id="kw-test__kw-common__notification__btn--navigate" class="w-full focus:overflow-hidden bg-{{ type }}-100 rounded-xl shadow-lg pointer-events-auto ring-1 ring-{{
      type
    }}-500">
    <div class="p-4">
      <div class="flex items-center">
        <div class="shrink-0 mr-3">
          <span *ngIf="!options.progressBar" class="w-8 h-8 text-{{ type }}-500 fill-current"
            [inlineSVG]="'assets/icons/32/' + type + '.svg'"></span>
          <mat-spinner *ngIf="options.progressBar" class="kwp-inline-progress-spinner !m-0 kw-progress-spinner-blue"
            diameter="26" strokeWidth="4">
          </mat-spinner>
        </div>
        <div class="w-0 flex-1 pt-0.5">
          <p *ngIf="title" class="font-medium text-md">
            {{ title | translate }}
          </p>
          <p *ngIf="message" class="mt-1 font-light" [innerHTML]="message | translate"></p>
        </div>
        <div *ngIf="options.closeButton" class="flex shrink-0 ml-4">
          <button accessibility-id="kw-test__kw-common__notification__btn--close" (click)="remove()"
            class="inline-flex text-gray-900 transition duration-300 rounded-md opacity-50">
            <span class="sr-only">Close</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
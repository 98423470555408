export class UnitConverter {
  public static milliAmpereToAmpere(value: number): number {
    return value / 1_000;
  }

  public static ampereToMilliAmpere(value: number): number {
    return value * 1_000;
  }

  public static milliWattToKiloWatt(value: number): number {
    return value / 1_000_000;
  }

  public static kiloWattToMilliWatt(value: number): number {
    return value * 1_000_000;
  }

  public static secondsToMinutes(value: number): number {
    return value / 60;
  }

  public static minutesToSeconds(value: number): number {
    return value * 60;
  }

  public static milliWattToWatt(value: number): number {
    return value / 1_000;
  }

  public static wattToMilliWatt(value: number): number {
    return value * 1_000;
  }
}

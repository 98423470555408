import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { PortalRoutes } from '../../portal-routes.definition';
import { PortalApiAuthService } from './portal-api-auth.service';

@Injectable({ providedIn: 'root' })
export class LoginStateGuard implements CanActivate {
  constructor(private readonly router: Router, private portalApiAuthService: PortalApiAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateAuth();
  }

  public canActivateAuth(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.portalApiAuthService.doSilentLoginIfPossible().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return this.router.createUrlTree(['/' + PortalRoutes.MAIN]); // Redirect to main
        } else {
          return true; // Stay on the login page
        }
      })
    );
  }
}

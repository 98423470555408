import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiAuthRoute } from '@kw-shared/api-data-access';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/main/pages/login/login.component';
import { PortalRoutes } from './portal-routes.definition';
import { LoginStateGuard } from './services/auth/login-state.guard';
import { PortalAuthGuard } from './services/auth/portal-auth.guard';

const routes: ApiAuthRoute[] = [
  {
    path: '',
    component: AppComponent,
    children: [
      //{ path: '', pathMatch: 'full', redirectTo: PortalRoutes.MAIN },
      {
        path: '',
        pathMatch: 'full',
        canActivate: [LoginStateGuard],
        component: LoginComponent,
      },
      {
        path: PortalRoutes.MAIN,
        loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule),
        canActivate: [PortalAuthGuard],
        data: {
          securityRule: { allowWithoutAccount: true },
        },
      },
      {
        path: PortalRoutes.INVITE,
        loadChildren: () => import('./pages/user-invite/user-invite.module').then(m => m.UserInviteModule),
      },
      {
        path: PortalRoutes.UNSUBSCRIBE,
        loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
      },
      {
        path: 'design-system',
        loadChildren: () => import('@kw-shared/design-system').then(m => m.DesignSystemModule),
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

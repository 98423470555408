import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'kwp-delete-account-info-dialog',
    templateUrl: './delete-account-info-dialog.component.html',
    styleUrls: ['./delete-account-info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DeleteAccountInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected readonly matDialog: MatDialogRef<DeleteAccountInfoDialogComponent>,
  ) {}

  public close(): void {
    this.matDialog.close();
  }
}

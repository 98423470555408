import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MoreMenu } from './interfaces/more-menu.interface';

@Component({
  selector: 'kw-more-menu',
  templateUrl: './more-menu.component.html',
  styleUrls: ['./more-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreMenuComponent {
  @Input()
  moreMenus: MoreMenu[];

  @Input()
  type: 'horizontal' | 'vertical' = 'vertical';

  @Output()
  clicked = new EventEmitter<MoreMenu>();
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OptionsDialogElement } from './interfaces/options-dialog-element.interface';

@Component({
    selector: 'kw-options-dialog',
    templateUrl: './options-dialog.component.html',
    styleUrls: ['./options-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OptionsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data?: OptionsDialogElement[];
      centeredHeadline?: boolean;
    },
    private mdDialogRef: MatDialogRef<OptionsDialogComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mdDialogRef.addPanelClass('full-screen');
  }

  public cancel() {
    this.mdDialogRef.close(false);
  }

  public onClickAction(path: string): void {
    this.router.navigate([path]);
    this.mdDialogRef.close(false);
  }
}

import { ConfigurationType, LmgmtKey, PVConnectorSource } from '@keba-wallbox-plugin/plugin';
import { FormInputType, SettingsFormData } from '../../components';
import { WallboxProductCodeRegex } from '../../utils/WallboxRegexes';

export const phaseSwitchFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: LmgmtKey.CONNECTOR_PHASE_ENABLED,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.TOGGLE,
        description: 'kwa.settings.phase-switch.enable.description',
        title: 'kwa.settings.phase-switch.enable.title',
        listenOnChanges: true,
        changeValueConfirmation: {
          condition: (oldValue: any, nextValue: any) => nextValue === true || nextValue === String(true),
          data: {
            title: 'kwa.settings.phase-switch.enable.change-alert-dialog.title',
            description: 'kwa.settings.phase-switch.enable.change-alert-dialog.description',
            confirmText: 'kwa.settings.phase-switch.enable.change-alert-dialog.confirm',
            showConfirm: true,
            showCancel: true,
          },
        },
        accessibilityId: 'connector-phase-enable',
      },
      {
        key: LmgmtKey.CONNECTOR_PHASE_SOURCE,
        configurationType: ConfigurationType.LMGMT,
        type: FormInputType.SELECT,
        description: 'kwa.settings.phase-switch.connector-phase-source.description',
        title: 'kwa.settings.phase-switch.connector-phase-source.title',
        hideElement: true,
        hideConditions: { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
        translatePrefix: 'kwa.settings.phase-switch.connector-phase-source.options.',
        selectOptions: [
          {
            key: PVConnectorSource.NONE,
            text: 'none',
            accessibilityId: PVConnectorSource.NONE,
          },
          {
            key: PVConnectorSource.CPM_DIR_CTRL,
            text: 'cpm-direct-control',
            accessibilityId: PVConnectorSource.CPM_DIR_CTRL,
          },
          {
            key: PVConnectorSource.MODBUS,
            text: 'modbus',
            accessibilityId: PVConnectorSource.MODBUS,
          },
          {
            key: PVConnectorSource.CPM_PROFILES,
            text: 'cpm-profiles',
            accessibilityId: PVConnectorSource.CPM_PROFILES,
          },
          {
            key: PVConnectorSource.UDP,
            text: 'udp',
            accessibilityId: PVConnectorSource.UDP,
          },
        ],
        showDependentOnOtherSetting: { [LmgmtKey.CONNECTOR_PHASE_ENABLED]: 'true' },
        accessibilityId: 'connector-phase-source',
      },
    ],
  },
];

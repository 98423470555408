import { Validators } from '@angular/forms';
import {
  BleKey,
  ConfigurationType,
  DiagnosticsKey,
  LmgmtKey,
  NetworkKey,
  RestapiKey,
  RfidKey,
  SystemKey,
  UsbKey,
} from '@keba-wallbox-plugin/plugin';

import { FirmwareVersions } from 'libs/common/utils';
import { FormInputType, SettingsFormData } from '../../components';
import { SettingsValidators } from '../../utils/SettingsValidators';
import { WallboxNameRegex, WallboxProductCodeRegex } from '../../utils/WallboxRegexes';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

export const deviceFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: RfidKey.ENABLE_RFID_AUTHORIZATION,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_device_authorization_description',
        title: 'kwa_lbl_settings_device_authorization_title',
        configurationType: ConfigurationType.RFID,
        listenOnChanges: true,
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
              ],
            },
          ],
        },
        accessibilityId: 'device-authorization',
      },
      {
        key: RfidKey.AUTHORIZATION_MODE_ONLINE,
        type: FormInputType.SELECT,
        description: 'kwa_lbl_settings_device_authorization_online_description',
        title: 'kwa_lbl_settings_device_authorization_online_title',
        configurationType: ConfigurationType.RFID,
        hideElement: true,
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
              ],
            },
          ],
        },
        accessibilityId: 'authorization-online',
        showDependentOnOtherSetting: {
          [RfidKey.ENABLE_RFID_AUTHORIZATION]: 'true',
        },
        selectOptions: [
          {
            key: 'FirstLocal',
            text: 'FirstLocal',
            accessibilityId: 'select-first-local',
          },
          {
            key: 'FirstOnline',
            text: 'FirstOnline',
            accessibilityId: 'select-first-online',
          },
          {
            key: 'OnlyLocal',
            text: 'OnlyLocal',
            accessibilityId: 'select-only-local',
          },
        ],
      },
      {
        key: RfidKey.AUTHORIZATION_MODE_OFFLINE,
        type: FormInputType.SELECT,
        description: 'kwa_lbl_settings_device_authorization_offline_description',
        title: 'kwa_lbl_settings_device_authorization_offline_title',
        configurationType: ConfigurationType.RFID,
        hideElement: true,
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
              ],
            },
          ],
        },
        accessibilityId: 'authorization-offline',
        showDependentOnOtherSetting: {
          [RfidKey.ENABLE_RFID_AUTHORIZATION]: 'true',
        },
        selectOptions: [
          {
            key: 'OfflineLocalUnknownAuthorization',
            text: 'OfflineLocalUnknownAuthorization',
            accessibilityId: 'select-unknown-authorization',
          },
          {
            key: 'OfflineLocalAuthorization',
            text: 'OfflineLocalAuthorization',
            accessibilityId: 'select-local-authorization',
          },
          {
            key: 'OfflineNoAuthorization',
            text: 'OfflineNoAuthorization',
            accessibilityId: 'select-no-authorization',
          },
          {
            key: 'OfflineNoCharging',
            text: 'OfflineNoCharging',
            accessibilityId: 'select-no-charging',
          },
          {
            key: 'OfflineFreeCharging',
            text: 'OfflineFreeCharging',
            accessibilityId: 'select-free-charging',
          },
        ],
      },
      {
        key: UsbKey.ENABLE_USB_INIT,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_device_authorization_usb_init_description',
        title: 'kwa_lbl_settings_device_authorization_usb_init_title',
        configurationType: ConfigurationType.USB,
        hideConditions: {
          operator: 'or',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
          ],
        },
        accessibilityId: 'usb-init',
      },
      {
        key: UsbKey.ENABLE_USB_CONFIG,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_device_authorization_usb_config_description',
        title: 'kwa_lbl_settings_device_authorization_usb_config_title',
        configurationType: ConfigurationType.USB,
        hideConditions: {
          operator: 'or',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
          ],
        },
        accessibilityId: 'usb-config',
      },
      {
        key: UsbKey.ENABLE_USB_UPDATE,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_device_authorization_usb_update_description',
        title: 'kwa_lbl_settings_device_authorization_usb_update_title',
        configurationType: ConfigurationType.USB,
        hideConditions: {
          operator: 'or',
          conditions: [
            { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
          ],
        },
        accessibilityId: 'usb-update',
      },
      {
        key: DiagnosticsKey.MAX_DAYS_OF_LOGS,
        type: FormInputType.NUMBER,
        description: 'kwa_lbl_settings_device_authorization_gdpr_data_description',
        title: 'kwa_lbl_settings_device_authorization_gdpr_data_title',
        configurationType: ConfigurationType.DIAGNOSTICS,
        accessibilityId: 'gdpr-days-logs',
        max: 32767,
        min: 0,
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
              ],
            },
          ],
        },
        validators: [Validators.min(0), Validators.max(32767), Validators.required],
      },
      {
        key: LmgmtKey.NOMINAL_VOLTAGE,
        type: FormInputType.SELECT,
        description: 'kwa_lbl_settings_device_authorization_nominal_voltage_description',
        title: 'kwa_lbl_settings_device_authorization_nominal_voltage_title',
        configurationType: ConfigurationType.LMGMT,
        accessibilityId: 'nominal-voltage', // TODO readonly via REST on P40
        selectOptions: [
          { key: '100', text: '100', accessibilityId: 'select-nominal-voltage-100' },
          { key: '110', text: '110', accessibilityId: 'select-nominal-voltage-110' },
          { key: '115', text: '115', accessibilityId: 'select-nominal-voltage-115' },
          { key: '120', text: '120', accessibilityId: 'select-nominal-voltage-120' },
          { key: '127', text: '127', accessibilityId: 'select-nominal-voltage-127' },
          { key: '220', text: '220', accessibilityId: 'select-nominal-voltage-220' },
          { key: '230', text: '230', accessibilityId: 'select-nominal-voltage-230' },
          { key: '240', text: '240', accessibilityId: 'select-nominal-voltage-240' },
        ],
        hideConditions: {
          operator: 'and',
          conditions: [{ condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 }],
        },
      },
      {
        key: SystemKey.SESSION_RESUM_AFTER_POWER_CUT,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_device_authorization_resume_after_power_cut_description',
        title: 'kwa_lbl_settings_device_authorization_resume_after_power_cut_title',
        configurationType: ConfigurationType.SYSTEM,
        configurationPropertyKey: SystemKey.SESSION_RESUM_AFTER_POWER_CUT,
        accessibilityId: 'resume-after-power-cut',
        hideConditions: {
          operator: 'or',
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'firmwareVersionsBelow', value: FirmwareVersions.P40_1_0_5 },
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
              ],
            },
          ],
        },
      },
      {
        key: RestapiKey.ALIAS,
        type: FormInputType.TEXT,
        configurationType: ConfigurationType.RESTAPI,
        configurationPropertyKey: RestapiKey.ALIAS,
        maxLength: 40,
        minLength: 1,
        pattern: WallboxNameRegex.VALID_NAME,
        patternText: 'kwa.settings.wallbox-name.requirement.letter-followed',
        validators: [
          Validators.required,
          Validators.pattern(WallboxNameRegex.VALID_NAME),
          SettingsValidators.apiAliasValidator,
        ],
        description: 'kwa_lbl_settings_device_alias_description',
        title: 'kwa_lbl_settings_device_alias_title',
        accessibilityId: 'device-name-alias',
        preventReadCallConditions: {
          operator: 'and',
          conditions: [{ condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE }],
        },
        hideConditions: {
          operator: 'or',
          conditions: [
            { condition: 'productCode', value: WallboxProductCodeRegex.IS_P30 },
            { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
          ],
        },
      },
      // This is only used for BLE devices
      {
        key: BleKey.BLE_DEVICE_NAME,
        type: FormInputType.TEXT,
        configurationType: ConfigurationType.BLE,
        configurationPropertyKey: BleKey.BLE_DEVICE_NAME,
        maxLength: 40,
        minLength: 1,
        pattern: WallboxNameRegex.VALID_NAME,
        patternText: 'kwa.settings.wallbox-name.requirement.letter-followed',
        validators: [
          Validators.required,
          Validators.pattern(WallboxNameRegex.VALID_NAME),
          SettingsValidators.apiAliasValidator,
        ],
        description: 'kwa_lbl_settings_device_alias_description',
        title: 'kwa_lbl_settings_device_alias_title',
        accessibilityId: 'ble-device-name',
        hideConditions: {
          operator: 'or',
          negate: true,
          conditions: [{ condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE }],
        },
      },
      {
        key: 'api_alias',
        type: FormInputType.TEXT,
        maxLength: 40,
        minLength: 1,
        pattern: WallboxNameRegex.VALID_NAME,
        patternText: 'kwa.settings.wallbox-name.requirement.letter-followed',
        validators: [SettingsValidators.apiAliasValidator],
        description: 'kwa_lbl_settings_device_alias_description',
        title: 'kwa_lbl_settings_device_alias_title',
        additionalCallType: RestapiKey.ALIAS,
        accessibilityId: 'api-alias',
        hideConditions: {
          operator: 'or',
          conditions: [{ condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 }],
        },
      },
      {
        key: NetworkKey.ENABLE_SSH_SERVER,
        type: FormInputType.TOGGLE,
        description: 'kwa_lbl_settings_device_ssh_enable_description',
        title: 'kwa_lbl_settings_device_ssh_enable_title',
        configurationType: ConfigurationType.NETWORK,
        configurationPropertyKey: NetworkKey.ENABLE_SSH_SERVER,
        hideConditions: {
          operator: 'or',
          negate: true,
          conditions: [
            {
              operator: 'and',
              conditions: [
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_REST },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
              ],
            },
            {
              operator: 'and',
              conditions: [
                { condition: 'connectionType', value: WallboxConnectionType.REMOTE },
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
              ],
            },
            {
              operator: 'and',
              negate: true,
              conditions: [
                { condition: 'productCode', value: WallboxProductCodeRegex.IS_P40 },
                { condition: 'connectionType', value: WallboxConnectionType.LOCAL_BLE },
              ],
            },
          ],
        },
        accessibilityId: 'ssh-enable',
      },
    ],
  },
];

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogMenu, DialogMenuElement } from './interfaces/menu-dialog.interface';

@Component({
  selector: 'kw-menu-dialog',
  templateUrl: './menu-dialog.component.html',
  styleUrls: ['./menu-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDialogComponent implements OnInit {
  public menu: DialogMenu;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { menu: DialogMenu },
    private mdDialogRef: MatDialogRef<MenuDialogComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menu = this.data.menu;
    this.mdDialogRef.addPanelClass('full-screen');
  }

  public close() {
    this.mdDialogRef.close(true);
  }

  public onClick(menuElement: DialogMenuElement): void {
    if (menuElement.url) {
      this.close();
      // navigate if url exits
      this.router.navigate([menuElement.url], { fragment: menuElement.fragment });
    } else {
      this.mdDialogRef.close(menuElement);
    }
  }
}

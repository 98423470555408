import {
  RemoteWallboxConnectionStatus,
  RemoteWallboxSettingsConfiguration,
  RemoteWallboxState,
} from '@kw-shared/api-data-access';
import { LocalWallboxState } from './LocalWallboxState';

export type WallboxState = LocalWallboxState | RemoteWallboxState;

export interface WallboxData {
  alias?: string;
  deviceName?: string;
  model?: string;
  serialNumber?: string;
  number?: number;
  state?: WallboxState;
  totalActivePower?: number | null; // mW
  lines?: {
    current: number; // mA
    socketPhase?: string; //L1 | L2 | L3
    voltage: number; // V
  }[];
  currentOffered?: number | null; // mA
  totalPowerFactor?: number | null; // %, e.g. 1000 = 100%
  maxCurrent?: number; // mA
  dipSwitchSettings?: boolean[];
  maxPhases?: MaxPhases; // 1, 3
  phaseUsed?: PhaseUsed; //L1, L2, L3 | L1_L2_L3 (all possible combinations)
  lifetimeChargedEnergy?: number; // mWh
  timeQuality?: number;
  failsafeCurrent?: number; // A

  configuration?: RemoteWallboxSettingsConfiguration; // only available for remote wallboxes!
  lastSeenConnected?: Date;
  connectionStatus?: RemoteWallboxConnectionStatus;
}

export enum MaxPhases {
  ONE = 1,
  THREE = 3,
}

export enum PhaseUsed {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  L1_L2_L3 = 'L1_L2_L3',
  L2_L3_L1 = 'L2_L3_L1', // this one is bugged in the REST API atm
  L3_L1_L2 = 'L3_L1_L2',
  L1_L3_L2 = 'L1_L3_L2',
  L2_L1_L3 = 'L2_L1_L3',
  L3_L2_L1 = 'L3_L2_L1',
}

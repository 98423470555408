export interface IAnalyticsTracker {
  init(): void;

  trackScreen(name: string): void;

  trackEvent(event: string, params?: object): void;

  trackException(exception: Error, severityLevel?: number): void;

  disableAnalytics(): void;
}

import { Injectable } from '@angular/core';
import { first } from 'lodash';
import { KWLogger } from '../../utils';
import { IAnalyticsTracker } from './interfaces/IAnalyticsTracker';
import { cookiePrefix } from './interfaces/cookie-prefix.interface';

@Injectable({
  providedIn: 'root',
})
export abstract class AnalyticsService implements IAnalyticsTracker {
  private trackers?: IAnalyticsTracker[];
  private userId?: string;
  protected initialized = false;

  public init(trackers?: IAnalyticsTracker[]): void {
    this.trackers = trackers;
    this.tryInitAll();
  }

  public trackEvent(event: string, params: object = {}): void {
    if (this.initialized) {
      let paramsWithUserId = this.addUserIdIfPossible(params);
      this.execTrackers(t => t.trackEvent(event, paramsWithUserId));
      KWLogger.log(`tracking analytics event ${event}: %o`, paramsWithUserId);
    }
  }

  public trackScreen(name: string): void {
    if (this.initialized) {
      this.execTrackers(t => t.trackScreen(name));
      KWLogger.log('tracking screen view: ' + name);
    }
  }

  public trackException(exception: Error, severityLevel?: number | undefined): void {
    if (this.initialized) {
      this.execTrackers(t => t.trackException(exception, severityLevel));
      KWLogger.error('tracking exception: ' + exception);
    }
  }

  protected getTrackers(): IAnalyticsTracker[] {
    return this.trackers ?? [];
  }

  private tryInitAll(): void {
    if (!this.hasTrackers() || this.initialized) {
      return;
    }
    try {
      this.execTrackers(t => t.init());
    } finally {
      this.initialized = true;
    }
  }

  private hasTrackers() {
    return this.getTrackers() && this.getTrackers().length > 0;
  }

  protected execTrackers(action: (tracker: IAnalyticsTracker) => void) {
    if (this.hasTrackers()) {
      for (const tracker of this.getTrackers()) {
        action(tracker);
      }
    }
  }

  public disableAnalytics(): void {
    this.execTrackers(t => t.disableAnalytics());
    this.removeAnalyticsCookies();
  }

  public setUserId(userId: string): void {
    this.userId = userId;
  }

  private removeAnalyticsCookies(): void {
    const cookiePrefixes = [
      cookiePrefix.GOOGLE_ANALYTICS_GA,
      cookiePrefix.GOOGLE_ANALYTICS_GAT,
      cookiePrefix.GOOGLE_ANALYTICS_GID,
    ];

    const cookies = document.cookie.split('; ');

    cookies.forEach(cookie => {
      const cookieName = first(cookie.split('='));

      cookiePrefixes.forEach(prefix => {
        if (cookieName !== undefined && cookieName.startsWith(prefix)) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      });
    });
  }

  private addUserIdIfPossible(params: object): object {
    if (this.userId && !params.hasOwnProperty('user_id')) {
      return { ...params, user_id: this.userId };
    }
    return params;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RemoteUpdateStateDefinition } from '@kw-shared/api-data-access';

@Component({
  selector: 'kw-update-state',
  templateUrl: './update-state.component.html',
  styleUrls: ['./update-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateStateComponent {
  @Input()
  update: RemoteUpdateStateDefinition;
}

import { Injectable } from '@angular/core';
import { Utils } from '@kw-shared/common';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { PortalRouteParams } from '../../../../apps/keba-wallbox-portal/src/app/portal-routes.definition';
import { Account } from '../../models';
import { ApiDataAccessStorageUtils } from '../../utils/ApiDataAccessStorageUtils';
import { ApiAuthService } from '../auth/api-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private accounts$ = new BehaviorSubject<Account[] | undefined>(undefined);
  private selectedAccount$ = new BehaviorSubject<Account | undefined>(undefined);

  constructor(private readonly authService: ApiAuthService) {
    authService.getUser$().subscribe(user => {
      if (user?.accounts?.length) {
        user.accounts = this.sortAccounts(user.accounts);
        this.accounts$.next(user.accounts);

        let selectedAccount: Account | undefined;
        const suggestedAccountId = new URLSearchParams(location.search).get(PortalRouteParams.SUGGESTED_ACCOUNT_ID);
        if (suggestedAccountId) {
          selectedAccount = user.accounts.find(a => a.id === suggestedAccountId);
        }

        const prevSelectedAccountId = ApiDataAccessStorageUtils.getSelectedAccountId();
        if (!selectedAccount && prevSelectedAccountId) {
          selectedAccount = user.accounts.find(a => a.id === prevSelectedAccountId);
        }

        if (selectedAccount) {
          if (!_.isEqual(selectedAccount, this.selectedAccount$.value)) {
            this.setSelectedAccount(selectedAccount);
          }
        } else {
          this.setSelectedAccount(Utils.first(user.accounts));
        }
      } else {
        this.accounts$.next([]);
        this.selectedAccount$.next(undefined);
      }
    });
  }

  public getAccounts$(): Observable<Account[] | undefined> {
    return this.accounts$.asObservable();
  }

  public getSelectedAccount$(): Observable<Account | undefined> {
    return this.selectedAccount$.asObservable();
  }

  public getSelectedAccount(): Account | undefined {
    return this.selectedAccount$.value;
  }

  public setSelectedAccount(account?: Account): void {
    this.selectedAccount$.next(account);
    ApiDataAccessStorageUtils.setSelectedAccountId(account?.id);
  }

  private sortAccounts(accounts: Account[]): Account[] {
    return accounts.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
  }
}

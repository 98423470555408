<div
  (click)="onCancelClicked(undefined)"
  accessibility-id="kw-test__kwcommon__confirm-dialog__btn--open"
  class="self-end w-8 h-8 cursor-pointer fill-current"
  inlineSVG="assets/icons/32/close.svg"
></div>

<div
  *ngIf="iconName; let icon"
  [inlineSVG]="'assets/icons/32/' + icon + '.svg'"
  [ngClass]="iconClass ?? ''"
  class="w-8 h-8 mb-3 -mt-4 fill-current"
></div>

<h2 [ngClass]="titleClass" class="mb-3 text-center">{{ headline | translate }}</h2>

<div [ngClass]="descriptionClass ?? ''" class="font-light text-center text-iron-500">
  <div *ngIf="isMarkdownDescription; else defaultDescription" [innerHTML]="marked(description || '' | translate)"></div>
  <div class="mt-4" *ngIf="additionalDescription">{{ additionalDescription | translate }}</div>
  <ng-template #defaultDescription>
    <p [innerHTML]="description || '' | translate"></p>
  </ng-template>
  <ng-content></ng-content>
</div>

<mat-spinner *ngIf="isLoading" [diameter]="24" class="my-6"></mat-spinner>

<div *ngIf="!showCancel && !showConfirm" class="mb-6"></div>

<footer *ngIf="!isLoading && (showCancel || showConfirm)" class="flex gap-2 mt-7 flex-wrap justify-center">
  <button
    (click)="onCancelClicked(false)"
    *ngIf="showCancel"
    class="whitespace-nowrap flex items-center"
    [ngClass]="cancelButtonClass ?? ''"
    accessibility-id="kw-test__kwcommon__confirm-dialog__btn--cancel"
  >
    {{ cancelButtonText ?? 'kwa_btn_no' | translate }}
  </button>
  <button
    (click)="onConfirmClicked()"
    *ngIf="showConfirm"
    class="whitespace-nowrap flex items-center"
    [ngClass]="confirmButtonClass ?? ''"
    accessibility-id="kw-test__kwcommon__confirm-dialog__btn--confirm"
  >
    {{ confirmButtonText ?? 'kwa_btn_yes' | translate }}
  </button>
</footer>

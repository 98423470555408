import { DescriptionDialogElement } from '../..';

export enum DescriptionDialogKey {
  CHARGING_TIME = 'CHARGING_TIME',
  CHARGED_ENERGY = 'CHARGED_ENERGY',
  CHARGING_POWER = 'CHARGING_POWER',
  VOLTAGE_LINES = 'VOLTAGE_LINES',
  AMPERAGE = 'AMPERAGE',
  AMPERAGE_LINES = 'AMPERAGE_LINES',
  MAXIMUM_AMPERAGE = 'MAXIMUM_AMPERAGE',
  POWER_FACTOR = 'POWER_FACTOR',
}

export const chargingSessionsDescriptionDefinition: DescriptionDialogElement[] = [
  {
    key: DescriptionDialogKey.CHARGING_TIME,
    title: 'charging-sessions-description.charging-time.title',
    description: 'charging-sessions-description.charging-time.description',
  },
  {
    key: DescriptionDialogKey.CHARGED_ENERGY,
    title: 'charging-sessions-description.charged-energy.title',
    description: 'charging-sessions-description.charged-energy.description',
  },
  {
    key: DescriptionDialogKey.CHARGING_POWER,
    title: 'charging-sessions-description.charging-power.title',
    description: 'charging-sessions-description.charging-power.description',
  },
  {
    key: DescriptionDialogKey.VOLTAGE_LINES,
    title: 'charging-sessions-description.voltage-lines.title',
    description: 'charging-sessions-description.voltage-lines.description',
  },
  {
    key: DescriptionDialogKey.AMPERAGE_LINES,
    title: 'charging-sessions-description.amperage-lines.title',
    description: 'charging-sessions-description.amperage-lines.description',
  },
  {
    key: DescriptionDialogKey.AMPERAGE,
    title: 'charging-sessions-description.amperage.title',
    description: 'charging-sessions-description.amperage.description',
  },
  {
    key: DescriptionDialogKey.MAXIMUM_AMPERAGE,
    title: 'charging-sessions-description.maximum-amperage.title',
    description: 'charging-sessions-description.maximum-amperage.description',
  },
  {
    key: DescriptionDialogKey.POWER_FACTOR,
    title: 'charging-sessions-description.power-factor.title',
    description: 'charging-sessions-description.power-factor.description',
  },
];

import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { RemoteWallboxOcppCertificate } from '../../../../models';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { DataWithCount } from '../../types/DataWithCount';
import { WebDataRepository } from '../base/WebDataRepository';
import { IOcppCertificatesRepository } from './IOcppCertificatesRepository';

export class OcppCertificatesRepository
  extends WebDataRepository<RemoteWallboxOcppCertificate>
  implements IOcppCertificatesRepository
{
  constructor(public override httpClient: AdvancedHttpClient, private basePath?: string) {
    super(httpClient, Utils.joinPaths(basePath, 'certificates'));
  }

  public getCertificatesForWallbox(wallboxId: string): Observable<DataWithCount<RemoteWallboxOcppCertificate>> {
    return this.httpClient.get<DataWithCount<RemoteWallboxOcppCertificate>>({ url: `${this.resource}/${wallboxId}` });
  }
}

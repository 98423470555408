import { Account } from './Account';
import { ApplicationFeature } from './ApplicationFeature';
import { BaseModel } from './BaseModel';
import { FixedUserRoleType } from './FixedUserRoleType';
import { SecurityPermission } from './SecurityPermission';

export interface User extends BaseModel {
  firstname?: string;
  lastname?: string;
  email: string;
  registrationDate: string;
  accounts: Account[];
  fixedRoles?: AccountUserRole[];
  accountPermissions: AccountUserPermission[];
  admin: boolean;
  serviceAdmin?: boolean;

  fullName: string; // calculated on frontend
}

export interface AccountUserRole extends BaseModel {
  accountId: string;
  type: FixedUserRoleType;
  tags?: string[];
  chargepoints?: string[];
}

export interface AccountUserPermission extends BaseModel {
  accountId: string;
  feature: ApplicationFeature;
  permission: SecurityPermission;
}

export type GdprReportStatus = 'IN_PROGRESS' | 'CREATED';

export interface GdprReport extends BaseModel {
  expirationDate?: Date;
  fileSource?: string;
  status?: GdprReportStatus;
}

import { UpdateStatus } from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';

export type WallboxUpdateStep = {
  title: string;
  updateState: UpdateStatus | undefined;
  finished: boolean;
  status?: UpdateStepStatus;
  progress?: number;
  additionalStates?: (UpdateStatus | undefined)[];
};

export interface WallboxUpdateStepStatus {
  status: UpdateStepStatus;
}

export enum UpdateStepStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
}

export interface UpdateProgressStatus {
  status: UpdateStatus;
  progress?: number;
}

import { DiagnosticStorageKey } from '@kw-shared/common';
import { Observable, of } from 'rxjs';
import { RemoteWallboxDiagnostics, WallboxDiagnosticsStatus } from '../../../../models';
import { FakeRepository } from '../base/FakeRepository';
import { IDiagnosticsRepository } from './IDiagnosticsRepository';

export class FakeDiagnosticsRepository
  extends FakeRepository<RemoteWallboxDiagnostics>
  implements IDiagnosticsRepository
{
  constructor() {
    super([
      {
        id: 'diag1',
        lastStatus: WallboxDiagnosticsStatus.Uploaded,
        latestStatusUpdate: new Date(),
        securityLog: false,
      },
    ]);
  }

  // Method for regular diagnostics export simulation
  public exportDiagnostics(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const diagnostic: RemoteWallboxDiagnostics = {
      id: DiagnosticStorageKey.DIAGNOSTIC + Math.random() * 100,
      lastStatus: WallboxDiagnosticsStatus.Uploading,
      latestStatusUpdate: new Date(),
      securityLog: false,
    };
    this.entities.push(diagnostic);

    // Simulate status change to "Uploaded"
    setTimeout(() => {
      diagnostic.latestStatusUpdate = new Date();
      diagnostic.lastStatus = WallboxDiagnosticsStatus.Uploaded;
    }, Math.random() * 10000);

    return of(diagnostic);
  }

  // New method to simulate security logs export
  public exportSecurityLogs(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const securityLog: RemoteWallboxDiagnostics = {
      id: DiagnosticStorageKey.SECURITY_LOG + Math.random() * 100,
      lastStatus: WallboxDiagnosticsStatus.Uploading,
      latestStatusUpdate: new Date(),
      securityLog: true,
    };
    this.entities.push(securityLog);

    // Simulate status change to "Uploaded"
    setTimeout(() => {
      securityLog.latestStatusUpdate = new Date();
      securityLog.lastStatus = WallboxDiagnosticsStatus.Uploaded;
    }, Math.random() * 10000);

    return of(securityLog);
  }

  // Method to get diagnostics download URL
  public getDiagnosticsFileDownloadUrl(diagnosticsId: string): Observable<Blob> {
    const url = `${location.href}/${diagnosticsId}/download`;
    const blob = new Blob([JSON.stringify({ url }, null, 2)], {
      type: 'application/json',
    });
    return this.delayResponse(of(blob));
  }

  public getSecurityLogsFileDownloadUrl(securityLogId: string): Observable<Blob> {
    const url = `${location.href}/${securityLogId}/download`;
    const blob = new Blob([JSON.stringify({ url }, null, 2)], {
      type: 'application/json',
    });
    return this.delayResponse(of(blob));
  }

  // Method to get the latest diagnostics
  public getLatestDiagnostics(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const diagnostic: RemoteWallboxDiagnostics = {
      id: DiagnosticStorageKey.DIAGNOSTIC + Math.random() * 100,
      lastStatus: WallboxDiagnosticsStatus.UploadFailure,
      latestStatusUpdate: new Date(),
      securityLog: false,
    };
    this.entities.push(diagnostic);

    // Simulate status change to "Uploaded"
    setTimeout(() => {
      diagnostic.latestStatusUpdate = new Date();
      diagnostic.lastStatus = WallboxDiagnosticsStatus.Uploaded;
    }, Math.random() * 10000);

    return of(diagnostic);
  }
  // Method to get the latest security logs
  public getLatestSecurityLog(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const diagnostic: RemoteWallboxDiagnostics = {
      id: DiagnosticStorageKey.SECURITY_LOG + Math.random() * 100,
      lastStatus: WallboxDiagnosticsStatus.UploadFailure,
      latestStatusUpdate: new Date(),
      securityLog: true,
    };
    this.entities.push(diagnostic);

    // Simulate status change to "Uploaded"
    setTimeout(() => {
      diagnostic.latestStatusUpdate = new Date();
      diagnostic.lastStatus = WallboxDiagnosticsStatus.Uploaded;
    }, Math.random() * 10000);

    return of(diagnostic);
  }
}

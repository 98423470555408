export interface WallboxPVStatusInfo {
  status: WallboxPVStatus;
  timeLeft?: number;
  endOfBoost?: Date;
  enabled?: boolean;
}

export enum WallboxPVStatus {
  PV_NONE = 'PV_NONE',
  PV_PRE_CHARGING = 'PV_PRE_CHARGING',
  PV_OPTIMIZING = 'PV_OPTIMIZING',
  PV_X1_ACTIVE = 'PV_X1_ACTIVE',
  PV_BOOST = 'PV_BOOST',
}

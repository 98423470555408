import { Observable } from 'rxjs';
import { RemoteWallboxOcppCertificate } from '../../../../models';
import { DataWithCount } from '../../types/DataWithCount';
import { FakeRepository } from '../base/FakeRepository';
import { IOcppCertificatesRepository } from './IOcppCertificatesRepository';

export class FakeOcppCertificatesRepository
  extends FakeRepository<RemoteWallboxOcppCertificate>
  implements IOcppCertificatesRepository
{
  constructor() {
    super([{ id: 'ocpp-certificate-1', serialNumber: '123', certificateUse: 'ABC' }]);
  }

  public getCertificatesForWallbox(wallboxId: string): Observable<DataWithCount<RemoteWallboxOcppCertificate>> {
    return super.getAll();
  }
}

<div class="flex items-center justify-center w-screen min-h-screen">
  <div class="w-full mx-auto md:w-1/2">
    <div class="flex flex-col items-center justify-center p-3">
      <div class="flex flex-col items-center w-full p-5 px-8 bg-white shadow-2xl rounded-2xl pb-7">
        <div
          accessibility-id="kw-test__kw-common__options-dialog__btn--close"
          class="flex justify-end w-full"
          (click)="cancel()"
        >
          <span class="w-8 h-8 fill-current" inlineSVG="assets/icons/32/close.svg"></span>
        </div>
        <div class="w-full divide-y divide-gray-400 divide-solid">
          <div *ngFor="let element of data.data; let first = first; index as index" class="pb-8 pt-8 px-4">
            <div
              (click)="onClickAction(element.linkPath)"
              class="font-light whitespace-pre-line"
              [attr.accessibility-id]="'kw-test__kw-common__options-dialog__element--' + index"
            >
              <p class="text-xl pb-1">
                {{ element.title | translate }}
              </p>
              <p class="pb-1">{{ element.description | translate }}</p>
            </div>
            <button class="link-primary" (click)="onClickAction(element.linkPath)">
              {{ element.linkText | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { AppType, Utils } from '@kw-shared/common';
import { AzureAIAnalyticsTracker } from '../../../../libs/common/services/analytics/AzureAIAnalyticsTracker';
import packageInfo from '../../../../package.json';
import { PortalMainRoutes } from '../app/pages/main/portal-main-routes.definition';
import { PortalRoutes } from '../app/portal-routes.definition';
import { IPortalEnvironment } from './IPortalEnvironment';

declare var BUILD_TIMESTAMP: number;

export const environment: IPortalEnvironment = {
  production: false,
  version: packageInfo.version,
  buildTimestamp: BUILD_TIMESTAMP,
  appType: AppType.PORTAL,
  api: {
    baseURL: 'https://dev-emobility-portal-backend.keba.com',
    azureLoginConfig: {
      clientId: '9b189845-5d98-4a1e-bdbc-6df96630ee9b',
      authority: 'https://kebaemobilityportaldev.b2clogin.com/kebaemobilityportaldev.onmicrosoft.com/b2c_1_susi',
      knownAuthorities: ['kebaemobilityportaldev.b2clogin.com'],
      scope: 'https://kebaemobilityportaldev.onmicrosoft.com/kecontact-api/api.access',
      loginFailedRoute: '',
    },
    noAccountsRoute: Utils.joinPaths(PortalRoutes.MAIN, PortalMainRoutes.PROFILE, PortalMainRoutes.PROFILE_EDIT),
    defaultAllowRouteWithoutAccount: false,
  },
  pdfGenerationApi: {
    baseUrl: 'https://dev-emobility-portal-backend.keba.com/pdfservice/render',
    authToken: '8Eg666fts8QtrXoN',
    projectName: 'keba-wallbox',
  },
  betaTestEMail: {
    mail: 'support-emobility-portal@keba.com',
  },
  i18n: {
    defaultLanguage: 'en',
    supportedLanguages: ['en', 'de', 'fr'],
  },
  analytics: {
    trackers: [
      new AzureAIAnalyticsTracker({
        appId: 'keba-portal-frontend',
        connectionString:
          'InstrumentationKey=0ecb56f3-67cc-4f98-9956-29e8470ad1d6;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
      }),
    ],
  },
  featureFlags: {
    hideRemoteChargepointParameters: false,
    hideUpcomingFeaturesInfo: false,
    hideX1X2Settings: true,
  },
};

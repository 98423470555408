import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiAuthService, ApiDataService, GdprReport, User } from '@kw-shared/api-data-access';
import { AppType, ENVIRONMENT, FileUtils, IEnvironment } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { last } from 'lodash';
import { ToastContainerDirective } from 'ngx-toastr';
import { BehaviorSubject, Subject, firstValueFrom, takeUntil } from 'rxjs';
import { ToastMessageService } from '../../services';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

export type ProfileInformationAction = 'request' | 'download' | 'delete';

export type CtaButton<T, I> = {
  key: T;
  class: 'kwp-button-primary button-primary small' | 'link-alert';
  icon?: string;
  isVisible: (data?: I | null) => boolean;
  isDisabled: (data?: I | null) => boolean;
  onClick: (data?: I | null) => void;
};

@Component({
  selector: 'kw-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInformationComponent implements OnInit, OnDestroy {
  @Input() set isDownloading(isDownloading: boolean) {
    this.isLoading$.next(isDownloading);
  }

  @Output() downloadReport = new EventEmitter<string>();

  public user?: User;
  public gdprReport$ = new Subject<GdprReport | undefined>();
  public isLoading$ = new BehaviorSubject<boolean>(true);

  public profileInfoActions: CtaButton<ProfileInformationAction, GdprReport>[] = [
    {
      key: 'request',
      class: 'kwp-button-primary button-primary small',
      isVisible: report => !!report && (!report.status || report.status === 'IN_PROGRESS'),
      isDisabled: report => report?.status === 'IN_PROGRESS',
      onClick: () => this.generateReport(),
    },
    {
      key: 'download',
      class: 'kwp-button-primary button-primary small',
      isVisible: report => report?.status === 'CREATED',
      isDisabled: report => !report?.fileSource,
      onClick: report => {
        if (report?.fileSource) this.openFile(report.fileSource);
      },
    },
    {
      key: 'delete',
      class: 'link-alert',
      icon: this.isApp ? 'delete' : undefined,
      isVisible: report => report?.status === 'CREATED',
      isDisabled: report => !report,
      onClick: () => this.deleteReport(),
    },
  ];

  @ViewChild(ToastContainerDirective, { static: false })
  messageContainer: ToastContainerDirective;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly apiAuthService: ApiAuthService,
    private readonly dataService: ApiDataService,
    private readonly dialog: MatDialog,
    private readonly messageService: ToastMessageService,
    private readonly translate: TranslateService,
    @Inject(ENVIRONMENT) private environment: IEnvironment
  ) {}

  ngOnInit() {
    this.listenToReportChanges();
    this.fetchProfileInformationStatus();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public get isApp(): boolean {
    return this.environment.appType === AppType.APP;
  }

  private fetchProfileInformationStatus(): void {
    this.apiAuthService
      .getUser$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async user => {
        if (user) {
          this.user = user;
          const gdprReport = await firstValueFrom(this.dataService.user.getGdprReport(user.id));
          this.gdprReport$.next(gdprReport ?? { id: '' });
        }
      });
  }

  private listenToReportChanges(): void {
    this.gdprReport$?.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      this.isLoading$.next(false);

      if (result?.status === 'IN_PROGRESS') {
        this.messageService.setInfo(
          'kw.user-profile.profile-information.information.request.description',
          'kw.user-profile.profile-information.information.request.title',
          {},
          this.messageContainer
        );
      }
    });
  }

  private async generateReport(): Promise<void> {
    this.isLoading$.next(true);

    if (this.user) {
      const gdprReport = await firstValueFrom(this.dataService.user.generateGdprReport(this.user.id));
      this.gdprReport$?.next(gdprReport);
    }
  }

  private deleteReport(): void {
    const name = this.translate.instant('kw.user-profile.profile-information.cta.delete-description');
    const description = this.translate.instant('kwp.delete.description', { name });

    const data = {
      icon: 'delete',
      title: 'kwp.delete.title',
      description,
      showCancel: true,
      showConfirm: true,
    };
    this.dialog
      .open(ConfirmDialogComponent, {
        data,
      })
      .beforeClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async result => {
        if (result) {
          this.isLoading$.next(true);

          if (this.user) {
            await firstValueFrom(this.dataService.user.deleteGdprReport(this.user.id));
            this.gdprReport$?.next({ id: '' });
          }
        }
      });
  }

  private async openFile(fileSrc: string): Promise<void> {
    if (this.isApp) {
      this.downloadReport.emit(fileSrc);
    } else {
      const fileName = last(fileSrc.split('/'));
      FileUtils.downloadFile(fileSrc, fileName ?? 'report');
    }
  }
}

import { IAccountRepository } from './repositories/accounts/IAccountRepository';
import { IAppVersionControlRepository } from './repositories/appVersionControl/IAppVersionControlRepository';
import { IChargingProfilesRepository } from './repositories/charging-profiles/IChargingProfilesRepository';
import { IChargingReportRepository } from './repositories/charging-reports/IChargingReportRepository';
import { IChargingSessionsRepository } from './repositories/charging-sessions/IChargingSessionsRepository';
import { IDiagnosticsRepository } from './repositories/diagnostics/IDiagnosticsRepository';
import { IEnrollmentRepository } from './repositories/enrollment/IEnrollmentRepository';
import { INotificationsRepository } from './repositories/notification/INotificationRepository';
import { IOcppCertificatesRepository } from './repositories/ocpp-certificates/IOcppCertificatesRepository';
import { IRfidAuthorizationRepository } from './repositories/rfid-authorization/IRfidAuthorizationRepository';
import { IRfidCardRepository } from './repositories/rfid-cards/IRfidCardRepository';
import { ITagRepository } from './repositories/tags/ITagRepository';
import { IUkUpdatesRepository } from './repositories/uk-updates/IUkUpdatesRepository';
import { IUpdatesRepository } from './repositories/updates/IUpdatesRepository';
import { IUserManagementRepository } from './repositories/user-management/IUserManagementRepository';
import { IUserRepository } from './repositories/users/IUserRepository';
import { IWallboxRepository } from './repositories/wallboxes/IWallboxRepository';

export abstract class ApiDataService {
  public user!: IUserRepository;
  public userManagement!: IUserManagementRepository;
  public accounts!: IAccountRepository;
  public enrollment!: IEnrollmentRepository;

  public wallboxes!: IWallboxRepository;
  public chargingProfiles!: IChargingProfilesRepository;
  public tags!: ITagRepository;

  public rfidAuthorization!: IRfidAuthorizationRepository;
  public rfidCards!: IRfidCardRepository;

  public chargingSessions!: IChargingSessionsRepository;
  public diagnostics!: IDiagnosticsRepository;
  public ocppCertificates!: IOcppCertificatesRepository;
  public updates!: IUpdatesRepository;
  public ukUpdates!: IUkUpdatesRepository;
  public notifications!: INotificationsRepository;
  public chargingReports!: IChargingReportRepository;

  // App specific
  public appVersionControl!: IAppVersionControlRepository;
}

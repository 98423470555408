<div class="inline-flex mb-5">
  <div class="w-6 h-6 mr-2 fill-basil-500" inlineSVG="/assets/icons/24/info.svg"></div>
  <div>
    <p class="text-sm font-light text-gray-900">
      {{ note | translate }}
    </p>
    <button
      *ngIf="popupInfo"
      accessibility-id="kw-test__settings__settings-deatil__btn--more-details"
      class="link-primary"
      (click)="openDialog()"
    >
      {{ 'kw_more_details' | translate }}
    </button>
  </div>
</div>

export abstract class FirmwareVersionUtils {
  public static isAtLeastVersion(version?: string | number[], compareVersion?: string | number[]): boolean {
    if (version == null) {
      return false;
    } else if (compareVersion == null) {
      return true;
    }

    const versionParts = this.convertToVersionParts(version);
    const compareVersionParts = this.convertToVersionParts(compareVersion);

    if (versionParts) {
      for (let i = 0; i < compareVersionParts.length; i++) {
        const firmWareVersionPart = i < versionParts.length ? versionParts[i] : 0;
        if (compareVersionParts[i] === firmWareVersionPart) {
          continue;
        }
        return compareVersionParts[i] < firmWareVersionPart;
      }
      return true;
    }
    return false;
  }

  public static parseVersionString(versionString?: string): number[] {
    if (versionString) {
      try {
        return versionString
          .replace(/[^0-9.]/g, '')
          .split('.')
          .map(s => parseInt(s));
      } catch (e) {}
    }
    return [0];
  }

  private static convertToVersionParts(version: string | number[]): number[] {
    if (typeof version === 'string') {
      return FirmwareVersionUtils.parseVersionString(version);
    } else {
      return version;
    }
  }
}

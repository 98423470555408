import { HttpResponse } from '@angular/common/http';
import { Observable, delay, of } from 'rxjs';
import {
  RemoteChargingReport,
  RemoteChargingReportDataType,
  RemoteChargingReportFrequency,
  RemoteChargingReportLanguage,
} from '../../../../models/RemoteChargingReport';
import { FakeRepository } from '../base/FakeRepository';
import { IChargingReportRepository } from './IChargingReportRepository';

export class FakeChargingReportRepository
  extends FakeRepository<RemoteChargingReport>
  implements IChargingReportRepository
{
  constructor() {
    super([
      {
        id: '660e82f38d0aeb168c4883f1',
        accountId: '07e140a0-adea-4aec-bdda-883736801ca9',
        name: 'Test Error',
        description: '',
        language: RemoteChargingReportLanguage.EN,
        dayOfMonthForCustomMonthly: 0,
        type: RemoteChargingReportDataType.PDF,
        frequency: RemoteChargingReportFrequency.MONTHLY,
        emailSubject: 'Testf',
        emailAddresses: ['philipp.hala@netural.com', 'test@ddfasdfasdfasefasdfasdfeae.com'],
        filterRfidCardTokenIds: [],
        filterChargepointSerialNumbers: [],
        filterTagNames: [],
        status: {
          acrPlanId: '660e830c8d0aeb168c4883f2',
          hasErrors: true,
          maxRetryCountExceeded: false,
          retries: 0,
          lastExecution: '2024-04-09T07:35:37.491Z',
          errorData: {
            transactionsFailed: true,
            pdfFailed: true,
            csvFailed: false,
            mailSendingTraces: [],
          },
        },
      },
      {
        id: '660e9958b1583e1821ef2dd0',
        accountId: '07e140a0-adea-4aec-bdda-883736801ca9',
        name: 'Test Error',
        description: '',
        language: RemoteChargingReportLanguage.EN,
        dayOfMonthForCustomMonthly: 0,
        type: RemoteChargingReportDataType.PDF,
        frequency: RemoteChargingReportFrequency.MONTHLY,
        emailSubject: 'Testf',
        emailAddresses: ['philipp.hala@netural.com', 'test@ddfasdfasdfasefasdfasdfeae.com'],
        filterRfidCardTokenIds: [],
        filterChargepointSerialNumbers: [],
        filterTagNames: [],
        status: {
          acrPlanId: '660e9974b1583e1821ef2dd1',
          hasErrors: false,
          maxRetryCountExceeded: false,
          retries: 0,
          lastExecution: '2024-04-09T07:35:37.491Z',
          errorData: {
            transactionsFailed: false,
            pdfFailed: false,
            csvFailed: false,
            mailSendingTraces: [],
          },
        },
      },
      {
        id: '660e9e90b1583e1821ef2dd2',
        accountId: '07e140a0-adea-4aec-bdda-883736801ca9',
        name: 'Test Error',
        description: '',
        language: RemoteChargingReportLanguage.EN,
        dayOfMonthForCustomMonthly: 0,
        type: RemoteChargingReportDataType.PDF,
        frequency: RemoteChargingReportFrequency.MONTHLY,
        emailSubject: 'Testf',
        emailAddresses: ['philipp.hala@netural.com', 'test@ddfasdfasdfasefasdfasdfeae.com'],
        filterRfidCardTokenIds: [],
        filterChargepointSerialNumbers: [],
        filterTagNames: [],
        status: {
          acrPlanId: '660e9ecdb1583e1821ef2dd3',
          hasErrors: false,
          maxRetryCountExceeded: false,
          retries: 0,
          lastExecution: '2024-04-09T07:35:37.491Z',
          errorData: {
            transactionsFailed: false,
            pdfFailed: false,
            csvFailed: false,
            mailSendingTraces: [],
          },
        },
      },
      {
        id: '660ea51cb1583e1821ef2dd4',
        accountId: '07e140a0-adea-4aec-bdda-883736801ca9',
        name: 'Test Error',
        description: '',
        language: RemoteChargingReportLanguage.EN,
        dayOfMonthForCustomMonthly: 0,
        type: RemoteChargingReportDataType.PDF,
        frequency: RemoteChargingReportFrequency.MONTHLY,
        emailSubject: 'Testf',
        emailAddresses: ['philipp.hala@netural.com', 'test@ddfasdfasdfasefasdfasdfeae.com'],
        filterRfidCardTokenIds: [],
        filterChargepointSerialNumbers: [],
        filterTagNames: [],
        status: {
          acrPlanId: '660ea65a97a4067b192bcd2c',
          hasErrors: false,
          maxRetryCountExceeded: false,
          retries: 0,
          lastExecution: '2024-04-09T07:35:37.491Z',
          errorData: {
            transactionsFailed: false,
            pdfFailed: false,
            csvFailed: false,
            mailSendingTraces: [],
          },
        },
      },
      {
        id: '660ebc9142589324b33640cd',
        accountId: '07e140a0-adea-4aec-bdda-883736801ca9',
        name: 'Test Error',
        description: '',
        language: RemoteChargingReportLanguage.EN,
        dayOfMonthForCustomMonthly: 0,
        type: RemoteChargingReportDataType.PDF,
        frequency: RemoteChargingReportFrequency.MONTHLY,
        emailSubject: 'Testf',
        emailAddresses: ['philipp.hala@netural.com', 'test@ddfasdfasdfasefasdfasdfeae.com'],
        filterRfidCardTokenIds: [],
        filterChargepointSerialNumbers: [],
        filterTagNames: [],
        status: {
          acrPlanId: '660ebc9a42589324b33640ce',
          hasErrors: true,
          maxRetryCountExceeded: false,
          retries: 0,
          lastExecution: '2024-04-09T07:35:37.491Z',
          errorData: {
            transactionsFailed: true,
            pdfFailed: true,
            csvFailed: false,
            mailSendingTraces: [],
          },
        },
      },
      {
        id: '660ebcb842589324b33640cf',
        accountId: '07e140a0-adea-4aec-bdda-883736801ca9',
        name: 'Test Error',
        description: '',
        language: RemoteChargingReportLanguage.EN,
        dayOfMonthForCustomMonthly: 0,
        type: RemoteChargingReportDataType.PDF,
        frequency: RemoteChargingReportFrequency.MONTHLY,
        emailSubject: 'Testf',
        emailAddresses: ['philipp.hala@netural.com', 'test@ddfasdfasdfasefasdfasdfeae.com'],
        filterRfidCardTokenIds: [],
        filterChargepointSerialNumbers: [],
        filterTagNames: [],
        status: {
          acrPlanId: '660ebcfc42589324b33640d0',
          hasErrors: false,
          maxRetryCountExceeded: false,
          retries: 0,
          lastExecution: '2024-04-09T07:35:37.491Z',
          errorData: {
            transactionsFailed: false,
            pdfFailed: false,
            csvFailed: false,
            mailSendingTraces: [
              {
                mail: 'philipp.hala@netural.com',
                failed: false,
                trace: '',
              },
              {
                mail: 'test@ddfasdfasdfasefasdfasdfeae.com',
                failed: false,
                trace: '',
              },
            ],
          },
        },
      },
    ]);
  }

  public unsubscribe(token: string): Observable<HttpResponse<any>> {
    const response = new HttpResponse({
      body: { message: 'Unsubscribed successfully' },
      status: 200,
    });
    return of(response).pipe(delay(1000));
  }
}

import { ApiDataService } from './ApiDataService';
import { FakeAccountRepository } from './repositories/accounts/FakeAccountRepository';
import { FakeAppVersionControlRepository } from './repositories/appVersionControl/FakeAppVersionControlRepository';
import { FakeChargingProfileRepository } from './repositories/charging-profiles/FakeChargingProfileRepository';
import { FakeChargingReportRepository } from './repositories/charging-reports/FakeChargingReportRepository';
import { FakeChargingSessionsRepository } from './repositories/charging-sessions/FakeChargingSessionsRepository';
import { FakeDiagnosticsRepository } from './repositories/diagnostics/FakeDiagnosticsRepository';
import { FakeEnrollmentRepository } from './repositories/enrollment/FakeEnrollmentRepository';
import { FakeNotificationRepository } from './repositories/notification/FakeNotificationRepository';
import { FakeOcppCertificatesRepository } from './repositories/ocpp-certificates/FakeOcppCertificatesRepository';
import { FakeRfidAuthorizationRepository } from './repositories/rfid-authorization/FakeRfidAuthorizationRepository';
import { FakeRfidCardRepository } from './repositories/rfid-cards/FakeRfidCardRepository';
import { FakeTagRepository } from './repositories/tags/FakeTagRepository';
import { FakeUkUpdateRepository } from './repositories/uk-updates/FakeUkUpdateRepository';
import { FakeUpdateRepository } from './repositories/updates/FakeUpdateRepository';
import { FakeUserManagementRepository } from './repositories/user-management/FakeUserManagementRepository';
import { FakeUserRepository } from './repositories/users/FakeUserRepository';
import { FakeWallboxRepository } from './repositories/wallboxes/FakeWallboxRepository';

export class FakeApiDataService extends ApiDataService {
  constructor() {
    super();

    this.wallboxes = new FakeWallboxRepository();
    this.tags = new FakeTagRepository();
    this.chargingSessions = new FakeChargingSessionsRepository();
    this.accounts = new FakeAccountRepository();
    this.user = new FakeUserRepository();
    this.userManagement = new FakeUserManagementRepository();
    this.diagnostics = new FakeDiagnosticsRepository();
    this.ocppCertificates = new FakeOcppCertificatesRepository();
    this.updates = new FakeUpdateRepository();
    this.notifications = new FakeNotificationRepository();
    this.enrollment = new FakeEnrollmentRepository();
    this.chargingProfiles = new FakeChargingProfileRepository();
    this.rfidCards = new FakeRfidCardRepository();
    this.chargingReports = new FakeChargingReportRepository();
    this.rfidAuthorization = new FakeRfidAuthorizationRepository();
    this.ukUpdates = new FakeUkUpdateRepository();

    // APP
    this.appVersionControl = new FakeAppVersionControlRepository();
  }
}

import { BaseModel } from './BaseModel';
import { FixedUserRoleType } from './FixedUserRoleType';

export interface InvitedUser extends BaseModel {
  email: string;

  chargePoints?: { id: string }[];
  firstname?: string;
  fixedRole?: FixedUserRoleType;
  invitationState?: UserInvitationState;
  lastname?: string;
  tags?: { id: string }[];
  accessibilityId?: string;
}

export enum UserInvitationState {
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  INVITED_EXISTING = 'INVITED_EXISTING',
  INVITED_NEW = 'INVITED_NEW',
}

export interface InvitedUserResponse extends BaseModel {
  token: string;
  expirationDate: string;
  invitationState: UserInvitationState;
  link: string;
  userAccountId: string;
  acceptedAccountId: string;
}

import { FixedUserRoleType, InvitedUser, SecurityService, UserInvitationState } from '@kw-shared/api-data-access';

export enum UserManagementAction {
  EDIT,
  DELETE,
  INVITE,
}

export abstract class UserManagementUtils {
  public static shouldHideAction(
    type: UserManagementAction,
    invitedUser: InvitedUser,
    securityService: SecurityService
  ): boolean {
    if (!invitedUser || securityService.isCurrentUser(invitedUser.email)) {
      return true;
    }

    switch (type) {
      case UserManagementAction.EDIT:
      case UserManagementAction.DELETE:
        return invitedUser.fixedRole === FixedUserRoleType.OWNER;
      case UserManagementAction.INVITE:
        return invitedUser.invitationState !== UserInvitationState.EXPIRED;
    }
  }

  public static getUserDescription(user?: InvitedUser): string {
    return (user?.firstname && user?.lastname ? user?.firstname + ' ' + user?.lastname : user?.email) ?? '';
  }

  public static getAssignableRoleTypes(): FixedUserRoleType[] {
    return Object.values(FixedUserRoleType).filter(role => role !== FixedUserRoleType.OWNER);
  }
}

import { Observable, of } from 'rxjs';
import { RemoteWallboxDiagnostics, WallboxDiagnosticsStatus } from '../../../../models';
import { FakeRepository } from '../base/FakeRepository';
import { IDiagnosticsRepository } from './IDiagnosticsRepository';

export class FakeDiagnosticsRepository
  extends FakeRepository<RemoteWallboxDiagnostics>
  implements IDiagnosticsRepository
{
  constructor() {
    super([
      {
        id: 'diag-1',
        lastStatus: WallboxDiagnosticsStatus.Uploaded,
        latestStatusUpdate: new Date(),
      },
    ]);
  }

  public getDiagnosticsFileDownloadUrl(diagnosticsId: string): Observable<Blob> {
    const url = `${location.href}/${diagnosticsId}/download`;
    const blob = new Blob([JSON.stringify({ url }, null, 2)], {
      type: 'application/json',
    });
    return this.delayResponse(of(blob));
  }

  public exportDiagnostics(wallboxId: string, securityLogs: boolean): Observable<RemoteWallboxDiagnostics> {
    const diagnostic: RemoteWallboxDiagnostics = {
      id: 'diag-' + Math.random() * 100,
      lastStatus: WallboxDiagnosticsStatus.Uploading,
      latestStatusUpdate: new Date(),
    };
    this.entities.push(diagnostic);

    // Simulate status change
    setTimeout(() => {
      diagnostic.latestStatusUpdate = new Date();
      diagnostic.lastStatus = WallboxDiagnosticsStatus.Uploaded;
    }, Math.random() * 10000);

    return of(diagnostic);
  }

  public getLatestDiagnostics(wallboxId: string): Observable<RemoteWallboxDiagnostics> {
    const diagnostic: RemoteWallboxDiagnostics = {
      id: 'diag-' + Math.random() * 100,
      lastStatus: WallboxDiagnosticsStatus.Uploading,
      latestStatusUpdate: new Date(),
    };
    this.entities.push(diagnostic);

    // Simulate status change
    setTimeout(() => {
      diagnostic.latestStatusUpdate = new Date();
      diagnostic.lastStatus = WallboxDiagnosticsStatus.Uploaded;
    }, Math.random() * 10000);

    return of(diagnostic);
  }
}

export interface WallboxStateDisplayDefinition {
  stateColor: WallboxStateColor;
  title: string;
  icon?: string;
  accessibilityId?: string;
  reverseIconPosition?: boolean;
  enableOverlay?: boolean;
  overlayTitle?: string;
  overlayText?: string;
}

export enum WallboxStateColor {
  GREEN = 'basil',
  ORANGE = 'orange',
  RED = 'red',
  BLACK = 'black',
  BLUE = 'blue',
}

<div
  class="relative top-2 flex items-center justify-center transition-all delay-75 rounded-full w-19 h-19 shadow-red cursor-pointer hover:scale-105"
  [ngClass]="{
    'pointer-events-none': pvStatusInfo?.status === WallboxPVStatus.PV_X1_ACTIVE,
    'bg-gray-900 text-white': pvStatusInfo?.status === WallboxPVStatus.PV_BOOST,
    'bg-white border': pvStatusInfo?.status !== WallboxPVStatus.PV_BOOST
  }"
  (click)="disabled ? undefined : pvBoostClick.emit()"
>
  <span
    *ngIf="pvStatusInfo?.status === WallboxPVStatus.PV_BOOST && pvStatusInfo?.timeLeft"
    class="absolute -top-10 bg-gray-900 text-white px-2 py-1 rounded-full"
    >{{ (pvStatusInfo?.timeLeft ?? 0) * 1000 | format: BaseFormat.DURATION_TO_INTERVAL_SHORT }}</span
  >
  <span
    class="w-8 h-8 fill-current"
    inlineSVG="assets/icons/32/rocket.svg"
    [ngClass]="{ 'opacity-50': pvStatusInfo?.status === WallboxPVStatus.PV_X1_ACTIVE }"
  ></span>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgressState } from '@kw-shared/common';

@Component({
  selector: 'kw-download-progress',
  templateUrl: './download-progress.component.html',
  styleUrls: ['./download-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadProgressComponent {
  public readonly ProgressState = ProgressState;

  @Input()
  percentage?: number;

  @Input()
  progressState: ProgressState;
}

import { ConfigurationType, DisplayTextKey } from '@keba-wallbox-plugin/plugin';
import { AdditionalCall, FormInputType, SettingsFormData } from '../../components';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';

export const displayTextFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.LANGUAGE,
        type: FormInputType.SELECT,
        description: 'kwa.settings.display-text.language.description',
        title: 'kwa.settings.display-text.language.title',
        additionalCallType: AdditionalCall.DISPLAY_TEXT,
        listenOnChanges: true,
        translatePrefix: 'kw.settings.display-text.languages.',
        selectOptions: [
          { key: 'de', text: 'de', accessibilityId: 'select-de' },
          { key: 'en', text: 'en', accessibilityId: 'select-en' },
          { key: 'it', text: 'it', accessibilityId: 'select-it' },
          { key: 'ro', text: 'ro', accessibilityId: 'select-ro' },
        ],
        accessibilityId: 'language',
        changeValueConfirmation: {
          condition: (oldValue: any, nextValue: any) => nextValue !== oldValue,
          data: {
            title: 'kw.settings.display-text.change-language-alert-dialog.title',
            description: 'kw.settings.display-text.change-language-alert-dialog.description',
            confirmText: 'kw.settings.display-text.change-language-alert-dialog.confirm',
            showConfirm: true,
            showCancel: true,
          },
        },
      },
    ],
  },
  {
    title: 'kwa.settings.display-text.card-please.title',
    accessibilityId: 'card-please',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_PLEASE_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.card-please.description',
        title: 'kwa.settings.display-text.card-please.title',
        accessibilityId: 'card-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_PLEASE_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'card-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_PLEASE_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'card-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_PLEASE_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'card-frequency',
      },
    ],
  },
  {
    title: 'kwa.settings.display-text.insert-plug.title',
    accessibilityId: 'insert-plug',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.INSERT_PLUG_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.insert-plug.description',
        title: 'kwa.settings.display-text.insert-plug.title',
        accessibilityId: 'insert-plug-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.INSERT_PLUG_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'insert-plug-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.INSERT_PLUG_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'insert-plug-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.INSERT_PLUG_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'insert-plug-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.verifying-id.title',
    accessibilityId: 'verifying-id',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHECKING_CARD_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.verifying-id.description',
        title: 'kwa.settings.display-text.verifying-id.title',
        accessibilityId: 'check-card-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHECKING_CARD_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'check-card-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHECKING_CARD_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'check-card-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHECKING_CARD_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'check-card-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.expired-id.title',
    accessibilityId: 'expired-id',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_EXPIRED_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.expired-id.description',
        title: 'kwa.settings.display-text.expired-id.title',
        accessibilityId: 'card-exp-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_EXPIRED_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'card-exp-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_EXPIRED_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'card-exp-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_EXPIRED_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'card-exp-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.blocked-id.title',
    accessibilityId: 'blocked-id',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_BLOCKED_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.blocked-id.description',
        title: 'kwa.settings.display-text.blocked-id.title',
        accessibilityId: 'blocked-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_BLOCKED_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'blocked-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_BLOCKED_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'blocked-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_BLOCKED_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'blocked-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.invalid-id.title',
    accessibilityId: '',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_INVALID_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.invalid-id.description',
        title: 'kwa.settings.display-text.invalid-id.title',
        accessibilityId: 'invalid-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_INVALID_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'invalid-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_INVALID_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'invalid-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_INVALID_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'invalid-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.valid-id.title',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_OK_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.valid-id.description',
        title: 'kwa.settings.display-text.valid-id.title',
        accessibilityId: 'ok-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_OK_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'ok-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_OK_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'ok-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CARD_OK_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'ok-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.charging.title',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.charging.description',
        title: 'kwa.settings.display-text.charging.title',
        accessibilityId: 'charging-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'charging-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'charging-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'charging-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.suspended-session.title',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_SUSPENDED_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.suspended-session.description',
        title: 'kwa.settings.display-text.suspended-session.title',
        accessibilityId: 'suspended-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_SUSPENDED_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'suspended-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_SUSPENDED_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'suspended-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_SUSPENDED_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'suspended-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.stopped-session.title',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_STOPPED_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.stopped-session.description',
        title: 'kwa.settings.display-text.stopped-session.title',
        accessibilityId: 'stopped-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_STOPPED_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'stopped-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_STOPPED_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'stopped-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.CHARGING_STOPPED_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'stopped-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.reservation.title',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.RESERVED_ID_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.reservation.description',
        title: 'kwa.settings.display-text.reservation.title',
        accessibilityId: 'id-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.RESERVED_ID_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'id-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.RESERVED_ID_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'id-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.RESERVED_ID_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'id-frequency',
      },
    ],
  },

  {
    title: 'kwa.settings.display-text.wrong-reservation.title',
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.WRONG_RESERVATION_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.wrong-reservation.description',
        title: 'kwa.settings.display-text.wrong-reservation.title',
        accessibilityId: 'reservation-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.WRONG_RESERVATION_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'reservation-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.WRONG_RESERVATION_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'reservation-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.WRONG_RESERVATION_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'reservation-frequency',
      },
    ],
  },
  {
    title: 'kwa.settings.display-text.pv-boost.title',
    accessibilityId: 'pv-boost-id',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'firmwareVersionsBelow', value: '1.15.0' },
        { condition: 'localRestApiVersion', value: '>1' },
      ],
    },
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_BOOST_CHARGING_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.pv-boost.description',
        title: 'kwa.settings.display-text.pv-boost.title',
        accessibilityId: 'pv-boost-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_BOOST_CHARGING_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'pv-boost-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_BOOST_CHARGING_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'pv-boost-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_BOOST_CHARGING_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'pv-boost-frequency',
      },
    ],
  },
  {
    title: 'kwa.settings.display-text.pv-charging.title',
    accessibilityId: 'pv-charging-id',
    hideConditions: {
      operator: 'or',
      conditions: [
        { condition: 'connectionType', value: WallboxConnectionType.LOCAL_UDP },
        { condition: 'firmwareVersionsBelow', value: '1.15.0' },
        { condition: 'localRestApiVersion', value: '>1' },
      ],
    },
    elements: [
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_CHARGING_TEXT,
        type: FormInputType.TEXT,
        description: 'kwa.settings.display-text.pv-charging.description',
        title: 'kwa.settings.display-text.pv-charging.title',
        accessibilityId: 'pv-charging-text',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_CHARGING_MIN_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-least.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-least.title',
        accessibilityId: 'pv-charging-min-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_CHARGING_MAX_TIME,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-at-most.description',
        title: 'kwa.settings.display-text.input.displayed-text-at-most.title',
        accessibilityId: 'pv-charging-max-time',
      },
      {
        configurationType: ConfigurationType.DISPLAY_TEXT,
        key: DisplayTextKey.PV_CHARGING_FREQUENCY,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.display-text.input.displayed-text-repeated.description',
        title: 'kwa.settings.display-text.input.displayed-text-repeated.title',
        accessibilityId: 'pv-charging-frequency',
      },
    ],
  },
];

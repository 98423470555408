import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kw-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input()
  size: number = 40;

  @Input()
  userName: string;

  public getInitials(): string {
    if (this.userName?.trim()) {
      const parts = this.userName.trim().split(' ');
      let letters = parts[0].charAt(0);

      if (parts.length > 1) {
        letters += parts[1].charAt(0);
      }
      return letters;
    }
    return '?';
  }
}

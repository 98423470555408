export enum SortDirection {
  Ascending = '+',
  Descending = '-',
}

export class Sort<Entity> {
  constructor(public fieldName: keyof Entity, public direction: SortDirection = SortDirection.Ascending) {}

  public toString() {
    return this.direction + String(this.fieldName);
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppType, ConfirmDialogData, ENVIRONMENT, IEnvironment } from '@kw-shared/common';

@Component({
    selector: 'kw-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData,
    private mdDialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(ENVIRONMENT) private environment: IEnvironment
  ) {}

  get isApp(): boolean {
    return this.environment.appType === AppType.APP;
  }

  get isPortal(): boolean {
    return !this.isApp;
  }

  ngOnInit(): void {
    if (this.isApp) {
      this.mdDialogRef.addPanelClass('full-screen');
    }
  }

  public cancel(result: false | undefined) {
    this.mdDialogRef.close(result);
  }

  public confirm() {
    if (this.data.confirmOpenUrl) {
      // required to do it here instead of the opener, because iOS blocks site opens when the context isn't a click
      window.open(this.data.confirmOpenUrl, '_blank');
    }
    this.mdDialogRef.close(true);
  }
}

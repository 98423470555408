import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localeDate',
})
export class LocaleDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  public transform(value: Date | string | number, format?: string, locale?: string): string | null {
    // https://keba.atlassian.net/browse/KEB-2479 use european date format for all languages for now
    return this.datePipe.transform(
      value,
      format,
      undefined,
      locale ?? (this.translateService.currentLang?.includes('de') ? 'de' : 'fr')
    );
  }
}

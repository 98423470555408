import { FakeRepository } from '../base/FakeRepository';
import { RemoteTag } from '../../../../models';
import { ITagRepository } from './ITagRepository';

export class FakeTagRepository extends FakeRepository<RemoteTag> implements ITagRepository {
  constructor() {
    super([
      { id: '1', name: 'Home', chargePointsCount: 2 },
      { id: '2', name: 'Work', chargePointsCount: 0 },
      { id: '3', name: 'Private', chargePointsCount: 1 },
    ]);
  }
}

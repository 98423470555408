<div
  *ngIf="data"
  [ngClass]="{
    'min-h-screen': isApp,
    rounded: isPortal
  }"
  class="items-center w-full bg-white kwa-container kw-confirm-by-input-dialog"
>
  <div
    class="flex w-full h-8 mb-7"
    [ngClass]="{
      'pt-5': isApp,
      'pt-4': isPortal
    }"
  >
    <div
      class="ml-auto cursor-pointer"
      (click)="onClose()"
      [ngClass]="{
        'text-iron-500 mr-3': isApp,
        'text-black mr-4': isPortal
      }"
    >
      <span [inlineSVG]="'assets/icons/32/close.svg'" class="fill-current mr-2"></span>
    </div>
  </div>

  <div
    class="flex flex-col items-center w-full"
    [ngClass]="{
      'p-6': isApp,
      'p-16 pt-6': isPortal
    }"
  >
    <div *ngIf="data.icon" class="flex mb-4">
      <span
        class="flex items-center fill-current w-8 h-8"
        [ngClass]="data.iconColor || 'text-gray-900'"
        [inlineSVG]="'assets/icons/32/' + data.icon + '.svg'"
      ></span>
    </div>
    <h2 class="mb-3 text-center">{{ data.title | translate }}</h2>
    <div
      *ngIf="data.description"
      [innerHTML]="data.description | translate"
      class="font-light leading-6 mb-6 text-center"
      [ngClass]="{
        'text-sm': isPortal
      }"
    ></div>
    <div
      class="font-light leading-6 mb-6 text-center"
      [ngClass]="{
        'text-sm': isPortal
      }"
    >
      {{ 'kwa_txt_confirm_input_dialog_description' | translate: { phrase: phrase } }}
    </div>

    <div
      [ngClass]="{
        'w-full bg-white px-3 py-2 rounded border border-gray-900 transition': isPortal,
        'border-error-500': isPortal && inputDirty && !confirmationValid
      }"
    >
      <input
        #confirmationInput
        (blur)="onInputBlur()"
        [(ngModel)]="confirmation"
        [type]="'text'"
        name="confirmation-text"
        [ngClass]="{
          'input-rounded': isApp,
          'input-rounded--error': isApp && inputDirty && !confirmationValid,
          'text-gray-900 bg-transparent outline-none text-sm leading-6 w-full': isPortal
        }"
      />
    </div>
    <div
      class="flex"
      [ngClass]="{
        'flex-col gap-6 mt-16': isApp,
        'flex-row-reverse gap-8 mt-10': isPortal
      }"
    >
      <button
        (click)="onConfirmClick()"
        [ngClass]="{
          'button-primary': isApp,
          'kwp-button-primary ': isPortal
        }"
      >
        {{ data.confirmText ?? 'kwa_btn_confirm' | translate }}
      </button>
      <div class="flex justify-center">
        <button
          (click)="onClose()"
          [ngClass]="{
            'link-secondary': isApp,
            'kwp-button-secondary': isPortal
          }"
        >
          {{ 'kwa_btn_cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

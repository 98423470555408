export enum BaseFormat {
  DATE = 'DATE',
  DURATION_TO_INTERVAL = 'DURATION_TO_INTERVAL',
  DURATION_TO_INTERVAL_SHORT = 'DURATION_TO_INTERVAL_SHORT',
  DURATION_TO_INTERVAL_WITH_DAYS = 'DURATION_TO_INTERVAL_WITH_DAYS',
  DURATION_TO_INTERVAL_SHORT_WITHOUT_SECONDS = 'DURATION_TO_INTERVAL_SHORT_WITHOUT_SECONDS',
  MA_TO_A = 'MA_TO_A',
  MW_TO_KW = 'MW_TO_KW',
  MWH_TO_KWH = 'MWH_TO_KWH',
  PERCENTAGE = 'PERCENTAGE',
  TIME_FROM_NOW = 'TIME_FROM_NOW',
}

export type BaseUnit = {
  [key in BaseFormat]?: string;
};

export const baseUnit: BaseUnit = {
  [BaseFormat.MW_TO_KW]: 'kW',
  [BaseFormat.MA_TO_A]: 'A',
  [BaseFormat.MWH_TO_KWH]: 'kWh',
};

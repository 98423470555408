import { Pipe, PipeTransform } from '@angular/core';
import { BaseFormat } from './interfaces/base-format.interface';

@Pipe({
  name: 'reverse-format',
})
export class ReverseFormatPipe implements PipeTransform {
  transform(value: any, format?: BaseFormat): string | number {
    if (typeof value === 'undefined' || value === null || value === '') {
      return '';
    }

    if (!format) {
      return value as string;
    }

    switch (format) {
      case BaseFormat.MW_TO_KW: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return value * 1000000;
        }
        break;
      }

      case BaseFormat.MA_TO_A: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return value * 1000;
        }
        break;
      }

      case BaseFormat.PERCENTAGE: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return value * 10;
        }
        break;
      }

      case BaseFormat.MWH_TO_KWH: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return value * 1000000;
        }
        break;
      }
    }

    return value as string;
  }
}

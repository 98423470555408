import { DataAggregationPeriod, RemoteChargingSessionRfidCard } from '@kw-shared/api-data-access';
import { PhaseUsed, UnitConverter } from '@kw-shared/common';
import { subDays, subMinutes } from 'date-fns';
import { map, Observable, of } from 'rxjs';
import { RemoteWallboxChargingSession, RemoteWallboxChargingSessionWallboxFilter } from '../../../../models';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort } from '../../types/Sort';
import { FakeRepository } from '../base/FakeRepository';
import { ChargingSessionsSummary, IChargingSessionsRepository } from './IChargingSessionsRepository';

export class FakeChargingSessionsRepository
  extends FakeRepository<RemoteWallboxChargingSession>
  implements IChargingSessionsRepository
{
  constructor() {
    super([
      {
        id: '1',
        transactionId: 444005646,
        start: subMinutes(new Date(), 24).toISOString(),
        energyConsumed: 13651000,
        chargePointId: '1',
        connectorId: 1,
        duration: 8276392,
        chargePointSerial: '123',
        totalPowerFactor: 1000,
        totalActivePower: UnitConverter.kiloWattToMilliWatt(3),
        lines: [
          {
            socketPhase: PhaseUsed.L1,
            current: UnitConverter.ampereToMilliAmpere(1),
            voltage: 10,
          },
          {
            socketPhase: PhaseUsed.L2,
            current: UnitConverter.ampereToMilliAmpere(2),
            voltage: 20,
          },
          {
            socketPhase: PhaseUsed.L3,
            current: UnitConverter.ampereToMilliAmpere(3),
            voltage: 30,
          },
        ],
        currentOffered: UnitConverter.ampereToMilliAmpere(25.5),
      },
      {
        id: '2',
        transactionId: 446854788,
        start: '2023-02-03T07:09:06.219Z',
        end: '2023-02-03T12:12:04.485Z',
        energyConsumed: 26054,
        duration: 18178266,
        connectorId: 1,
        chargePointSerial: '123',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '3',
        transactionId: 1768141920,
        start: '2023-02-02T06:53:27.333Z',
        end: '2023-02-02T15:58:26.074Z',
        energyConsumed: 19027000,
        duration: 32698741,
        connectorId: 1,
        chargePointSerial: '123',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '4',
        transactionId: 400229920,
        start: '2023-02-01T07:02:48.161Z',
        end: '2023-02-02T07:45:34.011Z',
        energyConsumed: 6981,
        connectorId: 1,
        duration: 88965850,
        chargePointSerial: '345',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '5',
        transactionId: 2090202356,
        start: '2023-01-31T07:01:18.205Z',
        end: '2023-01-31T16:26:08.292Z',
        energyConsumed: 5395,
        connectorId: 1,
        duration: 33890087,
        chargePointSerial: '123',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '6',
        transactionId: 270275565,
        start: '2023-01-30T05:33:19.234Z',
        end: '2023-01-30T06:48:01.831Z',
        energyConsumed: 5203,
        connectorId: 1,
        duration: 4482597,
        chargePointSerial: '987',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '7',
        transactionId: 1329034841,
        start: '2023-01-30T05:31:49.485Z',
        end: '2023-01-30T05:32:19.045Z',
        energyConsumed: 20,
        connectorId: 1,
        duration: 29560,
        chargePointSerial: '987',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '8',
        transactionId: 1623701435,
        start: '2023-01-30T04:49:55.143Z',
        end: '2023-01-30T05:31:44.986Z',
        energyConsumed: 1,
        connectorId: 1,
        duration: 2509843,
        chargePointSerial: '987',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '9',
        transactionId: 600006651,
        start: '2023-01-29T20:06:45.138Z',
        end: '2023-01-30T04:49:40.749Z',
        energyConsumed: 0,
        connectorId: 1,
        duration: 31375611,
        chargePointSerial: '987',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
      {
        id: '0',
        transactionId: 900476279,
        start: '2023-01-28T20:19:14.665Z',
        end: '2023-01-28T20:54:41.117Z',
        energyConsumed: 2467,
        connectorId: 1,
        duration: 2126452,
        chargePointSerial: '987',
        totalPowerFactor: 2500,
        currentOffered: 1000,
        totalActivePower: 1000,
      },
    ]);
  }

  public getChargingSessionsByWallboxId(
    wallboxId: string,
    filters?: Filter[],
    sort?: Sort<RemoteWallboxChargingSession>[],
    pagination?: Pagination
  ): Observable<DataWithCount<RemoteWallboxChargingSession>> {
    return this.getAll(filters, sort, pagination);
  }

  public getCurrentChargingSessionsStatusByWallboxId(wallboxId: string): Observable<number> {
    return of(200);
  }

  public getCurrentChargingSessionsStatusByWallboxIdAndClientChargePointId(
    wallboxId: string,
    clientChargePointId: string
  ): Observable<number> {
    return of(200);
  }

  public getCurrentChargingSessionsByWallboxId(wallboxId: string): Observable<RemoteWallboxChargingSession> {
    return of({
      id: '1',
      transactionId: 444005646,
      start: subMinutes(new Date(), 24).toISOString(),
      energyConsumed: 13651000,
      chargePointId: '1',
      duration: 8276392,
      chargePointSerial: '123',
      totalPowerFactor: 1000,
      connectorId: 1,
      totalActivePower: UnitConverter.kiloWattToMilliWatt(3),
      lines: [
        {
          socketPhase: PhaseUsed.L1,
          current: UnitConverter.ampereToMilliAmpere(1),
          voltage: 10,
        },
        {
          socketPhase: PhaseUsed.L2,
          current: UnitConverter.ampereToMilliAmpere(2),
          voltage: 20,
        },
        {
          socketPhase: PhaseUsed.L3,
          current: UnitConverter.ampereToMilliAmpere(3),
          voltage: 30,
        },
      ],
      currentOffered: UnitConverter.ampereToMilliAmpere(25.5),
    });
  }

  public getCurrentChargingSessionsByWallboxIdAndClientChargePointId(
    wallboxId: string,
    clientChargePointId: string
  ): Observable<RemoteWallboxChargingSession> {
    return of({
      id: '1',
      transactionId: 444005646,
      start: subMinutes(new Date(), 24).toISOString(),
      energyConsumed: 13651000,
      chargePointId: wallboxId,
      duration: 8276392,
      chargePointSerial: '123',
      totalPowerFactor: 1000,
      clientChargePointId: clientChargePointId,
      connectorId: 1,
      totalActivePower: UnitConverter.kiloWattToMilliWatt(3),
      lines: [
        {
          socketPhase: PhaseUsed.L1,
          current: UnitConverter.ampereToMilliAmpere(1),
          voltage: 10,
        },
        {
          socketPhase: PhaseUsed.L2,
          current: UnitConverter.ampereToMilliAmpere(2),
          voltage: 20,
        },
        {
          socketPhase: PhaseUsed.L3,
          current: UnitConverter.ampereToMilliAmpere(3),
          voltage: 30,
        },
      ],
      currentOffered: UnitConverter.ampereToMilliAmpere(25.5),
    });
  }

  public startChargingSession(wallboxId: string, connectorId: number): Observable<void> {
    return of(void 0);
  }

  public stopChargingSession(wallboxId: string, transactionId: string): Observable<void> {
    return of(void 0);
  }

  public getAllWithSummary(
    filter?: Filter[],
    sort?: Sort<RemoteWallboxChargingSession>[],
    pagination?: Pagination
  ): Observable<ChargingSessionsSummary> {
    return this.getAll(filter, sort, pagination).pipe(
      map(result => {
        return {
          ...result,
          summary: {
            dateEnd: '2023-02-06T09:33:38.013Z',
            dateStart: '2023-01-20T19:41:15.514Z',
            id: null,
            totalChargedEnergy: 32770930,
            totalChargingDuration: 5776,
            hasMIDorME: true,
            totalActiveTransactions: 0,
          },
        };
      })
    );
  }

  public getAvailableFilterTags(): Observable<string[]> {
    return of(['Test']);
  }

  public getAvailableFilterWallboxes(): Observable<RemoteWallboxChargingSessionWallboxFilter[]> {
    return of([{ id: '123', chargePointSerial: '123', chargePointName: 'Test charge point' }]);
  }

  public getAvailableFilterRfidCardsForSingleWallbox(wallboxId: string): Observable<RemoteChargingSessionRfidCard[]> {
    return of([{ tokenId: '123', tokenOwner: 'Max Mustermann' }]);
  }

  public getAggregated(
    period: DataAggregationPeriod,
    filter?: Filter[]
  ): Observable<{ startDate: string; value: number }[]> {
    const list = [];
    for (let i = 0; i < 100; i++) {
      list.push({ startDate: subDays(new Date(), i).toISOString(), value: Math.round(Math.random() * 100) });
    }

    return this.delayResponse(of(list.reverse()));
  }
}

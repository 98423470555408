<div class="flex items-center justify-center w-screen min-h-screen mt-12">
  <div class="w-full mx-auto md:w-1/2">
    <div class="flex flex-col items-center justify-center p-3 pb-12">
      <div class="flex flex-col items-center w-full p-5 bg-white shadow-2xl rounded-2xl pb-7">
        <div
          accessibility-id="kw-test__kw-common__description-dialog__btn--close"
          class="flex justify-end w-full"
          (click)="cancel()"
        >
          <span class="w-8 h-8 fill-current" inlineSVG="assets/icons/32/close.svg"></span>
        </div>
        <div class="w-full space-y-4 divide-y divide-gray-200 divide-solid">
          <div *ngFor="let element of data.data; let first = first" [class.pt-4]="!first">
            <div
              [ngClass]="{
                'text-iron-500': !data.centeredHeadline,
                'text-center mb-5 text-2xl': data.centeredHeadline
              }"
              class="font-light"
            >
              {{ element.title | translate }}
            </div>
            <div class="font-light whitespace-pre-line" [ngClass]="{ 'text-center': data.centeredBody }">
              <span *ngIf="element.titleBold" class="font-bold">{{ element.titleBold | translate }} </span
              ><span [innerHTML]="element.description | translate"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

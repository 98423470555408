import {
  FeatureFlags,
  FirmwareVersionUtils,
  WallboxConnectionType,
  WallboxCustomVisibilityCheck,
} from '@kw-shared/common';

export type Conditions = conditionGroup | ConditionValuePair<ConditionTypes>;

export interface conditionGroup {
  operator: 'and' | 'or';
  negate?: boolean;
  conditions: Conditions[];
}

export interface ConditionTypes {
  productCode?: RegExp;
  connectionType?: WallboxConnectionType;
  firmwareVersionsBelow?: string;
  firmwareVersionsAfter?: string;
  startsWith?: string;
  customCheck?: WallboxCustomVisibilityCheck;
  featureFlag?: keyof FeatureFlags;
  localRestApiVersion?: VersionConditionString;
  isMasterWallbox?: boolean;
  isClientWallbox?: boolean;
  hasConnectedClients?: boolean;
}

export interface CheckConditionData {
  connectionType?: WallboxConnectionType;
  featureFlags?: FeatureFlags;
  wallboxProductCode?: string;
  wallboxFirmwareVersion?: string;
  wallboxRestApiVersion?: string;
  masterChargePointId?: string;
  connectedClients?: boolean;
  customShouldHide?: (customVisibilityCheck: WallboxCustomVisibilityCheck) => boolean;
}

type VersionConditionString = `<${number}` | `=${number}` | `>${number}`;

type ConditionValuePair<T> = {
  [K in keyof T]: { condition: K; value: T[K] };
}[keyof T];

export abstract class SettingsConditionUtils {
  public static checkIsVisible(data: CheckConditionData, hideConditions: Conditions | undefined): boolean {
    if (!hideConditions) {
      return true;
    }

    const isHidden = this.evaluateConditions(data, hideConditions);
    return !isHidden;
  }

  public static checkIsComingSoon(data: CheckConditionData, comingSoonConditions: Conditions | undefined): boolean {
    if (!comingSoonConditions) {
      return false;
    }

    return this.evaluateComingSoonConditions(data, comingSoonConditions);
  }

  private static evaluateConditions(data: CheckConditionData, hideConditions: Conditions): boolean {
    if ('operator' in hideConditions!) {
      // It's a VisibilityConditionGroup
      const results = hideConditions.conditions.map(cond => this.evaluateConditions(data, cond));
      const aggregatedResult = hideConditions.operator === 'and' ? results.every(Boolean) : results.some(Boolean);

      return hideConditions.negate ? !aggregatedResult : aggregatedResult;
    } else {
      // It's a ConditionValuePair
      return this.checkIsHiddenBasedOnCondition(data, hideConditions);
    }
  }

  private static evaluateComingSoonConditions(data: CheckConditionData, comingSoonConditions: Conditions): boolean {
    if ('operator' in comingSoonConditions!) {
      const results = comingSoonConditions.conditions.map(cond => this.evaluateConditions(data, cond));
      const aggregatedResult = comingSoonConditions.operator === 'and' ? results.every(Boolean) : results.some(Boolean);

      return comingSoonConditions.negate ? !aggregatedResult : aggregatedResult;
    } else {
      return this.checkIsHiddenBasedOnCondition(data, comingSoonConditions);
    }
  }

  private static checkIsHiddenBasedOnCondition(
    data: CheckConditionData,
    hideCondition: ConditionValuePair<ConditionTypes>
  ): boolean {
    if (!hideCondition) {
      return false;
    }

    switch (hideCondition.condition) {
      case 'productCode':
        if (data.wallboxProductCode && hideCondition.value?.test(data.wallboxProductCode)) {
          return true;
        }
        break;

      case 'connectionType':
        if (hideCondition.value === data.connectionType) {
          return true;
        }
        break;

      case 'firmwareVersionsBelow':
        if (
          data.wallboxFirmwareVersion &&
          !FirmwareVersionUtils.isAtLeastVersion(data.wallboxFirmwareVersion, hideCondition.value)
        ) {
          return true;
        }
        break;

      case 'firmwareVersionsAfter':
        if (
          data.wallboxFirmwareVersion &&
          FirmwareVersionUtils.isAtLeastVersion(data.wallboxFirmwareVersion, hideCondition.value)
        ) {
          return true;
        }
        break;

      case 'isMasterWallbox':
        if (data.masterChargePointId === null || data.masterChargePointId === undefined) {
          return true;
        }
        break;

      case 'isClientWallbox':
        if (data.masterChargePointId !== null && data.masterChargePointId !== undefined) {
          return true;
        }
        break;

      case 'hasConnectedClients':
        if (data.connectedClients) {
          return true;
        }
        break;

      case 'startsWith':
        if (
          data.wallboxFirmwareVersion &&
          hideCondition.value &&
          data.wallboxFirmwareVersion.startsWith(hideCondition.value)
        ) {
          return true;
        }
        break;

      case 'featureFlag':
        if (hideCondition.value && data.featureFlags && data.featureFlags[hideCondition.value]) {
          return true;
        }
        break;

      case 'localRestApiVersion': {
        if (
          hideCondition.value &&
          data.wallboxRestApiVersion &&
          data.connectionType === WallboxConnectionType.LOCAL_REST &&
          !this.checkVersionCondition(data.wallboxRestApiVersion, hideCondition.value)
        ) {
          return true;
        }
        break;
      }
      case 'customCheck':
        if (hideCondition.value && data.customShouldHide && data.customShouldHide(hideCondition.value)) {
          return true;
        }
        break;
    }

    return false;
  }

  private static checkVersionCondition(version?: string, condition?: VersionConditionString): boolean {
    if (condition && version) {
      const operator = condition.slice(0, 1);
      const compareVersion = parseFloat(condition.slice(1));
      const usedVersion = parseFloat(version);

      switch (operator) {
        case '>': {
          return usedVersion > compareVersion;
        }
        case '=': {
          return usedVersion === compareVersion;
        }
        case '<': {
          return usedVersion < compareVersion;
        }
      }
    }
    return false;
  }

  public static checkIsReadable(data: CheckConditionData, readableConditions: Conditions | undefined): boolean {
    if (!readableConditions) {
      return true;
    }

    const isReadable = this.evaluateConditions(data, readableConditions);
    return !isReadable;
  }
}

import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { intervalToDuration, isDate } from 'date-fns';
import { TimeUtils, UnitConverter, Utils } from '../utils';
import { BaseFormat } from './interfaces/base-format.interface';

@Pipe({
    name: 'format',
    standalone: false
})
export class FormatPipe implements PipeTransform {
  private readonly locale: string;

  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService
  ) {
    this.locale = translateService.currentLang;
  }

  transform(value: any, format?: BaseFormat, max?: number, ignoreLocale?: boolean): string | number | null {
    if (typeof value === 'undefined' || value === null || value === '') {
      return '';
    }

    if (!format) {
      return value as string;
    }

    switch (format) {
      case BaseFormat.TIME_FROM_NOW: {
        const duration = intervalToDuration({
          start: new Date(value),
          end: new Date(),
        });
        return (
          this.getTimeString(duration.hours) +
          ':' +
          this.getTimeString(duration.minutes) +
          ':' +
          this.getTimeString(duration.seconds)
        );
      }

      case BaseFormat.MW_TO_KW: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          let calculatedValue = UnitConverter.milliWattToKiloWatt(value);
          if (max) {
            calculatedValue = calculatedValue > max ? max : calculatedValue;
          }
          return this.decimalPipe.transform(calculatedValue, '1.1-1', ignoreLocale ? 'en' : this.locale);
        }
        break;
      }

      case BaseFormat.MA_TO_A: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return this.decimalPipe.transform(
            UnitConverter.milliAmpereToAmpere(value),
            '1.1-1',
            ignoreLocale ? 'en' : this.locale
          );
        }
        break;
      }

      case BaseFormat.PERCENTAGE: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return value / 10;
        }
        break;
      }

      case BaseFormat.DURATION_TO_INTERVAL: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          const hours = TimeUtils.millisecondsToHours(value);
          const minutes = TimeUtils.millisecondsToMinutes(value);

          if (!hours && !minutes) {
            return this.translateService.instant('kwa_txt_smaller_than_a_minute');
          }

          let time = '';
          if (hours) {
            time += this.decimalPipe.transform(hours) + ' ';
            if (hours > 1) {
              time += this.translateService.instant('kwa_txt_hours');
            } else {
              time += this.translateService.instant('kwa_txt_hour');
            }
            time += ' ';
          }
          if (minutes) {
            time += this.decimalPipe.transform(minutes) + ' ';
            if (minutes > 1) {
              time += this.translateService.instant('kwa_txt_minutes');
            } else {
              time += this.translateService.instant('kwa_txt_minute');
            }
          }
          return time;
        }
        break;
      }

      case BaseFormat.DURATION_TO_INTERVAL_WITH_DAYS:
        if (typeof value === 'number') {
          let seconds = value / 1000;
          let days = Math.floor(seconds / (60 * 60 * 24));
          let hours = Math.floor((seconds / (60 * 60)) % 24);
          let minutes = Math.floor((seconds / 60) % 60);

          if (!days && !hours && !minutes) {
            return this.translateService.instant('kwa_txt_smaller_than_a_minute');
          }

          let time = '';
          if (days) {
            time += `${days}d `;
          }
          if (hours) {
            time += `${hours}h `;
          }
          if (minutes) {
            time += `${minutes}min `;
          }

          return time;
        }
        break;

      case BaseFormat.MWH_TO_KWH: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          return this.decimalPipe.transform(value / 1000000, '1.1-1', ignoreLocale ? 'en' : this.locale) || '';
        }
        break;
      }

      case BaseFormat.DURATION_TO_INTERVAL_SHORT:
      case BaseFormat.DURATION_TO_INTERVAL_SHORT_WITHOUT_SECONDS: {
        if (typeof value === 'string') {
          value = Number(value);
        }
        if (typeof value === 'number') {
          if (value === 0) {
            return '-';
          }

          const hours = TimeUtils.millisecondsToHours(value);
          const minutes = TimeUtils.millisecondsToMinutes(value);
          let seconds = undefined;
          if (format === BaseFormat.DURATION_TO_INTERVAL_SHORT) {
            seconds = TimeUtils.millisecondsToSeconds(value);
          }

          return [hours, minutes, seconds]
            .filter(Utils.filterNullOrUndefined)
            .map(t => (t < 10 ? '0' + t : t))
            .join(':');
        }
        break;
      }

      case BaseFormat.DATE: {
        if (!isDate(new Date(value))) {
          break;
        }
        return this.datePipe.transform(value, 'short', undefined, this.locale) || '';
      }
    }

    return value as string;
  }

  private getTimeString(value: number | undefined): string {
    if (!value) {
      return '00';
    }

    if (value < 10) {
      return '0' + value;
    }
    return String(value);
  }
}

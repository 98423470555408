import { RemoteWallboxUpdateState } from '../models';

export type RemoteUpdateStateDefinition = {
  color?: 'error' | 'info' | 'gray';
  icon?: 'warning' | 'info' | 'updating';
  border?: boolean;
  label: string;
};

export const updateStateDefinition: {
  [key in RemoteWallboxUpdateState]: RemoteUpdateStateDefinition;
} = {
  [RemoteWallboxUpdateState.LATEST_VERSION]: { label: 'kwp.update-state.latest-version' },
  [RemoteWallboxUpdateState.UPDATE]: { label: 'kwp.update-state.update', color: 'info', icon: 'info', border: true },
  [RemoteWallboxUpdateState.CRITICAL_UPDATE]: {
    label: 'kwp.update-state.critical-update',
    color: 'error',
    icon: 'warning',
    border: true,
  },
  [RemoteWallboxUpdateState.UPDATING]: { label: 'kwp.update-state.updating', color: 'gray', icon: 'updating' },
};

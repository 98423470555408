import { SessionStatus, WallboxChargingSession } from '@kw-shared/common';
import { differenceInMilliseconds } from 'date-fns';
import { BaseModel } from './BaseModel';
import { RemoteChargingSessionRfidCard } from './RemoteChargingSessionRfidCard';

export interface RemoteWallboxChargingSession extends BaseModel {
  start: string;
  end?: string;
  transactionId: number;
  status?: SessionStatus;
  connectorId: number;

  chargePointId?: string;
  chargePointName?: string;
  chargePointSerial?: string;
  clientChargePointId?: string;

  duration?: number;

  tagNames?: string[];
  rfidData?: RemoteChargingSessionRfidCard;

  energyConsumed: number | null;
  totalActivePower: number | null; // mW
  lines?: {
    current: number; // mA
    socketPhase?: string; //L1 | L2 | L3
    voltage: number; // V
  }[];
  currentOffered: number | null; // mA
  totalPowerFactor: number | null; // %, e.g. 1000 = 100%;
  meterStart?: number | null;
  meterEnd?: number | null;
  ocmfMeterStart?: number | null;
  ocmfMeterEnd?: number | null;
  chargePointTransactionType?: ChargePointType | null;

  // TODO: determine if MID, ME or normal wallbox with no metering
  // TODO: add OCMF record
}

export function mapRemoteChargingSession(remoteChargingSession: RemoteWallboxChargingSession): WallboxChargingSession {
  const startDate = new Date(remoteChargingSession.start);
  const endDate = remoteChargingSession.end ? new Date(remoteChargingSession.end) : undefined;
  const status = remoteChargingSession.status ? remoteChargingSession.status : undefined;

  const energyConsumed = remoteChargingSession.energyConsumed
    ? Math.max(remoteChargingSession.energyConsumed ?? 0, 0)
    : null;

  return {
    id: remoteChargingSession.id,
    wallboxSerialNumber: remoteChargingSession.chargePointSerial,
    startDate: startDate,
    endDate: endDate,
    duration: endDate ? differenceInMilliseconds(endDate, startDate) : differenceInMilliseconds(new Date(), startDate),
    status: status,
    hasEnded: endDate != null,
    energyConsumed: energyConsumed,
    tokenId: remoteChargingSession.rfidData?.tokenId,
    meterStart: remoteChargingSession.ocmfMeterStart
      ? remoteChargingSession.ocmfMeterStart * 1000
      : remoteChargingSession.meterStart,
    meterEnd: remoteChargingSession.ocmfMeterEnd
      ? remoteChargingSession.ocmfMeterEnd * 1000
      : remoteChargingSession.meterEnd,
    chargePointType: remoteChargingSession.chargePointTransactionType,
    tokenOwner: remoteChargingSession.rfidData?.tokenOwner,
  };
}

export interface RemoteWallboxChargingSessionWallboxFilter {
  id: string;
  chargePointSerial: string;
  chargePointName?: string;
  chargePointConnectorID?: number;
}

export enum ChargePointType {
  MID = 'MID',
  ME = 'ME',
  NONE = 'NONE',
}

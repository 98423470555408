import { BaseModel } from '@kw-shared/api-data-access';

export interface RemoteChargingReport extends BaseModel {
  name: string;
  accountId?: string;
  description?: string;
  type: RemoteChargingReportDataType;
  language?: RemoteChargingReportLanguage;

  filterRfidCardTokenIds?: string[];
  filterChargepointSerialNumbers?: string[];
  filterTagNames?: string[];

  emailSubject?: string;
  emailAddresses: string[];

  frequency: RemoteChargingReportFrequency;
  dayOfMonthForCustomMonthly: number;

  status: {
    acrPlanId: string;
    hasErrors: boolean;
    maxRetryCountExceeded: boolean;
    retries: number;
    lastExecution: string;
    errorData: {
      transactionsFailed: boolean;
      pdfFailed: boolean;
      csvFailed: boolean;
      mailSendingTraces?: RemoteChargingReportMailSendingTraces[];
    };
  };
}

export interface RemoteChargingReportMailSendingTraces {
  mail: string;
  failed: boolean;
  trace: string;
}

export enum RemoteChargingReportSendingConfig {
  ENDOFMONTH = 'ENDOFMONTH',
  DAYOFMONTH = 'DAYOFMONTH',
}

export enum RemoteChargingReportDataType {
  CSV = 'CSV',
  PDF = 'PDF',
  ALL = 'ALL',
}

export enum RemoteChargingReportFrequency {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum RemoteChargingReportLanguage {
  DE = 'DE',
  EN = 'EN',
  FR = 'FR',
}

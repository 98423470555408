<ng-container *ngIf="isApp; else portalDialog">
  <div class="flex items-center justify-center w-screen min-h-screen">
    <div class="w-full p-3 pb-12 mx-auto md:w-1/2">
      <kw-base-confirm-dialog
        (cancel)="cancel($event)"
        (confirm)="confirm()"
        [cancelButtonText]="data.cancelText"
        [confirmButtonText]="data.confirmText"
        [descriptionClass]="data.descriptionClass"
        [description]="data.description"
        [additionalDescription]="data.additionalDescription"
        [headline]="data.title"
        [iconName]="data.icon"
        [showCancel]="data.showCancel"
        [showConfirm]="data.showConfirm"
        [isMarkdownDescription]="data.isMarkdownDescription"
        cancelButtonClass="button-secondary"
        confirmButtonClass="button-primary"
        containerClass="shadow-2xl rounded-2xl p-5 pb-7"
      ></kw-base-confirm-dialog>
    </div>
  </div>
</ng-container>

<ng-template #portalDialog>
  <kw-base-confirm-dialog
    (cancel)="cancel($event)"
    (confirm)="confirm()"
    [cancelButtonText]="data.cancelText"
    [confirmButtonClass]="'kwp-button-primary ' + (data.showCancel ? '!ml-8' : '')"
    [confirmButtonText]="data.confirmText"
    [descriptionClass]="'mx-16 text-gray-900 max-w-400 ' + (data.descriptionClass ?? '')"
    [description]="data.description"
    [headline]="data.title"
    [iconName]="data.icon"
    [showCancel]="data.showCancel"
    [showConfirm]="data.showConfirm"
    cancelButtonClass="kwp-button-secondary"
    containerClass="rounded p-4 !pb-16"
    iconClass="!mt-4"
    titleClass="max-w-400 text-center"
  ></kw-base-confirm-dialog>
</ng-template>

import { WallboxFirmwareVersionInfo } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { RemoteWallboxFirmwareUpdate } from '../../../../models/RemoteWallboxFirmwareUpdate';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { WebDataRepository } from '../base/WebDataRepository';
import { IUpdatesRepository } from './IUpdatesRepository';

export interface OfflineUpdateDetail {
  location: string;
  description: string;
  version: WallboxFirmwareVersionInfo;
  minimumRequiredVersion: WallboxFirmwareVersionInfo;
}

export class UpdatesRepository extends WebDataRepository<RemoteWallboxFirmwareUpdate> implements IUpdatesRepository {
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath: string
  ) {
    super(httpClient, 'updates');
  }

  public uploadUpdateFile(updateId: string, file: File, type?: string): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    if (type) {
      formData.append('fileType', type);
    }
    return this.httpClient.post({ url: `${this.resource}/upload/${updateId}`, body: formData });
  }

  public startFirmwareUpdate(wallboxId: string, updateVersionId: string): Observable<void> {
    return this.httpClient.put<void>({
      url: `${this.basePath}/${this.resource}/start/${wallboxId}`,
      body: { id: updateVersionId },
    });
  }
}

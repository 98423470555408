import { AdditionalActionCall, FormInputType, SettingsFormData } from '../../components';

export const factoryResetFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: AdditionalActionCall.FACTORY_RESET,
        type: FormInputType.BUTTON_PRIMARY,
        description: 'kwa.settings.factory-reset.button.description',
        title: 'kwa.settings.factory-reset.button.title',
        additionalActionCallType: AdditionalActionCall.FACTORY_RESET,
        accessibilityId: 'factory-reset',
      },
    ],
  },
];

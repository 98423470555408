export abstract class NGUtils {
  /**
   * Use to defer changes to avoid ElementChangedAfterChecked error
   * @param change
   */
  public static deferChange(change: () => void) {
    Promise.resolve(null).then(() => change());
  }

  /**
   * Use to run code after changes are rendered (e.g. elements are revealed by ngIf)
   * @param run
   */
  public static runAfterNextRender(run: () => void) {
    setTimeout(() => run());
  }
}

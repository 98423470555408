<div class="fixed top-0 z-10 w-full kwa-header-navigation {{ headerBackground || 'bg-white' }}">
  <div class="w-full py-5" [ngClass]="{ 'shadow-md ease-in duration-500': verticalOffset > 10 }">
    <div class="relative flex items-center h-8 gap-2 mx-3">
      <div
        class="z-10 flex-none"
        *ngIf="backButtonUrl"
        (click)="back()"
        accessibility-id="kw-test__kw-common__header-navigation__btn--close"
      >
        <div class="flex items-center">
          <span class="w-6 h-6 fill-current" inlineSVG="assets/icons/24/chevron-back.svg"></span>
          <div class="font-light">{{ backButtonTitle || 'kwa_lbl_header_empty_button' | translate }}</div>
        </div>
      </div>
      <div class="flex-1 truncate">
        <ng-container *ngIf="headerImage; else title">
          <img [src]="headerImage" />
          <span class="flex-1"></span>
        </ng-container>
        <ng-template #title>
          <div class="flex items-center flex-1 truncate">
            <span class="truncate">
              {{ headerTitle }}
            </span>
            <span
              *ngIf="infoText"
              class="fill-basil-500"
              inlineSVG="assets/icons/32/info.svg"
              (click)="openInfoText()"
              accessibility-id="kw-test__kw-common__header-navigation__btn--info"
            >
            </span>
          </div>
        </ng-template>
      </div>
      <div class="z-10 flex-none">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

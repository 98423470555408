import { ChargePointType } from '@kw-shared/api-data-access';

export enum SessionStatus {
  INITIATED = 'INITIATED',
  WAITING_AUTHORIZATION = 'WAITING_AUTHORIZATION',
  PWM_CHARGING = 'PWM_CHARGING',
  BLOCKED = 'BLOCKED',
  CLOSED = 'CLOSED',
}

export interface WallboxChargingSession {
  id?: string;
  tokenId?: string;
  wallboxSerialNumber?: string;
  alias?: string;
  status?: SessionStatus;

  startDate: Date;
  endDate?: Date;
  duration: number;
  energyConsumed: number | null;
  hasEnded?: boolean;
  meterStart?: number | null;
  meterEnd?: number | null;
  chargePointType?: ChargePointType | null;
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DescriptionDialogElement } from './interfaces/description-dialog-element.interface';

@Component({
    selector: 'kw-description-dialog',
    templateUrl: './description-dialog.component.html',
    styleUrls: ['./description-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DescriptionDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data?: DescriptionDialogElement[],
      centeredHeadline?: boolean;
      centeredBody?: boolean;
    },
    private mdDialogRef: MatDialogRef<DescriptionDialogComponent>
  ) {}

  ngOnInit(): void {
    this.mdDialogRef.addPanelClass('full-screen');
  }

  public cancel() {
    this.mdDialogRef.close(false);
  }
}

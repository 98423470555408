import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BrowsingCallbackDataAdded,
  CertificateVerificationMode,
  CommunicationChannel,
} from 'apps/keba-wallbox-app/src/plugins/keba-wallbox-plugin';
import { marked } from 'marked';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../apps/keba-wallbox-app/src/app/services/dialog/dialog.service';

export interface FullScreenDialogData {
  icons?: FullScreenDialogIcon[];
  title: string;
  subtitle?: string;
  description?: string;
  isMarkdownDescription?: boolean;
  isMarkdownTitle?: boolean;
  extendedDescription?: string;
  wallboxSerial?: string;
  infoTextTitle?: string;
  infoTextSteps?: string[];
  showClose?: boolean;
  ctaButtons?: CTAButton[];
  variant?: FullScreenDialogVariant;
  links?: LinkType[];
  wallbox?: BrowsingCallbackDataAdded;
}

export type CTAButton = {
  type?: CTAButtonType;
  key: string;
  text: string;
  class?: 'button-primary' | 'button-secondary' | 'link-secondary' | string | string[];
  action?: () => void;
  loading?: boolean;
};

export type LinkType = {
  key: string;
  text: string;
  href: string;
};

export type CTAButtonType = 'confirm' | 'cancel';
export type FullScreenDialogVariant = 'wide' | 'dense';
export type IconSizeType = 32 | 56;

export type FullScreenDialogIcon = {
  name: string;
  color?: 'text-iron-500';
  size?: IconSizeType;
  centered?: boolean;
};

@Component({
    selector: 'kw-full-screen-dialog',
    templateUrl: './full-screen-dialog.component.html',
    styleUrls: ['./full-screen-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FullScreenDialogComponent implements OnInit, OnDestroy {
  private blePairingSuccessSubscription: Subscription;
  private onFullScreenDialogBtnClickSubscription: Subscription;
  private isEnrollmentDialog: boolean;
  public readonly marked = marked;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: FullScreenDialogData,
    private mdDialogRef: MatDialogRef<FullScreenDialogComponent>,
    private dialogService: DialogService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mdDialogRef.addPanelClass('full-screen');
    this.fullScreenDialogBtnClickSubscription();
    this.isEnrollmentDialog = this.mdDialogRef.id.includes('enrollment-dialog');
    this.blePairingSubscription();
  }

  ngOnDestroy(): void {
    // Clean up the subscription
    this.blePairingSuccessSubscription.unsubscribe();
    this.onFullScreenDialogBtnClickSubscription.unsubscribe();
  }

  public onButtonClick(ctaButton: CTAButton): void {
    if (ctaButton.action) {
      ctaButton.action(); // Handle other buttons as before
    } else {
      this.mdDialogRef.close(ctaButton.type || ctaButton.key);
    }
  }

  public onClose(): void {
    this.mdDialogRef.close({ action: 'close' });
  }

  private fullScreenDialogBtnClickSubscription(): void {
    this.onFullScreenDialogBtnClickSubscription = this.dialogService.onFullScreenDialogBtnClick.subscribe(loading => {
      this.data.ctaButtons?.forEach(ctaButton => {
        if (ctaButton.key === 'retryConnection') {
          ctaButton.loading = loading;
          ctaButton.class = loading
            ? ['button-primary', 'kw-progress-spinner-white', 'flex', 'items-center', 'pointer-events-none']
            : 'button-primary';
        }
      });

      this.cdRef.detectChanges();
    });
  }

  private blePairingSubscription(): void {
    this.blePairingSuccessSubscription = this.dialogService.blePairingSuccessful.subscribe(success => {
      if (success) {
        this.dialogService.setWallboxConnected({
          serialNumber: this.data.wallbox?.serialNumber ?? '',
          name: this.data.wallbox?.name ?? '',
          channel: this.data.wallbox?.channel ?? CommunicationChannel.REST,
          address: this.data.wallbox?.address ?? '',
          verificationMode: this.data.wallbox?.verificationMode ?? CertificateVerificationMode.SECURE,
        });
        if (!this.isEnrollmentDialog) {
          this.mdDialogRef.close({ action: 'close', wallbox: this.data.wallbox });
        }
      }
    });
  }
}

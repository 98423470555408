import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, IEnvironment, Utils } from '@kw-shared/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PdfGenerationService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: IEnvironment,
    private readonly translateService: TranslateService
  ) {}

  public generatePdf(template: string, data: object): Observable<Blob | undefined> {
    const pdfGenerationApi = this.environment.pdfGenerationApi;
    if (!pdfGenerationApi) {
      return throwError(() => new Error('PDF generation api is not configured in environment!'));
    }

    const url = Utils.joinPaths(
      pdfGenerationApi.baseUrl,
      this.translateService.currentLang,
      pdfGenerationApi.projectName,
      template
    );
    return this.httpClient
      .request('POST', url, {
        params: { token: pdfGenerationApi.authToken },
        body: data,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map(response => response.body ?? undefined));
  }

  public canExportAsPdf(): boolean {
    return !!this.environment.pdfGenerationApi?.baseUrl;
  }
}

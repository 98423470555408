import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFormat } from '../../pipes';
import { WallboxPVStatus, WallboxPVStatusInfo } from '../../models';

@Component({
  selector: 'kw-pv-boost-button',
  templateUrl: './pv-boost-button.component.html',
  styleUrls: ['./pv-boost-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PvBoostButtonComponent {
  public readonly WallboxPVStatus = WallboxPVStatus;
  public readonly BaseFormat = BaseFormat;

  @Input()
  pvStatusInfo?: WallboxPVStatusInfo;

  @Input()
  disabled?: boolean;

  @Output()
  pvBoostClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}

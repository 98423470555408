import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UIChangeService {
  private subjects: Map<string, BehaviorSubject<{ oldValue: any; newValue: any }>> = new Map();

  triggerChange(key: string, oldValue: any, newValue: any): void {
    if (!this.subjects.has(key)) {
      this.subjects.set(key, new BehaviorSubject<{ oldValue: any; newValue: any }>({ oldValue, newValue }));
    } else {
      this.subjects.get(key)?.next({ oldValue, newValue });
    }
  }

  onChange(key: string): Observable<{ oldValue: any; newValue: any }> {
    if (!this.subjects.has(key)) {
      this.subjects.set(key, new BehaviorSubject<{ oldValue: any; newValue: any }>({ oldValue: null, newValue: null }));
    }
    return this.subjects.get(key)!.asObservable();
  }
}
